defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Components/RetailGameHistoryPayout', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Dictionary',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Api',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/GameHistoryPayout'
], function (m, Utils, Dictionary, RetailApi, GameHistoryPrize) {

  var GameHistoryPrizeComponent = {
    controller: function (args) {
      this.isLoading = m.prop(true);
      this.gameId = m.route.param('gameId');
      this.d = Dictionary.get.bind({ prefix: 'RetailGameHistory/' });
      this.game = m.prop({});
      this.settings = args.settings;
      this.payoutText = m.prop(this.settings.payoutText || '');
      this.customerServiceText = m.prop(this.settings.customerServiceText || '');
      this.hasBankAccount = m.prop(false);
      this.showErrorModal = args.showErrorModal;
      this.hideBackButton = m.prop(false);
      this.showReceipt = m.prop(false);
      this.returnUrl = m.prop(args.returnUrl);

      this.redirectBack = function (e) {
        e.preventDefault();
        window.history.back();
      };

      this.receiptContent = m.prop({
        title: this.d('Payout/Receipt/Title'),
        icon: 'circle_checkmark',
        callback: function () {
          var returnUrl = Utils.getReturnUrl();
          if (returnUrl) {
            window.location.href = returnUrl;
          } else {
            window.location.href = this.returnUrl();
          }
        }.bind(this),
        buttonText: this.d('Payout/Receipt/Button'),
        timeout: 8000,
        init: function () {
          Utils.ensightenEvent('spilhistorik_udbetaling', 'success');
        }
      });

      this.executePayout = function () {
        this.isLoading(true);
        RetailApi.executePayout(this.game().externalId).then(function (response) {
          if (response.status === 'success') {
            this.showReceipt(true);
          } else {
            this.showErrorModal();
          }
        }.bind(this), function (error) {
          this.showErrorModal(error);
        }.bind(this)).then(function () {
          this.isLoading(false);
          m.redraw();
        }.bind(this));
      }.bind(this);

      RetailApi.getGameDetails(this.gameId).then(function (response) {
        document.body.classList.add('is-logged-in');
        var playerGame = response.data;

        this.game({
          id: playerGame.Id,
          hasPayout: playerGame.WinStatus === 'PAID' || playerGame.WinStatus === 'PENDING',
          winStatus: playerGame.WinStatus,
          type: playerGame.Type,
          createdAt: playerGame.CreatedAt,
          channel: playerGame.Channel,
          wager: playerGame.Wager,
          win: parseInt(playerGame.Win, 10),
          externalId: playerGame.ExternalId
        });

        Utils.pushVirtualPage('/payout/' + this.gameId, (window.location.pathname.replace(/-/g, '_') + '/payout/' + this.gameId), '/payout/' + this.gameId);
      }.bind(this), function (error) {
        this.showErrorModal(error);
      }.bind(this)).then(function () {
        this.isLoading(false);
        m.redraw();
      }.bind(this));
    },
    view: function (controller) {
      return GameHistoryPrize(controller);
    }
  };

  return GameHistoryPrizeComponent;

});
