defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Models/PurposeModel', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Mithril/Scripts/Core/Model'
], function (m, Model) {
  'use strict';

  var PurposeModel = Model('PurposeModel', function (purpose, isActive) {
    purpose = purpose || {};
    isActive = isActive || false;

    this.purpose = m.prop(purpose.Purpose || null);
    this.type = m.prop(purpose.Type || null);
    this.header = m.prop(purpose.Header || null);
    this.description = m.prop(purpose.Description || null);
    this.identifier = m.prop(purpose.Identifier || null);
    this.isRequired = m.prop(purpose.MandatoryIfPresent || false);
    this.isVisible = m.prop(purpose.VisibleInDropdown);
    this.isActive = m.prop(isActive);
  });

  return PurposeModel;
});
