defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Intro', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button'
], function (m, StepHeader, Button) {

  var Intro = function (controller) {
    let dictionaryKey = '';

    if (!controller.model().fields.pincode() && controller.model().fields.offlineWagerLimit()) {
      dictionaryKey = 'Variant1/';
    } else if (controller.model().fields.isRetailAccount()) {
      if (!controller.model().fields.pincode() && controller.model().fields.hasOldOfflineWagerLimit()) {
        dictionaryKey = 'Variant4/';
      } else if (!controller.model().fields.pincode()) {
        dictionaryKey = 'Variant3/';
      } else {
        dictionaryKey = 'Variant2/';
      }
    } else {
      if (!controller.model().fields.pincode()) {
        dictionaryKey = 'Variant6/';
      } else {
        dictionaryKey = 'Variant5/';
      }
    }


    return m('div', {
      class: 'retail-page__content retail-page__no-progress'
    },
    m('.retail-page__row', [
      m('div', {
        class: 'retail-page__column retail-page__column--1'
      }, [
        StepHeader({
          title: controller.d(`Intro/${dictionaryKey}PageTitle`),
          abstract: controller.d(`Intro/${dictionaryKey}PageDescription`)
        })
      ]
      ),
      m('div', {
        class: 'retail-page__column retail-page__column--2'
      }, [
        m('.retail-button-container.retail-button-container--centered',
          Button({
            buttonText: controller.d(`Intro/${dictionaryKey}CTA`),
            onclick: function () {
              controller.routeToNextStep(null, true);
            }
          })
        )
      ])
    ])
    );
  };

  return Intro;

});
