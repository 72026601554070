defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/PNumber', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ProgressBar',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InputField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Checkbox',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, Utils, ProgressBar, BackButton, InputField, Checkbox, Button, StepHeader) {

  var StepPNumber = function (controller) {
    var userModel = controller.model().fields;
    var errors = controller.model().errors;
    var pNumber = controller.model().fields.cprNumber;

    this.isInvalid = function () {
      errors.cprNumber(controller.d('PNumber/InvalidPNumber'));
    };

    this.onKeyup = function (event) {
      var newValue = event.target.value.replace(/\D/g, '');
      if (newValue.length > 0) {
        if (newValue.length > 9) {
          controller.validateField(event);
        } else {
          controller.buttonIsDisabled(true);
        }
      } else {
        pNumber(null);
      }
    }.bind(this);


    this.onFocus = function (event) {
      controller.setFieldFocus(event);
      var newValue = event.target.value.replace(/\D/g, '');
      if (newValue.length > 0) {
        errors.cprNumber(null);
        pNumber(newValue);
      }
    };

    this.validatePNumber = function (pNumber) {
      pNumber = pNumber.replace(/\D/g, '');
      // pNumber validates if the first 6 digits is an actually birthday, taking leap year and other special dates into consideration. The 3 remaining characters just has to be digits.
      var pRegEx = /^(((0[1-9]|[12][0-9]|30)(0[13-9]|1[012])|31(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])02)[0-9]{2}|2902((([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))(-|\s)?\d{3}/;
      return pRegEx.test(pNumber);
    };

    this.onBlur = function (event) {
      var newValue = event.target.value.replace(/\D/g, '');

      if (newValue.length > 0) {
        pNumber(Utils.formatFaroeseId(newValue));
      }
      if (this.validatePNumber(newValue)) {
        controller.validateField(event);
      } else {
        this.isInvalid();
      }

      if (controller.fieldInFocus() === event.target.name) {
        controller.fieldInFocus(null);
      }
    }.bind(this);

    return [
      controller.progress ?
        ProgressBar({
          progress: controller.progress()
        }) : null,
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton()) : null,
      m('.retail-page__content',
        m('.retail-page__row has-rows-2', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, StepHeader({
            title: controller.d('PNumber/PageTitle'),
            abstract: controller.d('PNumber/PageDescription')
          })
          ),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, [
            InputField({
              model: userModel.cprNumber,
              onkeyup: this.onKeyup,
              onblur: this.onBlur,
              onfocus: this.onFocus,
              hasErrors: errors.cprNumber(),
              hasFocus: controller.fieldInFocus(),
              name: 'cprNumber',
              forceNumeric: true,
              type: 'text',
              labelText: controller.d('PNumber/LabelPNumber'),
              helpText: controller.d('PNumber/HelpTextPNumber')
            }),
            Checkbox({
              model: userModel.acceptTermsAndConditions,
              labelText: controller.d('PNumber/LabelTermsAndConditions'),
              name: 'acceptTermsAndConditions',
              onchange: controller.validateField,
              hasErrors: errors.acceptTermsAndConditions()
            }),
            m('div.retail-button-container.retail-button-container--centered',
              Button({
                isDisabled: controller.buttonIsDisabled(),
                isLoading: controller.isSubmitting(),
                buttonText: controller.d('PNumber/SubmitButton'),
                onclick: function () {
                  if (userModel.cprNumber() && userModel.cprNumber().length === 10 && this.validatePNumber(userModel.cprNumber())) {
                    controller.submit();
                  } else {
                    this.isInvalid();
                  }
                }.bind(this)
              })
            )
          ])
        ])
      )
    ];
  };

  return StepPNumber;

});
