defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Components/RetailGameHistoryOverview', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Dictionary',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Api',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/GameHistoryOverview',
], (m, Dictionary, RetailApi, GameHistoryOverview) => {

  const GameHistoryOverviewComponent = {
    controller: function (args) {
      this.d = Dictionary.get.bind({ prefix: 'RetailGameHistory/' });
      this.isLoading = m.prop(true);
      this.settings = args.settings;
      this.isWebview = m.prop(window.dsApplicationConfig);
      this.totalAmount = m.prop();
      this.currentAmount = m.prop();
      this.limitType = m.prop();

      const activeRightMenuSelector = document.querySelector('.is-showing-right-menu');

      if (activeRightMenuSelector) {
        activeRightMenuSelector.classList.remove('is-showing-right-menu');
        document.querySelector('.mobile-user-info-inner-wrapper').classList.remove('overlay-active');
        document.querySelector('.page-area').classList.remove('modal-wrapper', 'overlay-active');
      }

      const init = () => {
        RetailApi.getMostRestrictiveLimit().then((response) => {
          const limit = response.data;
          const totalAmount = Math.min(limit.Value, limit.Max);
          const currentAmount = totalAmount - limit.RemainingValue;
          this.totalAmount(totalAmount);
          this.currentAmount(currentAmount);
          this.limitType(limit.Type);
          this.isLoading(false);
          m.redraw();
        });
      };

      init();
    },
    view: function (controller) {
      return GameHistoryOverview(controller);
    }
  };

  return GameHistoryOverviewComponent;
});
