defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/GameHistoryPrize', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Mithril/Scripts/Helpers/Render',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Fireworks',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Loader'
], function (m, Render, Utils, Button, Fireworks, BackButton, Loader) {

  var GameHistoryPrize = function (controller) {
    if (controller.isLoading()) {
      return Loader();
    }

    var render = Render();

    render.ready.then(function () {
      var storelocator = document.querySelector('.js-shape-storelocator');
      if (storelocator && storelocator.href) {
        // If we find and js anchor point and the page is loaded with dsApplicationId, then replace href.
        if (controller.isWebview()) {
          storelocator.href = 'app://storelocator';
        } else {
          // otherwise presume we are on desktop with no storelocator feature enabled. Hence strip the a tag.
          var span = document.createElement('span');
          var innerHTML = storelocator.innerHTML;
          var spanText = document.createTextNode(innerHTML);
          span.appendChild(spanText);
          storelocator.parentNode.replaceChild(span, storelocator);
        }
      }
    });

    return m('.retail-page', { config: render.depend('container') }, [
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton()) : null,
      m('div', {
        class: [
          'retail-page__content',
          controller.game().hasPayout ? 'has-payout' : null
        ].join(' ')
      }, [
        m('.retail-prize__container', [
          Fireworks({
            title: controller.d('Prize/PrizeTitle'),
            amount: Utils.convertCentsToKronerAndFormat(controller.game().win)
          }),
          m('.retail-rich-text', [
            controller.game().winStatus === 'PENDING' ?
              [
                m('strong', controller.d('Prize/PayoutPossibilities/Title')),
                m('.retail-payout-possibilities__self-title', controller.d('Prize/PayoutPossibilities/SelfServiceTitle')),
                m('.retail-payout-possibilities__self-abstract', controller.d('Prize/PayoutPossibilities/SelfServiceAbstract')),
                m('.retail-button-container.retail-button-container--centered',
                  Button({
                    class: 'retail-payout__button',
                    buttonText: controller.d('Prize/PayoutPossibilities/SelfServiceButtonLabel'),
                    onclick: function () {
                      m.route('/payout/' + controller.game().id);
                    }
                  }),
                ),
                m('.retail-account-separator', {
                  'data-text': controller.d('Prize/PayoutPossibilities/SeparatorText')
                }),
                m('.retail-payout-possibilities__dealer-text', m.trust(controller.dealerText())),
                m('.retail-payout-possibilities__customer-service-text', m.trust(controller.customerServiceText()))
              ] : null
          ])
        ])
      ])
    ]);
  };

  return GameHistoryPrize;

});
