defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/ListField', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InputField',
], function (m, InputField) {

  var ListField = function (options) {
    return m('.retail-list-container', [
      m('.retail-list-field', [
        InputField({
          model: options.model,
          type: options.type,
          name: options.name,
          id: options.id,
          disabled: options.disabled,
          onkeyup: options.onkeyup,
          onfocus: options.onfocus,
          onblur: function (event) {
            setTimeout(function () {
              if (options.fieldInFocus() === options.name) {
                options.fieldInFocus(null); // reset focus selection
                options.validateField(event);
                m.redraw();
              }
            }, 250);
          },
          labelText: options.labelText,
          helpText: options.helpText,
          forceNumeric: options.forceNumeric,
          hasErrors: options.hasErrors,
          hasFocus: options.hasFocus
        }),
        options.list().length ? m('div', {
          class: [
            'retail-list__items',
            options.fieldInFocus() && options.fieldInFocus().indexOf(options.name) === 0 ? 'retail-list__items--visible' : ''
          ].join(' ')
        }, options.list().map(function (item, index) {
          return m('button', {
            type: 'button',
            name: options.name + '-button--' + index,
            class: 'retail-list__item',
            'data-hover-text': item.value + '-item-button',
            onkeyup: options.onkeyevent,
            onblur: function () {
              setTimeout(function () {
                if (document.activeElement.name === undefined || document.activeElement.name.indexOf(options.name) !== 0) {
                  options.fieldInFocus(null); // reset focus selection
                  m.redraw();
                }
              }, 250);
            },
            onclick: function (e) {
              e.preventDefault();
              options.setSelected(item.value);
            }
          }, item.value);
        })) : null
      ])
    ]);
  };

  return ListField;

});
