defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/IconButton', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Framework/PlayerAccountManagement/Scripts/Templates/Svg'
], function (m, Utils, Svg) {

  var IconButton = function (config) {
    var options = {
      icon: 'arrow_left',
      onclick: null,
      class: null,
      ...config
    };

    return m('button', {
      class: [
        'retail-icon-button',
        options.class
      ].join(' '),
      type: 'button',
      onclick: function (e) {
        if (options.onclick) {
          options.onclick(e);
        } else {
          var pushVirtualPageCallback = function () {
            Utils.pushVirtualPage(window.location.hash, (window.location.pathname.replace(/-/g, '_') + window.location.hash), window.location.hash);
            window.removeEventListener('popstate', pushVirtualPageCallback);
          };
          window.addEventListener('popstate', pushVirtualPageCallback);
          window.history.back();
        }
      },
      title: options.title || ''
    }, Svg(options.icon, {
      class: 'retail-icon-button__icon'
    }));
  };

  return IconButton;

});
