defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Password', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ProgressBar',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InputField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, ProgressBar, BackButton, InputField, Button, StepHeader) {

  var StepPassword = function (controller) {
    var userModel = controller.model().fields;
    var errors = controller.model().errors;
    var dictionaryKey = 'Password/';

    if (controller.createUsernameFlow()) {
      dictionaryKey = 'Username/Password/';
    }

    controller.verifyUsername();

    return m('div', [
      controller.progress ?
        ProgressBar({
          progress: controller.progress()
        }) : null,
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton()) : null,
      m('.retail-page__content',
        m('.retail-page__row has-rows-2', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, StepHeader({
            title: controller.d(dictionaryKey + 'PageTitle'),
            abstract: controller.d(dictionaryKey + 'PageDescription')
          })
          ),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, [
            !userModel.nemIdSignedIn() ?
              InputField({
                model: userModel.currentPassword,
                onfocus: controller.setFieldFocus,
                onkeyup: controller.validateField,
                hasErrors: errors.currentPassword(),
                hasFocus: controller.fieldInFocus(),
                name: 'currentPassword',
                type: 'password',
                labelText: controller.d('Password/LabelCurrentPassword')
              })
              : null,
            InputField({
              model: userModel.password,
              onfocus: controller.setFieldFocus,
              onkeyup: controller.validateField,
              hasErrors: errors.password(),
              hasFocus: controller.fieldInFocus(),
              name: 'password',
              type: 'password',
              labelText: controller.d('Password/LabelPassword'),
              helpText: controller.d('Password/HelpTextPassword')
            }),
            InputField({
              model: userModel.repeatPassword,
              onfocus: controller.setFieldFocus,
              onkeyup: controller.validateField,
              hasErrors: errors.repeatPassword(),
              hasFocus: controller.fieldInFocus(),
              name: 'repeatPassword',
              type: 'password',
              labelText: controller.d('Password/LabelPasswordConfirmation'),
              helpText: controller.d('Password/HelpTextPasswordConfirmation')
            }),
            m('div.retail-button-container.retail-button-container--centered',
              Button({
                isDisabled: controller.buttonIsDisabled(),
                isLoading: controller.isSubmitting(),
                buttonText: controller.d(dictionaryKey + 'SubmitButton'),
                onclick: controller.submit
              })
            )
          ])
        ])
      )
    ]);
  };

  return StepPassword;

});
