defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Loader', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril'
], function (m) {

  var Loader = function (config) {
    var options = {
      loaderText: null,
      ...config
    };

    return m('div', {
      class: 'retail-loader-container'
    }, [
      m('svg.retail-loader', {
        viewBox: '0 0 50 50'
      }, m('circle.path', {
        cx: '25',
        cy: '25',
        r: '20',
        fill: 'none',
        'stroke-width': '3'
      })),
      options.loaderText ? m('span.retail-loader__text', options.loaderText) : null
    ]);
  };

  return Loader;

});
