defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/ReactivatePlaycard', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, BackButton, Button, StepHeader) {

  var StepReoctivatePlaycard = function (controller) {
    return m('', [
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton()) : null,
      m('.retail-page__content',
        m('.retail-page__row has-rows-2', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, StepHeader({
            title: controller.d('ReactivatePlaycard/PageTitle'),
            abstract: controller.d('ReactivatePlaycard/PageDescription')
          })
          ),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, [
            m('.retail-rich-text', [
              m('.retail-rich-text__title', controller.d('ReactivatePlaycard/ChoiceTitle')),
            ]),
            m('.retail-button-container.retail-button-container--centered',
              Button({
                isLoading: controller.isSubmitting(),
                buttonText: controller.d('ReactivatePlaycard/SubmitButton'),
                onclick: () => {
                  controller.reactivatePlaycardWithNewPincode();
                }
              })
            ),
            m('.retail-button-container.retail-button-container--centered',
              Button({
                class: 'retail-button--white',
                buttonText: controller.d('ReactivatePlaycard/CancelButton'),
                onclick: () => {
                  controller.reactivatePlaycard(true);
                }
              })
            )
          ])
        ])
      )
    ]);
  };

  return StepReoctivatePlaycard;

});
