defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Models/LimitModel', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Mithril/Scripts/Core/Model',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Dictionary',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils'
], function (m, Model, Dictionary, Utils) {
  'use strict';

  var computeState = function (value, plannedAt, plannedValue) {
    if (plannedAt && !plannedValue) {
      return 'delete';
    } else if (value && !(plannedAt && plannedValue)) {
      return 'active';
    } else if (plannedAt && plannedValue) {
      return 'change';
    } else {
      return 'unset';
    }
  };

  var LimitModel = Model('LimitModel', function (limit) {
    limit = limit ? limit : {};
    limit.Value = limit.IsGlobal ? 0 : limit.Value;
    this.d = Dictionary.get.bind({ prefix: 'RetailProfile/WagerLimits/' });
    this.type = m.prop(limit.Type ? Utils.capitalize(limit.Type) : null);
    this.value = m.prop(limit.Value ? limit.Value / 100 : null);
    this.formatted = m.prop(limit.Value ? Utils.formatCurrency(limit.Value / 100) : null);
    this.stored =  m.prop(limit.Value ? limit.Value / 100 : null); // In order to manipulate limit value we need a reference to the server side value
    this.isGlobal = m.prop(limit.IsGlobal || false);
    this.isOperator = m.prop(limit.IsOperator || false);
    this.state = m.prop(computeState(limit.Value, limit.PlannedAt, limit.PlannedValue));
    this.max = m.prop(limit.Max ? limit.Max / 100 : null);
    this.maxFormatted = m.prop(limit.Max ? Utils.formatCurrency(limit.Max / 100) : null);
    this.plannedAt = m.prop(limit.PlannedAt);
    this.plannedValue = m.prop(limit.PlannedValue ? limit.PlannedValue / 100 : null);
    this.hasFocus = m.prop(false);
    this.hasErrors = m.prop([]);

    this.toJSON = function () {
      return {
        type: this.type(),
        value: this.value(),
        formatted: this.formatted(),
        stored: this.stored(),
        isGlobal: this.isGlobal(),
        isOperator: this.isOperator(),
        state: this.state(),
        plannedAt: this.plannedAt(),
        plannedValue: this.plannedValue(),
        errors: this.hasErrors()
      };
    };

    this.reset = function () {
      this.value(this.stored());
      this.formatted(this.stored() ? Utils.formatCurrency(this.stored()) : '');
      this.state(computeState(this.value(), this.plannedAt(), this.plannedValue()));
    };

    this.error = function (key, options) {
      options = options || {};
      var error = this.d('Errors/' + key, options);
      error = typeof error === 'string' ? error : key + ':MissingDictionary';
      this.hasErrors().push(error);
    }.bind(this);

    this.prettyType = function () {
      var type = this.d('Errors/Type/' + this.type());
      return typeof type === 'string' ? type : ':UnknownType';
    };

  });

  return LimitModel;
});
