defineDs('DanskeSpil/Domain/AvalonComponents/Scripts/Components/PageNavHeader',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'Shared/Framework/Mithril/Scripts/Helpers/Utils'
  ],
  function (Component, Utils) {

    Component('page-nav-header', function (m, route) {

      var elem = this.$element; // eslint-disable-line no-jquery/variable-pattern -- Reason: Not a jquery element

      var root = {

        controller: function () {
          // Detect all target elements on the page
          var targets = document.querySelectorAll('.js-page-nav-header-target');
          // Convert them to a real array
          this.targetsArray = [].slice.call(targets);

          /**
           * Scroll the page to the target of the clicked element
           */
          this.scrollPage = function scrollPage(event) {
            event.preventDefault();

            var scrollTarget = event.target.getAttribute('href');
            var currentScrollTop = document.documentElement.scrollTop;

            // set an offset if we are scrolling up on the page, to accomodate the height of the header
            var offset = currentScrollTop > $DS(scrollTarget).offset().top ? $DS('.top-navigation').height() : 0;
            var scrollTopValue = $DS(scrollTarget).offset().top - offset;

            $DS([document.documentElement, document.body]).animate({
              scrollTop: scrollTopValue
            }, 500);
          }.bind(this);

          this.pageIsReady = function pageIsReady() {
            // Set class to show the nav bar
            elem.classList.add('is-ready');

            // Clean up event listener
            document.removeEventListener('DOMContentLoaded', this.pageIsReady, false);

          }.bind(this);

          /**
           * Add height fix to the header, if it is not already there
           */
          this.addHeaderHeightFix = function addHeaderHeightFix() {

            if (!document.querySelector('.page-nav-header-static-fix')) {
              var insertTarget = document.querySelector('.container .header-static-fix');
              insertTarget.insertAdjacentHTML('afterend', '<div class="page-nav-header-static-fix"></div>');
            }
          };

          // Setup event listener
          document.addEventListener('DOMContentLoaded', this.pageIsReady.bind(this), false);
          this.addHeaderHeightFix();

        },

        view: function (ctrl) {
          return m('.page-nav-header__content', [
            // Build items
            ctrl.targetsArray.map(function (item) {
              return m('a.page-nav-header__item', {
                href: item.getAttribute('data-nav-UrlLink') === 'True' ? item.id : '#' + item.id,
                onclick: item.getAttribute('data-nav-UrlLink') === 'True' ? '' : ctrl.scrollPage
              }, item.getAttribute('data-nav-name'));
            })

          ]);
        }

      };

      // route
      route('/', root);

      Utils.logDeprecated('PageNavHeader.js - component loaded'); // Added by MIPE 2024-05-14
    });

  });
