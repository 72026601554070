defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Name', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ProgressBar',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InputField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, ProgressBar, BackButton, InputField, Button, StepHeader) {

  var StepName = function (controller) {
    var userModel = controller.model().fields;
    var errors = controller.model().errors;

    return [
      controller.progress ?
        ProgressBar({
          progress: controller.progress()
        }) : null,
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton({
          onclick: controller.routeToPrevStep ? controller.routeToPrevStep : null
        })) : null,
      m('.retail-page__content',
        m('.retail-page__row has-rows-2', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, StepHeader({
            title: controller.d('Name/PageTitle'),
            abstract: controller.d('Name/PageDescription')
          })
          ),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, controller.model().nameIsSet() ? m('i', {
            class: 'info-message'
          }, controller.d('Name/NameAlreadyValidated')) : [
            InputField({
              model: userModel.firstName,
              onkeyup: controller.validateField,
              hasErrors: errors.firstName(),
              name: 'firstName',
              labelText: controller.d('Name/LabelFirstName')
            }),
            InputField({
              model: userModel.lastName,
              onkeyup: controller.validateField,
              hasErrors: errors.lastName(),
              name: 'lastName',
              labelText: controller.d('Name/LabelLastName')
            }),
            m('div.retail-button-container.retail-button-container--centered',
              Button({
                isDisabled: controller.buttonIsDisabled(),
                isLoading: controller.isSubmitting(),
                buttonText: controller.d('Name/SubmitButton'),
                onclick: controller.submit
              })
            )
          ])
        ])
      )
    ];
  };

  return StepName;

});
