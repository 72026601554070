defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Email', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ProgressBar',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InputField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Checkbox',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, ProgressBar, BackButton, InputField, Checkbox, Button, StepHeader) {

  var StepEmail = function (controller) {
    var userModel = controller.model().fields;
    var errors = controller.model().errors;
    var showRetailMarketingTerms = controller.hasOwnProperty('showRetailMarketingTermsInEmail') && controller.showRetailMarketingTermsInEmail();
    var showEmailPermissionTermsInEmail = controller.hasOwnProperty('showEmailPermissionTermsInEmail') && controller.showEmailPermissionTermsInEmail();
    var showAppPermissionTerms = controller.hasOwnProperty('showAppPermissionTerms') && controller.showAppPermissionTerms();

    return [
      controller.progress ?
        ProgressBar({
          progress: controller.progress()
        }) : null,
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton({
          onclick: controller.routeToPrevStep ? controller.routeToPrevStep : null
        })) : null,
      m('.retail-page__content',
        m('.retail-page__row has-rows-2', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, StepHeader({
            title: controller.d('Email/PageTitle'),
            abstract: controller.d('Email/PageDescription')
          })
          ),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, [
            InputField({
              model: userModel.email,
              onkeyup: controller.validateField,
              hasErrors: errors.email(),
              name: 'email',
              type: 'email',
              labelText: controller.d('Email/LabelEmail')
            }),
            showRetailMarketingTerms ?
              Checkbox({
                model: userModel.marketingTerms,
                name: 'marketingTerms',
                labelText: controller.d('Email/LabelMarketingTerms'),
              }) : null,
            showEmailPermissionTermsInEmail ?
              Checkbox({
                model: userModel.emailPermission,
                name: 'showEmailPermissionTermsInEmail',
                labelText: controller.d('Email/LabelOnlineMarketingTerms'),
              }) : null,
            showAppPermissionTerms ?
              Checkbox({
                model: userModel.appPermission,
                name: 'appPermission',
                labelText: controller.d('Email/LabelPushPermissionsTerms'),
              }) : null,
            m('div.retail-button-container.retail-button-container--centered',
              Button({
                isDisabled: controller.buttonIsDisabled(),
                isLoading: controller.isSubmitting(),
                buttonText: controller.d('Email/SubmitButton'),
                onclick: controller.submit
              })
            )
          ])
        ])
      )
    ];
  };

  return StepEmail;

});