defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/ForgotPassword', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InputField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, InputField, BackButton, Button, StepHeader) {

  var StepForgotPassword = function (controller) {
    var userModel = controller.model().fields;
    var errors = controller.model().errors;

    return m('div', [
      m('.retail-page__button-container', BackButton()),
      m('.retail-page__content',
        controller.tokenIsValid() ?
          m('.retail-page__row has-rows-2', [
            m('.retail-page__column retail-page__column--1', StepHeader({
              title: controller.d('Password/PageTitle'),
              abstract: controller.d('Password/PageDescription')
            })
            ),
            m('.retail-page__column retail-page__column--2', [
              InputField({
                model: userModel.password,
                onfocus: controller.setFieldFocus,
                onkeyup: controller.validateField,
                hasErrors: errors.password(),
                hasFocus: controller.fieldInFocus(),
                name: 'password',
                type: 'password',
                labelText: controller.d('Password/LabelPassword'),
                helpText: controller.d('Password/HelpTextPassword')
              }),
              InputField({
                model: userModel.repeatPassword,
                onfocus: controller.setFieldFocus,
                onkeyup: controller.validateField,
                hasErrors: errors.repeatPassword(),
                hasFocus: controller.fieldInFocus(),
                name: 'repeatPassword',
                type: 'password',
                labelText: controller.d('Password/LabelPasswordConfirmation'),
                helpText: controller.d('Password/HelpTextPasswordConfirmation')
              }),
              Button({
                isDisabled: controller.buttonIsDisabled(),
                isLoading: controller.isSubmitting(),
                buttonText: controller.d('Password/SubmitButton'),
                onclick: controller.submit
              })
            ])
          ]) : // Token is not valid, lets show general page
          m('.retail-page__row has-rows-2', [
            m('.retail-page__column retail-page__column--1', StepHeader({
              title: controller.d('Lost/PageTitle'),
              abstract: controller.isApp() ? controller.d('Lost/PageDescriptionAppDetected') : controller.d('Lost/PageDescription')
            })
            ),
            m('.retail-page__column retail-page__column--2', [
              Button({
                isLoading: controller.isSubmitting(),
                buttonText: controller.d('Lost/SubmitButton'),
                onclick: function () {
                  window.location.href = controller.isApp() ? controller.appLoginLink() : controller.loginLink();
                }
              }),
              m('.retail-rich-text retail-rich-text--subtext', m.trust(controller.d('Lost/SubtextDescription'))),
            ])
          ])
      )
    ]);
  };

  return StepForgotPassword;

});
