defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/PersonalInfo', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Framework/PlayerAccountManagement/Scripts/Templates/StepGuide',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InputField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ListField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Checkbox',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Dropdown',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, StepGuide, InputField, ListField, Button, Checkbox, Dropdown, StepHeader) {

  var StepPersonalInfo = function (controller) {
    var userModel = controller.model().fields;
    var errors = controller.model().errors;

    return m('div', [
      StepGuide(3, controller.totalSteps()),
      m('.pam-page__content', [
        m('div', {
          class: 'pam-page__header'
        }, StepHeader({
          title: controller.d('PersonalInfo/PageTitle'),
          abstract: controller.d('PersonalInfo/PageDescription')
        })
        ),
        InputField({
          model: userModel.firstName,
          onfocus: controller.setFieldFocus,
          onblur: controller.validateField,
          hasErrors: errors.firstName(),
          hasFocus: controller.fieldInFocus(),
          name: 'firstName',
          labelText: controller.d('PersonalInfo/Name/LabelFirstName'),
          helpText: controller.d('PersonalInfo/Name/HelpTextFirstName')
        }),
        InputField({
          model: userModel.lastName,
          onfocus: controller.setFieldFocus,
          onblur: controller.validateField,
          hasErrors: errors.lastName(),
          hasFocus: controller.fieldInFocus(),
          name: 'lastName',
          labelText: controller.d('PersonalInfo/Name/LabelLastName'),
          helpText: controller.d('PersonalInfo/Name/HelpTextLastName')
        }),

        m('.fields', [
          ListField({
            model: userModel.street,
            onfocus: controller.setFieldFocus,
            onkeyup: controller.lookupAddress,
            hasErrors: errors.street(),
            hasFocus: controller.fieldInFocus(),
            fieldInFocus: controller.fieldInFocus,
            validateField: controller.validateField,
            setSelected: function (value) {
              controller.selectAddressFromSelection(value);
            },
            onkeyevent: function (event) {
              controller.fieldInFocus(event.target.name);
              if (event.key === 'Enter') {
                controller.selectAddressFromSelection(event.target.innerText);
              }
            },
            name: 'street',
            labelText: controller.d('PersonalInfo/Address/LabelStreet'),
            helpText: controller.d('PersonalInfo/Address/HelpTextStreet'),
            list: controller.addresses
          }),
          m('.fields-row', [
            InputField({
              model: userModel.houseNumber,
              onfocus: controller.setFieldFocus,
              onblur: controller.validateField,
              hasErrors: errors.houseNumber(),
              hasFocus: controller.fieldInFocus(),
              name: 'houseNumber',
              labelText: controller.d('PersonalInfo/Address/LabelHouseNumber'),
              helpText: controller.d('PersonalInfo/Address/HelpTextHouseNumber')
            }),
            InputField({
              model: userModel.level,
              onfocus: controller.setFieldFocus,
              hasErrors: errors.level(),
              hasFocus: controller.fieldInFocus(),
              name: 'level',
              labelText: controller.d('PersonalInfo/Address/LabelLevel'),
              helpText: controller.d('PersonalInfo/Address/HelpTextLevel')
            })
          ]),
          m('.fields-row', [
            ListField({
              model: userModel.zipCode,
              onfocus: controller.setFieldFocus,
              onkeyup: controller.lookupZip,
              hasErrors: errors.zipCode(),
              hasFocus: controller.fieldInFocus(),
              fieldInFocus: controller.fieldInFocus,
              validateField: controller.validateField,
              setSelected: function (value) {
                userModel.zipCode(value);
                controller.selectCityFromZip(value);
                controller.zips([]);
              },
              onkeyevent: function (event) {
                controller.fieldInFocus(event.target.name);
                if (event.key === 'Enter') {
                  userModel.zipCode(event.target.innerText);
                  controller.selectCityFromZip(event.target.innerText);
                  controller.fieldInFocus(null);
                }
              },
              name: 'zipCode',
              type: 'number',
              labelText: controller.d('PersonalInfo/Address/LabelZipCode'),
              helpText: controller.d('PersonalInfo/Address/HelpTextZipCode'),
              list: controller.zips
            }),
            ListField({
              model: userModel.city,
              onfocus: controller.setFieldFocus,
              onkeyup: controller.lookupCity,
              hasErrors: errors.city(),
              hasFocus: controller.fieldInFocus(),
              fieldInFocus: controller.fieldInFocus,
              validateField: controller.validateField,
              setSelected: function (value) {
                userModel.city(value);
                controller.cities([]);
              },
              onkeyevent: function (event) {
                controller.fieldInFocus(event.target.name);
                if (event.key === 'Enter') {
                  userModel.city(event.target.innerText);
                  controller.fieldInFocus(null);
                }
              },
              name: 'city',
              labelText: controller.d('PersonalInfo/Address/LabelCity'),
              helpText: controller.d('PersonalInfo/Address/HelpTextCity'),
              list: controller.cities
            })
          ]),
          Dropdown({
            model: userModel.country,
            choices: controller.model().availableCountries(),
            hasErrors: errors.country(),
            name: 'country',
            labelText: controller.d('PersonalInfo/Address/LabelCountry')
          })
        ]),
        Checkbox({
          model: userModel.acceptTermsAndConditions,
          labelText: controller.d('PersonalInfo/TermsAndConditions/LabelTermsAndConditions'),
          name: 'acceptTermsAndConditions',
          onchange: controller.validateField,
          hasErrors: errors.acceptTermsAndConditions()
        }),
        controller.initialValues().fields.acceptDataProcessing() ? null : Checkbox({
          model: userModel.acceptDataProcessing,
          labelText: controller.d('PersonalInfo/GameScanner/LabelGameScannerTerms'),
          name: 'acceptDataProcessing',
          onchange: controller.validateField,
          hasErrors: errors.acceptDataProcessing()
        }),
        m('div.retail-button-container.retail-button-container--centered',
          Button({
            isDisabled: controller.buttonIsDisabled(),
            isLoading: controller.isSubmitting(),
            buttonText: controller.d('PersonalInfo/SubmitButton'),
            onclick: function () {
              controller.submit(true);
            },
            uiTestId: 'retail-button-submit-button'
          })
        )
      ])
    ]);
  };

  return StepPersonalInfo;

});
