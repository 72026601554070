defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Api',
  [
    'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
    'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  ],
  function (ApiRequest, Utils) {
    // Variables:
    var baseUrl = DS.Config.CONTEXTPREFIX + '/scapi/danskespil/playeraccountmanagement';

    // Functions:
    var getProfile = function () {
      return ApiRequest({
        url: baseUrl + '/retail/updateprofile',
        method: 'GET',
        unwrapError: Utils.unwrapError
      });
    };

    var getProfileWithBankInformation = function () {
      return ApiRequest({
        url: baseUrl + '/retail/profileinclaccount',
        method: 'GET',
        unwrapError: Utils.unwrapError
      });
    };

    var getProfileWithCache = function () {
      return ApiRequest({
        url: baseUrl + '/retail/profileinclcache',
        method: 'GET',
        unwrapError: Utils.unwrapError
      });
    };

    var updateProfile = function (fields) {
      return ApiRequest({
        data: fields,
        url: baseUrl + '/retail/updateprofile',
        method: 'PUT',
        unwrapError: Utils.unwrapError
      });
    };

    var createProfile = function (fields) {
      return ApiRequest({
        data: fields,
        url: baseUrl + '/retail/createprofile',
        method: 'POST',
        unwrapError: Utils.unwrapError
      });
    };

    var discardUpgrade = function () {
      return ApiRequest({
        url: baseUrl + '/retail/discardupgrade',
        method: 'POST',
        unwrapError: Utils.unwrapError
      });
    };

    var doUpgrade = function () {
      return ApiRequest({
        url: baseUrl + '/retail/doupgrade',
        method: 'POST',
        unwrapError: Utils.unwrapError
      });
    };

    var isPasswordTokenValid = function (token) {
      return ApiRequest({
        method: 'GET',
        url: baseUrl + '/IsUpdatePasswordTokenValid/' + token,
        unwrapError: function () {
          return {
            statusCode: 404,
            message: Utils.getValidationMessage('invalidToken'),
          };
        }
      });
    };

    var changePassword = function (data) {
      return ApiRequest({
        method: 'POST',
        url: baseUrl + '/UpdatePasswordWithToken',
        data: data,
        unwrapError: Utils.unwrapError
      });
    };

    var getCities = function () {
      return ApiRequest({
        background: true,
        url: baseUrl + '/getcities'
      });
    };

    var getCountries = function () {
      return ApiRequest({
        url: baseUrl + '/getcountries'
      });
    };

    var getUploadFilePurposes = function (scope) {
      return ApiRequest({
        background: true,
        url: baseUrl + '/GetUploadFilePurposes' + (scope ? '?scope=' + scope : '')
      });
    };

    var getSecurityQuestions = function () {
      return ApiRequest({
        background: true,
        url: baseUrl + '/GetSecurityQuestions',
        unwrapSuccess: function (response) {
          return response.data.map(function (question) {
            return {
              Id: parseInt(question.MappingNumber, 10),
              Name: question.Question
            };
          });
        }
      });
    };

    var getUserDepositLimits = function () {
      return ApiRequest({
        background: true,
        url: baseUrl + '/GetAmountLimits',
        unwrapSuccess: function (response) {
          if (response?.data?.SavedUserDepositLimits) {
            return response.data.SavedUserDepositLimits;
          }

          return {};
        }
      });
    };

    var getPlayerLimits = function () {
      return ApiRequest({
        background: true,
        url: baseUrl + '/retail/GetLimits', // TODO: Change to wager limits once endpoint is done
        unwrapSuccess: function (response) {
          if (response?.data?.SavedUserWagerLimits) {
            return response.data.SavedUserWagerLimits;
          }

          if (response?.data?.SavedUserDepositLimits) {
            return response.data.SavedUserDepositLimits;
          }

          return {};
        }
      });
    };

    var setWagerLimits = function (data) {
      return ApiRequest({
        method: 'POST',
        url: baseUrl + '/retail/SetLimits',
        data: data,
        unwrapSuccess: function (response) {
          if (response?.data?.SavedUserWagerLimits) {
            return response.data.SavedUserWagerLimits;
          }

          if (response?.data?.SavedUserDepositLimits) {
            return response.data.SavedUserDepositLimits;
          }

          return {};
        }
      });
    };

    var setAmountLimits = function (data) {
      return ApiRequest({
        method: 'POST',
        url: baseUrl + '/SetAmountLimits',
        data: data
      });
    };

    var hasDocuments = function () {
      return ApiRequest({
        background: true,
        url: baseUrl + '/HasDocuments?checkPending=true'
      });
    };

    var uploadDocuments = function (data) {
      return ApiRequest({
        method: 'POST',
        url: baseUrl + '/Upload',
        data: data,
        serialize: function (data) {
          return data;
        }
      });
    };

    var closeAccount = function () {
      var confirmChecksum = Utils.getParameter('confirmChecksum');

      return ApiRequest({
        method: 'POST',
        url: baseUrl + '/closeaccount',
        unwrapError: Utils.unwrapError,
        headers: {
          'X-Checksum': confirmChecksum
        }
      });
    };

    var lockAccount = function () {
      var confirmChecksum = Utils.getParameter('confirmChecksum');

      return ApiRequest({
        method: 'POST',
        url: baseUrl + '/lockAccount',
        unwrapError: Utils.unwrapError,
        headers: {
          'X-Checksum': confirmChecksum
        }
      });
    };

    var restrictAccount = function (date) {
      if (!date) {
        date = new Date();
      }

      date = date instanceof Date ? date : new Date(date);

      var today = new Date();

      if (Utils.isSameDay(today, date)) {
        date.setDate(today.getDate() + 1); // Add one day to restrict for 24 hours
      }

      var formattedDate = Utils.formatDate(date);
      var timezoneOffset = date.getTimezoneOffset() * -1; // should be set as a negated minute offset

      var confirmChecksum = Utils.getParameter('confirmChecksum');
      var headersObj;

      if (confirmChecksum) {
        headersObj = {
          'X-Checksum': confirmChecksum
        };
      } else {
        headersObj = {};
      }

      return ApiRequest({
        method: 'POST',
        url: baseUrl + '/restrictaccount',
        data: {
          RestrictAccountEndDate: formattedDate,
          UtcMinutesOffset: timezoneOffset
        },
        unwrapError: Utils.unwrapError,
        headers: headersObj
      });
    };

    var getPlaycard = function () {
      return ApiRequest({
        url: baseUrl + '/GetPlayCard',
        unwrapError: Utils.unwrapError
      });
    };

    var orderPlaycard = function () {
      return ApiRequest({
        method: 'POST',
        url: baseUrl + '/OrderPlayCard',
        unwrapError: Utils.unwrapError
      });
    };

    var reOrderPlaycard = function () {
      return ApiRequest({
        method: 'POST',
        url: baseUrl + '/ReorderPlayCard',
        unwrapError: Utils.unwrapError
      });
    };

    var togglePlaycardStatus = function () {
      return ApiRequest({
        method: 'POST',
        url: baseUrl + '/TogglePlayCardStatus',
        unwrapError: Utils.unwrapError
      });
    };

    var getVisualPlayCard = function () {
      return ApiRequest({
        url: baseUrl + '/WebViewGetVisualPlayCard',
        unwrapError: Utils.unwrapError
      });
    };

    var getMobileLinkQR = function () {
      return ApiRequest({
        url: baseUrl + '/WebViewGetMobilePayQR',
        unwrapError: Utils.unwrapError
      });
    };

    var getNotificationPermissions = function () {
      return ApiRequest({
        method: 'GET',
        url: DS.Config.CONTEXTPREFIX + '/scapi/danskespil/integration/eloqua/playernotificationsettings/get',
        unwrapError: Utils.unwrapError,
        unwrapSuccess: function (response) {
          var data = response.data;
          var modelObj = {
            marketingTerms: data.RetailPermission,
            emailPermission: data.EmailPermission,
            emailWinningPermission: data.EmailWinnerNotificationPermission,
            smsWinningPermission: data.SmsWinnerNotificationPermission,
            appPermission: data.AppPermission,
            appPermissionQuestioned: data.AppPermissionQuestioned
          };

          return modelObj;
        }
      });
    };

    var setNotificationPermissions = function (data) {
      return ApiRequest({
        method: 'POST',
        data: data,
        url: DS.Config.CONTEXTPREFIX + '/scapi/danskespil/integration/eloqua/playernotificationsettings/update',
        unwrapError: Utils.unwrapError
      });
    };

    var getDawaAddress = function (data) {
      return ApiRequest({
        method: 'GET',
        url: 'https://api.dataforsyningen.dk/adresser/autocomplete?q=' + data + '&fuzzy=',
        unwrapSuccess: function (response) {
          return response.map(function (entry) {
            return {
              value: entry.tekst,
              model: {
                street: entry.adresse.vejnavn,
                zipCode: entry.adresse.postnr,
                city: entry.adresse.postnrnavn,
                houseNumber: entry.adresse.husnr,
                door: entry.adresse.dør,
                level: entry.adresse.etage,
              }
            };
          });
        }
      });
    };

    var getGameHistory = function (data) {
      if (!data) data = {};

      var page = data.page || 1;
      var period = data.period || 365;
      var offset = data.offset || 1;
      var vertical = data.vertical || 'aktive';
      var channel = 'forhandler'; // TODO: Change with 'forhandler'

      return ApiRequest({
        method: 'GET',
        url: baseUrl + '/playergames/' + page + '/' + period + '/' + offset + '/' + vertical + '/' + channel,
        unwrapError: Utils.unwrapError
      });
    };

    var getUncollectedPrizes = function () {
      return ApiRequest({
        method: 'GET',
        url: DS.Config.CONTEXTPREFIX + '/scapi/danskespil/playeraccountmanagement/UncollectedPrizes',
        unwrapError: Utils.unwrapError
      });
    };

    var getGameDetails = function (id) {
      return ApiRequest({
        method: 'GET',
        url: DS.Config.CONTEXTPREFIX + '/scapi/danskespil/playeraccountmanagement/PlayerGame/' + id,
        unwrapError: Utils.unwrapError
      });
    };

    var validateUsernameAndEmail = function (data) {
      return ApiRequest({
        method: 'POST',
        url: baseUrl + '/ValidateUsernameAndEmail',
        data: data,
        unwrapError: function (response, headers) {
          return {
            response: response,
            headers: headers
          };
        }
      });
    };

    var executePayout = function (winId) {
      return ApiRequest({
        method: 'POST',
        url: DS.Config.CONTEXTPREFIX + '/scapi/danskespil/playeraccountmanagement/PayoutToBank',
        data: {
          winId: winId
        },
        unwrapError: Utils.unwrapError
      });
    };

    var removePincodeExclusion = () => {
      return ApiRequest({
        method: 'POST',
        url: DS.Config.CONTEXTPREFIX + '/scapi/danskespil/playeraccountmanagement/RemovePincodeExclusion',
        unwrapError: () => {}
      });
    };

    var reactivatePlaycard = () => {
      return ApiRequest({
        method: 'POST',
        url: DS.Config.CONTEXTPREFIX + '/scapi/danskespil/playeraccountmanagement/ReactivatePlaycard',
        unwrapError: Utils.unwrapError
      });
    };

    var getOfflineLoginExclusion = () => {
      return ApiRequest({
        method: 'GET',
        url: DS.Config.CONTEXTPREFIX + '/scapi/danskespil/playeraccountmanagement/HasOfflineLoginExclusion',
        unwrapError: Utils.unwrapError
      });
    };

    var getMostRestrictiveLimit = () => {
      return ApiRequest({
        method: 'GET',
        url: DS.Config.CONTEXTPREFIX + '/scapi/danskespil/playeraccountmanagement/retail/GetMostRestrictiveLimit',
        unwrapError: Utils.unwrapError
      });
    };

    // Public functions:
    return {
      getProfile,
      getProfileWithBankInformation,
      getProfileWithCache,
      updateProfile,
      createProfile,
      discardUpgrade,
      doUpgrade,
      getCities,
      getCountries,
      getUploadFilePurposes,
      getSecurityQuestions,
      getPlayerLimits,
      setWagerLimits,
      setAmountLimits,
      hasDocuments,
      uploadDocuments,
      closeAccount,
      lockAccount,
      restrictAccount,
      getPlaycard,
      orderPlaycard,
      reOrderPlaycard,
      togglePlaycardStatus,
      getVisualPlayCard,
      getMobileLinkQR,
      getNotificationPermissions,
      setNotificationPermissions,
      isPasswordTokenValid,
      changePassword,
      getDawaAddress,
      getGameHistory,
      getGameDetails,
      validateUsernameAndEmail,
      executePayout,
      removePincodeExclusion,
      reactivatePlaycard,
      getOfflineLoginExclusion,
      getUserDepositLimits,
      getUncollectedPrizes,
      getMostRestrictiveLimit
    };

  });
