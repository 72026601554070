defineDs('DanskeSpil/Domain/RetailAccount/Scripts/RetailPromo', [
  'DanskeSpil/Domain/Authentification/Scripts/LoginCache',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils'
], function (LoginCache, Utils) {

  var $retailPromo = document.getElementById('retail-promo');

  if (!$retailPromo) {
    return;
  }

  var $retailPlaycardContainer = document.querySelector('.js-playcard-container');
  var $retailPlaycard = document.querySelector('.js-playcard');
  var $retailTartarSection = document.getElementById('tartar-section');
  var $retailWhySection = document.getElementById('why-section');
  var $retailDownloadSection = document.getElementById('download-section');
  var $retailDownloadSectiontitle1 = document.getElementById('download-section__title-1');
  var $retailDownloadSectiontitle2 = document.getElementById('download-section__title-2');
  var $retailScrollTextButton = document.getElementById('scroll-text-button');
  var hasScrolled = false;
  var scrollPos = 0;
  var startPos = 250;
  var downloadPercent = 110;
  var scrollDirection;
  var line1 = document.getElementById('line-1');
  var line2 = document.getElementById('line-2');
  var line3 = document.getElementById('line-3');
  var line4 = document.getElementById('line-4');
  var line5 = document.getElementById('line-5');
  var tartarImage = document.getElementById('tartar-svg__image');
  var tartarRect = document.getElementById('tartar-svg__rect');
  var tartarLogo = document.getElementById('tartar-svg__logo');

  if (LoginCache.isLoggedIn()) {
    document.body.classList.add('is-logged-in');
  }

  $retailScrollTextButton.addEventListener('click', function () { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    var scrollOnClick = scrollPos + window.innerHeight;
    if ('scrollBehavior' in document.documentElement.style) {
      window.scrollTo({
        top: scrollOnClick,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      window.scrollTo(0, scrollOnClick);
    }
  });

  // Force user to start from top
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
  }

  window.onload = function () {
    if (startPos) {
      setTimeout(function () {
        if ('scrollBehavior' in document.documentElement.style) {
          window.scrollTo({
            top: startPos,
            left: 0,
            behavior: 'smooth'
          });
        } else {
          window.scrollTo(0, startPos);
          document.querySelector('.js-info-statement__item').style.opacity = 1;
        }

        document.body.classList.add('has-loaded');
      }, 1000);
    }
  };

  var $opacityTargets = document.querySelectorAll('.js-info-statement__item');
  $opacityTargets = [].slice.call($opacityTargets); // IE11 fix

  var opacityScroll = function (entries) {
    if (!hasScrolled) return;

    entries.forEach(function (entry) {
      entry.target.style.opacity = entry.intersectionRatio;
    });
  };

  /* eslint-disable */
    var opacityObserver = new IntersectionObserver(opacityScroll, {
        threshold: [0, 0.05, 0.1, 0.15, 0.2, 0.5, 0.7, 0.8, 0.85, 0.9, 0.95, 1]
    });
    /* eslint-enable */

  $opacityTargets.forEach(function (target) { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    opacityObserver.observe(target);
  });


  var line1Length = line1.getTotalLength === 'function' ? line1.getTotalLength() : 1300;
  var line2Length = line2.getTotalLength === 'function' ? line2.getTotalLength() : 1300;
  var line3Length = line3.getTotalLength === 'function' ? line3.getTotalLength() : 1300;
  var line4Length = line4.getTotalLength === 'function' ? line4.getTotalLength() : 1300;
  var line5Length = line5.getTotalLength === 'function' ? line5.getTotalLength() : 1300;

  // Start drawing
  line1.style.strokeDasharray = line1Length;
  line1.style.strokeDashoffset = line1Length;
  line2.style.strokeDasharray = line2Length;
  line2.style.strokeDashoffset = line2Length;
  line3.style.strokeDasharray = line3Length;
  line3.style.strokeDashoffset = line3Length;
  line4.style.strokeDasharray = line4Length;
  line4.style.strokeDashoffset = line4Length;
  line5.style.strokeDasharray = line5Length;
  line5.style.strokeDashoffset = line5Length;

  document.addEventListener('scroll', function () {
    hasScrolled = true;
    // eslint-disable-next-line compat/compat
    var scrollY = typeof window.scrollY !== 'undefined' ? window.scrollY : window.pageYOffset;
    scrollDirection = scrollY > scrollPos ? 'down' : 'up';
    scrollPos = scrollY;

    var rotatePos = scrollPos / 10;

    if (window.innerWidth <= 640 && scrollPos > 0) {
      $retailPlaycardContainer.style.opacity = 0.5;
    } else {
      $retailPlaycardContainer.style.opacity = 1;
    }

    if (scrollPos >= ($retailTartarSection.offsetTop - window.innerHeight)) {
      document.body.classList.remove('tartar-section-active');
      $retailPlaycardContainer.classList.remove('is-fixed');
    } else {
      $retailPlaycardContainer.classList.add('is-fixed');
      $retailPlaycard.style.transform = 'rotateY(-' + rotatePos + 'deg)';
    }

    if (rotatePos >= 90 && rotatePos <= 270) {
      $retailPlaycard.classList.add('is-backside');
    } else {
      $retailPlaycard.classList.remove('is-backside');
    }

    if (scrollPos >= ($retailWhySection.offsetTop - window.innerHeight) && scrollPos < ($retailWhySection.offsetTop + $retailWhySection.clientHeight)) {
      document.body.classList.add('why-section-active');
      document.body.classList.remove('has-logo');
    } else {
      document.body.classList.remove('why-section-active');
    }

    if (scrollPos >= ($retailDownloadSection.offsetTop - window.innerHeight)) {
      document.body.classList.add('download-section-active');
      downloadPercent = downloadPercent > 0 && scrollDirection === 'down' ? (($retailDownloadSection.offsetTop - (window.innerHeight / 2)) - scrollPos) : 0;

      $retailDownloadSectiontitle1.style.transform = 'translateX(' + (-downloadPercent) + '%)';
      $retailDownloadSectiontitle2.style.transform = 'translateX(' + downloadPercent + '%)';
    } else {
      document.body.classList.remove('download-section-active');
    }

    if (scrollPos >= $retailTartarSection.offsetTop && scrollPos < ($retailTartarSection.offsetTop + $retailTartarSection.clientHeight)) {
      document.body.classList.add('tartar-section-active');
    }

    if (scrollPos >= ($retailTartarSection.offsetTop - window.innerHeight) && scrollPos < ($retailTartarSection.offsetTop + $retailTartarSection.clientHeight)) {
      var scrollPercent = (scrollPos - ($retailTartarSection.offsetTop - window.innerHeight)) / ($retailTartarSection.clientHeight * 0.2);

      var draw1 = line1Length * scrollPercent;
      var draw2 = line2Length * scrollPercent;
      var draw3 = line3Length * scrollPercent;
      var draw4 = line4Length * scrollPercent;
      var draw5 = line5Length * scrollPercent;

      if (scrollPercent >= 0 && scrollPercent <= 1) {
        document.body.classList.remove('has-logo');
        line1.style.opacity = 1;
        line2.style.opacity = 1;
        line3.style.opacity = 1;
        line4.style.opacity = 1;
        line5.style.opacity = 1;
        tartarRect.style.height = 0;
        line1.style.strokeDashoffset = line1Length - draw1;
        line2.style.strokeDashoffset = line2Length - draw2;
        line3.style.strokeDashoffset = line3Length - draw3;
        line4.style.strokeDashoffset = line4Length - draw4;
        line5.style.strokeDashoffset = line5Length - draw5;
      } else if (scrollPercent > 1 && scrollPercent <= 2) {
        if (scrollPercent >= 1.5) {
          line1.style.opacity = 0;
          line2.style.opacity = 0;
          line3.style.opacity = 0;
          line4.style.opacity = 0;
          line5.style.opacity = 0;
        }
        tartarRect.style.height = 0;
        tartarImage.style.opacity = scrollDirection === 'down' ? scrollPercent - 1 : 1;
      } else if (scrollPercent > 2) {
        var height = (scrollPercent - 2) * 100;
        var offset = (100 - height) / 2;
        tartarRect.style.height = height + '%';
        tartarRect.style.transform = 'rotate(-35deg) translate(-50%, ' + offset + '%)';
        tartarRect.setAttribute('height', height + '%');
        tartarRect.setAttribute('transform', 'matrix(0.819152, -0.573576, 0.573576, 0.819152, -880.108, 67.3979)');
        tartarLogo.setAttribute('transform', 'matrix(1, 0, 0, 1, ' + (window.innerWidth * 0.3) + ', ' + (window.innerHeight * 0.3) + ')');

        if (scrollPercent >= 2.5) {
          document.body.classList.add('has-logo');
          var logoOpacity = (scrollPercent - 2.5);
          tartarLogo.style.opacity = logoOpacity;
        } else {
          document.body.classList.remove('has-logo');
          tartarLogo.style.opacity = 0;
        }

        if (height >= 170) {
          var moveUpValue = 200 - height;
          tartarLogo.style.transform = 'translate(30%, ' + moveUpValue + '%)';

          if (moveUpValue <= -50) { // -50 = logo height + 10%
            document.body.classList.remove('tartar-section-active');
          }
        }
      }
    } else {
      document.body.classList.remove('tartar-section-active');
    }
  });

  Utils.setupLoginLinkEventListeners('/sikkertspil/opret');
});
