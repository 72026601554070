defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/CloseAccount', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Link',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, BackButton, Button, Link, StepHeader) {

  var StepCloseAccount = function (controller) {
    var userModel = controller.model().fields;
    var dictionaryKey = '';

    if (!userModel.isRetailAccount()) {
      dictionaryKey = 'OnlineAccount/';
    }

    return m('div', [
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton({ onclick: controller.backLinkCallback })) : null,
      m('.retail-page__content',
        m('.retail-page__row has-rows-2', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, StepHeader({
            title: controller.d('CloseAccount/PageTitle'),
            abstract: controller.d('CloseAccount/' + dictionaryKey + 'PageDescription')
          }),
          m('div', controller.d('CloseAccount/CloseNotice'))
          ),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, [
            Button({
              buttonText: controller.d('CloseAccount/SubmitButton'),
              onclick: function () {
                controller.restrictAccountSubmit('close');
              }
            }),
            m('.info-message', controller.d('CloseAccount/RofusText')),
            controller.rofusLink('InfoLink') ?
              m('div',
                Link({
                  class: 'retail-link--full-width',
                  href: controller.rofusLink('InfoLink').Url,
                  target: controller.rofusLink('InfoLink').Target,
                  text: controller.rofusLink('InfoLink').Text
                })
              )
              : null,
            controller.rofusLink('TreatmentPlacesLink') ?
              m('div',
                Link({
                  class: 'retail-link--full-width',
                  href: controller.rofusLink('TreatmentPlacesLink').Url,
                  target: controller.rofusLink('TreatmentPlacesLink').Target,
                  text: controller.rofusLink('TreatmentPlacesLink').Text
                })
              )
              : null,
          ])
        ])
      )
    ]);
  };

  return StepCloseAccount;

});