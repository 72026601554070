defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Recaptcha', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Framework/PlayerAccountManagement/Scripts/Helpers/Recaptcha',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorMessage'
], function (m, RecaptchaHelper, ErrorMessage) {

  var Recaptcha = function (config) {

    var options = {
      model: null,
      validate: null,
      hasErrors: null,
      id: '',
      ...config
    };

    return m('div', [
      m('div', {
        id: options.id,
        class: 'retail-recaptcha',
        config: function (elem, inited) {
          if (!inited) {
            RecaptchaHelper.reCaptchaInit(elem);
          }
        }
      }, []
      ),
      options.hasErrors ? ErrorMessage(options.hasErrors) : null
    ]);

  };
  return Recaptcha;
});
