defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/InfoRow', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Svg'
], function (m, Svg) {

  var InfoRow = function (config) {
    var options = {
      class: '',
      labelText: null,
      subLabelText: null,
      value: null,
      horizontal: false,
      clickable: typeof config.onclick === 'function',
      clickableIcon: 'arrow_right',
      testId: null,
      ...config
    };

    var selector = options.clickable ? 'button' : 'div';

    return m(selector, {
      'data-uitest-id': options.testId ? options.testId : '',
      class: [
        'retail-info-row',
        options.class ? options.class : null,
        options.clickable ? 'is-clickable' : null,
        options.horizontal ? 'retail-info-row--horizontal' : null
      ].join(' '),
      onclick: function (e) {
        if (options.clickable) {
          e.preventDefault();
          options.onclick();
        }
      }
    }, [
      options.labelText ? m('span.retail-info-row__label', [
        m('span.retail-info-row__label-text', options.labelText),
        options.subLabelText ? m('span.retail-info-row__label-subtext', options.subLabelText) : null
      ]) : null,
      options.value ? m('span.retail-info-row__value', m.trust(options.value)) : null,
      options.clickable ? Svg(options.clickableIcon, { class: 'retail-info-row__svg' }) : null
    ]);
  };



  return InfoRow;

});
