defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/GameHistoryDetails', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/GameHistoryNavigation',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/IconButton',
], (m, GameHistoryNavigation, StepHeader, IconButton) => {

  const GameHistoryDetails = (controller) => {
    return m('.retail-page', [
      m('.retail-page__content retail-page__no-progress', [

        !controller.isWebview() && m('.pam-page__header', StepHeader({ title: controller.d('List/PageTitle') })),

        GameHistoryNavigation(controller),

        m('.retail-game-receipt__container', [

          m('.retail-game-receipt__close-button',
            IconButton({
              icon: 'close',
              class: 'retail-game-receipt__close-button',
              title: 'Vend tilbage til oversigten'
            })),

          controller.game().hasValidCouponUrl
            ? m('iframe.retail-game-receipt__iframe', {
              onload: controller.resizeIframe,
              config: controller.render.depend(`iframe-${controller.game().id}`),
              src: controller.game().couponUrl,
              frameborder: '0',
              allowfullscreen: '0',
              id: controller.game().id
            })
            : m('div', 'No coupon available')

        ])
      ])
    ]);
  };

  return GameHistoryDetails;

});
