defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/ReorderPlaycard', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, Utils, BackButton, Button, StepHeader) {

  var StepReorderPlaycard = function (controller) {
    var userModel = controller.model().fields;

    return m('', [
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton()) : null,
      m('.retail-page__content',
        m('.retail-page__row has-rows-2', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, StepHeader({
            title: controller.d('ReorderPlaycard/PageTitle'),
            abstract: controller.d('ReorderPlaycard/PageDescription')
          })
          ),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, controller.playcardModel().canReorder() ? [
            m('.retail-rich-text', [
              m('.retail-rich-text__title', controller.d('OrderPlaycard/ShipPlaycardTitle')),
              m('div', userModel.firstName() + ' ' + userModel.lastName()),
              m('div', userModel.street() + ' ' + (userModel.houseNumber() ? userModel.houseNumber() : '') + (userModel.level() ? ', ' + userModel.level() : '') + (userModel.door() ? ' ' + userModel.door() : '')),
              m('div', userModel.zipCode() + ' ' + userModel.city()),
              m('.notice', controller.d('OrderPlaycard/AddressFromCPRRegistryNotice'))
            ]),
            m('.retail-button-container', [
              Button({
                class: 'retail-button--white',
                buttonText: controller.d('ReorderPlaycard/CancelButton'),
                onclick: function () {
                  if (Utils.getQueryParam('returnUrl')) {
                    window.location.href = Utils.getQueryParam('returnUrl');
                  }

                  controller.goToRoute('/');
                }
              }),
              Button({
                isLoading: controller.isSubmitting(),
                buttonText: controller.d('ReorderPlaycard/SubmitButton'),
                onclick: controller.reOrderPlaycard
              })
            ])
          ] : m('div', controller.d('ReorderPlaycard/CoolDownMessage')))
        ])
      )
    ]);
  };

  return StepReorderPlaycard;

});
