defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/OrderPlaycardPincode', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/RadioButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton'
], function (m, StepHeader, Button, RadioButton, BackButton) {

  var OrderPlaycardPincode = function (controller) {
    const dictionaryKey = 'OrderPlaycardPincode';
    const playcardModel = controller.playcardModel();
    const userModel = controller.model().fields;

    var selectOption = function (type) {
      playcardModel.orderWithSamePincode(type === 'samePincode');
      playcardModel.orderWithNewPincode(type === 'newPincode');
    };

    return [
      !controller.hideBackButton() ?  m('.retail-page__button-container', BackButton()) : null,
      m('.retail-page__content',
        m('.retail-page__row', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, StepHeader({
            title: controller.d(`${dictionaryKey}/PageTitle`),
            abstract: controller.d(`${dictionaryKey}/PageDescription`),
          })
          ),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, [
            m('.retail-rich-text',
              m('.retail-rich-text__title.retail-rich-text__title--spacing-md', controller.d(`${dictionaryKey}/OptionsLabel`))
            ),
            m('.retail-radio-button__group', [
              RadioButton({
                model: playcardModel.orderWithSamePincode,
                labelText: controller.d(`${dictionaryKey}/KeepPincodeLabel`, { PINCODE: userModel.pincode() }),
                id: 'offlineWagerLimitPeriodDaily',
                name: 'keepPincode',
                onchange: function () {
                  selectOption('samePincode');
                }
              }),
              RadioButton({
                model: playcardModel.orderWithNewPincode,
                labelText: controller.d(`${dictionaryKey}/CreateNewPincodeLabel`),
                id: 'offlineWagerLimitPeriodWeekly',
                name: 'createNewPincode',
                onchange: function () {
                  selectOption('newPincode');
                }
              }),
            ]),
            m('.retail-button-container.retail-button-container--centered',
              Button({
                buttonText: controller.d(`${dictionaryKey}/SubmitButton`),
                onclick: function () {
                  if (playcardModel.orderWithSamePincode()) {
                    controller.goToRoute('/order/confirm');
                  } else if (playcardModel.orderWithNewPincode()) {
                    controller.goToRoute('/edit-pincode');
                  }
                }
              })
            ),
          ])
        ])
      )
    ];
  };

  return OrderPlaycardPincode;

});
