defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/LimitPeriodSelection', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorMessage',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/RadioButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ProgressBar',
], function (m, BackButton, ErrorMessage, StepHeader, RadioButton, Button, ProgressBar) {

  const getVariant = (isRetailAccount, hasOldOfflineWagerLimit, initiator) => {
    if (initiator) {
      return '';
    }

    if (isRetailAccount) {
      if (hasOldOfflineWagerLimit) {
        return '/Variant3';
      } else {
        return '/Variant4';
      }
    } else {
      if (!hasOldOfflineWagerLimit) {
        return '/Variant2';
      } else {
        return '/Variant1';
      }
    }
  };

  const LimitPeriodSelection = function (controller, initiator) {
    var userModel = controller.model().fields;
    var errors = controller.model().errors;
    var dictionaryKey = controller.currentStep().dictionaryKey;
    const variant = getVariant(userModel.isRetailAccount(), userModel.hasOldOfflineWagerLimit(), initiator);
    var selectType = function (type) {
      userModel.offlineWagerLimitPeriodDaily(type === 'daily');
      userModel.offlineWagerLimitPeriodWeekly(type === 'weekly');
      userModel.offlineWagerLimitPeriodMonthly(type === 'monthly');
      userModel.offlineWagerLimitPeriod(type);
    };
    let errorMessage = controller.d('Errors/NoLimitPeriodSelected');
    if (errors.offlineWagerLimitPeriod()) {
      errorMessage = errors.offlineWagerLimitPeriod();
    }

    return [
      controller.progress ?
        ProgressBar({
          progress: controller.progress()
        }) : null,
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton({
          onclick: controller.routeToPrevStep
        })) : null,
      m('.retail-page__content', {
        class: controller.hideBackButton() ? 'retail-page__no-progress' : ''
      },
      m('.retail-page__row has-rows-2', [
        m('div', {
          class: 'retail-page__column retail-page__column--1'
        }, StepHeader({
          title: controller.d(`${dictionaryKey + variant}/PageTitle`),
          abstract: controller.d(`${dictionaryKey + variant}/PageDescription`)
        })
        ),
        m('div', {
          class: 'retail-page__column retail-page__column--2 retail-rich-text'
        }, [
          m('h3.retail-rich-text__title', m('strong', controller.d(`${dictionaryKey}/LimitTypeLabel`))),
          m('.retail-radio-button__group', [
            RadioButton({
              model: userModel.offlineWagerLimitPeriodDaily,
              labelText: controller.d(`${dictionaryKey}/DailyLimitOption`),
              id: 'offlineWagerLimitPeriodDaily',
              name: 'limitPeriod',
              onchange: function () {
                selectType('daily');
              }
            }),
            RadioButton({
              model: userModel.offlineWagerLimitPeriodWeekly,
              labelText: controller.d(`${dictionaryKey}/WeeklyLimitOption`),
              id: 'offlineWagerLimitPeriodWeekly',
              name: 'limitPeriod',
              onchange: function () {
                selectType('weekly');
              }
            }),
            RadioButton({
              model: userModel.offlineWagerLimitPeriodMonthly,
              labelText: controller.d(`${dictionaryKey}/MonthlyLimitOption`),
              id: 'offlineWagerLimitPeriodMonthly',
              name: 'limitPeriod',
              onchange: function () {
                selectType('monthly');
              }
            }),
          ]),
          errors.offlineWagerLimitPeriod() ? ErrorMessage(errorMessage) : null,
          m('div.retail-button-container.retail-button-container--centered',
            Button({
              buttonText: controller.d(`${dictionaryKey}/SubmitButton`),
              onclick: function () {
                controller.submit();
              }
            })
          )
        ])
      ])
      )
    ];
  };

  return LimitPeriodSelection;

});
