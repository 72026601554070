defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/UpdateOverview', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button'
], function (m, StepHeader, Button) {

  var UpdateOverview = function (controller) {
    var userModel = controller.model().fields;

    return m('div', {
      class: 'retail-page__content retail-page__no-progress'
    },
    m('.retail-page__row', [
      m('div', {
        class: 'retail-page__column retail-page__column--1'
      }, [
        StepHeader({
          title: controller.d('UpdateOverview/PageTitle', { NAME: userModel.firstName() }),
          abstract: controller.d('UpdateOverview/PageDescription', { NAME: userModel.firstName() })
        })
      ]
      ),
      m('div', {
        class: 'retail-page__column retail-page__column--2'
      }, [
        m('.retail-button-container', [
          Button({
            buttonText: controller.d('UpdateOverview/SubmitButton'),
            onclick: function () {
              controller.goToRoute('/email');
            }
          }),
          Button({
            class: 'retail-button--white',
            buttonText: controller.d('UpdateOverview/CancelButton'),
            onclick: function () {
              window.location.href = controller.receiptLink().Url;
            }
          }),
        ])
      ])
    ])
    );
  };

  return UpdateOverview;

});
