defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Upgrade', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Framework/PlayerAccountManagement/Scripts/Templates/StepGuide',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/UpgradeSummary'
], function (m, StepGuide, Button, StepHeader, UpgradeSummary) {

  var StepUpgrade = function (controller) {

    return m('div', [
      StepGuide(1, controller.totalSteps()),
      controller.model().cachedUpgrade() ? UpgradeSummary(controller) : m('.pam-page__content', [
        m('div', {
          class: 'pam-page__header'
        }, StepHeader({
          title: controller.d('Upgrade/PageTitle'),
          abstract: controller.d('Upgrade/PageDescription')
        })
        ),
        m('.retail-button-container', [
          Button({
            isLoading: controller.isSubmitting(),
            class: 'retail-button--secondary',
            buttonText: controller.d('Upgrade/ExitButton'),
            onclick: controller.logout,
            uiTestId: 'retail-button-decline-button'
          }),
          Button({
            isLoading: controller.isSubmitting(),
            buttonText: controller.d('Upgrade/NextButton'),
            onclick: controller.routeToNextStep,
            uiTestId: 'retail-button-submit-button'
          })
        ])
      ])
    ]);
  };

  return StepUpgrade;

});
