defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Components/RetailCarousel', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'Common/Framework/EventHandling/Scripts/Event',
  'Shared/Framework/Mithril/Scripts/Helpers/Carousel',
  'DanskeSpil/Domain/Authentification/Scripts/LoginCache',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
], function (Component, Event, Carousel, LoginCache, StepHeader, Button) {

  Component('retail-carousel', function (m, route, settings) {

    var root = {
      controller: function () {
        this.settings = settings;
        this.carousel = m.prop([]);
        this.route = route;
        this.isVisible = m.prop(false);

        var currentUser = LoginCache.getUserObj();
        var carouselItems = this.settings.carousel.filter(function (carouselItem) {
          // Filter out if user has set wager limits, and slide should only be shown to users with no limits
          if (carouselItem.noWagerLimits && currentUser && currentUser.customerInfo && currentUser.customerInfo.hasLimits) {
            return false;
          }

          return true;
        });

        this.carousel(carouselItems);

        this.initCarousel = function ($element, isInitialized) {
          if (isInitialized) return;

          var settings = {
            withDots: false,
            withArrows: true
          };

          new Carousel($element, settings);
        }.bind(this);

        Event.subscribe('retailCarousel:visiblity', function (data) {
          this.isVisible(data.visible);
        }.bind(this));
      },
      view: function (controller) {
        if (!controller.carousel().length) {
          return;
        }

        if (!controller.isVisible()) {
          return;
        }

        return m('div', {
          class: 'retail-page__content has-two-columns retail-carousel',
          config: controller.initCarousel
        },
        m('.retail-carousel__outer-wrapper',
          m('.retail-carousel__inner-wrapper',
            controller.carousel().map(function (carouselItem) {
              return m('div', {
                class: 'retail-page__row has-rows-2 retail-carousel__item'
              }, [
                m('div', {
                  class: 'retail-page__column retail-page__column--1'
                }, [
                  StepHeader({
                    title: carouselItem.title,
                    abstract: carouselItem.description
                  }),
                  carouselItem.ctaButton.Url ?
                    Button({
                      class: 'retail-button--white retail-carousel__cta-button',
                      buttonText: carouselItem.ctaButton.Title,
                      onclick: function () {
                        window.location.href = carouselItem.ctaButton.Url;
                      }
                    }) : null,
                ]),
                m('div', {
                  class: 'retail-page__column retail-page__column--2'
                }, carouselItem.image ? m('img', {
                  class: 'retail-carousel__image',
                  src: carouselItem.image.Source,
                  alt: carouselItem.image.AlternateText
                }) : null
                )
              ]);
            })
          )
        )
        );
      }
    };

    // Routes
    route('/', root);

  });

});