defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/EmailPermissions', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ProgressBar',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Checkbox',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
], function (m, ProgressBar, BackButton, StepHeader, Checkbox, Button) {

  var StepEmailPermissions = function (controller) {
    var userModel = controller.model().fields;
    var errors = controller.model().errors;

    return m('div', [
      controller.progress ?
        ProgressBar({
          progress: controller.progress()
        }) : null,
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton()) : null,
      m('.retail-page__content',
        m('.retail-page__row has-rows-2', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, StepHeader({
            title: controller.d('EmailPermissions/PageTitle')
          })
          ),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, [
            userModel.isRetailAccount() || userModel.marketingTerms() ?
              [
                m('.retail-rich-text', m.trust(controller.retailEmailPermissionText())),
                Checkbox({
                  model: userModel.marketingTerms,
                  labelText: controller.d('EmailPermissions/LabelEmail'),
                  labelClass: 'retail-checkbox__label--large',
                  name: 'marketingTerms',
                  hasErrors: errors.marketingTerms(),
                  isDisabled: userModel.emailPermission()
                })
              ] : null,
            !userModel.isRetailAccount() ?
              [
                userModel.marketingTerms() ? m('hr') : null,
                m('.retail-rich-text', m.trust(controller.onlineEmailPermissionText())),
                Checkbox({
                  model: userModel.emailPermission,
                  onchange: function () {
                    if (userModel.emailPermission()) {
                      userModel.marketingTerms(false);
                    } else {
                      var prevValue = controller.model().initialValues.marketingTerms;
                      userModel.marketingTerms(prevValue);
                    }
                  },
                  labelText: controller.d('EmailPermissions/LabelEmail'),
                  labelClass: 'retail-checkbox__label--large',
                  name: 'emailPermission',
                  hasErrors: errors.emailPermission(),
                })
              ] : null,
            m('div.retail-button-container.retail-button-container--centered',
              Button({
                isLoading: controller.isSubmitting(),
                buttonText: controller.d('EmailPermissions/SubmitButton'),
                onclick: function () {
                  controller.updatePermissions('marketing');
                }
              })
            )
          ])
        ])
      )
    ]);
  };

  return StepEmailPermissions;

});
