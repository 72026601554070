defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Receipt', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Svg',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
], function (m, Svg, Stepheader, Button) {

  var StepReceipt = function (controller) {
    var handleCallback = function () {
      if (controller.receiptContent().callback) {
        controller.receiptContent().callback();
      } else if (controller.goToRoute) {
        controller.goToRoute('/');
      }
    };

    return m('.retail-page__content retail-page__no-progress',
      m('.retail-receipt', {
        config: function (ignoreContext, isInit) {
          if (isInit) return;

          if (typeof controller.receiptContent().init === 'function') {
            controller.receiptContent().init();
          }

          if (controller.receiptContent().timeout) {
            setTimeout(handleCallback, controller.receiptContent().timeout);
          }
        }
      }, [
        controller.receiptContent().icon ?
          Svg(controller.receiptContent().icon, {
            class: 'retail-receipt__icon'
          }) : null,
        Stepheader({
          title: controller.receiptContent().title,
          abstract: controller.receiptContent().abstract || null
        }),
        controller.receiptContent().buttonText ?
          m('div.retail-button-container.retail-button-container--centered',
            Button({
              buttonText: controller.receiptContent().buttonText,
              onclick: controller.receiptContent().callback,
              uiTestId: 'retail-receipt-button'
            })
          ) : null,
        controller.receiptContent().partialTemplate ? controller.receiptContent().partialTemplate : null
      ])
    );
  };

  return StepReceipt;

});
