defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/OrderPlaycardIntro', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button'
], function (m, Utils, StepHeader, Button) {

  var OrderPlaycardIntro = function (controller) {
    const playcardModel = controller.playcardModel();
    const canOrder = playcardModel.canReorder() || !playcardModel.isPhysicalCard();
    const canReorder = playcardModel.canReorder();
    const reorderFlow = window.location.hash === '#retail-playcard=/reorder' || window.location.hash === '#retail-profile=/reorder';
    let dictionaryKey = 'OrderPlaycardIntro';
    let pageDescriptionDictionaryText = canOrder && playcardModel.isPhysicalCard() ? 'PageDescriptionExistingCard' : 'PageDescription';

    let submit = () => {
      controller.goToRoute('/order/pincode-option');
    };

    if (!reorderFlow && playcardModel.playcardStatus() === 'unblock') {
      dictionaryKey = 'UnblockPlaycard';
      pageDescriptionDictionaryText = 'PageDescription';
      submit = controller.togglePlaycardStatus;
    } else if (!reorderFlow && playcardModel.playcardStatus() === 'block') {
      dictionaryKey = 'BlockPlaycard';
      pageDescriptionDictionaryText = 'PageDescription';
      submit = controller.togglePlaycardStatus;
    } else if (!reorderFlow && Utils.isRetailIpad()) {
      dictionaryKey = 'RetailIpadPlaycard';
      pageDescriptionDictionaryText = 'PageDescription';
    } else if (!canReorder && playcardModel.playcardStatus() !== 'order') {
      dictionaryKey = 'OrderPlaycardIntro/Cooldown';
    }

    return m('div', {
      class: 'retail-page__content retail-page__no-progress'
    },
    m('.retail-page__row', [
      m('div', {
        class: 'retail-page__column retail-page__column--1'
      }, StepHeader({
        title: controller.d(`${dictionaryKey}/PageTitle`),
        abstract: controller.d(`${dictionaryKey}/${pageDescriptionDictionaryText}`),
      })
      ),
      m('div', {
        class: 'retail-page__column retail-page__column--2'
      }, [
        m('.retail-button-container', {
          class: !canOrder ? 'retail-button-container--centered' : null
        }, [
          Button({
            class: 'retail-button--secondary',
            buttonText: controller.d(`${dictionaryKey}/CancelButton`),
            onclick: function () {
              controller.goToRoute('/');
            }
          }),
          canOrder ? Button({
            buttonText: controller.d(`${dictionaryKey}/SubmitButton`),
            onclick: submit
          }) : null
        ]),
      ])
    ])
    );
  };

  return OrderPlaycardIntro;

});
