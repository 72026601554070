defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/FileDropdown', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/FileUploadModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorMessage'
], function (m, Utils, FileUploadModel, ErrorMessage) {

  var FileDropdown = function (options) {
    return m('div', [
      m('div', {
        class: [
          'retail-file-dropdown-container',
          options.files().length > 0 ? 'has-value' : '',
          options.hasErrors ? 'has-errors' : null,
        ].join(' ')
      }, [
        m('select', {
          class: ['retail-file-dropdown__select', options.files().length > 0 ? 'retail-file-dropdown__select-additional' : ''].join(' '),
          name: options.name || '',
          id: options.id || options.name,
          onchange: function () {
            var visiblePurposes = options.purposes.filter(function (purpose) {
              return purpose.isVisible();
            });
            var selectedPurpose = visiblePurposes[this.selectedIndex - 1];
            var newFile = new FileUploadModel(selectedPurpose, options.uploadSettings, options.dictionary, true);
            options.files().push(newFile);
          }
        },
        m('option',  { value: '', hidden: 'hidden', selected: 'selected', disabled: 'disabled' }, options.files().length > 0 ? options.dictionary('UploadDocuments/UploadMoreFiles') : options.dictionary('UploadDocuments/SelectPurpose')),
        options.purposes
          .filter(function (purpose) {
            return purpose.isVisible();
          })
          .map(function (purpose) {
            return m('option', {
              value: purpose.purpose()
            }, purpose.header());
          }))
      ]),
      options.hasErrors ? ErrorMessage(Utils.getValidationMessage('noPurposeSelected')) : null
    ]);
  };

  return FileDropdown;

});
