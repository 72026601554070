defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/PlaycardOverview', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InfoRow',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Svg',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/NotificationDialog'
], function (m, Utils, InfoRow, Svg, StepHeader, Button, NotificationDialog) {

  var StepPlaycardOverview = function (controller) {
    var userModel = controller.model().fields;
    var playcardModel = controller.playcardModel();
    var playcardLinkButtonText = controller.d('PlaycardOverview/LinkOrderCard');
    var playcardLinkButtonSubText = null;

    // Only render links if dictionary exists
    var playCardLinkMobilePay = controller.d('PlaycardOverview/LinkMobilePayCard');
    var playcardLinkReorder = controller.d('PlaycardOverview/LinkReorderPlaycard');

    if (playcardModel.playcardStatus() === 'unblock') {
      playcardLinkButtonText = controller.d('PlaycardOverview/LinkUnblockCard');
    } else if (playcardModel.playcardStatus() === 'block') {
      playcardLinkButtonText = controller.d('PlaycardOverview/LinkBlockCard');
      playcardLinkButtonSubText = controller.d('PlaycardOverview/LinkBlockCardSubText');
    }

    return m('div', {
      class: 'retail-page__content has-two-columns'
    },
    m('div', {
      class: [
        'retail-page__row has-rows-2',
        controller.showPlaycardFrontside() ? 'is-frontside' : 'is-backside'
      ].join(' ')
    }, [
      userModel.isPending() && controller.showPendingNotification() ?
        NotificationDialog({
          title: controller.d('UserIsPending'),
          onClose: function () {
            controller.showPendingNotification(false);
          }
        }) : null,
      m('div', {
        class: 'retail-page__column retail-page__column--1 retail-visual-playcard'
      }, [
        m('.retail-visual-playcard__name', userModel.firstName() + ' ' + userModel.lastName()),
        m('.retail-visual-playcard__id', playcardModel.digitalPlaycardNumber() ? Utils.splitString(playcardModel.digitalPlaycardNumber()) : null),
        m('.retail-visual-playcard__card-container', userModel.isActive() ? [
          m('button', {
            type: 'button',
            title: controller.d('PlaycardOverview/Frontside/CardHelpText'),
            class: 'retail-visual-playcard__image-container retail-visual-playcard__front-side',
            onmouseenter: function () {
              controller.mouseoverPlaycard(true);
            },
            onmouseleave: function () {
              controller.mouseoverPlaycard(false);
            },
            onclick: function () {
              controller.showPlaycardFrontside(false);
            }
          }, [
            Svg('touch', {
              class: 'retail-visual-playcard__hover-icon'
            }),
            Svg('spil-id-forside', {
              class: 'retail-visual-playcard__background-icon'
            })
          ]),
          m('button', {
            type: 'button',
            title: controller.d('PlaycardOverview/Backside/CardHelpText'),
            class: 'retail-visual-playcard__image-container retail-visual-playcard__back-side',
            onmouseenter: function () {
              controller.mouseoverPlaycard(true);
            },
            onmouseleave: function () {
              controller.mouseoverPlaycard(false);
            },
            onclick: function () {
              controller.showPlaycardFrontside(true);
            }
          }, m('.retail-visual-playcard__image', playcardModel.digitalPlaycardImage() ? m.trust(playcardModel.digitalPlaycardImage()) : null))
        ] : m('.retail-visual-playcard__empty', '')),
        userModel.isActive() ?
          m('.retail-visual-playcard__text-container', {
            class: controller.mouseoverPlaycard() ? 'is-visible' : null
          }, [
            m('.retail-visual-playcard__front-side-text', controller.d('PlaycardOverview/Frontside/CardHelpText')),
            m('.retail-visual-playcard__back-side-text', controller.d('PlaycardOverview/Backside/CardHelpText'))
          ]) : null
      ]
      ),
      m('div', {
        class: 'retail-page__column retail-page__column--2'
      }, [
        m('.retail-link-list__front-side', [
          StepHeader({
            title: controller.d('PlaycardOverview/Frontside/Title'),
            abstract: userModel.isActive() ? controller.d('PlaycardOverview/Frontside/Description') : controller.d('PlaycardOverview/Frontside/DescriptionPending')
          }),
          m('div.retail-button-container.retail-button-container--centered',
            Button({
              buttonText: controller.appLink().Text,
              class: 'retail-button--white',
              onclick: function () {
                window.location.href = controller.appLink().Url;
              }
            }),
          ),
          userModel.isActive() && playcardModel.playcardStatus() === 'order' ?
            m('div.retail-button-container.retail-button-container--centered',
              Button({
                buttonText: playcardLinkButtonText,
                class: 'retail-button--white',
                onclick: function () {
                  controller.goToRoute('/manage');
                }
              })
            ) : null
        ]),
        m('.retail-link-list__back-side', m('.list', [
          userModel.isActive() && playcardModel.playcardStatus() !== 'order' && playcardLinkReorder ?
            InfoRow({
              labelText: playcardLinkReorder,
              horizontal: true,
              onclick: function () {
                controller.goToRoute('/reorder');
              }
            }) : null,
          userModel.isActive() ?
            InfoRow({
              labelText: playcardLinkButtonText,
              subLabelText: playcardLinkButtonSubText,
              horizontal: true,
              onclick: function () {
                controller.goToRoute('/manage');
              }
            }) : null,
          playCardLinkMobilePay && controller.mobilePayLink() ?
            InfoRow({
              labelText: playCardLinkMobilePay,
              subLabelText: controller.d('PlaycardOverview/LinkMobilePayCardSubText'),
              horizontal: true,
              onclick: controller.openMobilePay
            })
            : null,
          controller.subMenuLink() ?
            controller.subMenuLink().map(function (menuLink) {
              return InfoRow({
                labelText: menuLink.label,
                horizontal: true,
                onclick: function () {
                  window.location.href = menuLink.url;
                }
              });
            })
            : null
        ]))
      ])
    ])
    );
  };

  return StepPlaycardOverview;

});
