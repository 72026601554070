defineDs('DanskeSpil/Framework/PlayerAccountManagement/Scripts/Templates/StepGuide', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril'
], function (m) {
  'use strict';

  function StepGuide(currentStep, totalSteps, routes, labels) {
    if (!totalSteps) {
      totalSteps = currentStep.totalSteps;
      currentStep = currentStep.currentStep;
    }

    function setClass(i, routes, labels) {
      var elementClass = '';
      if (currentStep > i) {
        elementClass += 'is--active ';
      }
      if (!routes) {
        elementClass += 'no-pointer ';
      }
      if (!!labels && labels.length > 0 && (i + 1 === currentStep)) {
        elementClass += 'has--label';
      }
      return elementClass;
    }

    function stepViews() {
      var steps = [];
      for (var i = 0; i < totalSteps; i++) {
        steps.push(m('li.step-guide__step', {
          class: setClass(i, routes, labels),
          onclick: currentStep > i ? function () {
            if (routes) {
              m.route(routes[this]);
            }
          }.bind(i) : null
        }, !!labels && labels.length > 0 && ((i + 1) === currentStep) ? i + 1 + '. ' +  labels[i] : i + 1));
      }
      return steps;
    }

    return [
      m('ol.step-guide', {},
        stepViews()
      )
    ];
  }

  return StepGuide;
});
