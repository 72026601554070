defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Components/RetailFrontpagePlaycard', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Api',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils'
], function (Component, Api, Utils) {

  Component('retail-frontpage-playcard', function (m, route, settings) {

    const root = {
      controller: function () {
        this.settings = settings;
        const birthdayText = this.settings?.birthdayText ?? '';
        const user = m.prop(null);
        const playcard = m.prop(null);
        const isLoading = m.prop(true);
        const hasError = m.prop(true);
        const enlargeQr = m.prop(false);
        const birthday = m.prop(null);

        const formatBirthday = (date) => {
          let birthday = date.split(' ')[0];

          if (birthday) {
            return birthday.replaceAll('-', '.');
          }

          return null;
        };

        const handleQrLabelClick = () => {
          enlargeQr(!enlargeQr());
        };

        const loadData = async () => {
          isLoading(true);
          let userData;
          let playcardData;

          try {
            [userData, playcardData] = await Promise.all([Api.getProfile(), Api.getVisualPlayCard()]);
            if (userData.status !== 'success') throw new Error('Failed to load user data');
            if (playcardData.status !== 'success') throw new Error('Failed to load playcard');

            user(userData.data.profile);
            playcard(playcardData.data);
            birthday(formatBirthday(user().dateOfBirth.value));
            hasError(false);
          } catch (e) {
            console.error(e);
            hasError(true);
          } finally {
            isLoading(false);
          }
        };

        loadData();

        document.body.setAttribute('data-override-logout-return-url', `/${window.location.pathname.split('/')[1]}`);

        return { user, playcard, isLoading, handleQrLabelClick, enlargeQr, hasError, birthdayText, birthday };
      },
      view: function ({ user, playcard, isLoading, handleQrLabelClick, enlargeQr, hasError, birthdayText, birthday }) {
        if (isLoading()) {
          return (
            <div className={'retail-hero__playcard retail-hero__playcard--loading'}>Henter Spil-id...</div>
          );
        }

        if (hasError()) {
          return (
            <div className={'retail-hero__playcard retail-hero__playcard--loading'}>Kunne ikke hente Spil-id</div>
          );
        }

        return (
          <div className={'retail-hero__playcard-inner'}>
            <div className={'retail-hero__playcard-name'}>{user().firstName.value ?? ''} {user().lastName.value ?? ''}</div>
            <div className={'retail-hero__playcard-age'}>{birthdayText + birthday()}</div>
            <div className={'retail-hero__playcard'}>
              <div className={`retail-hero__playcard-qr${enlargeQr() ? ' retail-hero__playcard-qr--enlarge' : ''}`} onclick={handleQrLabelClick}>{m.trust(playcard().svg.Content)}
                <div className={'retail-hero__playcard-qr-label'}>{!enlargeQr() ? 'FORSTØR' : 'FORMINDSK'} QR</div>
              </div>
              <div className={'retail-hero__game-id'}>
                <div className={'retail-hero__game-id-label'}>Spil-id:</div>
                <div className={'retail-hero__game-id-number'}>{Utils.splitString(playcard().number)}</div>
              </div>
            </div>
          </div>
        );
      }
    };

    // Routes
    route('/', root);

  });

});
