defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/GameScanner', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ProgressBar',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InfoRow',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Checkbox',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Loader'
], function (m, ProgressBar, BackButton, StepHeader, InfoRow, Checkbox, Button, Loader) {

  var StepGameScanner = function (controller) {
    var userModel = controller.model().fields;
    var errors = controller.model().errors;
    userModel.runKnowYourCustomerFlow(true); // Ask backend to run KYC on customer

    this.displayAddress = function () {
      var address = '';

      address += userModel.street() + ' ';
      address += userModel.houseNumber() + (userModel.level() ? userModel.level() : '') + ', ';
      address += userModel.zipCode() + '  ';
      address += userModel.city() + ', ';
      address += userModel.country();

      return InfoRow({
        labelText: controller.d('GameScanner/LabelAddress'),
        value: address
      });
    };

    // If user has already accepted dataprocessing then just submit and show a loader for UI purposes
    if (controller.model().hasConsentedToGameScanner()) {
      controller.submit();

      return Loader();
    }

    return [
      controller.progress ?
        ProgressBar({
          progress: controller.progress()
        }) : null,
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton({
          onclick: controller.routeToPrevStep ? controller.routeToPrevStep : null
        })) : null,
      m('.retail-page__content',
        m('.retail-page__row has-rows-2', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, [
            StepHeader({
              title: controller.d('GameScanner/PageTitle')
            }),
            InfoRow({
              labelText: controller.d('GameScanner/LabelName'),
              value: userModel.firstName() + ' ' + userModel.lastName()
            }),
            controller.showAddress() && userModel.street() ?
              this.displayAddress() : null,
            InfoRow({
              labelText: controller.d('GameScanner/LabelEmail'),
              value: userModel.email()
            }),
            InfoRow({
              labelText: controller.d('GameScanner/LabelPhone'),
              value: userModel.phone()
            }),
            userModel.username() ? InfoRow({
              labelText: controller.d('GameScanner/LabelUsername'),
              value: userModel.username()
            }) : null
          ]),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, [
            InfoRow({
              labelText: controller.d('GameScanner/LabelTerms')
            }),
            Checkbox({
              model: userModel.acceptDataProcessing,
              labelText: controller.d('GameScanner/LabelGameScannerTerms'),
              name: 'acceptDataProcessing',
              onchange: controller.validateField,
              hasErrors: errors.acceptDataProcessing(),
              isDisabled: controller.model().hasConsentedToGameScanner()
            }),
            m('div.retail-button-container.retail-button-container--centered',
              Button({
                isDisabled: controller.buttonIsDisabled(),
                isLoading: controller.isSubmitting(),
                buttonText: controller.d('GameScanner/SubmitButton'),
                onclick: controller.submit,
                config: controller.checkElementInViewport,
                class: controller.elementIsInViewport() ? null : 'is-floating'
              })
            )
          ])
        ])
      )
    ];
  };

  return StepGameScanner;

});
