defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/ConfirmWagerLimits', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InfoRow',
], function (m, Utils, BackButton, Button, StepHeader, InfoRow) {

  var StepConfirmWagerLimits = function (controller) {

    if (controller.model().userLimits().changes().length === 0) {
      controller.goToRoute('/offline-limits'); // no pending changes lets go back to limits.
    }

    this.cancel = function () {
      controller.model().userLimits().reset();
      window.history.back();
    };

    return m('div', [
      m('.retail-page__button-container', BackButton({
        onclick: this.cancel
      }
      )),
      m('.retail-page__content',
        m('.retail-page__row has-rows-2', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, StepHeader({
            title: controller.d('WagerLimits/Confirm/PageTitle'),
            abstract: controller.d('WagerLimits/Confirm/PageDescription')
          })
          ),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, [
            controller.model().userLimits().changes().map(function (limit) {
              return new InfoRow({
                labelText: controller.d('WagerLimits/' + limit.type() + 'Limit'),
                value: limit.value() ? controller.d('WagerLimits/ChangedLimit', {
                  TYPE: controller.d('WagerLimits/' + limit.type()),
                  VALUE: limit.formatted(),
                  ACTIVEFROM: limit.stored() > limit.value() || limit.stored() === null || limit.state() === 'revert' ? controller.d('WagerLimits/Confirm/Now') : Utils.formatDateTime(new Date(), 1)
                }) : controller.d('WagerLimits/DeletedLimit', {
                  TYPE: controller.d('WagerLimits/' + limit.type()),
                  ACTIVEFROM: Utils.formatDateTime(new Date(), 1)
                })
              });
            }),
            m('.retail-button-container', [
              Button({
                class: 'retail-button--white',
                buttonText: controller.d('WagerLimits/Confirm/CancelButton'),
                onclick: this.cancel
              }),
              Button({
                buttonText: controller.d('WagerLimits/Confirm/ConfirmButton'),
                onclick: controller.confirmLimit
              })
            ])
          ])
        ])
      )
    ]);
  };

  return StepConfirmWagerLimits;

});
