defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Components/RetailUpgrade', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/Authentification/Scripts/LoginController',
  'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay', // TODO: Move out from NG, or use more generic one?
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Dictionary',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Api',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/WaitForAuthSynced',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/UserModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/PurposeModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/FileUploadModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/LimitModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Loader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorOverlay',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Upgrade',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/AccountInfo',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/PersonalInfo',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Security',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/SelectLimit',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/UpdateLimit',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/UploadDocuments',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Receipt',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/FinalizeUpgrade'
],
function (Component, DSUtils, LoginController, FrameworkOverlay, Dictionary, Utils, RetailApi, WaitForAuthSynced, UserModel, PurposeModel, FileUploadModel, LimitModel, Loader, ErrorOverlay, Upgrade, AccountInfo, PersonalInfo, Security, SelectLimit, UpdateLimit, UploadDocuments, Receipt, FinalizeUpgrade) {

  // Component:
  Component('retail-upgrade', [Dictionary, WaitForAuthSynced], function (m, route, settings) {

    document.body.classList.add('page-retail-upgrade');

    // Declare steps in upgrade flow
    var routeConfiguration = [
      { order: 0, route: '/upgrade', fields: [], template: Upgrade },
      { order: 1, route: '/accountInfo', fields: ['email', 'phone'], template: AccountInfo },
      { order: 2, route: '/personalInfo', fields: ['firstName', 'lastName', 'street', 'houseNumber', 'zipCode', 'city', 'country', 'acceptTermsAndConditions'], template: PersonalInfo },
      { order: 3, route: '/security', fields: ['securityQuestion', 'securityAnswer'], template: Security },
      { order: 4, route: '/selectLimit', fields: ['limitType', 'limitFormatted'], template: SelectLimit },
      { order: null, route: '/updateLimit', fields: [], template: UpdateLimit },
      { order: 5, route: '/finalizeUpgrade', fields: [], template: FinalizeUpgrade },
      { order: null, route: '/uploadDocuments', fields: ['isActive', 'hasValidBankAccount'], template: UploadDocuments },
      { order: null, route: '/receipt', template: Receipt }
    ];

    // Get the current step from route on page load
    var currentStep = routeConfiguration.filter(function (step) {
      var locationHash = window.location.hash.toLowerCase();
      var hash = locationHash.replace('#retail-upgrade=', '');
      return hash === step.route.toLowerCase();
    });

    // It empty get the current step from the routeConfiguration array (setting [0] in the controller)
    currentStep = currentStep.length ? currentStep[0] : routeConfiguration[0];

    var preSelectedPurposes = Utils.getQueryParam('purpose') ? Utils.getQueryParam('purpose').split(',') : [];

    var root = {
      controller: function () {
        this.isLoading = m.prop(true);
        this.isSubmitting = m.prop(false);
        this.classPrefix = m.prop('pam-page');
        this.buttonIsDisabled = m.prop(true);
        this.modalError = m.prop(null);
        this.routeConfiguration = routeConfiguration;
        this.currentStep = m.prop(currentStep);
        this.totalSteps = m.prop(6);
        this.progress = m.prop(0);
        this.model = m.prop(new UserModel());
        this.initialValues = m.prop(new UserModel());
        this.d = Dictionary.get.bind({ prefix: 'RetailUpgrade/' });
        this.purposes = m.prop([]);
        this.limitMinimum = settings.LimitMinimum;
        this.uploadSettings = settings.UploadSettings;
        this.maxLimits = settings.MaxLimits;
        this.hasLoadedDocuments = m.prop(false);
        this.hasPreloadFiles = m.prop(false);
        this.noFilesSelected = m.prop(false);
        this.receiptContent = m.prop({
          title: this.d('Receipt/Upload/PageTitle'),
          abstract: this.d('Receipt/Upload/PageDescription'),
          icon: 'circle_checkmark',
          timeout: null,
          callback: function () {
            var returnUrl = Utils.getReturnUrl();
            if (returnUrl) {
              window.location.href = returnUrl;
            } else {
              window.location.href = settings.receiptLink.Url;
            }
          },
          buttonText: settings.receiptLink.Text
        });
        this.fieldInFocus = m.prop();
        this.backAllowed = m.prop(false);
        this.noPaddingTop = m.prop((Utils.getQueryParam('dsApplicationId') || window.dsApplicationConfig) || false);
        this.hideBackButton = m.prop((Utils.getQueryParam('dsApplicationId') || window.dsApplicationConfig) || false);
        this.postfix = ' kr.';

        // Collections to hold suggested zipcodes and cities
        this.zips = m.prop([]);
        this.cities = m.prop([]);
        this.addresses = m.prop([]);

        var getCities = function () {
          return RetailApi.getCities().then(function (response) {
            this.model().citiesList(response.data);
          }.bind(this));
        }.bind(this);

        var getCountries = function () {
          return RetailApi.getCountries().then(function (response) {
            this.model().availableCountries(response.data);
          }.bind(this));
        }.bind(this);

        var getPurposes = function () {
          return RetailApi.getUploadFilePurposes('RetailSignup').then(function (response) {
            this.purposes(response.data.map(function (purpose) {
              var purposeModel = new PurposeModel(purpose, true);
              var foundMatch = preSelectedPurposes.some(function (preSelected) {
                return preSelected.toLowerCase() === (purposeModel.identifier().toLowerCase());
              });
              if (foundMatch) {
                this.model().files().push(new FileUploadModel(purposeModel, this.uploadSettings, this.d, false));
              }
              return purposeModel;
            }.bind(this)));
            m.redraw();
          }.bind(this));
        }.bind(this);

        var getSecurityQuestions = function () {
          return RetailApi.getSecurityQuestions().then(function (response) {
            this.model().securityQuestions(response);
          }.bind(this));
        }.bind(this);

        var getUserDepositLimits = () => {
          return RetailApi.getUserDepositLimits().then((response) => {
            var limits = response.limits.map(function (limit) {
              return new LimitModel(limit);
            });

            this.model().userLimits().sitecoreLimits([
              { type: 'Daily', value: settings.maxRetailDaily },
              { type: 'Weekly', value: settings.maxRetailWeekly },
              { type: 'Monthly', value: settings.maxRetailMonthly }
            ]);
            this.model().userLimits().limits(limits);
            this.model().userLimits().limitRequired(true);
            this.model().userLimits().limitMinimum(settings.LimitMinimum);

            m.redraw();
          });
        };

        var hasDocuments = function () {
          return RetailApi.hasDocuments().then(function (response) {
            this.model().hasDocuments(response.data.HasDocuments || false);
            m.redraw();
          }.bind(this));
        }.bind(this);

        this.hasFilesUploaded = function () {
          return this.model().files().filter(function (file) {
            return file.file !== null;
          }).length > 0;
        }.bind(this);

        this.addFileRequirement = function (identifiers) {
          if (this.hasPreloadFiles() || this.purposes().length === 0) {
            return;
          }
          identifiers = Array.isArray(identifiers) ? identifiers : (typeof identifiers === 'string' ? [identifiers] : []);

          identifiers.forEach(function (identifier) {
            this.purposes().forEach(function (purpose) {
              if (purpose.identifier() === identifier) {
                this.model().files().push(new FileUploadModel(purpose, this.uploadSettings, this.d, false));
              }
            }.bind(this));
          }.bind(this));

          this.hasPreloadFiles(true);
        }.bind(this);

        this.loadDocumentData = function () {
          this.hasLoadedDocuments(true);
          m.sync([getPurposes(), hasDocuments()]).then(function () {
            m.redraw();
          }.bind(this));
        }.bind(this);


        RetailApi.getProfileWithCache().then(function (response) {
          var model = response.data.profile || {};
          this.model().update(model);
          if (model.limitValue.value) {
            this.model().fields.limitFormatted(Utils.formatLimit(model.limitValue.value));
          }
          this.model().usernameIsSet(model.usernameAlreadySet.value);
          this.model().cachedUpgrade(model.cachedUpgrade.value);
          this.initialValues().update(model);

          if (this.model().fields.accountTypeChangeInProgress()) {
            if (!this.model().fields.cprCheck()) {
              Utils.pushVirtualPage('/uploadDocuments', (window.location.pathname.replace(/-/g, '_') + '/uploadDocuments'), '/uploadDocuments');
              route('/uploadDocuments')();
            } else {
              this.model().fields.accountTypeChangeInProgress(false);
              var data = Utils.mapModelToApiData(['accountTypeChangeInProgress', 'isRetailAccount', 'cprCheck'], this.model().fields);
              RetailApi.updateProfile(data).then(function (response) {
                var hasErrors = this.model().setErrors(response.data);
                if (hasErrors) return;

                this.model().update(response.data.profile);
                return this.complete(response);
              }.bind(this), function (error) {
                this.showErrorModal(error);
              }.bind(this)).then(function () {
                this.isLoading(false);
                this.buttonIsDisabled(true);
                m.redraw();
              }.bind(this));
            }
          } else if (!this.model().fields.isRetailAccount()) {
            this.receiptContent({
              title: this.d('Receipt/AlreadyRegistered/PageTitle'),
              abstract: this.d('Receipt/AlreadyRegistered/PageDescription'),
              icon: 'circle_checkmark',
              timeout: 5000,
              callback: function () {
                var returnUrl = Utils.getQueryParam('loginSuccessUrl') || Utils.getQueryParam('returnUrl') || Utils.getQueryParam('redirectUrl');
                window.location.href = returnUrl || window.location.origin;
              }.bind(this),
              buttonText: this.d('Receipt/AlreadyRegistered/SubmitButton')
            });
            Utils.pushVirtualPage('/receipt', (window.location.pathname.replace(/-/g, '_') + '/receipt'), '/receipt');
            route('/receipt')(); // TODO: Clearify with business that this is correct.
          } else {
            Utils.pushVirtualPage('/upgrade', (window.location.pathname.replace(/-/g, '_') + '/upgrade'), '/upgrade');
            route('/upgrade')();
          }

          m.sync([getCities(), getCountries(), getSecurityQuestions(), getUserDepositLimits()]).then(function () {
            this.isLoading(false);
            m.redraw();
          }.bind(this));

        }.bind(this), function () {
          var loader = document.querySelector('.retail-loader');
          if (loader) {
            loader.style.display = 'none'; // lets hide spinner while we wait.
          }
          LoginController.openLogin({
            // Outcommented these, as they are extracted from other aprams that has not been in effect since RedirectBasedLogin was enabled (in 2021). /MIPE, 2022-03-15
            // cancelUrl: Utils.getQueryParam('redirectUrl') || window.location.origin
          });
        }.bind(this));

        this.updateProgress = function (locationHash) {
          var hash = locationHash.replace('#retail-upgrade=', '');
          var step;

          if (hash !== '') {
            step = this.routeConfiguration.filter(function (step) {
              return step.route.toLowerCase() === hash.toLowerCase();
            })[0];
          } else {
            step = this.routeConfiguration[0];
          }

          this.currentStep(step);

          if (!step.order) return;

          var newProgress = step.order / this.totalSteps() * 100;
          this.progress(newProgress);
        }.bind(this);

        this.setFieldFocus = function (e) {
          if (typeof this.fieldInFocus !== 'function') {
            return;
          }

          // If function is called with event from onfocus handler, set field to have focus.
          // If called without event, then set first input field to have focus
          var fieldName = e && e.target ? e.target.name : null;

          if (fieldName) {
            this.fieldInFocus(fieldName);
          }
        }.bind(this);

        this.validateField = function (ev) {
          if (ev.key === 'Tab' && !ev.target.value.length) return;

          var fieldName = ev.target.name;
          this.model().validate([fieldName]);

          var hasErrors = this.model().validate(this.currentStep().fields, false);
          this.buttonIsDisabled(hasErrors); // Disable button if fields has errors

          if (fieldName === this.fieldInFocus()) {
            this.fieldInFocus(null);
          }
        }.bind(this);

        this.resetErrors = function (ev) {
          var fieldName = ev.target.name;

          if (typeof this.model().errors[fieldName] === 'function') {
            this.model().errors[fieldName](null);
          }
        }.bind(this);

        this.addFile = function (file, index) {
          this.model().files()[index].file(file);
        }.bind(this);

        this.removeSelection = function (selection) {
          var removeIndex = this.model().files().indexOf(selection);
          this.model().files().splice(removeIndex, 1);
        }.bind(this);

        this.validateUpload = function () {
          this.model().files().forEach(function (file) {
            var hasErrors = file.file() && file.validate();
            this.buttonIsDisabled(hasErrors);
          }.bind(this));
        }.bind(this);

        this.validateLimit = function () {
          var hasErrors = this.model().validate(['limitType', 'limitFormatted']);
          if (!hasErrors && this.model().fields.limitValue() < this.limitMinimum) { // Need to make sure we are above minimum limit
            this.model().errors.limitFormatted(this.d('Errors/LimitBelowGlobalMinimum', { AMOUNT: this.limitMinimum }));
            hasErrors = true;
          }
          var maxLimitIdentifier = 'Max' + this.model().fields.limitType();
          var maxLimit = this.maxLimits.hasOwnProperty(maxLimitIdentifier) && this.maxLimits[maxLimitIdentifier]
            ? this.maxLimits[maxLimitIdentifier] : Number.MAX_VALUE;
          if (!hasErrors && (this.model().fields.limitValue() > maxLimit)) {
            this.model().errors.limitFormatted(this.d('Errors/LimitAboveGlobalMaximum', {
              AMOUNT: Utils.formatCurrency(this.maxLimits[maxLimitIdentifier]),
              PERIOD: this.d('Errors/Period/' + this.model().fields.limitType())
            }));
            hasErrors = true;
          }
          this.buttonIsDisabled(hasErrors);
          return hasErrors;
        }.bind(this);

        this.discard = function () {
          this.isLoading(true);
          RetailApi.discardUpgrade().then(function (ignoreResponse) {
            this.logout();
            window.location = window.location.origin;
          }.bind(this));
        }.bind(this);

        this.logout = function (disableCallback) {
          this.buttonIsDisabled(true);
          this.isSubmitting(true);
          LoginController.logout({
            callback: function () {
              if (disableCallback) return;
              var redirect = Utils.getQueryParam('redirectUrl');
              window.location.href = redirect ? redirect : window.location.origin;
            },
            redirect: false
          });
        }.bind(this);

        this.complete = function (response) {
          var profile = response.data && response.data.profile ? response.data.profile : null;
          if (profile && !profile.isPending.value && !profile.isRetailAccount.value && !profile.accountTypeChangeInProgress.value) {
            // Force logout the user
            this.logout(true);

            this.receiptContent({
              title: this.d('Receipt/Success/PageTitle'),
              abstract: this.d('Receipt/Success/PageDescription'),
              icon: 'circle_checkmark',
              timeout: null,
              callback: function () {
                // var returnUrl = Utils.getQueryParam('returnUrl');
                var redirectUrl = Utils.getQueryParam('redirectUrl');
                var brandUrl = DSUtils.cookie(DS.Config.CONTEXT + 'currentBrandURL');

                if (window.dsApplicationConfig) {
                  window.location.href = '/blaa-konto/login';
                  return;
                }

                var options = {
                  // Outcommented cancelUrl, as it is extracted from other onCancel that has not been in effect since RedirectBasedLogin was enabled (in 2021). /MIPE, 2022-03-15
                  // cancelUrl: returnUrl || redirectUrl || window.location.origin,
                };

                var url = window.location.href.split('#')[0];
                url = Utils.appendParameter('loginSuccessUrl', redirectUrl || brandUrl, url);
                options.loginSuccessUrl = url + '#retail-upgrade=/receipt';
                options.variant = profile.isFromFaroe.value ? 'RetailUpgradeFaroe' : 'RetailUpgrade';
                LoginController.openLogin(options);

              }.bind(this),
              buttonText: this.d('Receipt/Success/SubmitButton')
            });

            Utils.ensightenEvent('signup_' + window.DS.Config.CONTEXT, 'success');
            Utils.pushVirtualPage('/receipt', (window.location.pathname.replace(/-/g, '_') + '/receipt'), '/receipt');
            route('/receipt')();
          } else {
            Utils.pushVirtualPage('/uploadDocuments', (window.location.pathname.replace(/-/g, '_') + '/uploadDocuments'), '/uploadDocuments');
            route('/uploadDocuments')();
          }
          window.scrollTo(0, 0);
        }.bind(this);

        this.upgrade = function () {
          this.isLoading(true);
          RetailApi.doUpgrade().then(function (response) {
            var model = response.data.profile || {};
            this.model().update(model);
            this.complete(response);
          }.bind(this), function (error) {
            this.showErrorModal(error);
          }.bind(this)).then(function () {
            this.isLoading(false);
            m.redraw();
          }.bind(this));
        }.bind(this);

        this.submit = function (preventUpdate) {
          var fields = this.currentStep().fields;
          var hasAcceptedDefaultMarketingPermission = false;

          if (this.currentStep().route === '/accountInfo') {
            var usernameFields = ['username', 'password', 'repeatPassword'];
            hasAcceptedDefaultMarketingPermission = this.model().fields.marketingDefaultTerms(); // Persist to secondary updateprofile api call
            if (!this.model().usernameIsSet()) {
              Utils.extendFields(fields, usernameFields);
            } else {
              fields = Utils.removeFields(fields, usernameFields);
            }
            if (this.model().fields.email() === this.initialValues().fields.email()) { // Prevent updating email if is unchanged
              fields = Utils.removeFields(fields, ['email']);
            }
            if (this.model().fields.phone() === this.initialValues().fields.phone()) { // Prevent updating phone if is unchanged
              fields = Utils.removeFields(fields, ['phone']);
            }
            preventUpdate = fields.length === 0;
            this.buttonIsDisabled(true);
            this.isSubmitting(true);
          }

          if (this.currentStep().route === '/personalInfo') {
            if (!this.initialValues().fields.acceptDataProcessing()) {
              Utils.extendFields(fields, ['acceptDataProcessing']);
            }
          }

          if (this.model().validate(fields)) return;

          if (preventUpdate) {
            this.buttonIsDisabled(true);
            this.isSubmitting(false);
            return this.routeToNextStep();
          }

          if (this.currentStep().route === '/updateLimit' || this.currentStep().route === '/selectLimit') {
            // Append with data from previous step
            Utils.extendFields(fields, ['street', 'houseNumber', 'zipCode', 'city', 'country', 'accountTypeChangeInProgress', 'securityQuestion', 'securityAnswer', 'marketingDefaultTerms']);
            if (!this.initialValues().fields.acceptDataProcessing()) {
              Utils.extendFields(fields, ['acceptDataProcessing']);
            }

            var limitData;

            if (this.currentStep().route === '/selectLimit') {
              limitData = Utils.mapLimitToApiData(this.model().fields.limitType(), this.model().fields.limitValue());
              fields = Utils.removeFields(fields, ['limitType', 'limitFormatted']);
            } else {
              limitData = this.model().userLimits().mapToData();
            }

            this.model().fields.accountTypeChangeInProgress(true);

            if (this.currentStep().route === '/selectLimit' || this.model().userLimits().hasChanges()) {
              RetailApi.setAmountLimits(limitData).then(function (ignoreResponse) {
                // We do not want to block upgrade flow if set limits fail. User will be prompted for setLimits once properly
                // logged in with an online account if no limits has been selected.
              });
            }
          }

          var data = Utils.mapModelToApiData(fields, this.model().fields);
          this.isLoading(true);

          RetailApi.updateProfile(data).then(function (response) {
            if (this.currentStep().route === '/selectLimit' || this.currentStep().route === '/updateLimit') {
              return this.complete(response);
            }

            var hasErrors = this.model().setErrors(response.data);
            if (hasErrors) return;

            this.model().update(response.data.profile);
            this.model().fields.marketingDefaultTerms(hasAcceptedDefaultMarketingPermission);

            this.routeToNextStep();
          }.bind(this), function (error) {
            this.showErrorModal(error);
          }.bind(this)).then(function () {
            this.isLoading(false);
            this.buttonIsDisabled(true);
            this.isSubmitting(false);
            m.redraw();
          }.bind(this));
        }.bind(this);

        this.routeToNextStep = function (routePath) {
          if (routePath) {
            Utils.pushVirtualPage(routePath, (window.location.pathname.replace(/-/g, '_') + routePath), routePath);
            route(routePath)();
            window.scrollTo(0, 0);
            return;
          }

          var nextStep = this.routeConfiguration[this.currentStep().order + 1];
          if (nextStep.route === '/personalInfo') {
            this.clearAddress();
          }

          if (nextStep.route === '/security') {
            // Reset previous selected security question
            this.model().fields.securityQuestion(null);
          }

          if (nextStep.route === '/selectLimit' && this.model().userLimits().hasLimit()) {
            this.routeToNextStep('/updateLimit');
            return;
          }

          Utils.pushVirtualPage(nextStep.route, (window.location.pathname.replace(/-/g, '_') + nextStep.route), nextStep.route);
          route(nextStep.route)();
          window.scrollTo(0, 0);
        }.bind(this);

        this.clearAddress = function () {
          this.model().fields.street(null);
          this.model().fields.houseNumber(null);
          this.model().fields.level(null);
          this.model().fields.zipCode(null);
          this.model().fields.city(null);
        };

        this.upload = function () {
          var hasErrors = this.model().files().map(function (file) {
            return file.validate();
          }).some(function (error) {
            return error;
          });

          if (this.model().files().length === 0) {
            hasErrors = true;
            this.noFilesSelected(true);
          }

          if (hasErrors) {
            return;
          }

          var data = Utils.mapFilesToApiData(this.model);
          this.isLoading(true);

          RetailApi.uploadDocuments(data).then().then(function (response) {
            var data = response.data;
            if (!data.OperationSuccess) {
              this.showErrorModal(data);
              return;
            }

            this.model().files([]); // Lets reset all uploaded files.

            this.logout(true);
            if (Utils.isOddsetApp()) {
              this.receiptContent({
                title: this.d('Receipt/AppLoginAttempt/PageTitle'),
                abstract: this.d('Receipt/AppLoginAttempt/PageDescription'),
                icon: 'exclemation-mark',
              });
              Utils.pushVirtualPage('/receipt_app_pending', (window.location.pathname.replace(/-/g, '_') + '/receipt_app_pending'), '/receipt_app_pending');
            } else {
              Utils.pushVirtualPage('/receipt', (window.location.pathname.replace(/-/g, '_') + '/receipt'), '/receipt');
            }
            route('/receipt')();
          }.bind(this), function (error) {
            this.showErrorModal(error);
          }.bind(this)).then(function () {
            this.isLoading(false);
            this.buttonIsDisabled(true);
          }.bind(this));

        }.bind(this);

        this.showErrorModal = (error) => {
          var confirm = null;
          var dismisable = true;

          if (error.statusCode === 401) {
            confirm = function () {
              window.location.href = Utils.appendParameter('loginSuccessUrl', encodeURIComponent(window.location.href), settings.loginLink.Url);
            };
            dismisable = false;
          }

          this.modalError(new FrameworkOverlay(ErrorOverlay({
            title: this.d('ErrorModal/Title'),
            body: error.message,
            confirm: confirm,
            dismisable: dismisable,
            confirmButtonClass: settings.loginLink.CssClass || null,
            confirmButton: confirm ? settings.loginLink.Text : null
          }))).show();
        };

        this.selectAddressFromSelection = function (value) {
          var match = this.addresses().filter(function (entry) {
            return entry.value.indexOf(value) === 0;
          });

          if (match.length) {
            var model = match[0].model;
            this.model().fields.street(model.street);
            this.model().fields.houseNumber(model.houseNumber);
            this.model().fields.zipCode(model.zipCode);
            this.model().fields.city(model.city);
            model.level ? this.model().fields.level([model.level, model.door].join('. ')) : null;
            this.model().validate(['street', 'houseNumber', 'zipCode', 'city']);
            this.addresses([]);
          }
        }.bind(this);

        this.selectCityFromZip = function (zip) {
          var match = this.model().citiesList().filter(function (entry) {
            return entry.ZipCode.indexOf(zip) === 0;
          });
          if (match.length) {
            this.model().fields.zipCode(match[0].ZipCode);
            this.model().fields.city(match[0].City);
            this.model().validate(['city']);
          } else {
            this.model().fields.city(null);
          }
        }.bind(this);

        this.lookupZip = function (e) {
          this.setFieldFocus(e);
          var val = e.target.value;

          if (val.length === 4 || e.key === 'Enter') {
            this.selectCityFromZip(val);
            this.fieldInFocus(null);
          }

          if (val.length) {
            var matches = this.model().citiesList().filter(function (entry) {
              return entry.ZipCode.indexOf(val) === 0;
            }).map(function (entry, index) {
              return {
                value: entry.ZipCode,
                highlighted: index === 0
              };
            });
            this.zips(matches);
          } else {
            this.zips([]);
          }
        }.bind(this);

        this.lookupCity = function (e) {
          this.setFieldFocus(e);
          var val = e.target.value;

          if (val.length) {
            var matches = this.model().citiesList().filter(function (entry) {
              return entry.City.toLowerCase().indexOf(val.toLowerCase()) === 0;
            }).map(function (entry, index) {
              return {
                value: entry.City,
                highlighted: index === 0
              };
            });
            this.cities(matches);
          } else {
            this.cities([]);
          }
        }.bind(this);

        this.lookupAddress = function (e) {
          var val = e.target.value;
          if (e.key === 'Enter') {
            this.selectAddressFromSelection(val);
            this.addresses([]);
            return;
          }

          if (val.length) {
            RetailApi.getDawaAddress(val).then(function (response) {
              this.addresses(response);
              m.redraw();
            }.bind(this));
          } else {
            this.addresses([]);
          }
        }.bind(this);

        this.updateProgress(window.location.hash);

        window.addEventListener('popstate', function (e) {
          this.updateProgress(e.currentTarget.location.hash);
        }.bind(this), false);

      },
      view: function (controller) {
        var currentStep = controller.currentStep().route.replace('/', '').toLowerCase();
        if (controller.isLoading()) {
          return Loader();
        }

        return m('div', {
          class: [
            'pam-page',
            'retail-upgrade',
            currentStep ? 'retail-upgrade--' + currentStep : '',
          ].join(' '),
          'data-uitest-id': Utils.replaceSlashWithHyphens(route(), 'retail-page-upgrade')
        }, routeConfiguration.reduce(function (template, step) {
          if (route() === step.route) {
            template = step.template(controller, null, controller.classPrefix());
          }

          return template;
        }, null));
      }
    };


    // Routes
    route('/', root);

    for (var index in routeConfiguration) {
      var step = routeConfiguration[index];

      route(step.route, root);
    }
  });

});
