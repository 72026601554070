defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/RetailWallet', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril'
], function (m) {

  var RetailWallet = function (options) {
    return m('.retail-wallet',
      m('h2.retail-wallet__title', options.title),
      m('.retail-wallet__amount', m.trust(options.amount)),
      m('.retail-wallet__amount-notice', options.amountNotice),
      m('.retail-wallet__pickup-notice', m.trust(options.pickupNotice))
    );
  };

  return RetailWallet;

});
