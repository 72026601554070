defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Overlay',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
    'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Svg',
    'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button'
  ],
  function (m, StepHeader, Svg, Button) {

    // Context:
    var Overlay = function (className, config) {
      var options = {
        dismisable: true,
        title: '',
        body: '',
        confirm: null,
        dismissButton: 'Annullér',
        confirmButton: 'Bekræft',
        confirmButtonClass: null,
        ...config
      };

      var outsideClick = function (dismiss) {
        return function () {
          event.stopPropagation();
          if (event.target === this) {
            dismiss();
          }
        };
      };

      // Return:
      return {
        class: m.prop(className),

        render: function (dismiss) {
          return m('div', {
            class: this.class(),
            onclick: options.dismisable ? outsideClick(dismiss) : function () {}
          }, [
            m('.retail-overlay', [
              options.dismisable ?
                m('button', {
                  class: 'close-icon-wrapper',
                  onclick: dismiss,
                  type: 'button'
                }, [
                  Svg('close', {
                    class: 'close-icon'
                  }),
                ]) : null,
              options.icon ? Svg(options.icon, { class: 'retail-overlay__icon' }) : null,
              StepHeader({
                title: options.title,
                abstract: options.body
              }),
              m('.retail-button-container.retail-button-container--centered', [
                options.confirm ?
                  Button({
                    class: [
                      'overlay-confirm__button',
                      options.confirmButtonClass ? options.confirmButtonClass : null
                    ].join(' '),
                    onclick: options.confirm,
                    buttonText: options.confirmButton
                  })
                  : null,
                options.dismisable && options.dismissButton ?
                  Button({
                    class: [
                      'overlay-cancel__button',
                      options.dismissButtonClass ? options.dismissButtonClass : null
                    ].join(' '),
                    onclick: dismiss,
                    buttonText: options.dismissButton
                  })
                  : null
              ])
            ])
          ]);
        }
      };
    };
    // Public functions:
    return Overlay;

  });
