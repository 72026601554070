defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/AlreadyCreated', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, Button, StepHeader) {

  var StepAlreadyCreated = function (controller) {
    var userModel = controller.model().fields;
    var dictionaryKey = controller.isRetailIpad() ? 'AlreadyCreated/RetailIpad/' : 'AlreadyCreated/';

    return m('.retail-page__content', m('.retail-page__row', [
      m('div', {
        class: 'retail-page__column retail-page__column--1'
      }, [
        StepHeader({
          title: controller.d(dictionaryKey + 'PageTitle', { NAME: userModel.firstName() }),
          abstract: controller.d(dictionaryKey + 'PageDescription', { NAME: userModel.firstName() })
        }),
        m('.retail-button-container', [
          Button({
            class: 'retail-button--secondary',
            buttonText: controller.d('AlreadyCreated/CancelButton'),
            onclick: function () {
              window.location.href = controller.loginPage();
            }
          }),
          m('div.retail-button-container.retail-button-container--centered',
            Button({
              buttonText: controller.d('AlreadyCreated/SubmitButton'),
              onclick: controller.receiptContent().callback
            })
          )
        ])
      ])
    ]));
  };

  return StepAlreadyCreated;

});