defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorMessage', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
], function (m) {

  var ErrorMessage = function (errors, options) {
    if (!errors) return;
    options = options || {};

    if (typeof errors === 'string') {
      errors = [errors];
    }

    return m('.retail-errors', errors.map(function (singleError) {
      return m('div', {
        class: [
          'retail-errors__single-error',
          options.center ? 'retail-errors__single-error--center' : null
        ].join(' ')
      }, m.trust(singleError));
    }));
  };

  return ErrorMessage;

});
