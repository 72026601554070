defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/ConfirmAccountRestriction', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Link',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
], function (m, Utils, BackButton, Button, Link, StepHeader) {

  // This step is now only for restriction with 24hours.
  // All other scenarios is using DsLogin identify confirm, and does not go through this template.

  var StepConfirmAccountRestriction = function (controller) {
    var userModel = controller.model().fields;
    var onlineAccount = userModel.isRetailAccount() ? '' : '/OnlineAccount';
    var dictionaryKey = 'RestrictAccount/Confirm/24hours' + onlineAccount;

    var pageDescription = dictionaryKey + '/PageDescription';

    return m('div', [
      m('.retail-page__button-container', BackButton()),
      m('.retail-page__content',
        m('.retail-page__row has-rows-2', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, StepHeader({
            title: controller.d(dictionaryKey + '/PageTitle'),
            abstract: controller.d(pageDescription)
          })
          ),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, [

            m('.retail-button-container',
              [
                Button({
                  class: 'retail-button--white',
                  buttonText: controller.d(dictionaryKey + '/CancelButton'),
                  onclick: function () {
                    var returnUrl = Utils.getQueryParam('returnUrl');
                    if (returnUrl) {
                      window.location.href = returnUrl;
                    } else {
                      controller.route('/')();
                    }
                  }
                }),
                Button({
                  buttonText: controller.d(dictionaryKey + '/SubmitButton'),
                  onclick: function () {
                    controller.restrictAccount();
                  }
                })
              ]),
            m('.info-message', controller.d('CloseAccount/RofusText')),
            controller.rofusLink('InfoLink') ?
              m('div',
                Link({
                  class: 'retail-link--full-width',
                  href: controller.rofusLink('InfoLink').Url,
                  target: controller.rofusLink('InfoLink').Target,
                  text: controller.rofusLink('InfoLink').Text
                })
              )
              : null,
            controller.rofusLink('TreatmentPlacesLink') ?
              m('div',
                Link({
                  class: 'retail-link--full-width',
                  href: controller.rofusLink('TreatmentPlacesLink').Url,
                  target: controller.rofusLink('TreatmentPlacesLink').Target,
                  text: controller.rofusLink('TreatmentPlacesLink').Text
                })
              )
              : null,
          ])
        ])
      )
    ]);
  };

  return StepConfirmAccountRestriction;

});
