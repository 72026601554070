defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/InputField', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Svg',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorMessage'
], function (m, Utils, Svg, ErrorMessage) {

  var InputField = function (options) {
    var hasFocus = options.hasFocus === options.name;

    return m('.retail-input-container', {
      'data-uitest-id': 'retail-input-container' + (options.name ? '-' + options.name : '')
    }, [
      m('div', {
        class: [
          'retail-input-field',
          options.class ? options.class : null,
          options.hasErrors ? 'has-errors' : null,
          options.model() || options.placeholder ? 'has-value' : null,
          hasFocus === options.name ? 'has-focus' : null,
          options.disabled ? 'is-disabled' : null,
          options.placeholder ? 'has-placeholder' : null
        ].join(' ')
      }, [
        m('input', {
          class: 'retail-input-field__input js-retail-input-field__input',
          type: options.type || 'text',
          name: options.name || '',
          id: options.id || options.name,
          disabled: options.disabled || false,
          oninput: function (e) {
            if (typeof options.oninput === 'function') {
              options.oninput(e);
              return;
            }

            options.model(e.target.value);
          },
          onkeyup: function (e) {
            if (typeof options.onkeyup === 'function') {
              options.onkeyup(e);
            }
          },
          onfocus: function (e) {
            if (Utils.isMobile() || Utils.isTabletDevice()) {
              document.body.classList.add('has-keyboard-open');
            }

            if (typeof options.onfocus === 'function') {
              options.onfocus(e);
            }
          },
          onblur: function (e) {
            if (Utils.isMobile() || Utils.isTabletDevice()) {
              // Fix issue regarding click on button submit while keyboard is open
              // If we don't delay the bodyClass change, then the button jumps to another position and the click event is never triggered
              setTimeout(function () {
                document.body.classList.remove('has-keyboard-open');
              }, 100);
            }

            if (typeof options.onblur === 'function') {
              options.onblur(e);
            }
          },
          onmouseleave: function (ev) {
            if (typeof options.onmouseleave === 'function') {
              options.onmouseleave(ev);
            }
          },
          placeholder: options.placeholder || options.labelText,
          value: options.model(),
          forceNumeric: options.forceNumeric,
          config: options.config ?? function (element) {
            if (options.setcaret) {
              if (element.selectionStart > options.setcaret) {
                element.setSelectionRange(options.setcaret, options.setcaret);
              }
              options.setcaret = null;
            }
            if (options.forceNumeric) {
              element.setAttribute('inputmode', 'numeric');
              element.setAttribute('pattern', '[0-9]*');
            }
          }
        }),
        options.labelText ? m('label', {
          class: 'retail-input-field__label',
          for: options.id || options.name,
          'data-label-text': options.labelText
        }, m('span.retail-input-field__label-placeholder', options.labelText)) : null,
        options.noErrorIcon ? null : Svg('round-error', {
          class: 'retail-input-field__error-svg',
        })
      ]),
      hasFocus && options.helpText ? m('.retail-input-field__help-text', options.helpText) : null,
      options.hasErrors ? ErrorMessage(options.hasErrors) : null
    ]);
  };

  return InputField;

});
