defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Phone', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ProgressBar',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InputField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, ProgressBar, BackButton, InputField, Button, StepHeader) {

  var StepPhone = function (controller) {
    var userModel = controller.model().fields;
    var errors = controller.model().errors;

    return [
      controller.progress ?
        ProgressBar({
          progress: controller.progress()
        }) : null,
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton({
          onclick: controller.routeToPrevStep ? controller.routeToPrevStep : null
        })) : null,
      m('.retail-page__content',
        m('.retail-page__row has-rows-2', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, StepHeader({
            title: controller.d('Phone/PageTitle'),
            abstract: controller.d('Phone/PageDescription')
          })
          ),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, [
            InputField({
              model: userModel.phone,
              onkeyup: controller.validateField,
              hasErrors: errors.phone(),
              name: 'phone',
              type: 'tel',
              labelText: controller.d('Phone/LabelPhone')
            }),
            m('div.retail-button-container.retail-button-container--centered',
              Button({
                isDisabled: controller.buttonIsDisabled(),
                isLoading: controller.isSubmitting(),
                buttonText: controller.d('Phone/SubmitButton'),
                onclick: controller.submit
              })
            )
          ])
        ])
      )
    ];
  };

  return StepPhone;

});