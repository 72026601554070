defineDs('Shared/Framework/Mithril/Scripts/Helpers/RouteParam', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
], function (m) {

  var RouteParam = function (paramKey) {
    // Early return, as we're not trying to set anything
    return function (value) {
      if (value === undefined) return m.route.param(paramKey);
      var params = m.route.param();
      if (paramKey !== 'page') params.page = 1;

      var str = Object.keys(params).reduce(function (string, key) {
        var paramValue = key === paramKey ? value : params[key];
        return string + paramValue + '/';
      }, '/');
      m.route(str);
    };
  };

  return RouteParam;

});
