defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/RadioButton', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorMessage'
], function (m, ErrorMessage) {

  var RadioButton = function (options) {
    return [
      m('div', {
        class: [
          'retail-radio-button-container',
          options.hasErrors ? 'has-errors' : null,
          options.isDisabled ? 'is-disabled' : null,
          options.class ? options.class : null
        ].join(' '),
        'data-uitest-id': 'retail-radio-button-container' + (options.name ? '-' + options.name : '')
      }, [
        m('input', {
          type: 'radio',
          class: 'retail-radio-button__input',
          name: options.name || '',
          id: options.id || options.name,
          checked: options.model(),
          disabled: options.isDisabled || false,
          onkeyup: function (ev) {
            if (ev.keyCode === 32 || ev.key === 'Enter') {
              options.model(true);
              if (options.onchange) {
                options.onchange(ev);
              }
            }
          },
          onchange: function (ev) {
            options.model(true);

            if (options.onchange) {
              options.onchange(ev);
            }
          },
          'data-uitest-id': 'retail-radio-button__input' + (options.name ? '-' + options.name : '')
        }),
        m('label', {
          class: [
            'retail-radio-button__label',
            options.labelClass ? options.labelClass : null
          ].join(' '),
          for: options.id || options.name
        }, m.trust(options.labelText))
      ]),
      options.hasErrors ? ErrorMessage(options.hasErrors) : null
    ];
  };

  return RadioButton;

});
