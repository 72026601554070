defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Models/ForgotPasswordModel', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Mithril/Scripts/Core/Model',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
], function (m, Model, Utils) {
  'use strict';

  var ForgotPasswordModel = Model('ForgotPasswordModel', function () {
    this.token = m.prop();

    this.fields = {
      password: m.prop(null),
      repeatPassword: m.prop(null),
    };

    /*
     * Create an error key for each field value in the model (Doing this programatically do avoid double maintenance of fields in model)
    */
    this.errors = {};

    this.clearErrors = function () {
      Object.keys(this.fields).forEach(function (field) {
        this.errors[field] = m.prop(null);
      }.bind(this));
    }.bind(this);

    this.clearErrors();

    // Do simple validation to check if field is not null, not false and not empty string
    this.validate = function (validateFields, setErrors) {
      if (!validateFields) return;
      if (typeof validateFields === 'string') [validateFields];
      if (typeof setErrors === 'undefined') {
        setErrors = true; // If setErrors is not set then default to true
      }

      var hasErrors = false;

      for (var index in validateFields) {
        var fieldName = validateFields[index];
        var val = this.fields[fieldName]();
        var isNotValid = null;

        if (val === false) {
          isNotValid =  Utils.getValidationMessage('required');
        } else if ((!val && val !== 0) || (typeof val === 'string' && val.trim() === '')) {
          isNotValid = Utils.getValidationMessage('isEmpty');
        }

        if (setErrors) {
          this.errors[fieldName](isNotValid);
        }

        if (!hasErrors) {
          hasErrors = isNotValid;
        }
      }

      return hasErrors;
    }.bind(this);
  });

  return ForgotPasswordModel;
});
