defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril'
], function (m) {

  const Button = (options) => {
    const { config, type, isLoading, isDisabled, onFocus, onclick, buttonText, href, uiTestId = 'retail-button' } = options;
    const elemType = href ? 'a' : 'button';

    let attributes = {};
    if (href) {
      attributes.href = href;
    }

    if (elemType === 'button') {
      attributes.type = type || 'button';
    }

    return m(elemType, Object.assign(attributes, {
      config: config || null,
      disabled: isLoading || false, // We do NOT disable button on props.isDisabled, because users will often click the submit button when final input field has not been validated (because field still has focus)
      class: [
        'retail-button',
        'has-text',
        isDisabled ? 'is-disabled' : null,
        isLoading ? 'is-loading' : null,
        options.class ? options.class : null
      ].join(' '),
      onfocus: () => {
        if (typeof onFocus === 'function') {
          onFocus();
        }
      },
      onclick: (ev) => {
        if (typeof onclick === 'function') {
          ev.preventDefault();
          onclick();
        }
      },
      'aria-label': buttonText,
      'data-uitest-id': uiTestId,
    }), [
      m('span', {
        'data-text': isLoading ? 'Loading' : buttonText,
        class: 'retail-button__text'
      }, isLoading ? 'Loading' : buttonText)
    ]);
  };

  return Button;
});
