defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Username', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ProgressBar',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InputField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, ProgressBar, BackButton, InputField, Button, StepHeader) {

  var StepUsername = function (controller) {
    var userModel = controller.model().fields;
    var errors = controller.model().errors;

    return m('div', [
      controller.progress ?
        ProgressBar({
          progress: controller.progress()
        }) : null,
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton()) : null,
      m('.retail-page__content',
        m('.retail-page__row has-rows-2', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, StepHeader({
            title: controller.d('Username/PageTitle'),
            abstract: controller.d('Username/PageDescription')
          })
          ),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, controller.model().usernameIsSet() ?
            m('div', controller.d('Username/UsernameIsAlreadySet'))
            : [
              InputField({
                model: userModel.username,
                onfocus: controller.setFieldFocus,
                onkeyup: controller.validateField,
                hasErrors: errors.username(),
                name: 'username',
                labelText: controller.d('Username/LabelUsername'),
                helpText: controller.d('Username/HelpTextUsername')
              }),
              m('div.retail-button-container.retail-button-container--centered',
                Button({
                  isDisabled: controller.buttonIsDisabled(),
                  isLoading: controller.isSubmitting(),
                  buttonText: controller.d('Username/SubmitButton'),
                  onclick: controller.createUsername
                })
              )
            ]
          )
        ])
      )
    ]);
  };

  return StepUsername;

});
