defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/LimitPeriodConfirm', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ProgressBar',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InfoRow'
], function (m, Utils, ProgressBar, BackButton, Button, StepHeader, InfoRow) {

  const getVariant = (isRetailAccount, initiator) => {
    if (initiator) {
      return '';
    }

    if (!isRetailAccount) {
      return '/Variant2';
    } else {
      return '/Variant1';
    }
  };

  const LimitPeriodConfirm = function (controller, initiator) {
    var userModel = controller.model().fields;
    var dictionaryKey = controller.currentStep().dictionaryKey;
    var limitPeriodType;

    const variant = getVariant(userModel.isRetailAccount(), initiator);

    const forceRestartFlowIfNoTypeOrValue = () => {
      userModel.offlineWagerLimitPeriodDaily(null);
      userModel.offlineWagerLimitPeriodWeekly(null);
      userModel.offlineWagerLimitPeriodMonthly(null);

      if (typeof controller.goToRoute === 'function') {
        controller.goToRoute('/');
      }
    };

    if (!userModel.offlineWagerLimit()) {
      forceRestartFlowIfNoTypeOrValue();
    }

    switch (userModel.offlineWagerLimitPeriod()) {
    case 'daily':
      limitPeriodType = controller.d(`${dictionaryKey}/Daily`);
      break;
    case 'weekly':
      limitPeriodType = controller.d(`${dictionaryKey}/Weekly`);
      break;
    case 'monthly':
      limitPeriodType = controller.d(`${dictionaryKey}/Monthly`);
      break;
    default:
      forceRestartFlowIfNoTypeOrValue();
      break;
    }

    return [
      controller.progress ?
        ProgressBar({
          progress: controller.progress()
        }) : null,
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton({
          onclick: controller.routeToPrevStep
        })) : null,
      m('.retail-page__content', {
        class: controller.hideBackButton() ? 'retail-page__no-progress' : ''
      },
      m('.retail-page__row has-rows-2', [
        m('div', {
          class: 'retail-page__column retail-page__column--1'
        }, StepHeader({
          title: controller.d(`${dictionaryKey + variant}/PageTitle`, { TYPE: limitPeriodType }),
          abstract: controller.d(`${dictionaryKey + variant}/PageDescription`)
        })
        ),
        m('div', {
          class: 'retail-page__column retail-page__column--2 retail-rich-text'
        }, [
          m('h3', { class: 'retail-rich-text__title' }, controller.d(`${dictionaryKey}/TypeLabel`)),
          InfoRow({
            labelText: controller.d(`${dictionaryKey}/InputLabel`, { TYPE: Utils.capitalize(limitPeriodType) }),
            value: Utils.formatCurrency(userModel.offlineWagerLimit()),
            horizontal: true,
            class: 'retail-info-row--not-bold'
          }),
          m('h3', { class: 'retail-rich-text__title' }, controller.d(`${dictionaryKey}/PendingLabel`)),
          InfoRow({
            labelText: controller.d(`${dictionaryKey + variant}/EffectiveLabel`),
            horizontal: true,
            class: 'retail-info-row--not-bold'
          }),
          m('div.retail-button-container.retail-button-container--centered',
            Button({
              isDisabled: controller.buttonIsDisabled(),
              isLoading: controller.isSubmitting(),
              buttonText: controller.d(`${dictionaryKey}/SubmitButton`),
              onclick: controller.submit
            })
          )
        ])
      ])
      )
    ];
  };

  return LimitPeriodConfirm;

});