defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Security', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Framework/PlayerAccountManagement/Scripts/Templates/StepGuide',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Dropdown',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InputField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, StepGuide, Button, Dropdown, InputField, StepHeader) {

  var StepSecurity = function (controller) {
    var userModel = controller.model().fields;
    var errors = controller.model().errors;

    return m('div', [
      StepGuide(4, controller.totalSteps()),
      m('.pam-page__content', [
        m('div', {
          class: 'pam-page__header'
        }, StepHeader({
          title: controller.d('Security/PageTitle'),
          abstract: controller.d('Security/PageDescription')
        })
        ),
        Dropdown({
          model: userModel.securityQuestion,
          choices: controller.model().securityQuestions(),
          onchange: controller.validateField,
          hasErrors: errors.securityQuestion(),
          name: 'securityQuestion',
          labelText: controller.d('Security/LabelQuestion'),
          placeholder: controller.d('Security/LabelSelectQuestion')
        }),
        InputField({
          model: userModel.securityAnswer,
          onfocus: controller.setFieldFocus,
          onblur: controller.validateField,
          hasErrors: errors.securityAnswer(),
          hasFocus: controller.fieldInFocus(),
          name: 'securityAnswer',
          type: 'text',
          labelText: controller.d('Security/LabelAnswer'),
          helpText: controller.d('Security/HelpTextAnswer')
        }),
        m('div.retail-button-container.retail-button-container--centered',
          Button({
            isDisabled: controller.buttonIsDisabled(),
            isLoading: controller.isSubmitting(),
            buttonText: controller.d('Security/SubmitButton'),
            onclick: function () {
              controller.submit(true);
            },
            uiTestId: 'retail-button-submit-button'
          })
        )
      ])
    ]);
  };

  return StepSecurity;

});
