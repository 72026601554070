defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton', [
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/IconButton'
], function (IconButton) {

  var BackButton = function (config) {
    var options = {
      icon: 'arrow_left',
      title: 'Tilbage',
      onclick: null,
      class: 'retail-back-button',
      ...config
    };

    return IconButton(options);
  };

  return BackButton;

});
