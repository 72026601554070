defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Components/RetailUpdateAccount', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'DanskeSpil/Domain/Authentification/Scripts/LoginCache',
  'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Dictionary',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Api',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/UserModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Loader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorOverlay',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Intro',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/LimitPeriodSelection',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/LimitPeriodValue',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/LimitPeriodConfirm',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/PINCode',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Receipt'
],
function (Component, LoginCache, FrameworkOverlay, Dictionary, Utils, RetailApi, UserModel, Loader, ErrorOverlay, Intro, LimitPeriodSelection, LimitPeriodValue, LimitPeriodConfirm, PINCode, Receipt) {

  // Declare steps
  var routeConfiguration = [
    { route: '/', template: Intro },
    { order: 2, route: '/pincode', fields: ['pincode'], skipSubmit: true, hideBackButton: true, dictionaryKey: 'SetPINCode', template: PINCode },
    { order: 3, route: '/repeat-pincode', fields: ['pincode', 'pincodeConfirm'], optionalFields: ['adviseEloquaOfAccountUpdate'], dictionaryKey: 'ConfirmPINCode', template: PINCode },
    { order: 4, route: '/limit/period', fields: ['offlineWagerLimitPeriod'], hideBackButton: true, skipSubmit: true, dictionaryKey: 'LimitPeriodSelection', template: LimitPeriodSelection },
    { order: 5, route: '/limit/value', fields: ['offlineWagerLimitPeriod', 'offlineWagerLimit'], skipSubmit: true, dictionaryKey: 'LimitPeriodValue', template: LimitPeriodValue },
    { order: 6, route: '/limit/confirm', fields: ['offlineWagerLimitPeriod', 'offlineWagerLimit'], optionalFields: ['adviseEloquaOfAccountUpdate'], dictionaryKey: 'LimitPeriodConfirm', template: LimitPeriodConfirm },
    { order: 7, route: '/stepReceipt', template: Receipt },
  ];

  // Get the current step from route on page load
  var currentStep = routeConfiguration.filter(function (step) {
    var locationHash = window.location.hash.toLowerCase();
    var hash = locationHash.replace('#retail-update-account=', '');
    return hash === step.route.toLowerCase();
  });

  // It empty get the current step from the routeConfiguration array (setting [0] in the controller)
  if (!currentStep.length) {
    currentStep = routeConfiguration[0];
  } else {
    currentStep = currentStep[0];
  }

  // Component:
  Component('retail-update-account', [Dictionary], function (m, route, settings) {

    var root = {
      controller: function () {
        this.d = Dictionary.get.bind({ prefix: 'RetailUpdateAccount/' });
        this.isLoading = m.prop(true);
        this.buttonIsDisabled = m.prop(false);
        this.isSubmitting = m.prop(false);
        this.model = m.prop(new UserModel());
        this.modalError = m.prop(null);
        this.routeConfiguration = routeConfiguration;
        this.currentStep = m.prop(currentStep);
        this.receiptLink = m.prop(settings.receiptLink);
        this.receiptContent = m.prop({
          title: this.d('Receipt/AlreadyUpdatedTitle'),
          buttonText: settings.receiptLink.Text,
          icon: 'circle_checkmark',
          callback: function () {
            if (Utils.getQueryParam('returnUrl')) {
              window.location.href = Utils.getQueryParam('returnUrl');
            } else {
              window.location.href = settings.receiptLink.Url;
            }
          },
          timeout: 30000,
          partialTemplate: null
        });
        this.fieldInFocus = m.prop();
        this.backAllowed = m.prop(false);
        this.showRetailMarketingTermsInEmail = m.prop(false);
        this.showEmailPermissionTermsInEmail = m.prop(false);
        this.showAppPermissionTerms = m.prop(false);
        this.noLimitPeriodSelected = m.prop(null);
        this.userNeedsInteraction = m.prop(true);
        this.hideBackButton = m.prop(false);
        this.maskPincodeInput = m.prop(false);
        this.globalWagerLimits = m.prop(null);
        this.logoutRedirectUrl = Utils.appendParameter('loginSuccessUrl', encodeURIComponent(window.location.href), settings.loginLink.Url);
        Utils.redirectOnLogout(this.logoutRedirectUrl);

        var getProfile = function () {
          return RetailApi.getProfileWithBankInformation().then(function (response) {
            var model = response.data.profile || {};
            this.model().update(model);

            if (this.model().fields.pincode() && this.model().fields.offlineWagerLimit()) {
              this.userNeedsInteraction(false);
              this.routeToNextStep();
            } else {
              let dictionaryReceiptKey = '';

              if (this.model().fields.isRetailAccount()) {
                if (this.model().fields.offlineWagerLimit()) {
                  dictionaryReceiptKey = 'Variant1/';
                } else {
                  dictionaryReceiptKey = 'Variant2/';
                }
              } else {
                if (this.model().fields.offlineWagerLimit() && this.model().fields.hasOldOfflineWagerLimit()) {
                  dictionaryReceiptKey = 'Variant4/';
                } else if (this.model().fields.offlineWagerLimit()) {
                  dictionaryReceiptKey = 'Variant3/';
                } else {
                  dictionaryReceiptKey = 'Variant2/';
                }
              }

              this.receiptContent().title = this.d(`Receipt/${dictionaryReceiptKey}Title`);
              this.receiptContent().buttonText = this.d(`Receipt/${dictionaryReceiptKey}CTA`);

              if (['Variant3/', 'Variant4/'].includes(dictionaryReceiptKey)) {
                this.receiptContent().partialTemplate = m('.info-message.info-message--grey', m('h3', this.d(`Receipt/${dictionaryReceiptKey}NoticeTitle`)), m('div', m.trust(this.d(`Receipt/${dictionaryReceiptKey}NoticeDescription`))));
              }
            }

            const skipFirsStepForVendor = ['SHAPE_RETAILACCOUNT_MOBILENATIVE', 'SHAPE_RETAILACCOUNT_IPADNATIVE', 'SHAPE_ODDSET_MOBILENATIVE', 'SHAPE_DANTOTO_MOBILENATIVE'];
            if (window.dsApplicationConfig && skipFirsStepForVendor.includes(window.dsApplicationConfig?.ApplicationId)) {
              this.routeToNextStep();
            }

            if (window.dsApplicationConfig && ['SHAPE_RETAILACCOUNT_IPADNATIVE'].includes(window.dsApplicationConfig?.ApplicationId)) {
              this.maskPincodeInput(true);
            }

            this.isLoading(false);
            m.redraw();
          }.bind(this), function (error) {
            this.showErrorModal(error);
          }.bind(this));
        }.bind(this);

        this.goToRoute = function (routePath) {
          var step = this.routeConfiguration.filter(function (step) {
            return step.route.toLowerCase() === routePath.toLowerCase();
          })[0];

          this.currentStep(step);
          Utils.pushVirtualPage(routePath, (window.location.pathname.replace(/-/g, '_') + routePath), routePath);
          window.scrollTo(0, 0);
          route(routePath)();
        }.bind(this);

        this.showErrorModal = (error) => {
          var confirm = null;

          if (error.statusCode === 401) {
            confirm = function () {
              window.location.href = Utils.appendParameter('loginSuccessUrl', encodeURIComponent(window.location.href), settings.loginLink.Url);
            };
          }

          this.modalError(new FrameworkOverlay(ErrorOverlay({
            title: this.d('ErrorModal/Title'),
            body: error.message,
            confirm: confirm,
            dismisable: false,
            confirmButtonClass: settings.loginLink.CssClass || null,
            confirmButton: confirm ? settings.loginLink.Text : null
          }))).show();
        };

        this.validateField = (ev, validateHandler) => {
          if (ev.key === 'Tab' && !ev.target.value.length) return;

          var fieldName = ev.target.name;
          this.model().validate([fieldName]);

          var hasErrors = this.model().validate(this.currentStep().fields, false);

          if (!hasErrors && typeof validateHandler === 'function') {
            validateHandler();
          }
        };

        this.mergeAndValidateField = (ev, validateHandler) => {
          if (this.currentStep().route === '/pincode') {
            this.mergeFieldsToSingleModelField('pincode', ['pin1', 'pin2', 'pin3', 'pin4']);
          }

          if (this.currentStep().route === '/repeat-pincode') {
            this.mergeFieldsToSingleModelField('pincodeConfirm', ['pin1Confirm', 'pin2Confirm', 'pin3Confirm', 'pin4Confirm']);
          }

          this.validateField(ev, validateHandler);
        };

        this.mergeFieldsToSingleModelField = (newFieldName, fieldNames = []) => {
          const fields = fieldNames.map((fieldName) => {
            return this.model().fields[fieldName]();
          });

          this.model().fields[newFieldName](fields.join(''));
        };

        this.findFirstErrorAndRouteToPage = (errors) => {
          if (errors.includes('pincode') || errors.includes('pincodeConfirm')) {
            this.resetFields(['pincode', 'pincodeConfirm', 'pin1', 'pin2', 'pin3', 'pin4', 'pin1Confirm', 'pin2Confirm', 'pin3Confirm', 'pin4Confirm']);
          }

          if (errors.includes('offlineWagerLimitPeriod')) {
            this.resetFields(['offlineWagerLimitPeriodDaily', 'offlineWagerLimitPeriodWeekly', 'offlineWagerLimitPeriodMonthly', 'offlineWagerLimitPeriod']);
          }

          if (errors.includes('offlineWagerLimit')) {
            this.resetFields(['offlineWagerLimit']);
          }

          const step = this.routeConfiguration.find((routeConfig) => routeConfig.fields?.some((fieldKey) => errors.includes(fieldKey)));
          this.currentStep(step);
          this.hideBackButton(this.currentStep().hideBackButton || false);
          this.goToRoute(step.route);
        };

        this.isValidWithinGlobalLimits = () => {
          const type = Utils.capitalize(this.model().fields.offlineWagerLimitPeriod());
          const value = this.model().fields.offlineWagerLimit();

          if (!this.globalWagerLimits()) {
            return true;
          }

          const globalLimit = this.globalWagerLimits().find((limit) => {
            return limit.Type.toLowerCase() === type.toLowerCase() && (limit.IsGlobal || limit.IsOperator);
          });

          if (!globalLimit) {
            return true;
          }

          if (Number(value) <= Utils.convertCentsToKroner(globalLimit.Value)) {
            return true;
          }

          this.model().errors.offlineWagerLimit(this.d(`Errors/ValueAboveMaxLimit${type}`, { VALUE: Utils.formatCurrency(value), LIMIT: Utils.convertCentsToKronerAndFormat(globalLimit.Value) }));
          return false;
        };

        this.submit = function () {
          var fields = this.currentStep().fields;
          var hasErrors = this.model().validate(fields);

          if (hasErrors) {
            return;
          }

          if (this.currentStep().route === '/pincode') {
            this.resetFields(['pincodeConfirm', 'pin1Confirm', 'pin2Confirm', 'pin3Confirm', 'pin4Confirm']);
            this.routeToNextStep(null, true);
            return;
          }

          if (this.currentStep().route === '/limit/value') {
            if (!this.isValidWithinGlobalLimits()) {
              return;
            }

            this.goToRoute('/limit/confirm');
            return;
          }

          if (this.currentStep().skipSubmit) {
            this.routeToNextStep();
            return;
          }

          if (this.model().fields.pincode() && this.model().fields.offlineWagerLimit()) {
            this.model().fields.adviseEloquaOfAccountUpdate(true);
          }

          if (this.currentStep().optionalFields) {
            fields = this.currentStep().fields.concat(this.currentStep().optionalFields);
          }

          var data = Utils.mapModelToApiData(fields, this.model().fields, this.model().initialValues);
          this.isLoading(true);

          RetailApi.updateProfile(data).then(function (response) {
            var errors = this.model().setErrors(response.data, true);
            this.buttonIsDisabled(true);

            if (errors) {
              this.findFirstErrorAndRouteToPage(errors);
            } else {
              this.model().update(response.data.profile); // Make sure our frontend UserModel is in sync with backend
              this.routeToNextStep();
            }
          }.bind(this), function (error) {
            this.showErrorModal(error);
          }.bind(this)).then(function () {
            this.isLoading(false);
            m.redraw();
          }.bind(this));
        }.bind(this);

        this.resetFields = (fields) => {
          fields.forEach((fieldKey) => {
            this.model().fields[fieldKey](null);
          });
        };

        this.routeToPrevStep = function () {
          var currentStep = this.currentStep();
          this.resetFields(currentStep.fields);

          var newStep = this.routeConfiguration[currentStep.order - 2];
          this.currentStep(newStep);

          if (!newStep) {
            return;
          }

          if (newStep.route === '/pincode') {
            this.resetFields(['pin1', 'pin2', 'pin3', 'pin4']);
          }

          if (currentStep.route === '/repeat-pincode') {
            this.resetFields(['pin1Confirm', 'pin2Confirm', 'pin3Confirm', 'pin4Confirm']);
          }

          if (currentStep.route === '/limit/value') {
            this.resetFields(['offlineWagerLimitPeriodDaily', 'offlineWagerLimitPeriodWeekly', 'offlineWagerLimitPeriodMonthly', 'offlineWagerLimitPeriod']);
          }

          this.hideBackButton(this.currentStep().hideBackButton || false);

          Utils.pushVirtualPage(newStep.route, (window.location.pathname.replace(/-/g, '_') + newStep.route), newStep.route);
          route(newStep.route)();
          window.scrollTo(0, 0);
        }.bind(this);

        this.routeToNextStep = function (routePath, setAutoFocus) {
          if (routePath) {
            Utils.pushVirtualPage(routePath, (window.location.pathname.replace(/-/g, '_') + routePath), routePath);
            this.goToRoute(routePath);
            return;
          }

          for (var index in this.routeConfiguration) {
            var step = this.routeConfiguration[index];
            var requiredFields = step.fields;

            // If route does not have missing fields and is part of the flow (defined by order), then load this
            if (!requiredFields && step.order) {
              Utils.pushVirtualPage(step.route, (window.location.pathname.replace(/-/g, '_') + step.route), step.route);
              this.currentStep(step);
              this.hideBackButton(this.currentStep().hideBackButton || false);

              route(step.route)();
              window.scrollTo(0, 0);
              break;
            }

            if (!requiredFields) {
              continue;
            }

            var missingFields = requiredFields.filter(function (field) {
              return !this.model().fields[field]();
            }.bind(this));

            if (missingFields.length) {
              Utils.pushVirtualPage(step.route, (window.location.pathname.replace(/-/g, '_') + step.route), step.route);
              this.currentStep(step);
              this.hideBackButton(this.currentStep().hideBackButton || false);

              route(step.route)();
              window.scrollTo(0, 0);
              break;
            }
          }

          if (setAutoFocus) {
            if (step.route === '/pincode') {
              document.querySelector('[name="pin1"]')?.focus();
            }

            if (step.route === '/repeat-pincode') {
              document.querySelector('[name="pin1Confirm"]')?.focus();
            }
          }

          if (step.route === '/stepReceipt') {
            const playerId = LoginCache.getPlayerId();

            if (playerId && this.userNeedsInteraction()) {
              Utils.pushGaEvent('sikkert_spil', 'spil_id_updated', '', playerId);
            }
          }
        }.bind(this);

        this.setFieldFocus = function (e) {
          if (typeof this.fieldInFocus !== 'function') {
            return;
          }

          // If function is called with event from onfocus handler, set field to have focus.
          // If called without event, then set first input field to have focus
          var fieldName = e && e.target ? e.target.name : null;

          if (fieldName) {
            this.fieldInFocus(fieldName);
          }
        }.bind(this);

        var getDepositLimits = () => {
          return RetailApi.getUserDepositLimits().then((response) => {
            this.globalWagerLimits(response.limits);
          });
        };

        m.sync([getProfile(), getDepositLimits()]);

        document.body.setAttribute('data-override-logout-return-url', settings.homeLink?.Url || `/${window.location.pathname.split('/')[1]}`);

        Utils.ensightenEvent('login', 'success_used_prompt_for_update');
      },
      view: function (controller) {
        if (controller.isLoading()) {
          return Loader();
        }

        return m('div', {
          class: 'retail-page retail-update-account',
          'data-uitest-id': Utils.replaceSlashWithHyphens(route(), 'retail-page-update-account')
        }, routeConfiguration.reduce(function (template, step) {
          if (route() === step.route) {
            template = step.template(controller);
          }

          return template;
        }, null));
      }
    };


    // Routes
    route('/', root);

    for (var index in routeConfiguration) {
      var step = routeConfiguration[index];

      route(step.route, root);
    }
  });

});
