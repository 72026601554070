defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Limits', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ProgressBar',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/LimitField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorMessage'
], function (m, Utils, ProgressBar, BackButton, LimitField, Button, StepHeader, ErrorMessage) {

  var StepLimits = function (controller, initiator) {
    const dictionaryKey = controller.currentStep().dictionaryKey;

    const getVariant = (isRetailAccount, initiator) => {
      if (initiator) {
        return '';
      }

      if (!isRetailAccount) {
        return '/Variant2';
      } else {
        return '/Variant1';
      }
    };

    const variant = getVariant(controller.model().fields.isRetailAccount(), initiator);

    const cannotDeleteLimit = (limit) => {
      const max = limit.max();
      const userLimit = limit.value();
      const isOperator = limit.isOperator();
      return !!(max === userLimit && isOperator);
    };

    return m('div', [
      controller.progress ?
        ProgressBar({
          progress: controller.progress()
        }) : null,
      m('.retail-page__button-container', [
        !controller.hideBackButton() ? BackButton({
          onclick: () => { controller.goToRoute('/'); }
        }) : null
      ]),
      m('.retail-page__content',
        m('.retail-page__row has-rows-2', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, StepHeader({
            title: controller.d(`${dictionaryKey + variant}/PageTitle`),
            abstract: controller.d(`${dictionaryKey + variant}/PageDescription`)
          })
          ),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, [
            controller.model().userLimits().limits().map(function (limit) {
              return new LimitField({
                model: limit,
                hasErrors: limit.hasErrors().length > 0,
                postfix: controller.postfix,
                confirm: controller.goToConfirmLimit,
                buttonText: controller.d(`${dictionaryKey}/Add${limit.type()}Button`),
                limitText: controller.d(`${dictionaryKey}/${limit.type()}Limit`),
                stateText: limit.plannedAt() ? (limit.plannedValue() ? controller.d(`${dictionaryKey}/ChangedLimit`, {
                  TYPE: controller.d(`${dictionaryKey}/${limit.type()}`),
                  VALUE: Utils.formatCurrency(limit.plannedValue()),
                  ACTIVEFROM: Utils.formatDateTime(new Date(limit.plannedAt()))
                }) : controller.d(`${dictionaryKey}/DeletedLimit`, {
                  TYPE: controller.d(`${dictionaryKey}/${limit.type()}`),
                  ACTIVEFROM: Utils.formatDateTime(new Date(limit.plannedAt()))
                })) : null,
                operatorText: controller.d(`${dictionaryKey}/OperatorLimit`, {
                  TYPE: controller.d(`${dictionaryKey}/${limit.type()}`),
                  VALUE: Utils.formatCurrency(limit.max())
                }),
                cancelDelete: controller.d(`${dictionaryKey}/CancelDelete`),
                cancelChange: controller.d(`${dictionaryKey}/CancelChange`),
                cannotDelete: cannotDeleteLimit(limit),
              });
            }),
            controller.noLimitsChanged() && controller.d(`${dictionaryKey}/Errors/NoLimitsChanged`).length > 0 ? ErrorMessage([controller.d(`${dictionaryKey}/Errors/NoLimitsChanged`)]) : null,
            m('div.retail-button-container.retail-button-container--centered',
              Button({
                buttonText: controller.d(`${dictionaryKey}/SubmitButton`),
                onclick: controller.goToConfirmLimit
              })
            )
          ])
        ])
      )
    ]);
  };

  return StepLimits;

});
