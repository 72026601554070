defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Components/RetailGameHistory', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Dictionary',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorOverlay',
  'DanskeSpil/Domain/RetailAccount/Scripts/Components/RetailGameHistoryList',
  'DanskeSpil/Domain/RetailAccount/Scripts/Components/RetailGameHistoryDetails',
  'DanskeSpil/Domain/RetailAccount/Scripts/Components/RetailGameHistoryOverview',
  'DanskeSpil/Domain/RetailAccount/Scripts/Components/RetailGameHistoryPrize',
  'DanskeSpil/Domain/RetailAccount/Scripts/Components/RetailGameHistoryPayout'
], (Component, FrameworkOverlay, Utils, Dictionary, ErrorOverlay, RetailGameHistoryList, RetailGameHistoryDetails, RetailGameHistoryOverview, RetailGameHistoryPrize, RetailGameHistoryPayout) => {

  Component('retail-game-history', [Dictionary], function (m, ignoredRoute, settings, ignoredProperty, element) {
    this.settings = settings;
    this.modalError = m.prop(null);
    const defaultRoute = `/${this.settings.page}/${this.settings.period.toLowerCase()}/${this.settings.offset}/${this.settings.vertical.toLowerCase()}`;

    document.body.setAttribute('data-override-logout-return-url', `/${window.location.pathname.split('/')[1]}`);
    Utils.setupLoginLinkEventListeners();

    this.showErrorModal = (error) => {
      let confirm = null;

      if (error.statusCode === 401) {
        confirm = () => {
          window.location.href = Utils.appendParameter('loginSuccessUrl', encodeURIComponent(window.location.href), settings.loginLink.Url);
        };
      }

      this.modalError(new FrameworkOverlay(ErrorOverlay({
        title: error.message || this.d('ErrorModal/Title'),
        confirm: confirm,
        dismisable: true,
        dismissButton: null, // Hide button, but allow modal icon close
        confirmButtonClass: settings.loginLink.CssClass || null,
        confirmButton: confirm ? settings.loginLink.Text : null,
      }))).show();
    };

    m.route.mode = 'hash';

    m.route(element, defaultRoute, {

      '/:page/:period/:offset/:type': m.component(RetailGameHistoryList, {
        settings: this.settings,
        showErrorModal: this.showErrorModal
      }),

      '/overblik/': m.component(RetailGameHistoryOverview, {
        settings: this.settings,
        showErrorModal: this.showErrorModal
      }),

      '/:page/:period/:offset/:type/:gameId': m.component(RetailGameHistoryDetails, {
        settings: this.settings,
        showErrorModal: this.showErrorModal
      }),

      '/prize/:gameId': m.component(RetailGameHistoryPrize, {
        settings: this.settings,
        showErrorModal: this.showErrorModal
      }),

      '/payout/:gameId': m.component(RetailGameHistoryPayout, {
        settings: this.settings,
        showErrorModal: this.showErrorModal,
        returnUrl: `${window.location.pathname}#${defaultRoute}`
      }),

    });

  });

});