defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Dropdown', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorMessage'
], function (m, ErrorMessage) {

  var Dropdown = function (options) {
    var isUntouched =  options.model() > -1 || (options.model() && options.model().toString().length);

    return m('div', [
      m('div', {
        class: [
          'retail-dropdown-container',
          'has-value',
          options.hasErrors ? 'has-errors' : null,
        ].join(' ')
      }, [
        m('select', {
          class: [
            'retail-dropdown__select',
            isUntouched ? 'retail-dropdown__select--untouched' : ''
          ].join(' '),
          name: options.name || '',
          id: options.id || options.name,
          onchange: function (e) {
            options.model(options.choices[this.selectedIndex - (options.placeholder ? 1 : 0)].Id);
            if (options.onchange) {
              options.onchange(e);
            }
          }
        }, [
          options.placeholder ? m('option', {
            selected: isUntouched,
            hidden: true,
            disabled: true
          }, options.placeholder) : null,
          options.choices.map(function (choice) {
            return m('option', {
              value: choice.Id,
              selected: choice.Id === options.model()
            }, choice.Name);
          })
        ]),
        isUntouched ? m('label', {
          class: 'retail-dropdown__label',
          for: options.id || options.name,
          'data-label-text': options.labelText
        }, m('span.retail-input-field__label-placeholder', m.trust(options.labelText))) : null
      ]),
      options.hasErrors ? ErrorMessage(options.hasErrors) : null
    ]);
  };

  return Dropdown;

});
