defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Fireworks', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril'
], function (m) {


  var Firework = function (config) {
    var options = {
      scale: 1,
      left: '50%',
      top: '50%',
      offset: '500',
      fragments: 12,
      ...config
    };

    return m('.firework', {
      style: [
        'transform: scale(' + options.scale + ')',
        'left:' + options.left,
        'top:' + options.top,
        '--animation-offset:' + options.offset + 'ms'
      ].join(';')
    },
    Array.apply(null, Array(options.fragments)).map(function (ignoreItem, index) {
      return m('.fragment.fragment-' + options.fragments + '--' + (index + 1), '');
    })
    );
  };

  var Fireworks = function (options) {
    return m('.retail-prize__fireworks-container', [
      m('h2.retail-prize__title', options.title),
      m('.retail-prize__amount', options.amount),
      new Firework({ scale: 0.5, left: '30%', top: '40%', offset: 400 }),
      new Firework({ scale: 0.4, left: '90%', top: '10%', offset: 400, fragments: 6 }),
      new Firework({ scale: 0.8, left: '50%', top: '65%', offset: 800, fragments: 8 }),
      new Firework({ scale: 0.35, left: '65%', top: '25%', offset: 800 }),
      new Firework({ scale: 0.5, left: '10%', top: '75%', offset: 800 }),
      new Firework({ scale: 0.6, left: '20%', top: '75%', offset: 1200 }),
      new Firework({ scale: 0.5, left: '45%', top: '20%', offset: 1200 }),
      new Firework({ scale: 0.4, left: '70%', top: '40%', offset: 1200, fragments: 8 }),
      new Firework({ scale: 0.6, left: '95%', top: '5%', offset: 1600 }),
      new Firework({ scale: 0.45, left: '35%', top: '35%', offset: 1600 }),
      new Firework({ scale: 0.65, left: '10%', top: '75%', offset: 1600, fragments: 6 }),
      new Firework({ scale: 0.6, left: '75%', top: '70%', offset: 2000, fragments: 8 }),
      new Firework({ scale: 1, left: '20%', top: '15%', offset: 2000 }),
      new Firework({ scale: 0.5, left: '40%', top: '80%', offset: 2000, fragments: 6 }),
      new Firework({ scale: 0.6, left: '90%', top: '70%', offset: 2400 }),
      new Firework({ scale: 0.6, left: '10%', top: '15%', offset: 2400, fragments: 8 }),
      new Firework({ scale: 0.8, left: '45%', top: '5%', offset: 2400 })
    ]);
  };

  return Fireworks;
});
