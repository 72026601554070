defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/UpdateLimit', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Framework/PlayerAccountManagement/Scripts/Templates/StepGuide',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/LimitField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, StepGuide, Utils, LimitField, Button, StepHeader) {

  var StepUpdateLimit = function (controller) {
    var userLimitModel = controller.model().userLimits;

    const getPlaceholderText = (limit) => {
      if (!limit.value() && !limit.stored()) {
        return controller.d('UpdateLimit/NoLimitFound');
      } else if (!limit.value() && limit.stored()) {
        return controller.d('UpdateLimit/LimitWillBeDeleted', { VALUE: limit.stored() });
      } else {
        return null;
      }
    };

    return m('div', [
      StepGuide(5, controller.totalSteps()),
      m('.pam-page__content', [
        m('div', {
          class: 'pam-page__header'
        }, StepHeader({
          title: controller.d('UpdateLimit/PageTitle'),
          abstract: controller.d('UpdateLimit/PageDescription')
        })
        ),
        userLimitModel().limits().map(function (limit) {
          return new LimitField({
            model: limit,
            hasErrors: limit.hasErrors().length > 0,
            postfix: controller.postfix,
            alwaysVisible: true,
            limitText: controller.d(`UpdateLimit/${limit.type()}Limit`),
            stateText: limit.plannedAt() ? (limit.plannedValue() ? controller.d('UpdateLimit/ChangedLimit', {
              TYPE: controller.d(`UpdateLimit/${limit.type()}`),
              VALUE: Utils.formatCurrency(limit.plannedValue()),
              ACTIVEFROM: Utils.formatDateTime(new Date(limit.plannedAt()))
            }) : controller.d('UpdateLimit/DeletedLimit', {
              TYPE: controller.d(`UpdateLimit/${limit.type()}`),
              ACTIVEFROM: Utils.formatDateTime(new Date(limit.plannedAt()))
            })) : null,
            operatorText: controller.d('UpdateLimit/OperatorLimit', {
              TYPE: controller.d(`UpdateLimit/${limit.type()}`),
              VALUE: Utils.formatCurrency(limit.max())
            }),
            cancelDelete: controller.d('UpdateLimit/CancelDelete'),
            cancelChange: controller.d('UpdateLimit/CancelChange'),
            placeholder: getPlaceholderText(limit),
            cannotDelete: !limit.value()
          });
        }),
        m('div.retail-button-container.retail-button-container--centered',
          Button({
            isDisabled: controller.buttonIsDisabled(),
            isLoading: controller.isSubmitting(),
            buttonText: controller.d('UpdateLimit/SubmitButton'),
            onclick: function () {
              if (controller.model().userLimits().validate()) {
                controller.submit();
              }
            },
            uiTestId: 'retail-button-submit-button'
          })
        )
      ])
    ]);
  };

  return StepUpdateLimit;

});
