defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/PayoutMethod', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, BackButton, Button, StepHeader) {

  var StepPayoutMethod = function (controller) {

    return m('div', [
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton()) : null,
      m('.retail-page__content',
        m('.retail-page__row has-rows-2', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, StepHeader({
            title: controller.d('PayoutMethod/PageTitle'),
            abstract: controller.d('PayoutMethod/PageDescription')
          })
          ),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, [
            Button({
              isLoading: controller.isSubmitting(),
              buttonText: controller.d('PayoutMethod/SubmitButton'),
              onclick: function () {
                if (controller.goToUploadDocumentation && typeof controller.goToUploadDocumentation === 'function') {
                  controller.goToUploadDocumentation();
                }
              }
            })
          ])
        ])
      )
    ]);
  };

  return StepPayoutMethod;

});