defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/GameHistoryList', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/GameHistoryNavigation',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Svg',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Link',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Loader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/RetailWallet',
], (m, Utils, GameHistoryNavigation, StepHeader, Svg, Link, Button, Loader, RetailWallet) => {

  const GameHistoryList = function (controller) {
    if (controller.isLoading()) return Loader();

    return m('.retail-page', [
      m('.retail-page__content', {
        class: !controller.isWebview() && 'retail-page__no-progress'
      }, [

        !controller.isWebview() && m('.pam-page__header', StepHeader({ title: controller.d('List/PageTitle') })),

        GameHistoryNavigation(controller),

        controller.state.type() === 'afsluttede' && controller.uncollectedPrizes()?.amount > 0
          ? RetailWallet({
            title: controller.d('Wallet/Title'),
            amount: controller.d('Wallet/Amount',
              { AMOUNT: Utils.convertCentsToKronerAndFormat(controller.uncollectedPrizes().amount) }),
            amountNotice: controller.d('Wallet/AmountNotice'),
            pickupNotice: controller.d('Wallet/PickupNotice')
          })
          : null,

        controller.state.items().length
          ? [
            controller.state.items().map(function (item) {
              return m('.game-history-card', {
                class: [
                  item.win > 0 && item.winStatus === 'PENDING' ? 'has-active-payout' : null,
                  item.win > 0 && item.winStatus !== 'PENDING' ? 'has-payout' : null,
                  controller.payoutSlideOpen() === item.id ? 'is-open' : null
                ].join(' ')
              }, [
                m('.game-history-card__row', [
                  m('.game-history-card__column-1', [
                    m('.game-history-card__title', item.type),
                    m('div', Utils.formatDateShort(item.createdAt)),
                    m('div', item.channel)
                  ]),
                  m('.game-history-card__column-2', [
                    Link({
                      onclick: () => {
                        controller.viewBetslipDetails(item);
                      },
                      class: 'game-history-card__title',
                      text: controller.d('List/ViewBetSlip')
                    }),
                    m('.game-wager', Utils.convertCentsToKronerAndFormat(item.wager))
                  ])
                ]),

                item.win > 0
                  ? m('.game-history-card__row', [
                    m('.game-history-card__column-1', m('.game-history-card__title', controller.d('List/PrizeLabel'))),
                    m('.game-history-card__column-2', m('.game-history-card__title', Utils.convertCentsToKronerAndFormat(item.win)))
                  ])
                  : null,

                item.win > 0
                  ? m('.payout-container.is-retail',
                    m('button.payout-container__toggle-button', {
                      type: 'button',
                      title: controller.d('List/PrizeLabel'),
                      onclick: () => {
                        let setSlide;

                        if (controller.payoutSlideOpen() === item.id) {
                          Utils.ensightenEvent('cashout', 'slide_close');
                          setSlide = null;
                        } else {
                          Utils.ensightenEvent('cashout', 'slide_open');
                          setSlide = item.id;
                        }

                        controller.payoutSlideOpen(setSlide);
                      }
                    }, [

                      Svg('arrow_left', { class: 'payout-container__toggle-icon' }),

                      item.winStatus === 'PENDING' && Svg('prize-available', {
                        class: 'payout-container__toggle-payout-icon'
                      }),
                    ]),

                    m('.payout__info', [
                      m('.payout__info-container', [
                        m('.payout__info-title', controller.d('List/PayoutInfo/Title')),
                        m('.payout__info-abstract', controller.d('List/PayoutInfo/Abstract')),
                        m('.payout__info-amount', Utils.convertCentsToKronerAndFormat(item.win))
                      ]),

                      (item.winStatus === 'PAID' || item.winStatus === 'CLAIMED') || !item.canBePaidOut
                        ? m('div', controller.d('List/PrizePaidText'))
                        : Button({
                          buttonText: controller.d('List/PayoutInfo/ButtonLabel'),
                          class: 'retail-button--white',
                          onclick: () => {
                            controller.payoutSlideOpen(item.id);
                            Utils.ensightenEvent('cashout', 'udbetal');
                            m.route(`/prize/${item.id}`);
                          },
                          onfocus: () => {
                            controller.payoutSlideOpen(item.id);
                          }
                        })
                    ])
                  )
                  : null
              ]);
            }),

            m('.retail-button-container retail-button-container--centered', [
              controller.state.hasPrev()
                ? Button({
                  class: 'retail-button--secondary',
                  buttonText: controller.d('List/LoadPrevious'),
                  onclick: () => {
                    let page = parseInt(controller.state.page(), 10);
                    controller.state.page(--page);
                  }
                })
                : null,

              controller.state.hasNext()
                ? Button({
                  buttonText: controller.d('List/LoadNext'),
                  onclick: () => {
                    let page = parseInt(controller.state.page(), 10);
                    controller.state.page(++page);
                  }
                })
                : null
            ])
          ]
          : m('.game-history__no-games', [
            Svg('grey-dice', { class: 'game-history__no-games-icon' }),

            m('.game-history__no-games-text',
              controller.state.type() === 'aktive'
                ? controller.d('List/NoActiveGames')
                : controller.d('List/NoFinishedGames')
            )
          ])
      ])
    ]);
  };

  return GameHistoryList;

});
