defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/FinalizeUpgrade', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Framework/PlayerAccountManagement/Scripts/Templates/StepGuide',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Checkbox',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, StepGuide, Button, Checkbox, StepHeader) {

  var StepFinalizeUpgrade = function (controller) {

    return m('', [
      StepGuide(6, controller.totalSteps()),
      m('.pam-page__content', [
        m('div', {
          class: 'pam-page__header'
        }, StepHeader({
          title: controller.d('FinalizeUpgrade/PageTitle'),
          abstract: controller.d('FinalizeUpgrade/PageDescription')
        })
        ),
        Checkbox({
          model: controller.model().fields.emailPermission,
          labelText: controller.d('PersonalInfo/NewsPermissions/LabelNews'),
          name: 'emailPermission',
          hasErrors: controller.model().errors.emailPermission()
        }),
        m('div.retail-button-container.retail-button-container--centered',
          Button({
            isLoading: controller.isSubmitting(),
            buttonText: controller.d('FinalizeUpgrade/NextButton'),
            onclick: controller.finalize,
            uiTestId: 'retail-button-submit-button'
          })
        )
      ])
    ]);
  };

  return StepFinalizeUpgrade;

});
