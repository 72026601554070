defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/BasicAccountInfo', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ProgressBar',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Dropdown',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InfoRow',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InputField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ListField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Checkbox',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Recaptcha'
], function (m, Utils, ProgressBar, BackButton, Dropdown, StepHeader, InfoRow, InputField, ListField, Checkbox, Button, Recaptcha) {

  var StepBasicAccountInfo = function (controller) {
    var userModel = controller.model().fields;
    var errors = controller.model().errors;

    return m('div', [
      controller.progress ?
        ProgressBar({
          progress: controller.progress()
        }) : null,
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton()) : null,
      m('.retail-page__content',
        m('.retail-page__row has-rows-2', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, StepHeader({
            title: controller.d('BasicAccountInfo/PageTitle'),
            abstract: controller.d('BasicAccountInfo/PageDescription')
          })
          ),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, [
            InfoRow({
              labelText: controller.d('BasicAccountInfo/Cpr/HeaderCprNumber'),
              value: controller.d('BasicAccountInfo/Cpr/DescriptionCprNumber')
            }),
            InputField({
              model: userModel.cprNumber,
              onkeyup: function (ev) {
                controller.resetErrors(ev);
                var newValue = ev.target.value.replace(/\D/g, '');
                if (newValue.length > 0) {
                  if (newValue.length > 10) {
                    controller.validateField(event);
                  } else {
                    controller.buttonIsDisabled(true);
                  }
                } else {
                  userModel.cprNumber(null);
                }
              },
              onblur: function (ev) {
                var newValue = ev.target.value.replace(/\D/g, '');

                if (newValue.length > 0) {
                  userModel.cprNumber(Utils.formatCprNumber(newValue));
                }
                if (controller.validateCprNumber(newValue)) {
                  controller.validateField(event);
                } else {
                  errors.cprNumber(controller.d('BasicAccountInfo/Cpr/InvalidCprNumber'));
                }

                if (controller.fieldInFocus() === event.target.name) {
                  controller.fieldInFocus(null);
                }
              },
              onfocus: function (ev) {
                controller.setFieldFocus(ev);
                var newValue = ev.target.value.replace(/\D/g, '');
                if (newValue.length > 0) {
                  errors.cprNumber(null);
                  userModel.cprNumber(newValue);
                }
              },
              hasErrors: errors.cprNumber(),
              hasFocus: controller.fieldInFocus(),
              name: 'cprNumber',
              forceNumeric: true,
              type: 'text',
              labelText: controller.d('BasicAccountInfo/Cpr/LabelCprNumber'),
              helpText: controller.d('BasicAccountInfo/Cpr/HelpTextCprNumber')
            }),
            m('hr'),
            InfoRow({
              labelText: controller.d('BasicAccountInfo/Personal/HeaderPersonal'),
              value: controller.d('BasicAccountInfo/Personal/DescriptionPersonal')
            }),
            InputField({
              model: userModel.firstName,
              onfocus: controller.setFieldFocus,
              onblur: controller.validateField,
              hasErrors: errors.firstName(),
              hasFocus: controller.fieldInFocus(),
              name: 'firstName',
              labelText: controller.d('BasicAccountInfo/Personal/Name/LabelFirstName'),
              helpText: controller.d('BasicAccountInfo/Personal/Name/HelpTextFirstName')
            }),
            InputField({
              model: userModel.lastName,
              onfocus: controller.setFieldFocus,
              onblur: controller.validateField,
              hasErrors: errors.lastName(),
              hasFocus: controller.fieldInFocus(),
              name: 'lastName',
              labelText: controller.d('BasicAccountInfo/Personal/Name/LabelLastName'),
              helpText: controller.d('BasicAccountInfo/Personal/Name/HelpTextLastName')
            }),
            m('.fields', [
              ListField({
                model: userModel.street,
                onfocus: controller.setFieldFocus,
                onkeyup: controller.lookupAddress,
                hasErrors: errors.street(),
                hasFocus: controller.fieldInFocus(),
                fieldInFocus: controller.fieldInFocus,
                validateField: controller.validateField,
                setSelected: function (value) {
                  controller.selectAddressFromSelection(value);
                },
                onkeyevent: function (event) {
                  controller.fieldInFocus(event.target.name);
                  if (event.key === 'Enter') {
                    controller.selectAddressFromSelection(event.target.innerText);
                  }
                },
                name: 'street',
                labelText: controller.d('BasicAccountInfo/Personal/Address/LabelStreet'),
                helpText: controller.d('BasicAccountInfo/Personal/Address/HelpTextStreet'),
                list: controller.addresses
              }),
              InputField({
                model: userModel.houseNumber,
                onfocus: controller.setFieldFocus,
                onblur: controller.validateField,
                hasErrors: errors.houseNumber(),
                hasFocus: controller.fieldInFocus(),
                name: 'houseNumber',
                labelText: controller.d('BasicAccountInfo/Personal/Address/LabelHouseNumber'),
                helpText: controller.d('BasicAccountInfo/Personal/Address/HelpTextHouseNumber')
              }),
              m('.retail-multi-container', [
                ListField({
                  model: userModel.zipCode,
                  onfocus: controller.setFieldFocus,
                  onkeyup: controller.lookupZip,
                  hasErrors: errors.zipCode(),
                  hasFocus: controller.fieldInFocus(),
                  fieldInFocus: controller.fieldInFocus,
                  validateField: controller.validateField,
                  setSelected: function (value) {
                    userModel.zipCode(value);
                    controller.selectCityFromZip(value);
                    controller.zips([]);
                  },
                  onkeyevent: function (event) {
                    controller.fieldInFocus(event.target.name);
                    if (event.key === 'Enter') {
                      userModel.zipCode(event.target.innerText);
                      controller.selectCityFromZip(event.target.innerText);
                      controller.fieldInFocus(null);
                    }
                  },
                  name: 'zipCode',
                  type: 'number',
                  labelText: controller.d('BasicAccountInfo/Personal/Address/LabelZipCode'),
                  helpText: controller.d('BasicAccountInfo/Personal/Address/HelpTextZipCode'),
                  list: controller.zips
                }),
                ListField({
                  model: userModel.city,
                  onfocus: controller.setFieldFocus,
                  onkeyup: controller.lookupCity,
                  hasErrors: errors.city(),
                  hasFocus: controller.fieldInFocus(),
                  fieldInFocus: controller.fieldInFocus,
                  validateField: controller.validateField,
                  setSelected: function (value) {
                    userModel.city(value);
                    controller.cities([]);
                  },
                  onkeyevent: function (event) {
                    controller.fieldInFocus(event.target.name);
                    if (event.key === 'Enter') {
                      userModel.city(event.target.innerText);
                      controller.fieldInFocus(null);
                    }
                  },
                  name: 'city',
                  labelText: controller.d('BasicAccountInfo/Personal/Address/LabelCity'),
                  helpText: controller.d('BasicAccountInfo/Personal/Address/HelpTextCity'),
                  list: controller.cities
                }),
              ]),
            ]),
            Dropdown({
              model: userModel.country,
              choices: controller.model().availableCountries(),
              hasErrors: errors.country(),
              name: 'country',
              labelText: controller.d('BasicAccountInfo/Personal/Address/LabelCountry')
            }),
            m('hr'),
            InfoRow({
              labelText: controller.d('BasicAccountInfo/Phone/HeaderPhone'),
            }),
            InputField({
              model: userModel.phone,
              onblur: controller.validateField,
              onfocus: controller.setFieldFocus,
              hasErrors: errors.phone(),
              hasFocus: controller.fieldInFocus(),
              name: 'phone',
              type: 'tel',
              labelText: controller.d('BasicAccountInfo/Phone/LabelPhone'),
              helpText: controller.d('BasicAccountInfo/Phone/HelpTextPhone')
            }),
            m('hr'),
            InfoRow({
              labelText: controller.d('BasicAccountInfo/Email/HeaderEmail'),
            }),
            InputField({
              model: userModel.email,
              onfocus: controller.setFieldFocus,
              onblur: controller.validateField,
              hasErrors: errors.email(),
              hasFocus: controller.fieldInFocus(),
              name: 'email',
              type: 'email',
              labelText: controller.d('BasicAccountInfo/Email/LabelEmail'),
              helpText: controller.d('BasicAccountInfo/Email/HelpTextEmail')
            }),
            Checkbox({
              model: userModel.acceptTermsAndConditions,
              labelText: controller.d('BasicAccountInfo/Terms/LabelTermsAndConditions'),
              name: 'acceptTermsAndConditions',
              onchange: controller.validateField,
              hasErrors: errors.acceptTermsAndConditions()
            }),
            controller.recaptchaEnabled() ? Recaptcha({
              model: controller.recaptcha().model,
              hasErrors: controller.recaptcha().hasErrors(),
              validate: controller.validateRecaptcha,
              id: 'recaptchafield'
            }) : null,
            m('div.retail-button-container.retail-button-container--centered',
              Button({
                isDisabled: controller.buttonIsDisabled(),
                isLoading: controller.isSubmitting(),
                buttonText: controller.d('BasicAccountInfo/SubmitButton'),
                onclick: controller.verify
              })
            )
          ])
        ])
      )
    ]);
  };

  return StepBasicAccountInfo;

});
