defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Components/RetailLandingPage', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Dictionary',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
], function (Component, Dictionary, Button) {

  Component('retail-landing-page', [Dictionary], function (m, route, settings) {

    var root = {
      controller: function () {
        this.settings = settings;
        this.route = route;
        this.d = Dictionary.get.bind({ prefix: 'RetailLandingPage/' });

        this.loadAnimation = function (target) {
          document.querySelector('.' + target).classList.add(target + '--loaded');
        };

        this.resizeSplash = function () {
          var splashHeight = document.querySelector('.retail-landing-page__back-image');
          var container = document.querySelector('.retail-landing-page__splash');
          if (splashHeight && container) {
            container.style.height = splashHeight.height + 30 + 'px';
          }
        };

        // Hide brand-nav
        var brandNav = document.querySelector('.brand-nav');
        if (brandNav) {
          document.body.classList.add('RetailAccountHiddenMenu');
        }

      },
      view: function (controller) {
        var settings = controller.settings;

        controller.resizeSplash();

        return m('div', {
          class: 'retail-page__content has-two-columns retail-landing-page',
          'data-uitest-id': 'retail-landing-page'
        },
        m('.retail-landing-page__outer-wrapper',
          m('.retail-landing-page__inner-wrapper',
            m('div', {
              class: 'retail-page__row has-rows-2'
            }, [
              m('div', {
                class: 'retail-page__column retail-page__column--1'
              }, [
                m('.retail-landing-page__splash', [
                  m('.retail-landing-page__splash-header', controller.d('SplashTitle')),
                  m('img', {
                    class: 'retail-landing-page__front-image',
                    src: settings.splashFrontImage.source,
                    alt: settings.splashFrontImage.alternateText,
                    onload: function () {
                      controller.loadAnimation('retail-landing-page__front-image');
                      controller.loadAnimation('retail-landing-page__splash-header');
                    }
                  }),
                  m('img', {
                    class: 'retail-landing-page__back-image',
                    src: settings.splashBackImage.source,
                    alt: settings.splashBackImage.alternateText,
                    onload: function () {
                      controller.loadAnimation('retail-landing-page__back-image');
                    }
                  }),
                ]),
                m('div', { class: 'retail-landing-page__app-links' }, [
                  m('a', {
                    href: settings.appStoreLink.Url
                  }, m('img', {
                    class: 'retail-landing-page__app-logo',
                    src: settings.appStoreImage.source,
                    alt: settings.appStoreImage.alternateText,
                  })
                  ),
                  m('a', {
                    href: settings.googlePlayLink.Url
                  }, m('img', {
                    class: 'retail-landing-page__app-logo',
                    src: settings.googlePlayImage.source,
                    alt: settings.googlePlayImage.alternateText
                  })
                  )
                ])
              ]),
              m('div', {
                class: 'retail-page__column retail-page__column--2'
              }, [
                m('h1', { class: 'retail-landing-page__header' }, controller.d('PageTitle')),
                m('p', { class: 'retail-landing-page__abstract' }, m.trust(controller.d('PageText1'))),
                m('p', { class: 'retail-landing-page__abstract' }, m.trust(controller.d('PageText2'))),
                m('p', { class: 'retail-landing-page__abstract' }, m.trust(controller.d('PageText3'))),
                Button({
                  class: 'retail-landing-page__cta-button',
                  buttonText: settings.callToActionLink.Text,
                  onclick: function () {
                    window.location.href = settings.callToActionLink.Url;
                  }
                }),
                m('p', { class: 'retail-landing-page__read-more' }, m.trust(controller.d('PageText4'))),
                m('p', controller.d('PoweredBy')),
                m('.retail-landing-page__brand-links', [
                  settings.links.map(function (link) {
                    return m('a', { href: link.locationUrl }, m('img', {
                      class: 'retail-landing-page__brand-link',
                      style: 'background-color: ' + link.backgroundColor,
                      src: link.image.source,
                      alt: link.image.alternateText,
                    }));
                  })
                ])
              ])
            ])
          )
        )
        );
      }
    };

    // Routes
    route('/', root);

  });

});
