defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/AccountInfo', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Framework/PlayerAccountManagement/Scripts/Templates/StepGuide',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InputField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Checkbox',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, StepGuide, InputField, Button, Checkbox, StepHeader) {

  var StepAccountInfo = function (controller) {
    var userModel = controller.model().fields;
    var errors = controller.model().errors;

    return m('div', [
      StepGuide(2, controller.totalSteps()),
      m('.pam-page__content', [
        m('div', {
          class: 'pam-page__header'
        }, StepHeader({
          title: controller.d('AccountInfo/PageTitle'),
          abstract: controller.d('AccountInfo/PageDescription')
        })
        ),
        InputField({
          model: userModel.email,
          onfocus: controller.setFieldFocus,
          onblur: controller.validateField,
          hasErrors: errors.email(),
          hasFocus: controller.fieldInFocus(),
          name: 'email',
          type: 'email',
          labelText: controller.d('AccountInfo/Email/LabelEmail'),
          helpText: controller.d('AccountInfo/Email/HelpTextEmail')
        }),
        InputField({
          model: userModel.phone,
          onfocus: controller.setFieldFocus,
          onblur: controller.validateField,
          hasErrors: errors.phone(),
          hasFocus: controller.fieldInFocus(),
          name: 'phone',
          type: 'tel',
          labelText: controller.d('AccountInfo/Phone/LabelPhone'),
          helpText: controller.d('AccountInfo/Phone/HelpTextPhone')
        }),
        controller.model().usernameIsSet() ? null : ('div', [
          InputField({
            model: userModel.username,
            onfocus: controller.setFieldFocus,
            onblur: controller.validateField,
            hasErrors: errors.username(),
            hasFocus: controller.fieldInFocus(),
            name: 'username',
            labelText: controller.d('AccountInfo/Username/LabelUsername'),
            helpText: controller.d('AccountInfo/Username/HelpTextUsername')
          }),
          InputField({
            model: userModel.password,
            onfocus: controller.setFieldFocus,
            onblur: controller.validateField,
            hasErrors: errors.password(),
            hasFocus: controller.fieldInFocus(),
            type: 'password',
            name: 'password',
            labelText: controller.d('AccountInfo/Password/LabelPassword'),
            helpText: controller.d('AccountInfo/Password/HelpTextPassword')
          }),
          InputField({
            model: userModel.repeatPassword,
            onfocus: controller.setFieldFocus,
            onblur: controller.validateField,
            hasErrors: errors.repeatPassword(),
            hasFocus: controller.fieldInFocus(),
            name: 'repeatPassword',
            type: 'password',
            labelText: controller.d('AccountInfo/Password/LabelPasswordConfirmation'),
            helpText: controller.d('AccountInfo/Password/HelpTextPasswordConfirmation')
          })
        ]),
        controller.initialValues().fields.marketingDefaultTerms() ? null : Checkbox({
          model: userModel.marketingDefaultTerms,
          labelText: controller.d('AccountInfo/LabelDefaultMarketingTerms'),
          name: 'marketingDefaultTerms',
          hasErrors: errors.marketingDefaultTerms()
        }),
        m('div.retail-button-container.retail-button-container--centered',
          Button({
            isLoading: controller.isSubmitting(),
            buttonText: controller.d('AccountInfo/SubmitButton'),
            onclick: controller.submit,
            uiTestId: 'retail-button-submit-button'
          })
        )
      ])
    ]);
  };

  return StepAccountInfo;

});
