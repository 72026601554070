defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Components/RetailGameHistoryList', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Common/Framework/EventHandling/Scripts/CrossWindowEvents',
  'Shared/Framework/Mithril/Scripts/Helpers/RouteParam',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Dictionary',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Api',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/UserModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/GameHistoryList',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/GameHistoryOverview',
], (m, CrossWindowEvents, RouteParam, Dictionary, RetailApi, UserModel, GameHistoryList) => {

  const GameHistoryListComponent = {
    controller: function (args) {
      this.d = Dictionary.get.bind({ prefix: 'RetailGameHistory/' });
      this.filterDictionary = Dictionary.get.bind({ prefix: 'PlayerGames/GamesList' });
      this.isLoading = m.prop(true);
      this.settings = args.settings;
      this.payoutSlideOpen = m.prop(null);
      this.showErrorModal = args.showErrorModal;
      this.isWebview = m.prop(window.dsApplicationConfig);
      this.userModel = m.prop(new UserModel());
      this.limit = m.prop(null);

      const activeRightMenuSelector = document.querySelector('.is-showing-right-menu');

      if (activeRightMenuSelector) {
        activeRightMenuSelector.classList.remove('is-showing-right-menu');
        document.querySelector('.mobile-user-info-inner-wrapper').classList.remove('overlay-active');
        document.querySelector('.page-area').classList.remove('modal-wrapper', 'overlay-active');
      }

      this.filterCallback = (key) => this.state[key];

      const page = RouteParam('page');
      const period = RouteParam('period');
      const offset = RouteParam('offset');
      const type = RouteParam('type');

      this.state = {
        page,
        period,
        offset,
        type,
        items: m.prop([]),
        hasNext: m.prop(false),
        hasPrev: m.prop(false),
      };

      this.uncollectedPrizes = m.prop(null);

      this.viewBetslipDetails = (item) => m.route(`/${page()}/${period()}/${offset()}/${type()}/${item.id}`);

      const getUncollectedPrizes = () => RetailApi.getUncollectedPrizes()
        .then((response) => {
          this.uncollectedPrizes(response.data);
        });

      const getUser = () => RetailApi.getProfile()
        .then((response) => {
          const model = response.data.profile || {};
          this.userModel().update(model);
          document.body.classList.add('is-logged-in');
        });

      const getMostRestrictiveLimit = () => RetailApi.getMostRestrictiveLimit()
        .then((response) => {
          this.limit(response.data);
        });

      const getGameHistory = () => RetailApi.getGameHistory({
        page: this.state.page(),
        period: this.state.period(),
        offset: this.state.offset(),
        vertical: this.state.type()
      })
        .then((response) => {
          document.body.classList.add('is-logged-in');
          const playerGames = response.data.PlayerGames || [];
          this.state.hasNext(response.data.HasNext);
          this.state.hasPrev(response.data.HasPrevious);

          const formattedGames = playerGames.map((playerGame) => {
            return {
              id: playerGame.Id,
              hasPayout: playerGame.WinStatus === 'PAID' || playerGame.WinStatus === 'PENDING',
              winStatus: playerGame.WinStatus,
              type: playerGame.Type,
              createdAt: playerGame.CreatedAt,
              channel: playerGame.Channel,
              wager: playerGame.Wager,
              win: playerGame.Win,
              canBePaidOut: playerGame.CanBePaidOut
            };
          });

          this.state.items(formattedGames);
        }, (error) => {
          this.showErrorModal(error);
        });

      m.sync([
        getGameHistory(),
        getUncollectedPrizes(),
        getUser(),
        getMostRestrictiveLimit(),
      ]).then(() => {
        this.isLoading(false);
        m.redraw();
      });

      CrossWindowEvents.fire('ds.event.balanceUpdated', {});
    },
    view: function (controller) {
      return GameHistoryList(controller);
    }
  };

  return GameHistoryListComponent;
});
