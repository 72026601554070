defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/PINCode', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Mithril/Scripts/Helpers/Render',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ProgressBar',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InputField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorMessage'
], function (m, Render, ProgressBar, BackButton, InputField, StepHeader, ErrorMessage) {

  var StepPINCode = function (controller) {
    let render = Render();
    let errors = controller.model().errors;
    let dictionaryKey = controller.currentStep().dictionaryKey;
    let userModel = controller.model().fields;
    let maskInput = typeof controller.maskPincodeInput === 'function' ? controller.maskPincodeInput() : false;
    let fieldSuffix = '';

    if (controller.currentStep().route === '/repeat-pincode') {
      fieldSuffix = 'Confirm';
    }

    const onkeyupHandler = (ev, fieldName, cb) => {
      const val = ev.target.value.trim();

      if (ev.key === 'Backspace') {
        let number = fieldName.slice(3, 4);
        let prevNumber = number - 1;
        const prevFieldName = fieldName.replace(number, prevNumber);

        if (typeof userModel[prevFieldName] === 'function') {
          userModel[prevFieldName](null);
        }

        if (typeof userModel[fieldName] === 'function') {
          userModel[fieldName](null);
        }

        controller.model().clearErrors();

        focusField(prevFieldName);
        return;
      }

      if (isNaN(val) || !val.length) {
        userModel[fieldName]('');
        return;
      }

      if (val.length > 1) {
        const cappedValue = val.substring(0, 1);
        userModel[fieldName](cappedValue);
      }

      if (typeof cb === 'function') {
        cb();
      }
    };

    const isFieldDisabled = (fieldName) => {
      if (!userModel[fieldName]()?.length) {
        return true;
      }

      return false;
    };

    const onFocusHandler = (ev) => {
      const fieldName = ev.target.name;

      if (!fieldName) {
        return;
      }

      const fieldNumber = parseInt(fieldName.slice(3, 4), 10);
      const numberOfFields = document.querySelectorAll('.js-retail-input-field__input').length;

      for (var i = fieldNumber; i <= numberOfFields; i++) {
        userModel['pin' + i + fieldSuffix]('');
      }
    };

    const focusField = (fieldName) => {
      if (typeof controller.fieldInFocus === 'function') {
        controller.fieldInFocus(fieldName);
      }

      const $field = document.querySelector(`[name=${fieldName}]`);

      if ($field) {
        $field.focus(); // eslint-disable-line no-jquery/no-other-methods, no-jquery/no-event-shorthand -- Reason: Not a jquery selector
      }
    };

    render.ready.then((nodes) => {
      var context = nodes['pin1' + fieldSuffix].context;
      if (nodes['pin1' + fieldSuffix].rendered && !context.focusOnce) {
        focusField('pin1' + fieldSuffix);
        context.focusOnce = true;
      }
    });

    return m('div', {
      'ui-test-id': 'page-' + controller.currentStep().route.replace('/', '')
    }, [
      controller.progress ?
        ProgressBar({
          progress: controller.progress()
        }) : null,
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton({
          onclick: controller.routeToPrevStep
        })) : null,
      m('.retail-page__content', {
        class: controller.hideBackButton() ? 'retail-page__no-progress' : ''
      },
      m('.retail-page__row has-rows-2', [
        m('div', {
          class: 'retail-page__column retail-page__column--1'
        }, StepHeader({
          title: controller.d(`${dictionaryKey}/PageTitle`),
          abstract: controller.d(`${dictionaryKey}/PageDescription`)
        })
        ),
        m('div', {
          class: 'retail-page__column retail-page__column--2'
        }, m('.retail-pincode-input-container', [
          InputField({
            model: userModel['pin1' + fieldSuffix],
            onkeyup: (ev) => onkeyupHandler(ev, 'pin1' + fieldSuffix, () => controller.validateField(ev, focusField('pin2' + fieldSuffix))),
            onfocus: (ev) => onFocusHandler(ev),
            hasErrors: errors['pin1' + fieldSuffix](),
            name: 'pin1' + fieldSuffix,
            type: maskInput ? 'password' : 'tel',
            forceNumeric: true,
            noErrorIcon: true,
            class: 'retail-input-field--pincode',
            config: render.depend('pin1' + fieldSuffix)
          }),
          InputField({
            model: userModel['pin2' + fieldSuffix],
            onkeyup: (ev) => onkeyupHandler(ev, 'pin2' + fieldSuffix, () => controller.validateField(ev, focusField('pin3' + fieldSuffix))),
            onfocus: (ev) => onFocusHandler(ev),
            hasErrors: errors['pin2' + fieldSuffix](),
            name: 'pin2' + fieldSuffix,
            type: maskInput ? 'password' : 'tel',
            forceNumeric: true,
            noErrorIcon: true,
            class: 'retail-input-field--pincode',
            disabled: isFieldDisabled('pin1' + fieldSuffix)
          }),
          InputField({
            model: userModel['pin3' + fieldSuffix],
            onkeyup: (ev) => onkeyupHandler(ev, 'pin3' + fieldSuffix, () => controller.validateField(ev, focusField('pin4' + fieldSuffix))),
            onfocus: (ev) => onFocusHandler(ev),
            hasErrors: errors['pin3' + fieldSuffix](),
            name: 'pin3' + fieldSuffix,
            type: maskInput ? 'password' : 'tel',
            forceNumeric: true,
            noErrorIcon: true,
            class: 'retail-input-field--pincode',
            disabled: isFieldDisabled('pin2' + fieldSuffix)
          }),
          InputField({
            model: userModel['pin4' + fieldSuffix],
            onkeyup: (ev) => onkeyupHandler(ev, 'pin4' + fieldSuffix, () => controller.mergeAndValidateField(ev, controller.submit)),
            onfocus: (ev) => onFocusHandler(ev),
            hasErrors: errors['pin4' + fieldSuffix](),
            name: 'pin4' + fieldSuffix,
            type: maskInput ? 'password' : 'tel',
            forceNumeric: true,
            noErrorIcon: true,
            class: 'retail-input-field--pincode',
            disabled: isFieldDisabled('pin3' + fieldSuffix)
          }),
        ]),
        errors?.pincode() ? ErrorMessage(errors.pincode()) : null
        )
      ])
      )
    ]);
  };

  return StepPINCode;

});
