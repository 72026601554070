defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Address', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ProgressBar',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ListField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InputField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Dropdown',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, ProgressBar, BackButton, ListField, InputField, Dropdown, Button, StepHeader) {

  var StepAddress = function (controller) {
    var userModel = controller.model().fields;
    var errors = controller.model().errors;

    return m('div', [
      controller.progress ?
        ProgressBar({
          progress: controller.progress()
        }) : null,
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton({
          onclick: controller.routeToPrevStep ? controller.routeToPrevStep : null
        })) : null,
      m('.retail-page__content',
        m('.retail-page__row has-rows-2', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, StepHeader({
            title: controller.d('Address/PageTitle'),
            abstract: controller.d('Address/PageDescription')
          })
          ),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, [
            m('.fields', [
              ListField({
                model: userModel.street,
                onfocus: controller.setFieldFocus,
                onkeyup: controller.lookupAddress,
                hasErrors: errors.street(),
                hasFocus: controller.fieldInFocus(),
                fieldInFocus: controller.fieldInFocus,
                validateField: controller.validateField,
                setSelected: function (value) {
                  controller.selectAddressFromSelection(value);
                },
                onkeyevent: function (event) {
                  controller.fieldInFocus(event.target.name);
                  if (event.key === 'Enter') {
                    controller.selectAddressFromSelection(event.target.innerText);
                  }
                },
                name: 'street',
                labelText: controller.d('Address/LabelStreet'),
                list: controller.addresses
              }),
              InputField({
                model: userModel.houseNumber,
                onkeyup: controller.validateField,
                hasErrors: errors.houseNumber(),
                name: 'houseNumber',
                labelText: controller.d('Address/LabelHouseNumber')
              }),
              ListField({
                model: userModel.zipCode,
                onfocus: controller.setFieldFocus,
                onkeyup: controller.lookupZip,
                hasErrors: errors.zipCode(),
                fieldInFocus: controller.fieldInFocus,
                validateField: controller.validateField,
                setSelected: function (value) {
                  userModel.zipCode(value);
                  controller.selectCityFromZip(value);
                  controller.zips([]);
                },
                onkeyevent: function (event) {
                  controller.fieldInFocus(event.target.name);
                  if (event.key === 'Enter') {
                    userModel.zipCode(event.target.innerText);
                    controller.selectCityFromZip(event.target.innerText);
                    controller.fieldInFocus(null);
                  }
                },
                name: 'zipCode',
                type: 'number',
                labelText: controller.d('Address/LabelZipCode'),
                list: controller.zips
              }),
              ListField({
                model: userModel.city,
                onfocus: controller.setFieldFocus,
                onkeyup: controller.lookupCity,
                hasErrors: errors.city(),
                fieldInFocus: controller.fieldInFocus,
                validateField: controller.validateField,
                setSelected: function (value) {
                  userModel.city(value);
                  controller.cities([]);
                },
                onkeyevent: function (event) {
                  controller.fieldInFocus(event.target.name);
                  if (event.key === 'Enter') {
                    userModel.city(event.target.innerText);
                    controller.fieldInFocus(null);
                  }
                },
                name: 'city',
                labelText: controller.d('Address/LabelCity'),
                list: controller.cities
              }),
              Dropdown({
                model: userModel.country,
                choices: controller.model().availableCountries(),
                hasErrors: errors.country(),
                name: 'country',
                labelText: controller.d('Address/LabelCountry')
              })
            ]),
            m('div.retail-button-container.retail-button-container--centered',
              Button({
                isDisabled: controller.buttonIsDisabled(),
                isLoading: controller.isSubmitting(),
                buttonText: controller.d('Address/SubmitButton'),
                onclick: controller.submit
              })
            )
          ])
        ])
      )
    ]);
  };

  return StepAddress;

});
