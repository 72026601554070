defineDs('DanskeSpil/Framework/PlayerAccountManagement/Scripts/Templates/Calendar', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Common/Framework/ScriptLibraries/Scripts/Pikaday'
], function (m, Pikaday) {
  'use strict';

  var Calendar = function (value, minDate, maxDate, selector, toggled, callback, hideLabel) {

    var picker;
    var textInput;
    var triggerElem;
    var targetDate = m.prop(null);
    var dateValue;
    if (value() instanceof Date) {
      dateValue = value();
    } else {
      dateValue = new Date(value());
      value(new Date(value()));
    }

    var calendarLocalization = {
      months: ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'],
      weekdays: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
      weekdaysShort: ['S', 'M', 'T', 'O', 'T', 'F', 'L']
    };

    // Saves the set date to the passed value object
    var saveDate = function (val) {
      if (typeof value === 'function') {
        value(val);
        targetDate(val);
        if (typeof toggled === 'function') {
          toggled(true);
        }
        if (typeof callback === 'function') {
          callback();
        }
        m.redraw();
      } else {
        value = val;
      }
      picker.hide();
    };

    var config = function (elem, isInitialized) {
      if (!isInitialized) {
        textInput = elem.querySelector('label.calendar__text');

        // Show the datepicker when clicking the radio button and set the date to tomorrow
        triggerElem = document.querySelector(selector);
        triggerElem.addEventListener('click', function () {
          if (typeof toggled === 'function' && !toggled()) {
            if (!value()) {
              picker.setDate(targetDate() ? targetDate() : new Date());
              picker.show();
            }
          }
        });

        document.getElementById('calendar__text').addEventListener('click', function () {
          picker.setDate(targetDate() ? targetDate() : new Date());
          if (typeof toggled === 'function') {
            toggled(false);
          }
          picker.show();
        });

        // get the initial date to be displayed. If a value prop is passes use that, else default to now.
        var defaultDate = typeof value === 'function' ? value() : new Date();
        // Instantiate the Pikaday module
        picker = new Pikaday({
          firstDay: 1,
          field: textInput,
          trigger: triggerElem,
          bound: false,
          defaultDate: defaultDate,
          setDefaultDate: true,
          showDaysInNextAndPreviousMonths: true,
          enableSelectionDaysInNextAndPreviousMonths: true,
          onSelect: saveDate,
          reposition: false,
          format: 'D. MMMM YYYY',
          container: elem,
          i18n: calendarLocalization,
          minDate: minDate,
          maxDate: maxDate
        });

        picker.show();
      }
    };

    return m('.calendar', {
      config: config
    }, [
      m('label', {
        for: 'calendar__date',
        class: 'calendar__text',
        id: 'calendar__text',
        style: hideLabel ? 'display:none' : ''
      }, '' + dateValue.getDate() + '. ' + calendarLocalization.months[dateValue.getMonth()] + ' ' + dateValue.getFullYear())
    ]);
  };

  return Calendar;
});
