defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Components/RetailForgot', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay', // TODO: Move out from NG, or use more generic one?
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Dictionary',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Api',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/ForgotPasswordModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Loader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorOverlay',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/ForgotPassword',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/ForgotPincode',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Receipt'
],
function (Component, FrameworkOverlay, Dictionary, Utils, RetailApi, ForgotPasswordModel, Loader, ErrorOverlay, ForgotPassword, ForgotPincode, Receipt) {

  // Component:
  Component('retail-forgot', [Dictionary], function (m, route, settings) {

    // Declare steps in signup flow
    var routeConfiguration = [
      { order: 1, route: '/', fields: [], template: ForgotPassword },
      { order: 2, route: '/receipt', template: Receipt },
      { route: '/pincode', fields: [], template: ForgotPincode }
    ];

    // Get the current step from route on page load
    var currentStep = routeConfiguration.filter(function (step) {
      var locationHash = window.location.hash.toLowerCase();
      var hash = locationHash.replace('#retail-forgot=', '');
      return hash === step.route.toLowerCase();
    });

    // It empty get the current step from the routeConfiguration array (setting [0] in the controller)
    currentStep = currentStep.length ? currentStep[0] : routeConfiguration[0];

    var root = {
      controller: function () {
        this.isLoading = m.prop(false);
        this.isSubmitting = m.prop(false);
        this.isApp = m.prop((Utils.getQueryParam('dsApplicationId') || window.dsApplicationConfig) || false);
        this.buttonIsDisabled = m.prop(true);
        this.modalError = m.prop(null);
        this.routeConfiguration = routeConfiguration;
        this.currentStep = m.prop(currentStep);
        this.model = m.prop(new ForgotPasswordModel());
        this.loginLink = m.prop(settings.loginLink.Url ? settings.loginLink.Url : null);
        this.indexOfQueryParam = m.prop(this.loginLink().indexOf('?') > -1 ? this.loginLink().indexOf('?') : this.loginLink().length);
        this.appLoginLink = m.prop(this.loginLink() ? this.loginLink().substr(0, this.indexOfQueryParam()) : null);
        this.d = Dictionary.get.bind({ prefix: 'RetailForgot/' });
        this.receiptContent = m.prop({
          title: this.d('Receipt/Success/PageTitle'),
          abstract: this.d('Receipt/Success/PageDescription'),
          icon: 'circle_checkmark',
          callback: function () {
            window.location.href = settings.receiptLink.Url;
          },
          buttonText: this.d('Receipt/Success/SubmitButton'),
        });
        this.fieldInFocus = m.prop();
        this.token = m.prop(Utils.getQueryParam('token'));
        this.tokenIsValid = m.prop(null);

        if (this.token()) {
          this.isLoading(true);
          RetailApi.isPasswordTokenValid(this.token()).then(function () {
            Utils.extendFields(this.currentStep().fields, ['password', 'repeatPassword']);
            this.tokenIsValid(true);
            this.isLoading(false);
          }.bind(this), function (error) {
            this.showErrorModal(error);
            this.isLoading(false);
          }.bind(this));
        }

        this.setFieldFocus = function (ev) {
          if (typeof this.fieldInFocus !== 'function') {
            return;
          }

          // If function is called with event from onfocus handler, set field to have focus.
          // If called without event, then set first input field to have focus
          if (ev && ev.target) {
            this.fieldInFocus(ev.target.name);
            this.resetErrors(ev);
          }
        }.bind(this);

        this.validateField = function (ev) {
          if (ev.key === 'Tab' && !ev.target.value.length) return;

          var fieldName = ev.target.name;
          this.model().validate([fieldName]);

          var hasErrors = this.model().validate(this.currentStep().fields, false);

          if (hasErrors) {
            Utils.trackUserInputErrors('forgot_error', this.model().errors);
          }

          if (fieldName === this.fieldInFocus()) {
            this.fieldInFocus(null);
          }
          this.buttonIsDisabled(hasErrors); // Disable button if fields has errors
        }.bind(this);

        this.resetErrors = function (ev) {
          var fieldName = ev.target.name;

          if (typeof this.model().errors[fieldName] === 'function') {
            this.model().errors[fieldName](null);
          }
        }.bind(this);

        this.submit = function () {
          var fields = this.currentStep().fields;

          if (this.model().validate(fields)) return;

          var data = Utils.mapPasswordToApiData(this.model().fields.password(), this.model().fields.repeatPassword(), this.token());
          this.isLoading(true);
          this.buttonIsDisabled(true);
          RetailApi.changePassword(data).then(function () {
            var routePath = '/receipt';
            Utils.pushVirtualPage(routePath, (window.location.pathname.replace(/-/g, '_') + routePath), routePath);
            route(routePath)();
            this.buttonIsDisabled(false);
          }.bind(this), function (error) {
            console.error(error);
            this.model().errors['password'](error.message);
            this.isLoading(false);
          }.bind(this)).then(function () {
            this.isLoading(false);
            this.buttonIsDisabled(true);
            m.redraw();
          }.bind(this));
        }.bind(this);

        this.showErrorModal = (error) => {
          this.modalError(new FrameworkOverlay(ErrorOverlay({
            title: this.d('Errors/InvalidToken'),
            body: error.message,
            dismissButton: this.d('Errors/DismissButton'),
            dismisable: true,
          }))).show();
        };

      },
      view: function (controller) {
        if (controller.isLoading()) {
          return Loader();
        }

        var currentStep = controller.currentStep().route.replace('/', '').toLowerCase();

        return m('div', {
          class: [
            'retail-page',
            'retail-forgot',
            currentStep ? 'retail-forgot--' + currentStep : ''
          ].join(' '),
          'data-uitest-id': Utils.replaceSlashWithHyphens(route(), 'retail-page-forgot')
        }, routeConfiguration.reduce(function (template, step) {
          if (route() === step.route) {
            template = step.template(controller);
          }

          return template;
        }, null));
      }
    };


    // Routes
    route('/', root);

    for (var index in routeConfiguration) {
      var step = routeConfiguration[index];

      route(step.route, root);
    }
  });

});
