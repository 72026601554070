defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Models/FileUploadModel', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Mithril/Scripts/Core/Model',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils'
], function (m, Model, Utils) {
  'use strict';

  var FileUploadModel = Model('FileUploadModel', function (purpose, settings, dictionary, closeable) {
    settings = settings || {};
    closeable = closeable == null ? true : closeable;
    this.purpose = m.prop(purpose);
    this.file = m.prop(null);
    this.errors = m.prop([]);
    this.closeable = m.prop(closeable);

    // Do simple validation to check if field is not null, not false and not empty string
    this.validate = function () {
      // Lets clear previous errors
      this.errors([]);
      if (this.file()) {
        if (this.file().size > settings.MaxFileSize) {
          this.errors().push(dictionary('Errors/FileSizeExceedsLimit', {
            MAXFILESIZE: (settings.MaxFileSize / 1024 / 1024).toFixed(2),
            FAILEDFILE: this.file().name
          }));
        }

        var noFileTypeMatch = !settings.AllowedMimeTypes.some(function (fileType) {
          return fileType === Utils.fileExtension(this.file().name);
        }.bind(this));
        if (noFileTypeMatch) {
          this.errors().push(dictionary('Errors/FileTypeNotAllowed'));
        }

      } else {
        if (this.purpose().isActive() && this.purpose().isRequired()) {
          this.errors().push(dictionary('Errors/FileIsEmpty'));
        }
      }

      return this.errors().length > 0;
    }.bind(this);
  });

  return FileUploadModel;
});
