defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/GameHistoryOverview', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/GameHistoryNavigation',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Loader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/LimitOverview'
], (m, Utils, GameHistoryNavigation, StepHeader, Loader, LimitOverview) => {
  const GameHistoryOverview = (controller) => {
    if (controller.isLoading()) return Loader();

    return m('.retail-page', [

      m('.retail-page__content', {
        class: !controller.isWebview() && 'retail-page__no-progress'
      }, [

        !controller.isWebview() && m('.pam-page__header', StepHeader({ title: controller.d('List/PageTitle') })),

        GameHistoryNavigation(controller),

        LimitOverview({
          title: controller.d('LimitOverview/Title', { TYPE: controller.d(`LimitOverview/${Utils.capitalize(controller.limitType())}Label`) }),
          abstract: controller.d('LimitOverview/Abstract'),
          totalAmount: controller.totalAmount(),
          currentAmount: controller.currentAmount(),
          currentAmountLabel: controller.d('LimitOverview/CurrentAmountLabel'),
          limitLabel: controller.d('LimitOverview/LimitLabel'),
          limitInfo: controller.d('LimitOverview/LimitInfo'),
        }),

      ])
    ]);
  };

  return GameHistoryOverview;
});
