defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Components/RetailSignup', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay', // TODO: Move out from NG, or use more generic one?
  'DanskeSpil/Domain/Authentification/Scripts/SitecoreAuth',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Dictionary',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Api',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/WaitForAuthSynced',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/UserModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/PurposeModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/FileUploadModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Loader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorOverlay',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Name',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Address',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Email',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Phone',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/LimitPeriodSelection',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/LimitPeriodValue',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/LimitPeriodConfirm',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/PINCode',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/GameScanner',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/UploadDocuments',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Receipt',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/ResumeSignupFlow',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/AlreadyCreated'
],
function (Component, FrameworkOverlay, SitecoreAuth, Dictionary, Utils, RetailApi, WaitForAuthSynced, UserModel, PurposeModel, FileUploadModel, Loader, ErrorOverlay, Name, Address, Email, Phone, LimitPeriodSelection, LimitPeriodValue, LimitPeriodConfirm, PINCode, GameScanner, UploadDocuments, Receipt, ResumeSignupFlow, AlreadyCreated) {

  // Component:
  Component('retail-signup', [Dictionary, WaitForAuthSynced], function (m, route, settings) {

    // Declare steps in signup flow
    var routeConfiguration = [
      { order: 1, route: '/', fields: ['firstName', 'lastName'], template: Name, regularFlow: true },
      { order: 2, route: '/stepEmail', fields: ['email'], optionalFields: ['marketingTerms'], template: Email, regularFlow: true },
      { order: 3, route: '/stepPhone', fields: ['phone'], template: Phone, regularFlow: true },
      { order: 4, route: '/pincode', fields: ['pincode'], skipSubmit: true, dictionaryKey: 'SetPINCode', template: PINCode, regularFlow: true },
      { order: 5, route: '/repeat-pincode', fields: ['pincode', 'pincodeConfirm'], dictionaryKey: 'ConfirmPINCode', template: PINCode, regularFlow: true },
      { order: 6, route: '/limit/period', fields: ['offlineWagerLimitPeriod'], skipSubmit: true, dictionaryKey: 'LimitPeriodSelection', template: LimitPeriodSelection, regularFlow: true },
      { order: 7, route: '/limit/value', fields: ['offlineWagerLimit'], skipSubmit: true, dictionaryKey: 'LimitPeriodValue', template: LimitPeriodValue, regularFlow: true },
      { order: 8, route: '/limit/confirm', fields: ['offlineWagerLimitPeriod', 'offlineWagerLimit'], dictionaryKey: 'LimitPeriodConfirm', template: LimitPeriodConfirm, regularFlow: true },
      { order: 9, route: '/stepGameScanner', fields: ['acceptDataProcessing', 'runKnowYourCustomerFlow'], template: GameScanner, regularFlow: true },
      { order: 10, route: '/stepAddress', fields: ['street', 'houseNumber', 'zipCode', 'city', 'country'], template: Address, regularFlow: false },
      { order: 11, route: '/stepUploadDocuments', fields: ['isActive', 'hasValidBankAccount'], template: UploadDocuments, regularFlow: false },
      { order: 12, route: '/stepReceipt', template: Receipt, regularFlow: true },
      { order: null, route: '/stepResumeSignupFlow', template: ResumeSignupFlow },
      { order: null, route: '/stepAlreadyCreated', template: AlreadyCreated },
      { order: null, route: '/stepVerifyAccountDetails', template: Receipt },
    ];

    // Get the current step from route on page load
    var currentStep = routeConfiguration.filter(function (step) {
      var locationHash = window.location.hash.toLowerCase();
      var hash = locationHash.replace('#retail-signup=', '');
      return hash === step.route.toLowerCase();
    });

    // It empty get the current step from the routeConfiguration array (setting [0] in the controller)
    currentStep = currentStep.length ? currentStep[0] : routeConfiguration[0];

    history.replaceState(null, null, `#retail-signup=${currentStep.route}`);

    var preSelectedPurposes = Utils.getQueryParam('purpose') ? Utils.getQueryParam('purpose').split(',') : [];

    var root = {
      controller: function () {
        this.isLoading = m.prop(true);
        this.isSubmitting = m.prop(false);
        this.isRetailIpad = m.prop(Utils.isRetailIpad());
        this.buttonIsDisabled = m.prop(true);
        this.modalError = m.prop(null);
        this.routeConfiguration = routeConfiguration;
        this.currentStep = m.prop(currentStep);
        this.totalSteps = m.prop(routeConfiguration.filter((elem) => elem.regularFlow).length - 1); // Regularflow minus receipt page
        this.progress = m.prop(0);
        this.model = m.prop(new UserModel());
        this.d = Dictionary.get.bind({ prefix: 'RetailSignup/' });
        this.purposes = m.prop([]);
        this.uploadSettings = settings;
        this.hasLoadedDocuments = m.prop(false);
        this.hasPreloadFiles = m.prop(false);
        this.noFilesSelected = m.prop(false);
        this.forceRegularSteps = m.prop(true);
        this.loginPage = m.prop(settings.loginPage || '/');
        this.receiptContent = m.prop({
          title: this.d('Receipt/PageTitle'),
          icon: 'circle_checkmark',
          callback: function () {
            var returnUrl = Utils.getReturnUrl();
            if (returnUrl) {
              window.location.href = returnUrl;
            } else {
              window.location.href = settings.receiptLink.Url;
            }
          },
          buttonText: settings.receiptLink.Text,
          timeout: 8000,
          init: function () {
            Utils.ensightenEvent('signup_sikkert_spil', 'success');
          }
        });
        this.showAddress = m.prop(false);
        this.backAllowed = m.prop(false);
        this.noPaddingTop = m.prop(false);
        this.hideBackButton = m.prop(false);
        this.maskPincodeInput = m.prop(false);
        this.showRetailMarketingTermsInEmail = m.prop(true);
        this.fieldInFocus = m.prop();
        this.elementIsInViewport = m.prop(false);
        this.globalWagerLimits = m.prop(null);

        // Collections to hold suggested addresses, zipcodes and cities
        this.zips = m.prop([]);
        this.cities = m.prop([]);
        this.addresses = m.prop([]);

        var getCities = function () {
          return RetailApi.getCities().then(function (response) {
            this.model().citiesList(response.data);
          }.bind(this));
        }.bind(this);

        var getCountries = function () {
          return RetailApi.getCountries().then(function (response) {
            this.model().availableCountries(response.data);
          }.bind(this));
        }.bind(this);

        var getProfile = function () {
          if (window.dsApplicationConfig?.ApplicationId === 'SHAPE_RETAILACCOUNT_IPADNATIVE') {
            this.maskPincodeInput(true);
          }

          return RetailApi.getProfileWithBankInformation().then(function (response) {
            var model = response.data.profile || {};
            this.model().update(model);
            this.model().setDefaultCountry();
            document.body.classList.add('is-logged-in');

            if (this.model().fields.isActive() && this.isRetailIpad()) {
              Utils.ensightenEvent('signup_error', 'already_have_a_default_account');
              Utils.pushVirtualPage('stepAlreadyCreated', (window.location.pathname.replace(/-/g, '_') + 'stepAlreadyCreated'), 'stepAlreadyCreated');
              route('/stepAlreadyCreated')();
              window.scrollTo(0, 0);
              this.isLoading(false);
            } else if (this.model().fields.isActive()) {
              window.location.href = settings.receiptLink.Url + window.location.search;
              return;
            } else if (this.model().fields.isPending() && model.lastLogin.value !== '') {
              this.forceRegularSteps(false);
              Utils.ensightenEvent('signup_error', 'continue_unfinished_registration');
              Utils.pushVirtualPage('stepResumeSignupFlow', (window.location.pathname.replace(/-/g, '_') + 'stepResumeSignupFlow'), 'stepResumeSignupFlow');
              route('/stepResumeSignupFlow')();
              window.scrollTo(0, 0);
              this.isLoading(false);
            } else if (this.model().fields.isPending() && this.model().fields.isFromFaroe()) {
              Utils.ensightenEvent('signup_sikkert_spil', 'pending_fo');
              Utils.pushVirtualPage('stepUploadDocuments', (window.location.pathname.replace(/-/g, '_') + 'stepUploadDocuments'), 'stepUploadDocuments');
              route('/stepUploadDocuments')();
              window.scrollTo(0, 0);
              this.isLoading(false);
            } else if (this.model().fields.isPending()) {
              hasDocuments().then(function () {
                if (this.model().hasDocuments()) {
                  window.location.href = settings.receiptLink.Url + window.location.search;
                  return;
                } else {
                  this.isLoading(false);
                  m.redraw();
                }
              }.bind(this));
            } else {
              this.isLoading(false);
            }

            m.redraw();
          }.bind(this), function (error) {
            this.showErrorModal(error);
          }.bind(this));
        }.bind(this);

        var getDepositLimits = () => {
          return RetailApi.getUserDepositLimits().then((response) => {
            this.globalWagerLimits(response.limits);
          });
        };

        var getPurposes = function () {
          return RetailApi.getUploadFilePurposes('RetailSignup').then(function (response) {
            this.purposes(response.data.map(function (purpose) {
              var purposeModel = new PurposeModel(purpose, true);
              var foundMatch = preSelectedPurposes.some(function (preSelected) {
                return preSelected.toLowerCase() === (purposeModel.identifier().toLowerCase());
              });
              if (foundMatch) {
                this.model().files().push(new FileUploadModel(purposeModel, this.uploadSettings, this.d, false));
              }
              return purposeModel;
            }.bind(this)));
            m.redraw();
          }.bind(this));
        }.bind(this);

        var hasDocuments = function () {
          return RetailApi.hasDocuments().then(function (response) {
            this.model().hasDocuments(response.data.HasDocuments || false);
            m.redraw();
          }.bind(this));
        }.bind(this);

        this.hasFilesUploaded = function () {
          return this.model().files().filter(function (file) {
            return file.file() !== null;
          }).length > 0;
        }.bind(this);

        this.skipUpload = function () {
          if (this.hasFilesUploaded()) {
            this.receiptContent({
              title: this.d('Receipt/UploadPendingFiles/PageTitle'),
              abstract: this.d('Receipt/UploadPendingFiles/PageText'),
              icon: 'exclemation-mark',
              callback: function () {
                var returnUrl = Utils.getReturnUrl();
                if (returnUrl) {
                  window.location = returnUrl;
                } else {
                  window.location.pathname = settings.receiptLink.Url;
                }
              },
              timeout: 20000,
              buttonText: this.d('Receipt/UploadPendingFiles/ButtonText')
            });
          } else {
            this.receiptContent({
              title: this.d('Receipt/UploadNotAllowed/PageTitle'),
              abstract: this.d('Receipt/UploadNotAllowed/PageText'),
              icon: 'exclemation-mark',
              callback: function () {
                var returnUrl = Utils.getReturnUrl();
                if (returnUrl) {
                  window.location = returnUrl;
                } else {
                  window.location.pathname = settings.receiptLink.Url;
                }
              },
              timeout: 20000,
              buttonText: this.d('Receipt/UploadNotAllowed/ButtonText')
            });
          }
          Utils.pushVirtualPage('/stepUploadDocumentsRetail', (window.location.pathname.replace(/-/g, '_') + '/stepUploadDocumentsRetail'), '/stepuploaddocumentsretail');
          route('/stepReceipt')();
        }.bind(this);

        this.addFileRequirement = function (identifiers) {
          if (this.hasPreloadFiles() || this.purposes().length === 0) {
            return;
          }
          identifiers = Array.isArray(identifiers) ? identifiers : (typeof identifiers === 'string' ? [identifiers] : []);

          identifiers.forEach(function (identifier) {
            this.purposes().forEach(function (purpose) {
              if (purpose.identifier() === identifier) {
                this.model().files().push(new FileUploadModel(purpose, this.uploadSettings, this.d, false));
              }
            }.bind(this));
          }.bind(this));

          this.hasPreloadFiles(true);
        }.bind(this);

        this.checkElementInViewport = function (elem, isInitialized) {
          if (!isInitialized) {
            var detectElementInViewport = function () {
              this.elementIsInViewport(Utils.elementInViewport(elem));
            }.bind(this);

            window.addEventListener('scroll', detectElementInViewport);

            detectElementInViewport();
          }
        }.bind(this);

        this.updateProgress = function (locationHash) {
          var hash = locationHash.replace('#retail-signup=', '');
          var step = this.routeConfiguration.find(function (step) {
            return step.route.toLowerCase() === hash.toLowerCase();
          });

          if (!step) {
            step = this.routeConfiguration[0];
          }

          this.currentStep(step);

          if (!step.order) return;

          var newProgress = step.order / this.totalSteps() * 100;
          this.progress(newProgress);
        }.bind(this);

        this.setFieldFocus = function (e) {
          if (typeof this.fieldInFocus !== 'function') {
            return;
          }

          // If function is called with event from onfocus handler, set field to have focus.
          // If called without event, then set first input field to have focus
          var fieldName = e && e.target ? e.target.name : null;

          if (fieldName) {
            this.fieldInFocus(fieldName);
          }
        }.bind(this);

        this.validateField = function (ev, validateHandler) {
          if (ev.key === 'Tab' && !ev.target.value.length) return;

          var fieldName = ev.target.name;
          this.model().validate([fieldName]);

          var hasErrors = this.model().validate(this.currentStep().fields, false);
          this.buttonIsDisabled(hasErrors); // Disable button if fields has errors

          if (!hasErrors && typeof validateHandler === 'function') {
            validateHandler();
          }
        }.bind(this);

        this.mergeAndValidateField = (ev, validateHandler) => {
          if (this.currentStep().route === '/pincode') {
            this.mergeFieldsToSingleModelField('pincode', ['pin1', 'pin2', 'pin3', 'pin4']);
          }

          if (this.currentStep().route === '/repeat-pincode') {
            this.mergeFieldsToSingleModelField('pincodeConfirm', ['pin1Confirm', 'pin2Confirm', 'pin3Confirm', 'pin4Confirm']);
          }

          this.validateField(ev, validateHandler);
        };

        this.mergeFieldsToSingleModelField = (newFieldName, fieldNames = []) => {
          const fields = fieldNames.map((fieldName) => {
            return this.model().fields[fieldName]();
          });

          this.model().fields[newFieldName](fields.join(''));
        };

        this.addFile = function (file, index) {
          this.model().files()[index].file(file);
        }.bind(this);

        this.removeSelection = function (selection) {
          var removeIndex = this.model().files().indexOf(selection);
          this.model().files().splice(removeIndex, 1);
        }.bind(this);

        this.validateUpload = function () {
          this.model().files().forEach(function (file) {
            var hasErrors = file.file() && file.validate();
            this.buttonIsDisabled(hasErrors);
          }.bind(this));
        }.bind(this);

        this.findFirstErrorAndRouteToPage = (errors) => {
          if (errors.includes('pincode') || errors.includes('pincodeConfirm')) {
            this.resetFields(['pincode', 'pincodeConfirm', 'pin1', 'pin2', 'pin3', 'pin4', 'pin1Confirm', 'pin2Confirm', 'pin3Confirm', 'pin4Confirm']);
          }

          if (errors.includes('offlineWagerLimitPeriod')) {
            this.resetFields(['offlineWagerLimitPeriodDaily', 'offlineWagerLimitPeriodWeekly', 'offlineWagerLimitPeriodMonthly', 'offlineWagerLimitPeriod']);
          }

          if (errors.includes('offlineWagerLimit')) {
            this.resetFields(['offlineWagerLimit']);
          }

          const step = this.routeConfiguration.find((routeConfig) => routeConfig.fields?.some((fieldKey) => errors.includes(fieldKey)));
          this.currentStep(step);
          this.hideBackButton(this.currentStep().hideBackButton || false);
          this.goToRoute(step.route);
        };

        this.submit = function () {
          var fields = this.currentStep().fields;
          var hasErrors = this.model().validate(fields);

          if (hasErrors) {
            return;
          }

          if (this.currentStep().route === '/pincode') {
            this.resetFields(['pincodeConfirm', 'pin1Confirm', 'pin2Confirm', 'pin3Confirm', 'pin4Confirm']);
            this.routeToNextStep(null, true);
            return;
          }

          if (this.currentStep().route === '/limit/value') {
            if (!this.isValidWithinGlobalLimits()) {
              return;
            }

            this.goToRoute('/limit/confirm');
            return;
          }

          if (this.currentStep().skipSubmit) {
            this.routeToNextStep();
            return;
          }

          if (this.currentStep().optionalFields) {
            fields = this.currentStep().fields.concat(this.currentStep().optionalFields);
          }

          var data = Utils.mapModelToApiData(fields, this.model().fields, this.model().initialValues);
          this.isLoading(true);

          RetailApi.updateProfile(data).then(function (response) {
            var errors = this.model().setErrors(response.data, true);
            if (errors) {
              this.findFirstErrorAndRouteToPage(errors);
              Utils.trackUserInputErrors('signup_error', this.model().errors);
              return;
            }

            this.model().update(response.data.profile); // Make sure our frontend UserModel is in sync with backend

            if (fields.indexOf('marketingTerms') > -1) {
              Utils.ensightenEvent('permission_signup_' + this.model().fields.marketingTerms(), 'marketingTerms');
            }

            if (fields.indexOf('acceptDataProcessing') > -1) {
              this.model().nameIsSet(true);

              // Refresh cached user information to ensure header and login has recent info
              SitecoreAuth.getUserObj(function (ignoreResponse) {
              });
            }

            this.routeToNextStep();
          }.bind(this), function (error) {
            this.showErrorModal(error);
          }.bind(this)).then(function () {
            this.isLoading(false);
            this.buttonIsDisabled(true);
            m.redraw();
          }.bind(this));
        }.bind(this);

        this.resetFields = (fields) => {
          fields.forEach((fieldKey) => {
            this.model().fields[fieldKey](null);
          });
        };

        this.goToRoute = function (routePath) {
          var step = this.routeConfiguration.filter(function (step) {
            return step.route.toLowerCase() === routePath.toLowerCase();
          })[0];

          this.currentStep(step);
          Utils.pushVirtualPage(routePath, (window.location.pathname.replace(/-/g, '_') + routePath), routePath);
          route(routePath)();
        }.bind(this);

        this.routeToPrevStep = function () {
          window.scrollTo(0, 0);

          var currentStep = this.currentStep();
          var setRoute = this.routeConfiguration[currentStep.order - 2];

          if (!setRoute) {
            return;
          }

          if (setRoute.route === '/pincode') {
            this.resetFields([...setRoute.fields, 'pin1', 'pin2', 'pin3', 'pin4']);
          }

          if (currentStep.route === '/repeat-pincode') {
            this.resetFields([...currentStep.fields, 'pin1Confirm', 'pin2Confirm', 'pin3Confirm', 'pin4Confirm']);
          }

          if (currentStep.route === '/limit/value') {
            this.resetFields(['offlineWagerLimitPeriodDaily', 'offlineWagerLimitPeriodWeekly', 'offlineWagerLimitPeriodMonthly', 'offlineWagerLimitPeriod']);
          }

          Utils.pushVirtualPage(setRoute.route, (window.location.pathname.replace(/-/g, '_') + setRoute.route), setRoute.route);
          route(setRoute.route)();
        }.bind(this);

        this.routeToNextStep = function (routePath, setAutoFocus) {
          if (routePath) {
            Utils.pushVirtualPage(routePath, (window.location.pathname.replace(/-/g, '_') + routePath), routePath);
            route(routePath)();
            window.scrollTo(0, 0);
            return;
          }

          var currentStep = this.currentStep();
          var setRoute = this.routeConfiguration[currentStep.order];

          if (this.forceRegularSteps() && setRoute.regularFlow) {
            Utils.pushVirtualPage(setRoute.route, (window.location.pathname.replace(/-/g, '_') + setRoute.route), setRoute.route);
            route(setRoute.route)();
            window.scrollTo(0, 0);
            return;
          }

          var userIsPending = this.model().fields.isPending();

          if (this.currentStep().route === '/stepGameScanner' && userIsPending && this.isRetailIpad()) {
            this.skipUpload();
            return;
          }

          for (var index in this.routeConfiguration) {
            var step = this.routeConfiguration[index];
            var requiredFields = step.fields;

            // If route does not have missing fields and is part of the flow (defined by order), then load this
            if (!requiredFields && step.order) {
              Utils.pushVirtualPage(step.route, (window.location.pathname.replace(/-/g, '_') + step.route), step.route);
              route(step.route)();
              window.scrollTo(0, 0);
              break;
            }

            var missingFields = requiredFields.filter(function (field) {
              return !this.model().fields[field]();
            }.bind(this));

            if (missingFields.length) {
              if (step.route === '/stepUploadDocuments') {
                if (this.isRetailIpad()) {
                  this.skipUpload();
                  break;
                } else {
                  Utils.ensightenEvent('signup_sikkert_spil', 'pending');
                }
              }
              Utils.pushVirtualPage(step.route, (window.location.pathname.replace(/-/g, '_') + step.route), step.route);
              route(step.route)();
              window.scrollTo(0, 0);
              break;
            }
          }

          if (setAutoFocus) {
            if (step.route === '/pincode') {
              document.querySelector('[name="pin1"]')?.focus();
            }

            if (step.route === '/repeat-pincode') {
              document.querySelector('[name="pin1Confirm"]')?.focus();
            }
          }
        }.bind(this);

        this.upload = function () {
          var hasErrors = this.model().files().map(function (file) {
            return file.validate();
          }).some(function (error) {
            return error;
          });

          if (this.model().files().length === 0) {
            this.noFilesSelected(true);
            hasErrors = true;
          }

          if (hasErrors) {
            return;
          }

          var data = Utils.mapFilesToApiData(this.model);
          this.isLoading(true);

          RetailApi.uploadDocuments(data).then().then(function (response) {
            var data = response.data;
            if (!data.OperationSuccess) {
              this.showErrorModal(data);
              return;
            }

            this.model().files([]); // Lets reset all uploaded files.

            this.receiptContent({
              title: this.d('Receipt/UploadSuccess/PageTitle'),
              abstract: this.d('Receipt/UploadSuccess/PageText'),
              icon: 'circle_checkmark',
              callback: function () {
                var returnUrl = Utils.getReturnUrl();
                if (returnUrl) {
                  window.location = returnUrl;
                } else {
                  window.location.hash = '#retail-signup=/stepUploadDocuments';
                }
              },
              buttonText: this.d('Receipt/UploadSuccess/ButtonText')
            });

            Utils.pushVirtualPage('/stepUploadDocumentReceipt', (window.location.pathname.replace(/-/g, '_') + '/stepUploadDocumentReceipt'), '/stepUploadDocumentReceipt');
            route('/stepReceipt')();
            window.scrollTo(0, 0);
          }.bind(this), function (error) {
            this.showErrorModal(error);
          }.bind(this)).then(function () {
            this.isLoading(false);
            this.buttonIsDisabled(true);
          }.bind(this));

        }.bind(this);

        this.showErrorModal = (error) => {
          var confirm = null;
          var dismisable = true;

          if (error.statusCode === 401) {
            confirm = function () {
              window.location.href = Utils.appendParameter('loginSuccessUrl', encodeURIComponent(window.location.href), settings.loginLink.Url);
            };
            dismisable = false;
          }

          this.modalError(new FrameworkOverlay(ErrorOverlay({
            title: this.d('ErrorModal/Title'),
            body: error.message,
            confirm: confirm,
            dismisable: dismisable,
            confirmButtonClass: settings.loginLink.CssClass || null,
            confirmButton: confirm ? settings.loginLink.Text : null
          }))).show();
        };

        this.selectAddressFromSelection = function (value) {
          var match = this.addresses().filter(function (entry) {
            return entry.value.indexOf(value) === 0;
          });

          if (match.length) {
            var model = match[0].model;
            this.model().fields.street(model.street);
            this.model().fields.houseNumber(model.houseNumber);
            this.model().fields.zipCode(model.zipCode);
            this.model().fields.city(model.city);
            model.level ? this.model().fields.level([model.level, model.door].join('. ')) : null;
            this.model().validate(['street', 'houseNumber', 'zipCode', 'city']);
            this.addresses([]);
          }
        }.bind(this);

        this.selectCityFromZip = function (zip) {
          var match = this.model().citiesList().filter(function (entry) {
            return entry.ZipCode.indexOf(zip) === 0;
          });
          if (match.length) {
            this.model().fields.zipCode(match[0].ZipCode);
            this.model().fields.city(match[0].City);
            this.model().validate(['city']);
          } else {
            this.model().fields.city(null);
          }
        }.bind(this);

        this.lookupZip = function (e) {
          this.setFieldFocus(e);
          var val = e.target.value;

          if (val.length === 4 || e.key === 'Enter') {
            this.selectCityFromZip(val);
            this.fieldInFocus(null);
          }

          if (val.length) {
            var matches = this.model().citiesList().filter(function (entry) {
              return entry.ZipCode.indexOf(val) === 0;
            }).map(function (entry, index) {
              return {
                value: entry.ZipCode,
                highlighted: index === 0
              };
            });
            this.zips(matches);
          } else {
            this.zips([]);
          }
        }.bind(this);

        this.lookupCity = function (e) {
          this.setFieldFocus(e);
          var val = e.target.value;

          if (val.length) {
            var matches = this.model().citiesList().filter(function (entry) {
              return entry.City.toLowerCase().indexOf(val.toLowerCase()) === 0;
            }).map(function (entry, index) {
              return {
                value: entry.City,
                highlighted: index === 0
              };
            });
            this.cities(matches);
          } else {
            this.cities([]);
          }
        }.bind(this);

        this.isValidWithinGlobalLimits = () => {
          const type = Utils.capitalize(this.model().fields.offlineWagerLimitPeriod());
          const value = this.model().fields.offlineWagerLimit();

          if (!this.globalWagerLimits()) {
            return true;
          }

          const globalLimit = this.globalWagerLimits().find((limit) => {
            return limit.Type.toLowerCase() === type.toLowerCase() && (limit.IsGlobal || limit.IsOperator);
          });

          if (Number(value) <= Utils.convertCentsToKroner(globalLimit.Value)) {
            return true;
          }

          this.model().errors.offlineWagerLimit(this.d(`Errors/ValueAboveMaxLimit${type}`, { VALUE: Utils.formatCurrency(value), LIMIT: Utils.convertCentsToKronerAndFormat(globalLimit.Value) }));
          return false;
        };

        this.lookupAddress = function (e) {
          var val = e.target.value;
          if (e.key === 'Enter') {
            this.selectAddressFromSelection(val);
            this.addresses([]);
            return;
          }

          if (val.length) {
            RetailApi.getDawaAddress(val).then(function (response) {
              this.addresses(response);
              m.redraw();
            }.bind(this));
          } else {
            this.addresses([]);
          }
        }.bind(this);

        this.loadDocumentData = function () {
          this.hasLoadedDocuments(true);
          m.sync([getPurposes(), hasDocuments()]).then(function () {
            m.redraw();
          }.bind(this));
        }.bind(this);

        m.sync([getProfile(), getCities(), getCountries(), getDepositLimits()]);

        this.updateProgress(window.location.hash);

        window.addEventListener('popstate', function (e) {
          this.updateProgress(e.currentTarget.location.hash);
        }.bind(this), false);

        document.body.setAttribute('data-override-logout-return-url', settings.receiptLink?.Url || `/${window.location.pathname.split('/')[1]}`);

        Utils.setupLoginLinkEventListeners();
      },
      view: function (controller) {
        var currentStep = controller.currentStep().route.replace('/', '').toLowerCase();
        if (controller.isLoading()) {
          return Loader();
        }

        return m('div', {
          class: [
            'retail-page',
            'retail-signup',
            currentStep ? 'retail-signup--' + currentStep : ''
          ].join(' '),
          'data-uitest-id': Utils.replaceSlashWithHyphens(route(), 'retail-signup')
        }, routeConfiguration.reduce(function (template, step) {
          if (route() === step.route) {
            template = step.template(controller, 'retail-signup');
          }

          return template;
        }, null));
      }
    };


    // Routes
    route('/', root);

    for (var index in routeConfiguration) {
      var step = routeConfiguration[index];

      route(step.route, root);
    }
  });

});
