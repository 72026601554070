defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/FileUpload', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Svg',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorMessage'
], function (m, Utils, Svg, ErrorMessage) {

  var FileUpload = function (model, index, validate, add, remove, settings, dictionary) {
    var id = 'retail-file-upload--' + index;

    return m('div', [
      !model.purpose().isActive() ?
        m('button', {
          class: ['retail-file-upload--inactive', 'retail-button', 'has-text'].join(' '),
          onclick: function (e) {
            e.preventDefault();
            model.purpose().isActive(true);
          },
          'aria-label': 'activate-' + model.purpose().purpose() + '-button',
          'data-hover-text': model.purpose().purpose()
        }, [
          m('span', { class: 'retail-button__text' }, model.purpose().purpose())]) :
        m('div', {
          class: [
            'retail-file-upload',
            model.errors().length > 0 ? 'has-errors' : null
          ].join(' ')
        }, [
          model.closeable() ? m('button', {
            type: 'button',
            class: ['retail-file-upload__close-button'],
            onclick: function (e) {
              e.preventDefault();
              remove(model);
            },
            'aria-label': 'close-button'
          }, [
            m('span', [
              Svg('close', { class: 'retail-file-upload__close-button--svg' })
            ])
          ]) : null,
          m('div.retail-file-upload__header', [
            m('span', model.purpose().header()),
            model.purpose().isRequired() ? null : m('span.retail-file-upload__header-optional', dictionary('UploadDocuments/Optional'))
          ]),
          m('div.retail-file-upload__description', model.purpose().description()),
          !model.file() ? m('label', {
            class: 'retail-file-upload__button',
            for: id,
            'data-label-text': 'upload-' + model.purpose().purpose() + '-button'
          }, [
            m('span', [
              Svg('plus', { class: 'retail-file-upload__add-button--svg' })
            ]),
            m('span', { class: 'retail-file-upload__button-text' }, dictionary('UploadDocuments/AddFile'))
          ]) : m('div', { class: 'retail-file-upload__details' }, [
            m('div', { class: 'retail-file-upload__details-column--1' }, [
              m('div', { class: 'retail-file-upload__details-name' }, Utils.prettifyFileName(model.file().name)),
              m('div', { class: 'retail-file-upload__details-type' }, dictionary('UploadDocuments/FileType', { type: Utils.prettifyFileType(model.file().name) }))
            ]),
            m('div', {  class: 'retail-file-upload__details-column--2' }, [
              m('div', { class: 'retail-file-upload__details-size-label' }, dictionary('UploadDocuments/FileSize')),
              m('div', { class: 'retail-file-upload__details-size-value' }, Utils.prettifyFileSize(model.file().size))
            ]),
            m('div', { class: 'retail-file-upload__details-column--3' },
              m('button', {
                type: 'button',
                class: 'retail-file-upload__details-remove',
                onclick: function (e) {
                  e.preventDefault();
                  model.file(null);
                  model.errors([]);
                  document.getElementById(id).value = null;
                }
              }, m('span', [
                Svg('trashbin', { class: 'retail-file-upload__details-remove-icon' })
              ]))
            )
          ]),
          m('input', {
            class: 'retail-file-upload__input',
            type: 'file',
            accept: settings.AllowedMimeTypes,
            name: model.purpose().header(),
            id: id,
            onchange: function (event) {
              var newFile = event.target.files[0];
              add(newFile, index);
              validate();
            }
          }),
          model.errors().length > 0 ? model.errors().map(function (error) {
            return ErrorMessage(error);
          }) : null// TODO: Add dictionary text here for each input type
        ])
    ]);
  };

  return FileUpload;

});
