defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/NotificationDialog', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Svg',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/IconButton'
], function (m, Svg, IconButton) {

  var NotificationDialog = function (config) {
    var options = {
      class: null,
      type: 'info',
      title: '',
      closeIcon: 'close',
      onClose: null,
      ...config
    };

    return m('div', {
      class: [
        'retail-notification-dialog',
        'retail-notification-dialog--' + options.type,
        options.class ? options.class : null
      ].join(' '),
    }, [
      Svg(options.type, {
        class: 'retail-notification-dialog__icon'
      }),
      m('.retail-notification-dialog__content', options.title),
      IconButton({
        class: 'retail-notification-dialog__close-icon',
        icon: options.closeIcon,
        onclick: function () {
          if (typeof options.onClose === 'function') {
            options.onClose();
          }
        }
      })
    ]);
  };

  return NotificationDialog;

});
