defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/GameHistoryNavigation', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Mithril/Scripts/Helpers/RouteParam',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Link',
], (m, RouteParam, Utils, Link) => {

  const GameHistoryNavigation = (controller) => {
    const page = RouteParam('page')() || controller.settings?.page || 1;
    const period = RouteParam('period')() || controller.settings?.period || 365;
    const offset = RouteParam('offset')() || controller.settings?.offset || 1;
    const type = RouteParam('type')() || 'overblik';

    return m('.retail-tabs', [

      Link({
        onclick: () => {
          Utils.pushGaEventOnPageRedirect('sikkert_spil', 'cta_click', 'aktive spil');
          m.route(`/${page}/${period}/${offset}/aktive`);
        },
        class: type === 'aktive' && 'is-active',
        text: controller.d('List/ActiveGamesLabel')
      }),

      Link({
        onclick: () => {
          Utils.pushGaEventOnPageRedirect('sikkert_spil', 'cta_click', 'afsluttede spil');
          m.route(`/${page}/${period}/${offset}/afsluttede`);
        },
        class: type === 'afsluttede' && 'is-active',
        text: controller.d('List/FinishedGamesLabel')
      }),

      Link({
        onclick: () => {
          Utils.pushGaEventOnPageRedirect('sikkert_spil', 'cta_click', 'overblik');
          m.route('/overblik/');
        },
        class: type === 'overblik' && 'is-active',
        text: controller.d('List/OverviewLabel')
      })

    ]);
  };

  return GameHistoryNavigation;
});
