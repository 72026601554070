defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/ViewPINCode', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
], function (m, Utils, StepHeader, Button, BackButton) {

  var ViewPINCode = function (controller) {
    const hasSetPincode = controller.model().initialValues.pincode !== null;
    return [
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton()) : null,
      m('div', {
        class: 'retail-page__content'
      },
      m('.retail-page__row', [
        m('div', {
          class: 'retail-page__column retail-page__column--1'
        }, [
          StepHeader({
            title: controller.d('ViewPINCode/PageTitle')
          }),
          hasSetPincode ?
            m('.retail-rich-text', [
              m('strong', controller.d('ViewPINCode/CurrentPINCodeText', { PINCODE: controller.model().initialValues.pincode })),
              m('p', controller.d('ViewPINCode/PINCodeRecommendation'))
            ])
            : null,
        ]
        ),
        m('div', {
          class: 'retail-page__column retail-page__column--2'
        }, [
          hasSetPincode ?
            m('.retail-button-container.retail-button-container--centered',
              Button({
                buttonText: controller.d('ViewPINCode/CTA'),
                uiTestId: 'retail-button-view-pincode--cancel',
                onclick: function () {
                  var returnUrl = Utils.getReturnUrl();
                  if (returnUrl) {
                    window.location.href = returnUrl;
                  } else {
                    controller.goToRoute('/');
                  }
                }
              })
            )
            : null,
          m('.retail-button-container.retail-button-container--centered',
            Button({
              uiTestId: 'retail-button-view-pincode--edit',
              class: 'retail-button--secondary',
              buttonText: controller.d('ViewPINCode/SecondCTA'),
              onclick: function () {
                controller.goToRoute('/edit-pincode');
              }
            })
          )
        ])
      ])
      )
    ];
  };

  return ViewPINCode;

});
