defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril'
], function (m) {

  var Stepheader = function (config) {
    var options = {
      title: '',
      abstract: null,
      ...config
    };

    return m('.retail-step-header', [
      m('h1', {
        class: 'retail-step-header__title'
      }, m.trust(options.title)),
      options.abstract ?
        m('div', {
          class: 'retail-step-header__abstract'
        }, m.trust(options.abstract))
        : null
    ]);
  };

  return Stepheader;

});
