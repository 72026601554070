defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Components/RetailGameHistoryDetails', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Mithril/Scripts/Helpers/Render',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Dictionary',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Api',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/GameHistoryDetails'
], function (m, Render, Utils, Dictionary, RetailApi, GameHistoryDetails) {

  var GameHistoryDetailsComponent = {
    controller: function (args) {
      this.isLoading = m.prop(true);
      this.gameId = m.route.param('gameId');
      this.d = Dictionary.get.bind({ prefix: 'RetailGameHistory/' });
      this.game = m.prop({});
      this.render = Render();
      this.settings = args.settings;
      this.height = m.prop(null);
      this.width = m.prop(null);
      this.showErrorModal = args.showErrorModal;
      this.isWebview = m.prop(window.dsApplicationConfig);

      var controller = this;

      // Handle resize event from iframe
      var resizeMessageHandler = function (message) {
        try {
          var json = JSON.parse(message.data);
          if (json && json.event === 'resizeGameCoupon') {
            this.height(json.height);
            this.width(json.width);
            m.redraw();
          }
        } catch (e) {}
      }.bind(this);

      this.redirectBack = function (e) {
        e.preventDefault();
        window.history.back();
      };

      this.printReceipt = function () {
        document.getElementsByClassName('game-receipt__iframe')[0].contentWindow.print();
      };

      this.resizeIframe = function () {
        var body = this.contentWindow.document.body;
        var scrollHeight = body.scrollHeight;

        var setWidthAndHeight = function () {
          var totalVerticalMargin = parseInt(getComputedStyle(body).getPropertyValue('margin-top'), 10) + parseInt(getComputedStyle(body).getPropertyValue('margin-bottom'), 10);
          var totalHorizontalMargin = parseInt(getComputedStyle(body).getPropertyValue('margin-left'), 10) + parseInt(getComputedStyle(body).getPropertyValue('margin-right'), 10);

          // Iframe has 2:1 sizing per default, try to accommodate by setting height first and then checking width after
          controller.height(body.scrollHeight + totalVerticalMargin);
          m.redraw(true);
          controller.width(body.scrollWidth + totalHorizontalMargin);
          m.redraw();
        };

        setWidthAndHeight();

        // some iframe content does not send message handler, but they do send an iframe onload event.
        // unfortunately, the onload event is sent before the content of the iframe is fully loaded,
        // so we make a little check for a few seconds to see if content have changed. if so, we resize.
        // due to some rounding issues, we only redraw if content is more than 10px higher than before.
        var timesCheckedForChangeInIframe = 0;
        var checkForContentChangeInIframe = setInterval(function () {
          if (scrollHeight < body.scrollHeight - 10) {
            scrollHeight = body.scrollHeight;
            setWidthAndHeight();
          }

          if (timesCheckedForChangeInIframe++ > 10) {
            clearInterval(checkForContentChangeInIframe);
          }
        }.bind(this), 500);
      };

      this.onunload = function () {
        window.removeEventListener('message', resizeMessageHandler);
      };

      RetailApi.getGameDetails(this.gameId).then(function (response) {
        document.body.classList.add('is-logged-in');
        var playerGame = response.data;

        Utils.pushVirtualPage('/details/' + this.gameId, (window.location.pathname.replace(/-/g, '_') + '/details/' + this.gameId), '/details/' + this.gameId);

        this.game({
          id: playerGame.Id,
          isActive: playerGame.WagerStatus === 'STARTED',
          couponUrl: playerGame.CouponUrl || null,
          hasValidCouponUrl: (playerGame.CouponUrl && playerGame.CouponUrl.slice(0, 1) !== '?')
        });
      }.bind(this), function (error) {
        this.showErrorModal(error);
      }.bind(this)).then(function () {
        this.isLoading(false);
        m.redraw();
      }.bind(this));

      window.addEventListener('message', resizeMessageHandler);
    },
    view: function (controller) {
      return GameHistoryDetails(controller);
    }
  };

  return GameHistoryDetailsComponent;

});
