defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Components/RetailPlaycard', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'Common/Framework/EventHandling/Scripts/Event',
  'DanskeSpil/Domain/Authentification/Scripts/LoginController',
  'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Dictionary',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Api',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/UserModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/LimitModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/PlaycardModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Loader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Overlay',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorOverlay',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/PlaycardOverview',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/OrderPlaycardIntro',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/OrderPlaycardPincode',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/OrderPlaycardConfirm',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/ReactivatePlaycard',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Limits',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/ConfirmWagerLimits',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/ViewPINCode',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/PINCode',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Receipt'
],
function (Component, Event, LoginController, FrameworkOverlay, Dictionary, Utils, RetailApi, UserModel, LimitModel, PlaycardModel, Loader, Overlay, ErrorOverlay, PlaycardOverview, OrderPlaycardIntro, OrderPlaycardPincode, OrderPlaycardConfirm, ReactivatePlaycard, Limits, ConfirmWagerLimits, ViewPINCode, PINCode, Receipt) {

  // Declare steps in signup flow
  var routeConfiguration = [
    { route: '/', template: PlaycardOverview },
    { route: '/manage', template: OrderPlaycardIntro },
    { route: '/order/pincode-option', template: OrderPlaycardPincode },
    { route: '/order/confirm', template: OrderPlaycardConfirm },
    { route: '/reorder', template: OrderPlaycardIntro },
    { route: '/limits', template: Limits, dictionaryKey: 'WagerLimits' },
    { route: '/offline-limits', template: Limits, dictionaryKey: 'OfflineLimits' },
    { route: '/limits/confirm', template: ConfirmWagerLimits },
    { route: '/reactivate', template: ReactivatePlaycard },
    { route: '/pincode', template: ViewPINCode },
    { route: '/edit-pincode', fields: ['pincode'], dictionaryKey: 'SetPINCode', template: PINCode, },
    { route: '/repeat-pincode', fields: ['pincode', 'pincodeConfirm'], dictionaryKey: 'ConfirmPINCode', template: PINCode },
    { route: '/receipt', template: Receipt }
  ];

  // Get the current step from route on page load
  var currentStep = routeConfiguration.filter(function (step) {
    var locationHash = window.location.hash.toLowerCase();
    var hash = locationHash.replace('#retail-playcard=', '');
    return hash === step.route.toLowerCase();
  });

  // It empty get the current step from the routeConfiguration array (setting [0] in the controller)
  if (!currentStep.length) {
    currentStep = routeConfiguration[0];
  } else {
    currentStep = currentStep[0];
  }

  // Component:
  Component('retail-playcard', [Dictionary], function (m, route, settings) {

    var root = {
      controller: function () {
        this.d = Dictionary.get.bind({ prefix: settings.DictionaryRoot });
        this.isLoading = m.prop(true);
        this.buttonIsDisabled = m.prop(true);
        this.isSubmitting = m.prop(false);
        this.model = m.prop(new UserModel());
        this.playcardModel = m.prop(new PlaycardModel());
        this.modalError = m.prop(null);
        this.modalQRCode = m.prop(null);
        this.routeConfiguration = routeConfiguration;
        this.currentStep = m.prop(currentStep);
        this.fieldInFocus = m.prop();
        this.postfix = ' kr.';
        this.playcardRenewed = m.prop(false);
        this.receiptContent = m.prop({
          title: this.d('Receipt/OrderPlaycardTitle'),
          icon: 'circle_checkmark',
          callback: () => {
            var returnUrl = Utils.getReturnUrl();
            if (returnUrl) {
              if (this.playcardRenewed()) {
                window.location.href = Utils.appendParameter('playIdRenewed', 'true', returnUrl);
              } else {
                window.location.href = returnUrl;
              }
            } else if (settings.receiptLink) {
              window.location.href = settings.receiptLink.Url;
            } else {
              route('/')();
            }
          },
          buttonText: this.d('Receipt/OrderPlaycardButton')
        });
        this.appLink = m.prop(document.body.classList.contains('ios-device') ? settings.appStoreLink : settings.googlePlayLink);
        this.hideBackButton = m.prop((Utils.getQueryParam('dsApplicationId') || window.dsApplicationConfig) || false);
        this.mobilePayLink = m.prop(settings.mobilePayLink.Url || null);
        this.mobilePayQRCode = m.prop(null);
        this.zoomQR = m.prop(false);
        this.noLimitsChanged = m.prop(false);
        this.showPlaycardFrontside = m.prop(!(Utils.getQueryParam('show') && (Utils.getQueryParam('show') === 'backside')));
        this.showPendingNotification = m.prop(true);
        this.mouseoverPlaycard = m.prop(false);
        this.subMenuLink = m.prop(null);
        this.logoutRedirectUrl = Utils.appendParameter('loginSuccessUrl', encodeURIComponent(window.location.href), settings.loginLink.Url);
        Utils.redirectOnLogout(this.logoutRedirectUrl);

        if (Utils.getQueryParam('isLoggedIn')) {
          Utils.ensightenEvent('login', 'success');
        }

        this.openMobilePay = function () {
          if ((Utils.isMobile() || Utils.isTabletDevice()) && this.mobilePayLink()) {
            window.location.href = this.mobilePayLink().replace('{PLAYCARDNUMBER}', this.playcardModel().digitalPlaycardNumber());
          } else {
            this.modalQRCode(new FrameworkOverlay(Overlay('retail-overlay-container', {
              title: this.d('PlaycardOverview/QRCodeTitle'),
              body: this.mobilePayQRCode()
            }))).show();
          }
        }.bind(this);

        this.goToRoute = function (routePath) {
          var step = this.routeConfiguration.filter(function (step) {
            return step.route.toLowerCase() === routePath.toLowerCase();
          })[0];

          this.currentStep(step);

          window.scroll(0, 0);
          Utils.pushVirtualPage(routePath, (window.location.pathname.replace(/-/g, '_') + routePath), routePath);
          route(routePath)();
        }.bind(this);

        this.showErrorModal = (error) => {
          var confirm = null;

          if (error.statusCode === 401 && settings.loginLink.Url) {
            confirm = function () {
              window.location.href = Utils.appendParameter('loginSuccessUrl', encodeURIComponent(window.location.href), settings.loginLink.Url);
            };
          } else if (error.statusCode === 401) {
            LoginController.openLogin();
          }

          this.modalError(new FrameworkOverlay(ErrorOverlay({
            title: error.message || this.d('ErrorModal/Title'),
            confirm: confirm,
            dismisable: false,
            confirmButtonClass: settings.loginLink.CssClass || null,
            confirmButton: confirm ? settings.loginLink.Text : null
          }))).show();
        };

        this.confirmPlaycardOrder = () => {
          if (this.playcardModel().isPhysicalCard() && this.playcardModel().canReorder()) {
            this.reOrderPlaycard();
          } else if (this.playcardModel().isPhysicalCard()) {
            this.reactivatePlaycard();
          } else {
            this.orderPlaycard();
          }
        };

        this.orderPlaycard = function () {
          this.isSubmitting(true);
          RetailApi.orderPlaycard().then(function (response) {
            if (response.status === 'success') {
              Utils.ensightenEvent('card_order', 'success');
              this.playcardModel().playcardStatus('block');
              this.receiptContent().title = response.data.Abstract;

              Utils.pushVirtualPage('/receipt', (window.location.pathname.replace(/-/g, '_') + '/receipt'), '/receipt');

              if (Utils.isRetailIpad()) {
                this.receiptContent({
                  title: this.d('Receipt/RetailIpadOrderPlaycardTitle'),
                  icon: 'circle_checkmark',
                  callback: function () {
                    var returnUrl = Utils.getReturnUrl();
                    window.location.href = returnUrl;
                  },
                  buttonText: this.d('Receipt/RetailIpadPlaycardButton'),
                  timeout: 3000
                });
              }

              route('/receipt')();
            } else {
              Utils.ensightenEvent('card_order', 'error');
              this.showErrorModal();
            }
          }.bind(this), function (error) {
            this.showErrorModal(error);
            Utils.ensightenEvent('card_order', 'error_' + error.message);
          }.bind(this)).then(function () {
            this.isSubmitting(false);
            m.redraw();
          }.bind(this));
        }.bind(this);

        this.reactivatePlaycard = (renewPlaycardId = false) => {
          this.playcardRenewed(renewPlaycardId);

          RetailApi.reactivatePlaycard().then(() => {
            if (this.playcardModel().isPhysicalCard()) {
              this.receiptContent().title = this.d('Receipt/ReactivatePlaycardPhysicalCard');
            } else {
              this.receiptContent().title = this.d('Receipt/ReactivatePlaycard');
            }
            Utils.pushVirtualPage('/receipt', (window.location.pathname.replace(/-/g, '_') + '/receipt'), '/receipt');
            route('/receipt')();
          }, () => {
            Utils.ensightenEvent('card_reactivate', 'error');
            this.showErrorModal();
          }).then(() => {
            this.isSubmitting(false);
            m.redraw();
          });
        };

        this.reactivatePlaycardWithNewPincode = () => {
          RetailApi.removePincodeExclusion().then(() => {
            this.receiptContent().title = this.d('Receipt/ReactivatePlaycardWithPincode');
            Utils.pushVirtualPage('/pincode', (window.location.pathname.replace(/-/g, '_') + '/pincode'), '/pincode');
            this.goToRoute('/pincode');
          }, () => {
            Utils.ensightenEvent('card_reactivate', 'error');
            this.showErrorModal();
          }).then(() => {
            m.redraw();
          });
        };

        this.reOrderPlaycard = function () {
          this.isSubmitting(true);

          RetailApi.reOrderPlaycard().then(function (response) {
            if (response.status === 'success') {
              Utils.ensightenEvent('card_reorder', 'success');
              this.playcardModel().playcardStatus('block');
              this.receiptContent().title = response.data.Abstract;
              Utils.pushVirtualPage('/receipt', (window.location.pathname.replace(/-/g, '_') + '/receipt'), '/receipt');
              route('/receipt')();
            } else {
              Utils.ensightenEvent('card_reorder', 'error');
              this.showErrorModal();
            }
          }.bind(this), function (error) {
            Utils.ensightenEvent('card_reorder', 'error_' + error.message);
            this.showErrorModal(error);
          }.bind(this)).then(function () {
            this.isSubmitting(false);
            m.redraw();
          }.bind(this));
        }.bind(this);

        this.togglePlaycardStatus = function () {
          RetailApi.togglePlaycardStatus().then(function (response) {
            if (response.status.toLowerCase() === 'ok') {
              this.playcardModel().playcardStatus(response.data ? 'unblock' : 'block');
              var title = response.data ? 'BlockedPlaycardTitle' : 'UnblockedPlaycardTitle';
              this.receiptContent().title = this.d('Receipt/' + title);
              Utils.ensightenEvent('card_' + response.data ? 'unblock' : 'block', 'success');
              Utils.pushVirtualPage('/receipt', (window.location.pathname.replace(/-/g, '_') + '/receipt'), '/receipt');
              route('/receipt')();
              m.redraw();
            }
          }.bind(this), function (error) {
            Utils.ensightenEvent('card_block_unblock', 'error_' + error.message);
            this.showErrorModal(error);
          }.bind(this));
        }.bind(this);

        this.goToConfirmLimit = function () {
          var isValid = this.validateLimit();
          var hasChanges = this.model().userLimits().hasChanges();
          this.noLimitsChanged(!hasChanges && isValid);
          if (isValid && hasChanges) {
            this.goToRoute('/limits/confirm');
          }
        }.bind(this);

        this.validateLimit = function () {
          this.model().userLimits().clearErrors(); // Lets cleanup old errors
          return this.model().userLimits().validate();
        }.bind(this);

        this.confirmLimit = function () {
          var data = this.model().userLimits().mapToData();
          if (!this.validateLimit()) {
            // We found some errors lets stop
            return;
          }

          RetailApi.setWagerLimits(data).then(function (response) {
            Utils.trackLimitChanges('spilgraense', this.model().userLimits().limits());

            var limits = response.data.SavedUserWagerLimits.limits.map(function (limit) {
              return new LimitModel(limit);
            });

            this.model().userLimits().limits(limits);
            if (Utils.getQueryParam('returnUrl')) {
              window.location = Utils.getQueryParam('returnUrl');
            } else {
              Utils.pushVirtualPage('/', (window.location.pathname.replace(/-/g, '_') + '/'), '/');
              route('/')();
            }
          }.bind(this));
        }.bind(this);

        this.findFirstErrorAndRouteToPage = (errors) => {
          if (errors.includes('pincode')) {
            ['pincode', 'pincodeConfirm', 'pin1', 'pin2', 'pin3', 'pin4', 'pin1Confirm', 'pin2Confirm', 'pin3Confirm', 'pin4Confirm'].forEach((fieldKey) => {
              this.model().fields[fieldKey](null);
            });
          }

          const routeObj = this.routeConfiguration.find((routeConfig) => routeConfig.fields?.some((fieldKey) => errors.includes(fieldKey)));
          this.goToRoute(routeObj.route);
        };

        this.submit = function () {
          var fields = this.currentStep().fields;
          var hasErrors = this.model().validate(fields);

          if (hasErrors) {
            return;
          }

          if (this.currentStep().route === '/edit-pincode') {
            this.goToRoute('/repeat-pincode');
            return;
          }

          if (this.currentStep().optionalFields) {
            fields = this.currentStep().fields.concat(this.currentStep().optionalFields);
          }

          var data = Utils.mapModelToApiData(fields, this.model().fields, this.model().initialValues);
          this.isLoading(true);

          RetailApi.updateProfile(data).then(function (response) {
            var errors = this.model().setErrors(response.data, true);
            this.buttonIsDisabled(true);

            if (errors) {
              this.findFirstErrorAndRouteToPage(errors);
            } else {
              this.model().update(response.data.profile); // Make sure our frontend UserModel is in sync with backend
              this.goToRoute('/order/confirm');
            }
          }.bind(this), function (error) {
            this.showErrorModal(error);
          }.bind(this)).then(function () {
            this.isLoading(false);
            m.redraw();
          }.bind(this));
        }.bind(this);

        this.validateField = (ev, validateHandler) => {
          if (ev.key === 'Tab' && !ev.target.value.length) return;

          if (typeof this.fieldInFocus === 'function') {
            this.fieldInFocus(null);
          }

          var fieldName = ev.target.name;
          this.model().validate([fieldName]);

          var hasErrors = this.model().validate(this.currentStep().fields, false);
          this.buttonIsDisabled(hasErrors); // Disable button if fields has errors

          if (!hasErrors && typeof validateHandler === 'function') {
            validateHandler();
          }
        };

        this.mergeAndValidateField = (ev, validateHandler) => {
          if (this.currentStep().route === '/edit-pincode') {
            this.mergeFieldsToSingleModelField('pincode', ['pin1', 'pin2', 'pin3', 'pin4']);
          }

          if (this.currentStep().route === '/repeat-pincode') {
            this.mergeFieldsToSingleModelField('pincodeConfirm', ['pin1Confirm', 'pin2Confirm', 'pin3Confirm', 'pin4Confirm']);
          }

          this.validateField(ev, validateHandler);
        };

        this.mergeFieldsToSingleModelField = (newFieldName, fieldNames = []) => {
          const fields = fieldNames.map((fieldName) => {
            return this.model().fields[fieldName]();
          });

          this.model().fields[newFieldName](fields.join(''));
        };

        var getMobileLinkQRCode = function () {
          return RetailApi.getMobileLinkQR().then(function (response) {
            this.mobilePayQRCode(response.data.svg.Content);
          }.bind(this));
        }.bind(this);

        var getPlaycard = function () {
          return RetailApi.getPlaycard().then(function (response) {
            this.playcardModel().update(response.data);
          }.bind(this), function (error) {
            this.showErrorModal(error);
          }.bind(this));
        }.bind(this);

        var getVisualPlayCard = function () {
          return RetailApi.getVisualPlayCard().then(function (response) {
            this.playcardModel().digitalPlaycardImage(response.data.svg.Content);
            this.playcardModel().digitalPlaycardNumber(response.data.number);
            m.redraw();
          }.bind(this), function (error) {
            this.showErrorModal(error);
          }.bind(this));
        }.bind(this);

        var getPlayerLimits = function () {
          return RetailApi.getPlayerLimits().then(function (response) {
            var limits = response.limits.map(function (limit) {
              return new LimitModel(limit);
            });

            this.model().userLimits().sitecoreLimits([
              { type: 'Daily', value: settings.maxRetailDaily },
              { type: 'Weekly', value: settings.maxRetailWeekly },
              { type: 'Monthly', value: settings.maxRetailMonthly }
            ]);
            this.model().userLimits().limits(limits);
            this.model().userLimits().limitRequired(true);
            this.model().userLimits().limitMinimum(settings.LimitMinimum);
            m.redraw();
          }.bind(this), function (error) {
            this.modalError(new FrameworkOverlay(ErrorOverlay({
              body: error
            }))).show();
          }.bind(this));
        }.bind(this);

        RetailApi.getProfile().then(function (response) {
          var model = response.data.profile || {};
          this.model().update(model);
          document.body.classList.add('is-logged-in');

          Event.fire('retailCarousel:visiblity', {
            visible: route() === '/'
          });

          Event.fire('retailVideo:visiblity', {
            visible: route() === '/'
          });

          if (this.model().fields.isPending()) {
            this.isLoading(false);
            m.redraw();
            return;
          }

          // Only fetch additional information when we have a logged in user
          var requests = [getPlaycard(), getVisualPlayCard(), getPlayerLimits()];
          if (settings.enableMobilePay) {
            requests.push(getMobileLinkQRCode());
          }

          if (settings.links && settings.links.length) {
            this.subMenuLink(settings.links.map(function (item) {
              return {
                label: item.Link.Text,
                target: item.Link.Target,
                url: item.Link.Url
              };
            }));
          }

          m.sync(requests).then(function () {
            this.isLoading(false);
            m.redraw();
          }.bind(this));
        }.bind(this), function (error) {
          this.showErrorModal(error);
        }.bind(this));


        window.addEventListener('popstate', function () {
          Event.fire('retailCarousel:visiblity', {
            visible: route() === '/'
          });
          Event.fire('retailVideo:visiblity', {
            visible: route() === '/'
          });
        }.bind(this), false);
      },
      view: function (controller) {
        if (controller.isLoading()) {
          return Loader();
        }

        var routeClass = route().replace('/', '');

        return m('div', {
          class: [
            'retail-page retail-playcard',
            'retail-playcard--' + (routeClass ? routeClass : 'home')
          ].join(' '),
          'data-uitest-id': Utils.replaceSlashWithHyphens(route(), 'retail-page-playcard')
        }, routeConfiguration.reduce(function (template, step) {
          if (route() === step.route) {
            template = step.template(controller);
          }

          return template;
        }, null));
      }
    };


    // Routes
    route('/', root);

    for (var index in routeConfiguration) {
      var step = routeConfiguration[index];

      route(step.route, root);
    }
  });

});
