defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/RestrictAccount', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Framework/PlayerAccountManagement/Scripts/Templates/Calendar',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InfoRow',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorMessage',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/RadioButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Link'
], function (m, Calendar, BackButton, InfoRow, ErrorMessage, StepHeader, RadioButton, Button, Link) {

  var StepRestrictAccount = function (controller) {
    var userModel = controller.model().fields;
    var dictionaryKey = '';

    if (!userModel.isRetailAccount()) {
      dictionaryKey = 'OnlineAccount/';
    }

    var selectType = function (type) {
      userModel.restrict24hours(type === '24hour');
      userModel.restrictCustomDate(type === 'customDate');
      userModel.restrictUnlimited(type === 'unlimited');
      userModel.restrictType(type);
      controller.noPeriodSelected(false);
    };

    return m('div', [
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton({ onclick: controller.backLinkCallback })) : null,
      m('.retail-page__content',
        m('.retail-page__row has-rows-2', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, StepHeader({
            title: controller.d('RestrictAccount/PageTitle'),
            abstract: controller.d('RestrictAccount/' + dictionaryKey + 'PageDescription')
          })
          ),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, [
            m('.retail-radio-button__group', [
              RadioButton({
                model: userModel.restrict24hours,
                labelText: controller.d('RestrictAccount/Label24hours'),
                id: 'restrict24hours',
                name: 'restrictGroup',
                onchange: function () {
                  if (userModel.restrict24hours()) {
                    selectType('24hour');
                  }
                }
              }),
              userModel.restrict24hours() ? InfoRow({
                value: controller.d('RestrictAccount/' + dictionaryKey + 'Restrict24HoursDescription')
              }) : null,
              RadioButton({
                model: userModel.restrictCustomDate,
                labelText: controller.d('RestrictAccount/LabelChooseDate'),
                labelClass: 'js-restrict-account-page-date',
                id: 'restrictCustomDate',
                name: 'restrictGroup',
                onchange: function () {
                  if (userModel.restrictCustomDate()) {
                    selectType('customDate');
                  }
                }
              }),
              userModel.restrictCustomDate() ? [
                InfoRow({
                  value: controller.d('RestrictAccount/' + dictionaryKey + 'RestrictCustomDateDescription')
                }),
                Calendar(userModel.restrictDate, controller.calendarStartDate(), controller.calendarEndDate(), '.js-restrict-account-page-date'),
              ]
                : null,
              RadioButton({
                model: userModel.restrictUnlimited,
                labelText: controller.d('RestrictAccount/LabelUnlimited'),
                id: 'restrictUnlimited',
                name: 'restrictGroup',
                onchange: function () {
                  if (userModel.restrictUnlimited()) {
                    selectType('unlimited');
                  }
                }
              }),
              userModel.restrictUnlimited() ? InfoRow({
                value: controller.d('RestrictAccount/' + dictionaryKey + 'RestrictUnlimitedDescription')
              }) : null,
            ]),
            controller.noPeriodSelected() ? ErrorMessage([controller.d('Errors/NoPeriodSelected')]) : null,
            m('div.retail-button-container.retail-button-container--centered',
              Button({
                buttonText: controller.d('RestrictAccount/SubmitButton'),
                onclick: function () {
                  controller.restrictAccountSubmit();
                }.bind(this)
              })
            ),
            m('.info-message', controller.d('CloseAccount/RofusText')),
            controller.rofusLink('InfoLink') ?
              m('div',
                Link({
                  class: 'retail-link--full-width',
                  href: controller.rofusLink('InfoLink').Url,
                  target: controller.rofusLink('InfoLink').Target,
                  text: controller.rofusLink('InfoLink').Text
                })
              )
              : null,
            controller.rofusLink('TreatmentPlacesLink') ?
              m('div',
                Link({
                  class: 'retail-link--full-width',
                  href: controller.rofusLink('TreatmentPlacesLink').Url,
                  target: controller.rofusLink('TreatmentPlacesLink').Target,
                  text: controller.rofusLink('TreatmentPlacesLink').Text
                })
              )
              : null,
          ])
        ])
      )
    ]);
  };

  return StepRestrictAccount;

});
