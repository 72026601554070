defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/LimitPeriodValue', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ProgressBar',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InputField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, Utils, ProgressBar, BackButton, InputField, Button, StepHeader) {

  const getVariant = (isRetailAccount, initiator) => {
    if (initiator) {
      return '';
    }

    if (!isRetailAccount) {
      return '/Variant2';
    } else {
      return '/Variant1';
    }
  };

  const LimitPeriodValue = function (controller, initiator) {
    var userModel = controller.model().fields;
    var errors = controller.model().errors;
    var dictionaryKey = controller.currentStep().dictionaryKey;
    var limitPeriodType;

    const variant = getVariant(userModel.isRetailAccount(), initiator);

    const forceRestartFlowIfNoType = () => {
      userModel.offlineWagerLimitPeriodDaily(null);
      userModel.offlineWagerLimitPeriodWeekly(null);
      userModel.offlineWagerLimitPeriodMonthly(null);

      if (typeof controller.goToRoute === 'function') {
        controller.goToRoute('/');
      }
    };

    switch (userModel.offlineWagerLimitPeriod()) {
    case 'daily':
      limitPeriodType = controller.d(`${dictionaryKey}/Daily`);
      break;
    case 'weekly':
      limitPeriodType = controller.d(`${dictionaryKey}/Weekly`);
      break;
    case 'monthly':
      limitPeriodType = controller.d(`${dictionaryKey}/Monthly`);
      break;
    default:
      forceRestartFlowIfNoType();
      break;
    }

    return [
      controller.progress ?
        ProgressBar({
          progress: controller.progress()
        }) : null,
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton({
          onclick: controller.routeToPrevStep
        })) : null,
      m('.retail-page__content', {
        class: controller.hideBackButton() ? 'retail-page__no-progress' : ''
      },
      m('.retail-page__row has-rows-2', [
        m('div', {
          class: 'retail-page__column retail-page__column--1'
        }, StepHeader({
          title: controller.d(`${dictionaryKey + variant}/PageTitle`, { TYPE: limitPeriodType })
        })
        ),
        m('div', {
          class: 'retail-page__column retail-page__column--2'
        }, [
          InputField({
            model: userModel.offlineWagerLimit,
            hasErrors: errors.offlineWagerLimit(),
            name: 'offlineWagerLimit',
            type: 'tel',
            labelText: controller.d(`${dictionaryKey}/InputLabel`, { TYPE: Utils.capitalize(limitPeriodType) }),
            onkeyup: (ev) => {
              if (isNaN(ev.target.value)) {
                userModel.offlineWagerLimit(null);
                return;
              }

              controller.validateField(ev);
            },
            onblur: (ev) => {
              if (Number(ev.target.value) <= 0) {
                errors.offlineWagerLimit(controller.d('Errors/ValueBelowMinimumAllowed', { AMOUNT: ev.target.value }));
              }
            }
          }),
          m('div.retail-button-container.retail-button-container--centered',
            Button({
              isDisabled: controller.buttonIsDisabled(),
              isLoading: controller.isSubmitting(),
              buttonText: controller.d(`${dictionaryKey}/SubmitButton`),
              onclick: controller.submit
            })
          )
        ])
      ])
      )
    ];
  };

  return LimitPeriodValue;

});