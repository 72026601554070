defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/ProgressBar', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril'
], function (m) {

  var ProgressBar = function (config) {
    var options = {
      progress: 0,
      ...config
    };

    return m('.retail-progress-bar',
      m('.retail-progress-bar__progress', {
        style: { width: options.progress + '%' }
      })
    );
  };

  return ProgressBar;

});
