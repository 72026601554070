defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Components/RetailFaroeseSignup', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'DanskeSpil/Framework/PlayerAccountManagement/Scripts/Helpers/Recaptcha',
  'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay', // TODO: Move out from NG, or use more generic one?
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Dictionary',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Api',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/WaitForAuthSynced',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/UserModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/PurposeModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/FileUploadModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Loader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorOverlay',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/FaroeseAccountInfo',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/PNumber',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Name',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Address',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Phone',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/LimitPeriodSelection',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/LimitPeriodValue',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/LimitPeriodConfirm',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/PINCode',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/GameScanner',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/UploadDocuments',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Receipt'
],
function (Component, RecaptchaHelper, FrameworkOverlay, Dictionary, Utils, RetailApi, WaitForAuthSynced, UserModel, PurposeModel, FileUploadModel, Loader, ErrorOverlay, FaroeseAccountInfo, PNumber, Name, Address, Phone, LimitPeriodSelection, LimitPeriodValue, LimitPeriodConfirm, PINCode, GameScanner, UploadDocuments, Receipt) {

  // Component:
  Component('retail-faroese-signup', [Dictionary, WaitForAuthSynced], function (m, route, settings) {
    // Declare steps in signup flow
    var routeConfiguration = [
      { order: 1, route: '/', fields: ['email', 'username', 'password', 'repeatPassword'], template: FaroeseAccountInfo, regularFlow: true },
      { order: 2, route: '/stepPNumber', fields: ['cprNumber', 'acceptTermsAndConditions'], template: PNumber, regularFlow: true },
      { order: 3, route: '/stepName', fields: ['firstName', 'lastName'], template: Name, regularFlow: true },
      { order: 4, route: '/stepAddress', fields: ['street', 'houseNumber', 'zipCode', 'city', 'country'], template: Address, regularFlow: true },
      { order: 5, route: '/stepPhone', fields: ['phone'], template: Phone, regularFlow: true },
      { order: 6, route: '/stepGameScanner', fields: ['acceptDataProcessing'], template: GameScanner, regularFlow: true },
      { order: 7, route: '/pincode', fields: ['pin1', 'pin2', 'pin3', 'pin4', 'pincode'], dictionaryKey: 'SetPINCode', template: PINCode, regularFlow: true },
      { order: 8, route: '/repeat-pincode', fields: ['pincode', 'pin1Confirm', 'pin2Confirm', 'pin3Confirm', 'pin4Confirm', 'pincodeConfirm'], dictionaryKey: 'ConfirmPINCode', template: PINCode, regularFlow: true },
      { order: 9, route: '/limit/period', fields: ['offlineWagerLimitPeriod'], dictionaryKey: 'LimitPeriodSelection', template: LimitPeriodSelection, regularFlow: true },
      { order: 10, route: '/limit/value', fields: ['offlineWagerLimit'], dictionaryKey: 'LimitPeriodValue', template: LimitPeriodValue, regularFlow: true },
      { order: 11, route: '/limit/confirm', fields: ['offlineWagerLimitPeriod', 'offlineWagerLimit'], dictionaryKey: 'LimitPeriodConfirm', template: LimitPeriodConfirm, regularFlow: true },
      { order: 12, route: '/stepUploadDocuments', fields: ['isActive', 'hasValidBankAccount'], template: UploadDocuments },
      { order: 13, route: '/stepReceipt', template: Receipt }
    ];

    // Get the current step from route on page load
    var currentStep = routeConfiguration.filter(function (step) {
      var locationHash = window.location.hash.toLowerCase();
      var hash = locationHash.replace('#retail-faroese-signup=', '');
      return hash === step.route.toLowerCase();
    });

    // It empty get the current step from the routeConfiguration array (setting [0] in the controller)
    currentStep = currentStep.length ? currentStep[0] : routeConfiguration[0];

    var preSelectedPurposes = Utils.getQueryParam('purpose') ? Utils.getQueryParam('purpose').split(',') : [];

    var root = {
      controller: function () {
        this.isLoading = m.prop(false);
        this.isSubmitting = m.prop(false);
        this.isRetailIpad = m.prop(Utils.isRetailIpad());
        this.buttonIsDisabled = m.prop(true);
        this.modalError = m.prop(null);
        this.routeConfiguration = routeConfiguration;
        this.currentStep = m.prop(currentStep);
        this.totalSteps = m.prop(routeConfiguration.length);
        this.progress = m.prop(0);
        this.model = m.prop(new UserModel());
        this.d = Dictionary.get.bind({ prefix: 'RetailFaroeseSignup/' });
        this.purposes = m.prop([]);
        this.uploadSettings = settings.uploadSettings;
        this.recaptchaEnabled = m.prop(settings.recaptchaEnabled || false);
        this.hasLoadedDocuments = m.prop(false);
        this.hasPreloadFiles = m.prop(false);
        this.noFilesSelected = m.prop(false);
        this.forceRegularSteps = m.prop(true);
        this.receiptContent = m.prop({
          title: this.d('Receipt/Success/PageTitle'),
          abstract: this.d('Receipt/Success/PageDescription'),
          icon: 'circle_checkmark',
          callback: function () {
            var returnUrl = Utils.getReturnUrl();
            if (returnUrl) {
              window.location.href = returnUrl;
            } else {
              window.location.href = settings.receiptLink.Url;
            }
          },
          timeout: 8000,
          buttonText: settings.receiptLink.Text
        });
        this.showAddress = m.prop(true);
        this.backAllowed = m.prop(false);
        this.hideBackButton = m.prop(false);
        this.noPaddingTop = m.prop(false);
        this.maskPincodeInput = m.prop(false);
        this.showMarketingTermsInEmail = m.prop(true);
        this.fieldInFocus = m.prop();
        this.elementIsInViewport = m.prop(false);
        this.recaptcha = m.prop({
          model: m.prop(null),
          hasErrors: m.prop(null)
        });
        this.globalWagerLimits = m.prop(settings.globalWagerLimits);

        // Collections to hold suggested addresses, zipcodes and cities
        this.zips = m.prop([]);
        this.cities = m.prop([]);
        this.addresses = m.prop([]);

        var getCountries = function () {
          return RetailApi.getCountries().then(function (response) {
            this.model().availableCountries(response.data);
            this.model().setDefaultCountry('FO');
          }.bind(this));
        }.bind(this);

        var getPurposes = function () {
          return RetailApi.getUploadFilePurposes('RetailSignup').then(function (response) {
            this.purposes(response.data.map(function (purpose) {
              var purposeModel = new PurposeModel(purpose, true);
              var foundMatch = preSelectedPurposes.some(function (preSelected) {
                return preSelected.toLowerCase() === (purposeModel.identifier().toLowerCase());
              });
              if (foundMatch) {
                this.model().files().push(new FileUploadModel(purposeModel, this.uploadSettings, this.d, false));
              }
              return purposeModel;
            }.bind(this)));
            m.redraw();
          }.bind(this));
        }.bind(this);

        this.hasFilesUploaded = function () {
          return this.model().files().filter(function (file) {
            return file.file() !== null;
          }).length > 0;
        }.bind(this);

        this.addFileRequirement = function (identifiers) {
          if (this.hasPreloadFiles() || this.purposes().length === 0) {
            return;
          }
          identifiers = Array.isArray(identifiers) ? identifiers : (typeof identifiers === 'string' ? [identifiers] : []);

          identifiers.forEach(function (identifier) {
            this.purposes().forEach(function (purpose) {
              if (purpose.identifier() === identifier) {
                this.model().files().push(new FileUploadModel(purpose, this.uploadSettings, this.d, false));
              }
            }.bind(this));
          }.bind(this));

          this.hasPreloadFiles(true);
        }.bind(this);

        this.mergeAndValidateField = (ev, validateHandler) => {
          if (this.currentStep().route === '/pincode') {
            this.mergeFieldsToSingleModelField('pincode', ['pin1', 'pin2', 'pin3', 'pin4']);
          }

          if (this.currentStep().route === '/repeat-pincode') {
            this.mergeFieldsToSingleModelField('pincodeConfirm', ['pin1Confirm', 'pin2Confirm', 'pin3Confirm', 'pin4Confirm']);
          }

          this.validateField(ev, validateHandler);
        };

        this.mergeFieldsToSingleModelField = (newFieldName, fieldNames = []) => {
          const fields = fieldNames.map((fieldName) => {
            return this.model().fields[fieldName]();
          });

          this.model().fields[newFieldName](fields.join(''));
        };

        this.checkElementInViewport = function (elem, isInitialized) {
          if (!isInitialized) {
            var detectElementInViewport = function () {
              this.elementIsInViewport(Utils.elementInViewport(elem));
            }.bind(this);

            window.addEventListener('scroll', detectElementInViewport);

            detectElementInViewport();
          }
        }.bind(this);

        this.updateProgress = function (locationHash) {
          var hash = locationHash.replace('#retail-faroese-signup=', '');
          var step;

          if (hash !== '') {
            step = this.routeConfiguration.filter(function (step) {
              return step.route.toLowerCase() === hash.toLowerCase();
            })[0];
          } else {
            step = this.routeConfiguration[0];
          }

          this.currentStep(step);

          if (!step.order) return;

          var newProgress = step.order / this.totalSteps() * 100;
          this.progress(newProgress);
        }.bind(this);

        this.setFieldFocus = function (e) {
          if (typeof this.fieldInFocus !== 'function') {
            return;
          }

          // If function is called with event from onfocus handler, set field to have focus.
          // If called without event, then set first input field to have focus
          var fieldName = e && e.target ? e.target.name : null;

          if (fieldName) {
            this.fieldInFocus(fieldName);
          }
        }.bind(this);

        this.validateField = function (ev, validateHandler) {
          if (ev.key === 'Tab' && !ev.target.value.length) return;

          var fieldName = ev.target.name;
          this.model().validate([fieldName]);

          var hasErrors = this.model().validate(this.currentStep().fields, false);

          if (hasErrors) {
            Utils.trackUserInputErrors('signup_error_fo', this.model().errors);
          }

          if (fieldName === this.fieldInFocus()) {
            this.fieldInFocus(null);
          }
          this.buttonIsDisabled(hasErrors); // Disable button if fields has errors

          if (!hasErrors && typeof validateHandler === 'function') {
            validateHandler();
          }
        }.bind(this);

        this.resetErrors = function (ev) {
          var fieldName = ev.target.name;

          if (typeof this.model().errors[fieldName] === 'function') {
            this.model().errors[fieldName](null);
          }
        }.bind(this);

        this.validateRecaptcha = function () {
          if (!window.grecaptcha.getResponse().length) {
            Utils.ensightenEvent('signup_error_fo', 'user_did_not_use_recaptcha');
            this.recaptcha().hasErrors('Not clicked');
          } else {
            this.recaptcha().hasErrors(null);
          }
          this.buttonIsDisabled(this.recaptcha().hasErrors());
          m.redraw();
          return this.recaptcha().hasErrors();
        }.bind(this);

        this.validatePassword = function (event) {
          var fieldName = event && event.target ? event.target.name : '';

          this.model().validate(['password', 'repeatPassword']);
          var hasErrors = this.model().validate(['password', 'repeatPassword'], false);

          if (!hasErrors) {
            if (this.model().fields.password() !== this.model().fields.repeatPassword()) {
              this.model().errors.repeatPassword(this.d('FaroeseAccountInfo/Password/ErrorPasswordMismatch'));
              Utils.ensightenEvent('signup_error_fo', 'passwords_do_not_match');
              hasErrors = true;
            }
          }

          if (fieldName === this.fieldInFocus()) {
            this.fieldInFocus(null);
          }
          return hasErrors;
        }.bind(this);

        this.resetRecaptcha = RecaptchaHelper.reCaptchaReady;

        this.addFile = function (file, index) {
          this.model().files()[index].file(file);
        }.bind(this);

        this.removeSelection = function (selection) {
          var removeIndex = this.model().files().indexOf(selection);
          this.model().files().splice(removeIndex, 1);
        }.bind(this);

        this.validateUpload = function () {
          this.model().files().forEach(function (file) {
            var hasErrors = file.file() && file.validate();
            this.buttonIsDisabled(hasErrors);
          }.bind(this));
        }.bind(this);

        this.storeSavedData = function () {
          var savedData = JSON.parse(JSON.stringify(this.model().fields));
          Utils.clearIndividualFields(savedData, ['cprNumber', 'acceptTermsAndConditions', 'password', 'repeatPassword', 'pincode', 'pincodeConfirm', 'pin1', 'pin2', 'pin3', 'pin4', 'pin1Confirm', 'pin2Confirm', 'pin3Confirm', 'pin4Confirm']); // Lets clear sensitive data.
          Utils.storeValueInSession('savedData', savedData);
        }.bind(this);

        this.isValidWithinGlobalLimits = () => {
          const type = Utils.capitalize(this.model().fields.offlineWagerLimitPeriod());
          const value = this.model().fields.offlineWagerLimit();

          if (!this.globalWagerLimits()) {
            return true;
          }

          const globalLimit = this.globalWagerLimits()[`Global${type}WagerLimit`];

          if (Number(value) <= globalLimit) {
            return true;
          }

          this.model().errors.offlineWagerLimit(this.d(`Errors/ValueAboveMaxLimit${type}`, { VALUE: Utils.formatCurrency(value), LIMIT: Utils.formatCurrency(globalLimit) }));
          return false;
        };

        this.submit = function () {
          var fields = this.currentStep().fields;

          if (this.model().validate(fields)) return;

          if (this.currentStep().route === '/limit/value') {
            if (!this.isValidWithinGlobalLimits()) {
              return;
            }
          }

          if (this.currentStep().route === '/repeat-pincode') {
            if (this.model().fields.pincode() !== this.model().fields.pincodeConfirm()) {
              this.model().errors.pincode('Pinkoden matcher ikke');

              ['pincode', 'pincodeConfirm', 'pin1', 'pin2', 'pin3', 'pin4', 'pin1Confirm', 'pin2Confirm', 'pin3Confirm', 'pin4Confirm'].forEach((fieldKey) => {
                this.model().fields[fieldKey](null);
              });

              return this.routeToNextError();
            }
          }

          if (this.currentStep().route === '/limit/confirm') {
            fields = ['email', 'username', 'password', 'repeatPassword', 'marketingTerms', 'cprNumber', 'acceptTermsAndConditions', 'firstName', 'lastName',
              'street', 'houseNumber', 'zipCode', 'city', 'country', 'phone', 'acceptDataProcessing', 'offlineWagerLimitPeriod', 'offlineWagerLimit', 'pincode', 'pincodeConfirm'
            ];
            var data = Utils.mapModelToApiData(fields, this.model().fields);
            this.isLoading(true);
            this.buttonIsDisabled(true);

            RetailApi.createProfile(data).then(function (response) {
              var hasErrors = this.model().setErrors(response.data);
              if (hasErrors) {
                Utils.trackUserInputErrors('signup_error_fo', this.model().errors);

                ['pincode', 'pincodeConfirm', 'pin1', 'pin2', 'pin3', 'pin4', 'pin1Confirm', 'pin2Confirm', 'pin3Confirm', 'pin4Confirm'].forEach((fieldKey) => {
                  this.model().fields[fieldKey](null);
                });

                return this.routeToNextError();
              }

              Utils.clearValueInSession('savedData'); // Lets clean up all storage data no longer needed.
              this.model().update(response.data.profile); // Make sure our frontend UserModel is in sync with backend
              this.model().fields.hasValidBankAccount(false);
              this.model().fields.isFromFaroe(true);
              Utils.pushVirtualPage('/stepUploadDocuments', (window.location.pathname.replace(/-/g, '_') + '/stepUploadDocuments'), '/stepUploadDocuments');
              this.routeToNextStep('/stepUploadDocuments');
              this.buttonIsDisabled(false);
            }.bind(this), function (error) {
              this.showErrorModal(error);
            }.bind(this)).then(function () {
              this.isLoading(false);
              this.buttonIsDisabled(true);
              m.redraw();
            }.bind(this));
          } else {
            this.storeSavedData();
            this.routeToNextStep();
          }
        }.bind(this);

        this.verify = function () {
          if (this.recaptchaEnabled() && this.validateRecaptcha()) return;

          var fields = this.currentStep().fields;
          if (this.model().validate(fields) || this.validatePassword()) return;
          var data = Utils.mapModelAndRecaptchaToApiData(fields, this.model().fields, this.recaptcha().model());
          this.isLoading(true);
          RetailApi.validateUsernameAndEmail(data).then(function (ignoreResponse) {
            this.storeSavedData();
            this.routeToNextStep();
          }.bind(this), function (error) {
            var errors = error.response.errors;
            if (error.headers.status === 422) {
              Utils.mapValidationErrors(errors, this.model().errors);
            } else {
              this.showErrorModal(Utils.unwrapError(errors, error.headers));
            }
          }.bind(this)).then(function () {
            this.isLoading(false);
            this.buttonIsDisabled(true);
            m.redraw();
            this.resetRecaptcha();
          }.bind(this));
        }.bind(this);

        this.routeToNextError = function () {
          let canRouteToError = false;
          for (var index in this.routeConfiguration) {
            var step = this.routeConfiguration[index];
            var requiredFields = step.fields || [];

            var errorFields = requiredFields.filter(function (field) {
              return this.model().errors[field]();
            }.bind(this));

            if (errorFields.length) {
              canRouteToError = true;
              Utils.pushVirtualPage(step.route, (window.location.pathname.replace(/-/g, '_') + step.route), step.route);
              route(step.route)();
              break;
            }
          }

          if (!canRouteToError) {
            this.receiptContent({
              title: this.d('Receipt/CreationFailed/PageTitle'),
              abstract: this.d('Receipt/CreationFailed/PageDescription'),
              icon: 'exclemation-mark',
              callback: function () {
                if (Utils.getQueryParam('returnUrl')) {
                  window.location.href = Utils.getQueryParam('returnUrl');
                } else {
                  window.location.href = settings.receiptLink.Url;
                }
              },
              buttonText: this.d('Receipt/CreationFailed/SubmitButton')
            });
            Utils.pushVirtualPage('/stepReceipt', (window.location.pathname.replace(/-/g, '_') + '/stepReceipt'), '/stepReceipt');
            route('/stepReceipt')();
          }
        }.bind(this);

        this.routeToNextStep = function (routePath) {
          if (routePath) {
            Utils.pushVirtualPage(routePath, (window.location.pathname.replace(/-/g, '_') + routePath), routePath);
            route(routePath)();
            window.scrollTo(0, 0);
            return;
          }

          var currentStep = this.currentStep();
          var setRoute = this.routeConfiguration[currentStep.order];

          if (this.forceRegularSteps() && setRoute.regularFlow) {
            Utils.pushVirtualPage(setRoute.route, (window.location.pathname.replace(/-/g, '_') + setRoute.route), setRoute.route);
            route(setRoute.route)();
            return;
          }

          for (var index in this.routeConfiguration) {
            var step = this.routeConfiguration[index];
            var requiredFields = step.fields;

            // If route does not have missing fields and is part of the flow (defined by order), then load this
            if (!requiredFields && step.order) {
              Utils.pushVirtualPage(step.route, (window.location.pathname.replace(/-/g, '_') + step.route), step.route);
              route(step.route)();
              window.scrollTo(0, 0);
              break;
            }

            var missingFields = requiredFields.filter(function (field) {
              return !this.model().fields[field]();
            }.bind(this));

            if (missingFields.length) {
              Utils.pushVirtualPage(step.route, (window.location.pathname.replace(/-/g, '_') + step.route), step.route);
              route(step.route)();
              this.buttonIsDisabled(true);
              window.scrollTo(0, 0);
              break;
            }
          }
        }.bind(this);

        this.upload = function () {
          var hasErrors = this.model().files().map(function (file) {
            return file.validate();
          }).some(function (error) {
            return error;
          });

          if (this.model().files().length === 0) {
            this.noFilesSelected(true);
            hasErrors = true;
          }

          if (hasErrors) {
            return;
          }

          var data = Utils.mapFilesToApiData(this.model);
          this.isLoading(true);

          RetailApi.uploadDocuments(data).then().then(function (response) {
            var data = response.data;
            if (!data.OperationSuccess) {
              this.showErrorModal(data);
              return;
            }

            this.model().files([]); // Lets reset all uploaded files.

            Utils.pushVirtualPage('/stepReceipt', (window.location.pathname.replace(/-/g, '_') + '/stepReceipt'), '/stepReceipt');
            route('/stepReceipt')();
          }.bind(this), function (error) {
            this.showErrorModal(error);
          }.bind(this)).then(function () {
            this.isLoading(false);
            this.buttonIsDisabled(true);
          }.bind(this));

        }.bind(this);

        this.showErrorModal = (error) => {
          this.modalError(new FrameworkOverlay(ErrorOverlay({
            title: this.d('ErrorModal/Title'),
            body: error.message,
            dismissButton: this.d('ErrorModal/DismissButton'),
            dismisable: true,
          }))).show();
        };

        /*
         Placeholder functions selectAddressFromSelection, lookupZip, lookupCity, lookupAddress to maintain code functionality of reused page Address.js
         */
        this.selectAddressFromSelection = function () {
          return null;
        }.bind(this);

        this.lookupZip = function () {
          this.zips([]);
        }.bind(this);

        this.lookupCity = function () {
          this.cities([]);
        }.bind(this);

        this.lookupAddress = function () {
          this.addresses([]);
        }.bind(this);

        this.loadDocumentData = function () {
          this.hasLoadedDocuments(true);
          m.sync([getPurposes()]).then(function () {
            m.redraw();
          }.bind(this));
        }.bind(this);

        m.sync([getCountries()]).then(function () {
          this.isLoading(false);
          m.redraw();
        }.bind(this));

        this.updateProgress(window.location.hash);

        window.addEventListener('popstate', function (e) {
          this.updateProgress(e.currentTarget.location.hash);
          if (this.currentStep().order === 1 && this.recaptchaEnabled()) {
            // Returned to initial page with recaptcha enabled, lets reset page content.
            location.reload();
          }
        }.bind(this), false);

        if (this.isRetailIpad()) {
          this.receiptContent({
            title: this.d('Receipt/NotAllowedRetailIpad/PageTitle'),
            abstract: this.d('Receipt/NotAllowedRetailIpad/PageDescription'),
            icon: 'exclemation-mark'
          });
          Utils.pushVirtualPage('/receipt_retail_ipad_not_allowed_faroese', (window.location.pathname.replace(/-/g, '_') + '/receipt_retail_ipad_not_allowed_faroese'), '/receipt_retail_ipad_not_allowed_faroese');
          route('/stepReceipt')();
        } else {
          if (!this.model().fields.cprNumber() && this.currentStep().route !== '/') {
            // Since this flow is not session driven, we force a reset the flow, if we receive a page load not invoked from empty route "/"
            Utils.pushVirtualPage('/', (window.location.pathname.replace(/-/g, '_') + '/'), '/');
            route('/')();
          }
        }

        if (this.recaptchaEnabled()) {
          window.reCaptchaReady = RecaptchaHelper.reCaptchaReady;

          window.reCaptchaCallback = function () {
            var recaptcha = window.grecaptcha.getResponse();
            if (recaptcha.length > 0) {
              this.recaptcha().model(recaptcha);
            }
            this.validateRecaptcha().then(m.redraw, m.redraw);
          }.bind(this);
        }

        var savedData = Utils.getValueInSession('savedData');
        if (savedData) {
          this.model().update(savedData);
        }

        this.init = () => {
          document.body.setAttribute('data-override-logout-return-url', settings.receiptLink?.Url || window.location.pathname.split('/')[0]);

          Utils.setupLoginLinkEventListeners();

          if (window.dsApplicationConfig && ['SHAPE_RETAILACCOUNT_IPADNATIVE'].includes(window.dsApplicationConfig?.ApplicationId)) {
            this.maskPincodeInput(true);
          }
        };

        this.init();
      },
      view: function (controller) {
        var currentStep = controller.currentStep().route.replace('/', '').toLowerCase();
        if (controller.isLoading()) {
          return Loader();
        }

        return m('div', {
          class: [
            'retail-page',
            'retail-signup',
            currentStep ? 'retail-signup--' + currentStep : ''
          ].join(' '),
          'data-uitest-id': Utils.replaceSlashWithHyphens(route(), 'retail-page-faeroe-signup')
        }, routeConfiguration.reduce(function (template, step) {
          if (route() === step.route) {
            template = step.template(controller, 'retail-faroese-signup');
          }

          return template;
        }, null));
      }
    };


    // Routes
    route('/', root);

    for (var index in routeConfiguration) {
      var step = routeConfiguration[index];

      route(step.route, root);
    }
  });

});
