defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/UploadDocuments', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorMessage',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InfoRow',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/FileDropdown',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/FileUpload',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, BackButton, Button, ErrorMessage, InfoRow, FileDropdown, FileUpload, StepHeader) {

  var StepUploadDocuments = function (controller, ignoreInitiator, classPrefix) {
    classPrefix = classPrefix || 'retail-page';
    var fields = controller.model().fields;

    if (!controller.hasLoadedDocuments()) {
      controller.loadDocumentData();
    }
    var requirements = [];

    if (!fields.hasValidName()) {
      requirements.push('personal' + (fields.isFromFaroe() ? 'Faroe' : ''));
    }
    if (!fields.cprCheck() && fields.accountTypeChangeInProgress()) {
      requirements.push('address');
    }
    if (!fields.hasValidBankAccount() && fields.isFromFaroe()) {
      requirements.push('bankinfo');
    }
    if (fields.nemIdNotUsed()) {
      requirements.push('nemidExemption');
    }

    controller.addFileRequirement(requirements);


    return m('div', [
      controller.backAllowed() && !controller.hideBackButton() ? m('div', { class: classPrefix + '__button-container' }, BackButton()) : null,
      m('div', { class: [
        classPrefix + '__content',
        !controller.noPaddingTop() ? classPrefix + '__no-progress' : null
      ].join(' ') }, [
        controller.model().hasDocuments() ? m('span', { class: classPrefix + '__warning' }, controller.d('UploadDocuments/PendingFiles')) : null,
        StepHeader({
          title: controller.d('UploadDocuments/PageTitle'),
          abstract: controller.d('UploadDocuments/PageDescription')
        }),
        controller.model().files().map(function (fileUpload, index) {
          return FileUpload(fileUpload, index, controller.validateUpload, controller.addFile, controller.removeSelection, controller.uploadSettings, controller.d);
        }),
        FileDropdown({
          files: controller.model().files,
          purposes: controller.purposes(),
          dictionary: controller.d,
          uploadSettings: controller.uploadSettings,
          name: 'purposes'
        }),
        m('.retail-button-container', [
          controller.backAllowed() ? Button({
            buttonText: controller.d('UploadDocuments/CancelButton'),
            class: 'retail-button--secondary',
            onclick: function () {
              window.history.back();
            }
          }) : null,
          controller.hasFilesUploaded() ? Button({
            isDisabled: controller.buttonIsDisabled(),
            buttonText: controller.d('UploadDocuments/SubmitButton'),
            onclick: controller.upload
          }) : null
        ]),
        controller.noFilesSelected() && controller.model().files().length === 0 ? ErrorMessage(controller.d('Errors/NoFilesSelected'), { center: true }) : null,
        InfoRow({
          value: m.trust(controller.d('UploadDocuments/PageFooter')),
        })
      ]
      )
    ]);
  };

  return StepUploadDocuments;

});
