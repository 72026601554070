defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/EmailWinningsPermissions', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ProgressBar',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Checkbox',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
], function (m, ProgressBar, BackButton, StepHeader, Checkbox, Button) {

  var StepEmailWinningsPermissions = function (controller) {
    var userModel = controller.model().fields;
    var errors = controller.model().errors;

    return m('div', [
      controller.progress ?
        ProgressBar({
          progress: controller.progress()
        }) : null,
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton()) : null,
      m('.retail-page__content',
        m('.retail-page__row has-rows-2', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, StepHeader({
            title: controller.d('EmailWinningsPermissions/PageTitle'),
            abstract: controller.d('EmailWinningsPermissions/PageDescription')
          })
          ),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, [
            m('.retail-rich-text', [
              m('h3', { class: 'retail-rich-text__title' }, controller.d('EmailWinningsPermissions/SectionTitle')),
              Checkbox({
                model: userModel.emailWinningPermission,
                labelText: controller.d('EmailWinningsPermissions/LabelEmail'),
                name: 'emailWinningPermission',
                hasErrors: errors.emailWinningPermission(),
              }),
              Checkbox({
                model: userModel.smsWinningPermission,
                labelText: controller.d('EmailWinningsPermissions/LabelSMS'),
                name: 'smsWinningPermission',
                hasErrors: errors.smsWinningPermission(),
              })
            ]),
            m('div.retail-button-container.retail-button-container--centered',
              Button({
                isLoading: controller.isSubmitting(),
                buttonText: controller.d('EmailWinningsPermissions/SubmitButton'),
                onclick: function () {
                  controller.updatePermissions('winner');
                }
              })
            )
          ])
        ])
      )
    ]);
  };

  return StepEmailWinningsPermissions;

});
