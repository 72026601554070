defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Svg', [
  'DanskeSpil/Framework/PlayerAccountManagement/Scripts/Templates/Svg'
], function (FrameworkSvg) {
  'use strict';

  var Svg = function (icon, config) {
    if (!icon) return;

    var options = {
      href: '/Components/DanskeSpil/Domain/RetailAccount/Graphics/SpriteSheets/RetailAccountIcons.svg',
      ...config
    };

    return FrameworkSvg(icon, options);
  };

  return Svg;

});
