defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/UpgradeSummary', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InfoRow'
], function (m, Button, StepHeader, InfoRow) {

  var UpgradeSummary = function (controller) {

    var userModel = controller.model();
    var fields = userModel.fields;

    return m('.pam-page__content', [
      m('div', {
        class: 'pam-page__header'
      }, StepHeader({
        title: controller.d('Summary/PageTitle'),
        abstract: controller.d('Summary/PageDescription')
      })
      ),
      m('.pam-page__column', [
        m('h1', controller.d('Summary/SectionHeader')),
        InfoRow({
          testId: 'retail_profile--name',
          labelText: controller.d('Summary/LabelName'),
          value: fields.firstName() + ' ' + fields.lastName()
        }),
        InfoRow({
          testId: 'retail_profile--address',
          labelText: controller.d('Summary/LabelAddress'),
          value: fields.street() + ' ' + fields.houseNumber() + ', ' + fields.zipCode() + ' ' + fields.city() + ', ' + fields.country()
        }),
        InfoRow({
          testId: 'retail_profile--username',
          labelText: controller.d('Summary/LabelUsername'),
          value: fields.username(),
          subLabelText: userModel.usernameIsSet() ? controller.d('Summary/TextReusedUsername') : null
        }),
        InfoRow({
          testId: 'retail_profile--email',
          labelText: controller.d('Summary/LabelEmail'),
          value: fields.email()
        }),
        InfoRow({
          testId: 'retail_profile--phone',
          labelText: controller.d('Summary/LabelPhone'),
          value: fields.phone()
        }),
        InfoRow({
          testId: 'retail_profile--limit',
          labelText: controller.d('Summary/LabelLimit'),
          value: fields.limitFormatted() + controller.d('Summary/' + fields.limitType())
        }),

      ]),
      m('.retail-button-container', [
        Button({
          isLoading: controller.isSubmitting(),
          class: 'retail-button--secondary',
          buttonText: controller.d('Summary/CancelButton'),
          onclick: controller.discard
        }),
        Button({
          isLoading: controller.isSubmitting(),
          buttonText: controller.d('Summary/SubmitButton'),
          onclick: controller.upgrade
        })
      ])
    ]);
  };

  return UpgradeSummary;

});
