defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/FaroeseAccountInfo', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ProgressBar',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InputField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Checkbox',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Recaptcha'
], function (m, ProgressBar, BackButton, StepHeader, InputField, Checkbox, Button, Recaptcha) {

  var StepFaroeseAccountInfo = function (controller) {
    var userModel = controller.model().fields;
    var errors = controller.model().errors;

    return m('div', [
      controller.progress ?
        ProgressBar({
          progress: controller.progress()
        }) : null,
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton()) : null,
      m('.retail-page__content',
        m('.retail-page__row has-rows-2', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, StepHeader({
            title: controller.d('FaroeseAccountInfo/PageTitle'),
            abstract: controller.d('FaroeseAccountInfo/PageDescription')
          })
          ),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, [
            InputField({
              model: userModel.email,
              onfocus: controller.setFieldFocus,
              onblur: controller.validateField,
              hasErrors: errors.email(),
              hasFocus: controller.fieldInFocus(),
              name: 'email',
              type: 'email',
              labelText: controller.d('FaroeseAccountInfo/Email/LabelEmail'),
              helpText: controller.d('FaroeseAccountInfo/Email/HelpTextEmail')
            }),
            InputField({
              model: userModel.username,
              onfocus: controller.setFieldFocus,
              onblur: controller.validateField,
              hasErrors: errors.username(),
              hasFocus: controller.fieldInFocus(),
              name: 'username',
              labelText: controller.d('FaroeseAccountInfo/Username/LabelUsername'),
              helpText: controller.d('FaroeseAccountInfo/Username/HelpTextUsername')
            }),
            InputField({
              model: userModel.password,
              onfocus: controller.setFieldFocus,
              onblur: controller.validateField,
              hasErrors: errors.password(),
              hasFocus: controller.fieldInFocus(),
              type: 'password',
              name: 'password',
              labelText: controller.d('FaroeseAccountInfo/Password/LabelPassword'),
              helpText: controller.d('FaroeseAccountInfo/Password/HelpTextPassword')
            }),
            InputField({
              model: userModel.repeatPassword,
              onfocus: controller.setFieldFocus,
              onblur: controller.validatePassword,
              hasErrors: errors.repeatPassword(),
              hasFocus: controller.fieldInFocus(),
              name: 'repeatPassword',
              type: 'password',
              labelText: controller.d('FaroeseAccountInfo/Password/LabelPasswordConfirmation'),
              helpText: controller.d('FaroeseAccountInfo/Password/HelpTextPasswordConfirmation')
            }),
            Checkbox({
              model: controller.model().fields.marketingTerms,
              labelText: controller.d('FaroeseAccountInfo/NewsPermissions/LabelNews'),
              name: 'marketingTerms',
              hasErrors: controller.model().errors.marketingTerms()
            }),
            controller.recaptchaEnabled() ? Recaptcha({
              model: controller.recaptcha().model,
              hasErrors: controller.recaptcha().hasErrors(),
              validate: controller.validateRecaptcha,
              id: 'recaptchafield'
            }) : null,
            m('div.retail-button-container.retail-button-container--centered',
              Button({
                isDisabled: controller.buttonIsDisabled(),
                isLoading: controller.isSubmitting(),
                buttonText: controller.d('FaroeseAccountInfo/SubmitButton'),
                onclick: controller.verify
              })
            )
          ])
        ])
      )
    ]);
  };

  return StepFaroeseAccountInfo;

});
