defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils', [
  'Shared/Framework/Ensighten/Scripts/Ensighten',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Dictionary',
  'DanskeSpil/Framework/PlayerAccountManagement/Scripts/Helpers/Utils'
], function (Ensighten, Utils, Dictionary, PAMUtils) {

  var d = Dictionary.get.bind({ prefix: 'ErrorMessages/' });

  var mapModelToApiData = function (fields, modelFields, initialValues) {
    var data = {};

    for (var key in fields) {
      var field = fields[key];

      // If field values matches existing value, then don't send this data to API
      if (initialValues && modelFields[field]() === initialValues[field] && !['country', 'marketingTerms', 'pincode', 'pincodeConfirm'].includes(field)) {
        continue;
      }

      data[field] = {
        value: modelFields[field](),
        status: 'provided'
      };
    }

    return data;
  };

  var mapFilesToApiData = function (model) {
    var data = new FormData();
    model().files().forEach(function (file) {
      if (file.file()) {
        data.append(file.purpose().type(), file.file(), file.purpose().purpose() + '_' + file.file().name);
      }
    });
    data.append('identificationNumber', model().fields.cprNumber() || '');

    return data;
  };

  var mapLimitToApiData = function (limitType, limitValue) {
    var data = {};
    data['Max' + limitType] = limitValue;
    return data;
  };

  var mapModelAndRecaptchaToApiData = function (fields, modelFields, recaptcha, ignoreUsername) {
    var data = {};

    for (var key in fields) {
      var field = fields[key];

      data[field] = modelFields[field]();
    }

    data['Recaptcha'] = recaptcha;
    data['ValidateEmail'] = true;
    data['ValidateUsername'] = !ignoreUsername;

    return data;
  };

  var mapPasswordToApiData = function (password, repeatPassword, token) {
    var data = {};
    data['NewPassword'] = password;
    data['NewPasswordRepeat'] = repeatPassword;
    data['Token'] = token;
    return data;
  };

  var mapValidationErrors = function (errors, errorFields) {
    errors.forEach(function (error) {
      var key = error.Source.Pointer.substring(0, 1).toLocaleLowerCase() + error.Source.Pointer.substring(1);
      var match = errorFields[key];
      if (match) {
        errorFields[key](error.Detail);
      }
    });
  };


  var isSmallScreen = function () {
    return window.innerWidth < 640;
  };

  var isRetailIpad = function () {
    return document.body.classList.contains('is-retail-ipad');
  };

  var extendFields = function (fields, newFields) {
    newFields.forEach(function (field) {
      fields.push(field);
    });
  };

  var removeFields = function (fields, deleteFields) {
    return fields.filter(function (field) {
      return !deleteFields.some(function (deleteField) {
        return deleteField === field;
      });
    });
  };

  var clearIndividualFields = function (fields, fieldsToClear) {
    Object.keys(fields).forEach(function (fieldKey) {
      if (fieldsToClear.some(function (clearField) {
        return clearField === fieldKey;
      })) {
        fields[fieldKey] = null;
      }
    });
  };

  var prettifyFileName = function (file) {
    var extensionBegin = file.lastIndexOf('.');
    return file.substring(0, extensionBegin);
  };

  var prettifyFileType = function (file) {
    var extensionBegin = file.lastIndexOf('.');
    return file.substring(extensionBegin + 1, file.length);
  };

  var prettifyFileSize = function (size) {
    return (size / (1024 * 1024)).toFixed(2) + ' MB';
  };

  var fileExtension = function (file) {
    var extensionBegin = file.lastIndexOf('.');
    return file.substring(extensionBegin, file.length).toLowerCase();
  };

  var formatDate = function (date) {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }

    var month = leadingZero(date.getMonth() + 1);
    var day = leadingZero(date.getDate());
    var year = leadingZero(date.getFullYear());

    return [year, month, day].join('-');
  };

  var formatDateTime = function (date, dayOffset) {
    dayOffset = dayOffset ? dayOffset : 0;
    date.setDate(date.getDate() + dayOffset);
    var minutes = leadingZero(date.getMinutes());
    var hours = leadingZero(date.getHours());
    var day = leadingZero(date.getDate());
    var month = leadingZero(date.getMonth() + 1);

    return day + '/' + month + ' kl. ' + hours + ':' + minutes;
  };

  var isSameDay = function (firstDate, secondDate) {
    var date1 = firstDate instanceof Date ? firstDate : new Date(firstDate);
    var date2 = secondDate instanceof Date ? secondDate : new Date(secondDate);

    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  var leadingZero = function (val) {
    if (val >= 10) return val.toString();

    return '0' + val;
  };

  var capitalize = function (value) {
    if (typeof value !== 'string') return value;

    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  var formatLimit = function (value) {
    return value + ' kr.';
  };

  var formatCurrency = function (value) {
    return Intl.NumberFormat('da-DK', { style: 'currency', currency: 'DKK' }).format(value);
  };

  var convertCentsToKronerAndFormat = function (value) {
    var amount = PAMUtils.convertCentsToKroner(value);
    return formatCurrency(amount);
  };

  var formatFaroeseId = function (value) {
    if (value.length > 6) {
      return value.substring(0, 6) + '-' + value.substring(6, 9);
    } else {
      return value;
    }
  };

  var formatCprNumber = function (value) {
    if (value.length > 6) {
      return value.substring(0, 6) + '-' + value.substring(6, 10);
    } else {
      return value;
    }
  };

  var formatDateShort = function (date) {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }

    return PAMUtils.formatDate(date);
  };

  var elementInViewport = function (elem) {
    if (!elem) return;

    var top = elem.offsetTop;
    var left = elem.offsetLeft;
    var width = elem.offsetWidth;
    var height = elem.offsetHeight;

    while (elem.offsetParent) {
      elem = elem.offsetParent;
      top += elem.offsetTop;
      left += elem.offsetLeft;
    }

    return (
      top >= window.pageYOffset &&
      left >= window.pageXOffset &&
      (top + height) <= (window.pageYOffset + window.innerHeight) &&
      (left + width) <= (window.pageXOffset + window.innerWidth)
    );
  };

  var unwrapError = function (response, headers) {
    var statusCode = headers.status;

    //  Show error message to page UI
    return {
      statusCode: statusCode,
      message: getError(response)
    };
  };

  var getError = function (response) {
    if (response.errors && response.errors.length) {
      if (response.errors[0].Message) {
        return response.errors[0].Message;
      } else if (response.errors[0].Detail) {
        return response.errors[0].Detail;
      } else if (response.errors[0].Title) {
        return response.errors[0].Title;
      }
    }

    return d('GENERIC_ERROR');
  };

  var getValidationMessage = function (key) {
    var errors =  {
      required: d('REQUIRED'),
      isEmpty: d('IS_EMPTY'),
      noPurposeSelected: d('NO_PURPOSE_SELECTED'),
      invalidToken: d('INVALID_TOKEN')
    };

    if (errors.hasOwnProperty(key)) {
      return errors[key];
    }

    return '';
  };

  var getRestrictDictionaryReceiptKey = function (restrictType, isRetailAccount) {
    var onlineAccount = !isRetailAccount ? '/OnlineAccount' : '';
    if (restrictType === '24hour') {
      return 'RestrictAccount/Receipt/24hours' + onlineAccount;
    }
    if (restrictType === 'customDate') {
      return 'RestrictAccount/Receipt/CustomDate' + onlineAccount;
    }
    if (restrictType === 'unlimited') {
      return 'RestrictAccount/Receipt/Unlimited' + onlineAccount;
    }
  };


  var ensightenEvent = function (action, label, value) {
    var appSpecificContext = '_web';
    if (window.dsApplicationConfig) {
      if (window.dsApplicationConfig.ApplicationId === 'SHAPE_RETAILACCOUNT_IPADNATIVE') {
        appSpecificContext = '_oddset_retail';
      } else if (window.dsApplicationConfig.ApplicationId === 'SHAPE_RETAILACCOUNT_MOBILENATIVE') {
        appSpecificContext = '_app';
      } else if (window.dsApplicationConfig.ApplicationId === 'SHAPE_ODDSET_MOBILENATIVE') {
        appSpecificContext = '_oddset_app';
      }
    }
    Ensighten.pushGaEvent('sikkert_spil', action + appSpecificContext, label, value);
  };

  var isOddsetApp = function () {
    return Utils.getQueryParam('dsApplicationId') === 'SHAPE_ODDSET_MOBILENATIVE' || (window.dsApplicationConfig && window.dsApplicationConfig.ApplicationId === 'SHAPE_ODDSET_MOBILENATIVE') || false;
  };

  var trackUserInputErrors = function (category, errors) {
    Object.keys(errors).forEach(function (field) {
      var errorMessage = errors[field]();
      if (errorMessage) {
        ensightenEvent(category, errorMessage);
      }
    });
  };

  var trackLimitChanges = function (prefix, limits) {
    for (var key in limits) {
      var limit = limits[key];
      var action;
      var label = limit.type() + '_fra_' + limit.stored() + '_to_' + limit.value();

      if (!limit.value() && limit.stored()) {
        action = 'slet_graense';
      } else if (limit.value() && limit.stored()) {
        action = 'opdater_graense';
      } else if (limit.value() && !limit.stored()) {
        action = 'opret_graense';
      } else {
        // If value has not changed, then don't track this behavior  and skip to next limitType
        continue;
      }

      ensightenEvent(prefix + '_' + action, label);
    }
  };

  var getReturnUrl = function () {
    return Utils.getQueryParam('signupSuccessUrl') || Utils.getQueryParam('returnUrl') || Utils.getQueryParam('loginSuccessUrl') || null;
  };

  var redirectOnLogout = function (url) {
    if (!url) {
      url = window.location.origin;
    }

    window.DSAPI && DSAPI.ready(function () {
      DSAPI.Account.subscribeUserLoggedOut({
        callback: function () {
          window.location.href = url;
        }.bind(this)
      });
    }.bind(this));
  }.bind(this);

  var splitString = function (str, delimiter) {
    if (!str) return null;
    delimiter = delimiter || ' '; // Split with space

    var newStr = str.match(/.{1,4}(?=(.{4})+(?!.))|.{1,4}$/g);

    return newStr.join(delimiter);
  };

  var storeValueInSession = function (key, value) {
    if (value) {
      if (typeof value === 'object') {
        window.sessionStorage.setItem(key, JSON.stringify(value));
      }
      if (typeof value === 'string') {
        window.sessionStorage.setItem(key, value);
      }
    }
  };

  var getValueInSession = function (key) {
    var value = window.sessionStorage.getItem(key);

    return value ? JSON.parse(value) : null;
  };

  var clearValueInSession = function (key) {
    window.sessionStorage.removeItem(key);
  };

  var validateCprNumber = function (cprNumber) {
    cprNumber = cprNumber.replace(/\D/g, '');
    // pNumber validates if the first 6 digits is an actually birthday, taking leap year and other special dates into consideration. The 4 remaining characters just has to be digits.
    var pRegEx = /^(((0[1-9]|[12][0-9]|30)(0[13-9]|1[012])|31(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])02)[0-9]{2}|2902((([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))(-|\s)?\d{4}/;
    return pRegEx.test(cprNumber);
  };

  var setupLoginLinkEventListeners = function (loginSuccessUrl) {
    var loginLinks = document.querySelectorAll('[href^="/sikkertspil/login"], [href^="/sikkertspil/opret/login"]');

    // Map to a new array, because IE11 does not support forEach on NodeList
    var links = [].slice.call(loginLinks);

    links.forEach(function (link) {
      link.addEventListener('click', function (ev) {
        ev.preventDefault();
        var redirectUrl = encodeURIComponent(loginSuccessUrl || window.location.href);
        var cancelUrl = encodeURIComponent(window.location.href);
        var newUrl = PAMUtils.appendParameter('loginSuccessUrl', redirectUrl, link.href);
        newUrl = PAMUtils.appendParameter('cancelUrl', cancelUrl, newUrl);
        window.location.href = newUrl;
      }, false);
    });
  };

  var replaceSlashWithHyphens = function (str, prefix) {
    var returnVal = str.replace(/\//gi, '-');
    return prefix ? prefix + returnVal : returnVal;
  };

  return {
    mapModelToApiData: mapModelToApiData,
    mapFilesToApiData: mapFilesToApiData,
    mapLimitToApiData: mapLimitToApiData,
    mapModelAndRecaptchaToApiData: mapModelAndRecaptchaToApiData,
    mapPasswordToApiData: mapPasswordToApiData,
    mapValidationErrors: mapValidationErrors,
    isSmallScreen: isSmallScreen,
    extendFields: extendFields,
    removeFields: removeFields,
    clearIndividualFields: clearIndividualFields,
    prettifyFileName: prettifyFileName,
    prettifyFileType: prettifyFileType,
    prettifyFileSize: prettifyFileSize,
    getQueryParam: Utils.getQueryParam,
    fileExtension: fileExtension,
    isMobile: Utils.isMobile,
    isOddsetApp: isOddsetApp,
    isTabletDevice: Utils.isTabletDevice,
    formatDate: formatDate,
    formatDateTime: formatDateTime,
    isSameDay: isSameDay,
    leadingZero: leadingZero,
    capitalize: capitalize,
    formatLimit: formatLimit,
    formatFaroeseId: formatFaroeseId,
    formatCprNumber: formatCprNumber,
    formatCurrency: formatCurrency,
    convertCentsToKronerAndFormat: convertCentsToKronerAndFormat,
    convertCentsToKroner: PAMUtils.convertCentsToKroner,
    formatDateShort: formatDateShort,
    elementInViewport: elementInViewport,
    isRetailIpad: isRetailIpad,
    unwrapError: unwrapError,
    getValidationMessage: getValidationMessage,
    getRestrictDictionaryReceiptKey: getRestrictDictionaryReceiptKey,
    ensightenEvent: ensightenEvent,
    trackUserInputErrors: trackUserInputErrors,
    trackLimitChanges: trackLimitChanges,
    getReturnUrl: getReturnUrl,
    redirectOnLogout: redirectOnLogout,
    splitString: splitString,
    pushVirtualPage: Ensighten.pushVirtualPage,
    pushGaEvent: Ensighten.pushGaEvent,
    pushGaEventOnPageRedirect: Ensighten.pushGaEventOnPageRedirect,
    appendParameter: PAMUtils.appendParameter,
    storeValueInSession: storeValueInSession,
    getValueInSession: getValueInSession,
    clearValueInSession: clearValueInSession,
    validateCprNumber: validateCprNumber,
    getParameter: PAMUtils.getParameter,
    setupLoginLinkEventListeners: setupLoginLinkEventListeners,
    replaceSlashWithHyphens: replaceSlashWithHyphens
  };

});
