defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Checkbox', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorMessage'
], function (m, ErrorMessage) {

  var Checkbox = function (options) {
    return [
      m('div', {
        class: [
          'retail-checkbox-container',
          options.hasErrors ? 'has-errors' : null,
          options.isDisabled ? 'is-disabled' : null,
          options.class ? options.class : null
        ].join(' ')
      }, [
        m('input', {
          type: 'checkbox',
          class: 'retail-checkbox__input',
          name: options.name || '',
          id: options.id || options.name,
          checked: options.model(),
          disabled: options.isDisabled || false,
          onchange: function (e) {
            var checked = options.model();
            options.model(!checked);

            if (options.onchange) {
              options.onchange(e);
            }
          }
        }),
        m('label', {
          class: [
            'retail-checkbox__label',
            options.labelClass ? options.labelClass : null
          ].join(' '),
          for: options.id || options.name
        }, m.trust(options.labelText))
      ]),
      options.hasErrors ? ErrorMessage(options.hasErrors) : null
    ];
  };

  return Checkbox;

});
