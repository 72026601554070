defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorOverlay',
  [
    'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Overlay'
  ],
  function (Overlay) {

    // Context:
    var ErrorOverlay = function (config) {
      var options = {
        title: 'Der skete en fejl',
        body: '',
        className: '',
        dismisable: true,
        dismissButton: 'OK',
        confirmButtonClass: null,
        ...config
      };

      return Overlay('retail-overlay-container', options);
    };

    return ErrorOverlay;
  });
