defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Link', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril'
], function (m) {

  var Link = function (options) {
    if (options.href) {
      var anchorText = options.text;
      delete options.text; // Delete this because Mithril will set set title twice
      options.class = 'retail-link ' + options.class;
      return m('a', options, anchorText);
    }

    return m('button', {
      type: 'button',
      class: [
        'retail-link',
        options.class ? options.class : null
      ].join(' '),
      onclick: options.onclick || function (e) { e.preventDefault(); },
    }, options.text);
  };

  return Link;

});