defineDs('Shared/Framework/Mithril/Scripts/Helpers/QuickLink', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest'
], function (m, ApiRequest) {

  // Variables:
  var QuickLink = function (path) {
    var basePath = 'QuickLink';
    var quickLink = m.prop({});
    var loaded = m.prop(false);
    var ready = m.deferred();

    var get = function (key, replacements) {
      if (this && this.prefix) {
        key = this.prefix + key;
      }

      replacements = replacements || {};

      if (loaded()) {
        var base = quickLink();
        var segments = key.split('/');

        for (var i = 0; i < segments.length; i++) {
          var segment = segments[i];

          if (typeof base[segment] === 'undefined') {
            console.warn('QuickLink key not found: ' + key);

            break;
          }

          base = base[segment];
        }

        if (base instanceof Array) {
          base = base[Math.floor(Math.random() * base.length)];
        }

        // Replacements
        if (typeof base === 'string') {
          var keys = Object.keys(replacements);

          for (i = 0; i < keys.length; i++) {
            var k = keys[i];
            var val = replacements[k];

            base = base.replace(new RegExp('{' + k.toUpperCase() + '}', 'g'), val);
          }
        }

        return base || '!';
      }

      return '?';
    };

    // Initialize:
    var url = DS.Config.CONTEXTPREFIX + '/scapi/danskespil/navigation/' + basePath.toLowerCase() + '/get?path=' + path + '&variants=1';

    // ApiRequest will take care of localstorage caching and avoiding multiple request to same ressource:
    ApiRequest({
      method: 'GET',
      url: url,
      localCacheTTL: 5 * 60, // Cache 5min
      noCache: false, // Dont add ?noCache queryparam, as it would break 304 Not Modified headers
      unwrapSuccess: function (response) {
        return response.data[Object.keys(response.data)[0]];
      }
    }).then(function (response) {
      quickLink(response);
      loaded(true);
      ready.resolve();
    });

    // Public functions:
    return {
      get: get,
      ready: ready.promise
    };

  };

  return QuickLink;

});
