defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/SelectLimit', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Framework/PlayerAccountManagement/Scripts/Templates/StepGuide',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InputField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorMessage',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, StepGuide, Utils, InputField, ErrorMessage, Button, StepHeader) {

  var StepSelectLimit = function (controller) {
    var userModel = controller.model().fields;
    var errors = controller.model().errors;

    this.onLimitInput = function (event) {
      var newValue = event.target.value.replace(/\D/g, '');
      if (newValue.length > 0) {
        newValue = parseInt(newValue, 10);
        userModel.limitValue(newValue);
        userModel.limitFormatted(Utils.formatLimit(newValue));
      } else {
        userModel.limitValue(null);
        userModel.limitFormatted(null);
      }
    };

    this.onFocus = function (event) {
      controller.model().clearErrors();
      controller.setFieldFocus(event);
      var newValue = event.target.value.replace(/\D/g, '');
      if (newValue.length > 0) {
        userModel.limitFormatted(Utils.formatLimit(newValue));
      }
    };

    this.onBlur = function (event) {
      var newValue = event.target.value.replace(/\D/g, '');
      controller.fieldInFocus(null);
      if (newValue.length > 0) {
        userModel.limitFormatted(Utils.formatCurrency(newValue));
      }
      controller.validateLimit();
    };

    this.onKeyUp = function (event) {
      var value = event.target.value.replace(/\D/g, '');
      if (value.length > 1) {
        controller.validateLimit();
      }
    };

    var setType = function (type) {
      userModel.limitType(type);
      controller.model().clearErrors();
    };

    return m('div', [
      StepGuide(5, controller.totalSteps()),
      m('.pam-page__content', [
        m('div', {
          class: 'pam-page__header'
        }, StepHeader({
          title: controller.d('SelectLimit/PageTitle'),
          abstract: controller.d('SelectLimit/PageDescription')
        })
        ),
        m('.pam-button-container', [
          ['Daily', 'Weekly', 'Monthly'].map(function (limit) {
            return Button({
              buttonText: controller.d('SelectLimit/' + limit + 'Limit'),
              class: [
                'limit-button',
                userModel.limitType() === limit ? 'limit-button--selected' : ''
              ].join(' '),
              onclick: function () {
                setType(limit);
              }
            });
          })
        ]),
        errors.limitType() ? ErrorMessage('Ingen grænse type valgt') : null,
        userModel.limitType() ? InputField({
          model: userModel.limitFormatted,
          oninput: this.onLimitInput,
          onfocus: this.onFocus,
          onblur: this.onBlur,
          onkeyup: this.onKeyUp,
          hasErrors: errors.limitFormatted(),
          hasFocus: controller.fieldInFocus(),
          forceNumeric: true,
          name: 'limitFormatted',
          type: 'text',
          labelText: controller.d('SelectLimit/' + userModel.limitType() + 'Limit'),
          helpText: controller.d('SelectLimit/HelpText' + userModel.limitType() + 'Limit'),
          setcaret: userModel.limitFormatted() && controller.postfix ? userModel.limitFormatted().length - controller.postfix.length : 1, // Default to index one to almost make it pretty when amount is null.
        }) : null,
        m('div.retail-button-container.retail-button-container--centered',
          Button({
            isDisabled: controller.buttonIsDisabled(),
            isLoading: controller.isSubmitting(),
            buttonText: controller.d('SelectLimit/SubmitButton'),
            onclick: function () {
              if (!controller.validateLimit()) {
                controller.submit();
              }
            },
            uiTestId: 'retail-button-submit-button'
          })
        )
      ])
    ]);
  };

  return StepSelectLimit;

});
