defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/GameHistoryPayout', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Loader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Receipt'
], function (m, Utils, StepHeader, Button, BackButton, Loader, Receipt) {

  var GameHistoryPayout = function (controller) {
    if (controller.isLoading()) {
      return Loader();
    }

    if (controller.showReceipt()) {
      return Receipt(controller);
    }

    return m('.retail-page', [
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton()) : null,
      m('div', {
        class: [
          'retail-page__content',
          controller.game().hasPayout ? 'has-payout' : null
        ].join(' ')
      }, [
        StepHeader({
          title: controller.hasBankAccount() ? controller.d('Payout/BankAccountPageTitle') : controller.d('Payout/NemKontoPageTitle'),
        }),
        m('.retail-prize__container', [
          m('h2', {
            class: 'retail-prize__title'
          }, controller.d('Payout/PrizeTitle')),
          m('.retail-prize__amount', Utils.convertCentsToKronerAndFormat(controller.game().win)),
          m('.retail-rich-text', [
            m('div', m.trust(controller.payoutText())),
            m('.retail-button-container', [
              Button({
                buttonText: controller.d('Payout/ConfirmButton'),
                onclick: function () {
                  controller.executePayout();
                }
              }),
              Button({
                class: 'retail-button--white',
                buttonText: controller.d('Payout/CancelButton'),
                onclick: function () {
                  window.history.back();
                }
              })
            ]),
            m('.retail-payout-possibilities__customer-service-text', m.trust(controller.customerServiceText()))
          ])
        ])
      ])
    ]);
  };

  return GameHistoryPayout;

});
