defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Models/UserModel', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Mithril/Scripts/Core/Model',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/UserLimitsModel'
], function (m, Model, Utils, UserLimitModel) {
  'use strict';

  var UserModel = Model('UserModel', function (content) {
    content = content || {};
    this.citiesList = m.prop([]);
    this.availableCountries = m.prop([]);
    this.securityQuestions = m.prop([]);
    this.hasDocuments = m.prop(false);
    this.files = m.prop([]);
    this.userLimits = m.prop(new UserLimitModel());

    var defaultRestrictDate = new Date();
    defaultRestrictDate.setDate(defaultRestrictDate.getDate() + 30);

    this.fields = {
      username: m.prop(content.username ? content.username.value : null),
      email: m.prop(content.email ? content.email.value : null),
      password: m.prop(null),
      repeatPassword: m.prop(null),
      currentPassword: m.prop(null),
      cprNumber: m.prop(content.cprNumber ? content.cprNumber.value : null),
      hasValidCpr: m.prop(content.hasValidCpr ? content.hasValidCpr.value : false),
      firstName: m.prop(content.firstName ? content.firstName.value : null),
      lastName: m.prop(content.lastName ? content.lastName.value : null),
      hasValidName: m.prop(content.hasValidName ? content.hasValidName.value : false),
      street: m.prop(content.street ? content.street.value : null),
      houseNumber: m.prop(content.houseNumber ? content.houseNumber.value : null),
      level: m.prop(content.level ? content.level.value : null),
      door: m.prop(content.door ? content.door.value : null),
      zipCode: m.prop(content.zipCode ? content.zipCode.value : null),
      city: m.prop(content.city ? content.city.value : null),
      country: m.prop(content.country ? content.country.value : null),
      phone: m.prop(content.phone ? content.phone.value : null),
      amountLimit: m.prop(content.amountLimit ? content.amountLimit.value : null),
      marketingTerms: m.prop(content.marketingTerms ? content.marketingTerms.value : false),
      marketingDefaultTerms: m.prop(content.marketingDefaultTerms ? content.marketingDefaultTerms.value : false),
      pushpermissionMarketingTerms: m.prop(content.pushpermissionMarketingTerms ? content.pushpermissionMarketingTerms.value : false),
      acceptDataProcessing: m.prop(content.acceptDataProcessing ? content.acceptDataProcessing.value : false),
      acceptTermsAndConditions: m.prop(content.acceptTermsAndConditions ? content.acceptTermsAndConditions.value : false),
      bankAccount: m.prop(content.bankAccount ? content.bankAccount.value : '-'),
      emailPermission: m.prop(content.emailPermission || false),
      emailRetailPermission: m.prop(content.emailRetailPermission || false),
      emailWinningPermission: m.prop(content.emailWinningPermission || false),
      smsWinningPermission: m.prop(content.smsWinningPermission || false),
      appPermission: m.prop(content.appPermission || false),
      appPermissionQuestioned: m.prop(content.appPermissionQuestioned || false),
      cprCheck: m.prop(content.cprCheck ? content.cprCheck.value : false),
      hasValidBankAccount: m.prop(content.hasValidBankAccount ? content.hasValidBankAccount.value : false), // Explicit overridden in faroese flow.
      restrictCustomDate: m.prop(false),
      restrictDate: m.prop(defaultRestrictDate.toString()),
      restrict24hours: m.prop(false),
      restrictType: m.prop(null), // '24hour', 'customDate', 'unlimited' or 'close'
      restrictUnlimited: m.prop(false),
      nemIdSignedIn: m.prop(content.nemIdSignedIn ? content.nemIdSignedIn.value : false),
      nemIdNotUsed: m.prop(content.nemIdNotUsed),
      isPending: m.prop(content.isPending ? content.isPending.value : true),
      isActive: m.prop(content.hasOwnProperty('isPending') ? !content.isPending.value : false),
      isRetailAccount: m.prop(content.isRetailAccount ? content.isRetailAccount.value : true),
      isFromFaroe: m.prop(content.isFromFaroe ? content.isFromFaroe.value : false),
      accountTypeChangeInProgress: m.prop(content.accountTypeChangeInProgress ? content.accountTypeChangeInProgress.value : false),
      limitType: m.prop(null),
      limitValue: m.prop(null),
      limitFormatted: m.prop(null),
      securityQuestion: m.prop({ Id: '', name: null }),
      securityAnswer: m.prop(null),
      runKnowYourCustomerFlow: m.prop(false),
      pin1: m.prop(null),
      pin2: m.prop(null),
      pin3: m.prop(null),
      pin4: m.prop(null),
      pin1Confirm: m.prop(null),
      pin2Confirm: m.prop(null),
      pin3Confirm: m.prop(null),
      pin4Confirm: m.prop(null),
      pincode: m.prop(content.pincode ? content.pincode.value : null),
      pincodeConfirm: m.prop(content.pincode ? content.pincode.value : null),
      offlineWagerLimitPeriodDaily: m.prop(null),
      offlineWagerLimitPeriodWeekly: m.prop(null),
      offlineWagerLimitPeriodMonthly: m.prop(null),
      offlineWagerLimitPeriod: m.prop(content.offlineWagerLimitPeriod ? content.offlineWagerLimitPeriod.value : null),
      offlineWagerLimit: m.prop(content.offlineWagerLimit ? content.offlineWagerLimit.value / 100 : null),
      hasOldOfflineWagerLimit: m.prop(content.hasOldOfflineWagerLimit?.value),
      adviseEloquaOfAccountUpdate: m.prop(false)
    };

    // Copy the fields without reference
    this.initialValues = JSON.parse(JSON.stringify(this.fields));

    this.nameIsSet = m.prop(false);
    this.usernameIsSet = m.prop(this.fields.username());
    this.hasConsentedToGameScanner = m.prop(false);
    this.cachedUpgrade = m.prop(false);

    // Set user's country based on either value provided or fallback to DK
    this.setDefaultCountry = function (setCountry) {
      var selectedCountry = this.availableCountries().filter(function (country) {
        if (setCountry === 'FO') {
          return country.Id === 'FO';
        }

        if (!setCountry) {
          return country.Id === 'DK';
        }

        return country.Id === content.country;
      });

      if (selectedCountry.length) {
        this.fields.country(selectedCountry[0].Id);
      }
    }.bind(this);

    /*
     * Create an error key for each field value in the model (Doing this programatically do avoid double maintenance of fields in model)
    */
    this.errors = {};

    Object.keys(this.fields).forEach(function (field) {
      this.errors[field] = m.prop(null);
    }.bind(this));

    this.update = function (content) {
      Object.keys(content).forEach(function (fieldKey) {
        if (content.hasOwnProperty(fieldKey) && content[fieldKey]) {
          var val;

          if (typeof content[fieldKey] === 'string' || typeof content[fieldKey] === 'boolean') {
            val = content[fieldKey];
          } else {
            val = content[fieldKey].value;
          }

          if (this.fields[fieldKey]) {
            this.fields[fieldKey](val);
          }
        }
      }.bind(this));

      // Never display password
      this.fields.password(null);
      this.fields.repeatPassword(null);
      this.fields.currentPassword(null);

      // Reset placeholder pincode fields on update
      ['pin1', 'pin2', 'pin3', 'pin4', 'pin1Confirm', 'pin2Confirm', 'pin3Confirm', 'pin4Confirm'].forEach((fieldKey) => {
        this.fields[fieldKey](null);
      });

      // Set props based on user info
      this.fields.isActive(!this.fields.isPending());
      this.usernameIsSet(this.fields.username());
      this.hasConsentedToGameScanner(this.fields.acceptDataProcessing());

      if (!this.fields.country()) {
        this.setDefaultCountry();
      }

      this.initialValues = JSON.parse(JSON.stringify(this.fields));
    }.bind(this);

    this.setErrors = function (data, returnErrorFields) {
      if (data.success) {
        return false;
      }

      var errorKeys = [];

      for (var field in data.profile) {
        if (this.errors[field]) {
          var errorMessage = data.profile[field].error;
          this.errors[field](errorMessage);

          if (errorMessage) {
            errorKeys.push(field);
          }
        }
      }

      if (returnErrorFields) {
        return errorKeys;
      }

      return true;
    }.bind(this);

    this.clearErrors = function () {
      Object.keys(this.fields).forEach(function (field) {
        this.errors[field] = m.prop(null);
      }.bind(this));
    }.bind(this);

    // Do simple validation to check if field is not null, not false and not empty string
    this.validate = function (validateFields, setErrors) {
      if (!validateFields) return;
      if (typeof validateFields === 'string') [validateFields];
      if (typeof setErrors === 'undefined') {
        setErrors = true; // If setErrors is not set then default to true
      }

      var hasErrors = false;

      for (var index in validateFields) {
        var fieldName = validateFields[index];
        var val = this.fields[fieldName]();
        var isNotValid = null;

        if (val === false) {
          isNotValid =  Utils.getValidationMessage('required');
        } else if ((!val && val !== 0) || (typeof val === 'string' && val.trim() === '')) {
          isNotValid = Utils.getValidationMessage('isEmpty');
        }

        if (setErrors) {
          this.errors[fieldName](isNotValid);
        }

        if (!hasErrors) {
          hasErrors = isNotValid;
        }
      }

      return hasErrors;
    }.bind(this);
  });

  return UserModel;
});
