defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/ResumeSignupFlow', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader'
], function (m, Button, StepHeader) {

  var StepResumeSignupFlow = function (controller) {

    return m('', [
      m('div', {
        class: 'retail-page__content retail-page__no-progress'
      }, m('.retail-page__row has-rows-2', [
        m('div', {
          class: 'retail-page__column retail-page__column--1'
        }, StepHeader({
          title: controller.d('ResumeSignupFlow/PageTitle'),
          abstract: controller.d('ResumeSignupFlow/PageDescription')
        })),
        m('div', {
          class: 'retail-page__column retail-page__column--2'
        },
        m('.retail-button-container',
          Button({
            buttonText: controller.d('ResumeSignupFlow/SubmitButton'),
            onclick: function () {
              controller.routeToNextStep();
            }
          })
        )
        )
      ]))
    ]);
  };

  return StepResumeSignupFlow;

});
