defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Models/UserLimitsModel', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Mithril/Scripts/Core/Model',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils'
], function (m, Model, Utils) {
  'use strict';

  var UserLimitsModel = Model('UserLimitsModel', function () {
    this.limits = m.prop([]);
    this.limitRequired = m.prop(false);
    this.limitMinimum = m.prop(1);
    this.sitecoreLimits = m.prop([]);

    this.clearErrors = function () {
      this.limits().forEach(function (limit) {
        limit.hasErrors([]);
      });
    };

    this.hasLimit = function () {
      return this.limits().some((limit) => limit.value());
    };

    this.reset = function () {
      this.limits().forEach(function (limit) {
        limit.reset();
      });
    };

    this.mapToData = function () {
      var changes = this.changes().map(function (limit) {
        var newMax = 'Max' + limit.type();
        var object = {};
        var isDeleting = limit.value() === null;
        object[newMax] = isDeleting ? '-1' : +limit.value();
        return object;
      });

      return changes.length > 0 ? Object.assign.apply(Object, changes) : null;
    };

    this.changes = function () {
      return this.limits().filter(function (limit) {
        return limit.stored() !== limit.value() || limit.state() === 'revert';
      });
    };

    const limitIsSetByUser = (limit) => {
      const maxLimitSetByUserOrOperator = limit.max();
      const limitSetByUserOrOperator = limit.value();
      const operatorHaveSetALimit = limit.isOperator();
      const limitIsCurrentlyBeingDeleted = limit.state() === 'delete';

      if (!limitIsCurrentlyBeingDeleted && limitSetByUserOrOperator) {
        if (!operatorHaveSetALimit || (operatorHaveSetALimit && maxLimitSetByUserOrOperator !== limitSetByUserOrOperator)) {
          return true;
        }
      }

      return false;
    };

    this.validate = function () {
      var isValid = true;
      var sitecoreLimitHit = false;

      this.limits().forEach(function (limit, index) {
        const otherLimits = this.limits().filter((otherLimit) => otherLimit !== limit);
        const someOtherLimitsSetByUser = otherLimits.some((otherLimit) => limitIsSetByUser(otherLimit));

        // Validate limits with lesser period granularity
        this.limits().forEach(function (lesserLimit, lesserIndex) {
          if (lesserIndex < index) {
            if (lesserLimit.value() !== null && limit.value() !== null) {
              if (lesserLimit.value() >= limit.value()) {
                if (lesserLimit.plannedValue() !== null && lesserLimit.plannedValue() < limit.value()) {
                  // A smaller limits is already being processed, so we are good.
                } else {
                  if (lesserLimit.value() > limit.value() && limit.value() !== limit.stored() && limit.value() !== '-1' && lesserLimit.state() !== 'delete') {
                    limit.error('IsLessThan', {
                      TYPE: limit.prettyType(),
                      OTHERTYPE: lesserLimit.prettyType(),
                      LIMIT: limit.formatted(),
                      LESSERLIMIT: lesserLimit.formatted()
                    });
                    isValid = false;
                  }
                }
              }
              if (lesserLimit.plannedValue() && lesserLimit.plannedValue() > limit.value()) {
                limit.error('IsLessThanPlanned', {
                  TYPE: limit.prettyType(),
                  PLANNEDTYPE: lesserLimit.prettyType(),
                  LIMIT: limit.formatted(),
                  LESSERLIMIT: Utils.formatCurrency(lesserLimit.plannedValue())
                });
                isValid = false;
              }
            }
          }
        });

        // Validate limits with larger period granularity
        this.limits().forEach(function (largerLimit, largerIndex) {
          if (largerIndex > index) {
            if (largerLimit.value() !== null) {
              if (largerLimit.value() < limit.value()) {
                if (largerLimit.plannedValue() >= limit.value()) {
                  // A larger limits is already being processed, so we are good.
                } else if (largerLimit.plannedValue() !== null) {
                  if (largerLimit.plannedValue() < limit.value()) {
                    limit.error('IsLargerThanPlanned', {
                      TYPE: limit.prettyType(),
                      PLANNEDTYPE: largerLimit.prettyType(),
                      LIMIT: limit.formatted(),
                      LARGERLIMIT: Utils.formatCurrency(largerLimit.plannedValue())
                    });
                    isValid = false;
                  }
                } else if (!(limit.value() === limit.stored()) && largerLimit.state() !== 'delete') {
                  limit.error('IsLargerThan', {
                    TYPE: limit.prettyType(),
                    OTHERTYPE: largerLimit.prettyType(),
                    LIMIT: limit.formatted(),
                    LARGERLIMIT: largerLimit.formatted()
                  });
                  isValid = false;
                }
              }
            }
          }
        });
        // Validate max and sitecore max

        this.sitecoreLimits().forEach(function (sitecorelimit) {
          if (sitecorelimit.type === limit.type() && sitecorelimit.value && limit.value() > sitecorelimit.value) {
            limit.error('IsTooLarge',
              {
                TYPE: limit.prettyType(),
                LIMITFORMATTET: (sitecorelimit.value ? Utils.formatCurrency(sitecorelimit.value) : null)
              });
            isValid = false;
            sitecoreLimitHit = true;
          }
        });

        if (!sitecoreLimitHit && limit.max() && limit.value() > limit.max()) {
          limit.error('IsTooLarge',
            {
              TYPE: limit.prettyType(),
              LIMITFORMATTET: limit.maxFormatted()
            });
          isValid = false;
        }

        if (limit.stored() !== null && limit.value() === null && this.limitRequired() && !someOtherLimitsSetByUser) {
          limit.error('LimitCantBeRemoved', {
            TYPE: limit.prettyType()
          });
          limit.reset();
          isValid = false;
        }

        if (this.limitMinimum() && limit.value() !== null && limit.value() < this.limitMinimum()) {
          limit.error('LimitBelowMinimum', {
            TYPE: limit.prettyType(),
            LIMIT: limit.formatted(),
            AMOUNT: this.limitMinimum()
          });
          isValid = false;
        }

        if (limit.value() && limit.plannedValue() && limit.value() === limit.plannedValue()) {
          limit.error('IsEqualToPlanned', {
            TYPE: limit.prettyType(),
            LIMIT: limit.formatted()
          });
          isValid = false;
        }
      }.bind(this));
      return isValid;
    };

    this.hasChanges = function () {
      return this.changes().length > 0;
    };

  });

  return UserLimitsModel;
});
