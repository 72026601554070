defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/ProfileOverview', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InfoRow',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Svg',
], function (m, Utils, StepHeader, InfoRow, Svg) {

  var ProfileOverview = function (controller) {
    var initialValues = controller.model().initialValues;
    const userModel = controller.model().fields;
    const playcardModel = controller.playcardModel();

    return m('div', {
      class: 'retail-page__content retail-page__no-progress'
    }, m('.retail-page__row has-rows-2', [
      m('div', {
        class: 'retail-page__column retail-page__column--1'
      }, [
        StepHeader({
          title: controller.d('ProfileOverview/PageTitle')
        }),
        m('div', {
          class: 'list list--no-bottom-margin'
        }, [
          InfoRow({
            testId: 'retail_profile--name',
            labelText: controller.d('ProfileOverview/LabelName'),
            value: initialValues.firstName + ' ' + initialValues.lastName,
            horizontal: true,
          }),
          InfoRow({
            testId: 'retail_profile--email',
            labelText: controller.d('ProfileOverview/LabelEmail'),
            value: initialValues.email,
            horizontal: true,
            onclick: function () {
              controller.goToRoute('/email');
            }
          }),
          InfoRow({
            testId: 'retail_profile--phone',
            labelText: controller.d('ProfileOverview/LabelPhone'),
            value: initialValues.phone,
            horizontal: true,
            onclick: function () {
              controller.goToRoute('/phone');
            }
          }),
          InfoRow({
            testId: 'retail_profile--account',
            labelText: controller.d('ProfileOverview/LabelAccount'),
            value: initialValues.bankAccount,
            class: 'retail-info-row__large-icon',
            horizontal: true,
            onclick: function () {
              controller.goToRoute('/payoutMethod');
            },
            clickableIcon: 'round-info'
          })
        ]),
        m('button', {
          'data-uitest-id': 'retail_profile--upload',
          class: 'retail-file-upload__button',
          type: 'button',
          horizontal: true,
          onclick: function () {
            controller.goToRoute('/uploadDocuments');
          }
        }, [
          m('span', [
            Svg('plus', { class: 'retail-file-upload__add-button--svg' }),
          ]),
          m('span', { class: 'retail-file-upload__button-text' }, 'Tilføj dokumentation')
        ]),
        StepHeader({
          title: controller.d('ProfileOverview/LoginTitle')
        }),
        m('.list', [
          controller.model().usernameIsSet() ?
            m('', [
              InfoRow({
                testId: 'retail_profile--username',
                labelText: controller.d('ProfileOverview/LabelUsername'),
                value: initialValues.username,
                horizontal: true,
              }),
              InfoRow({
                testId: 'retail_profile--password',
                labelText: controller.d('ProfileOverview/LabelPassword'),
                value: '•••••••',
                horizontal: true,
                onclick: function () {
                  controller.goToRoute('/password');
                }
              })
            ])
            : InfoRow({
              testId: 'retail_profile--create-username',
              labelText: controller.d('ProfileOverview/LabelUsername'),
              value: 'Opret brugernavn',
              horizontal: true,
              onclick: function () {
                Utils.ensightenEvent('opret', 'brugernavn');
                controller.goToRoute('/username');
              }
            })
        ]),
        userModel.isActive() ?
          [
            StepHeader({
              title: controller.d('ProfileOverview/PlaycardTitle')
            }),
            m('.list', [
              !controller.hasOfflineExclusion() ?
                InfoRow({
                  testId: 'retail_profile--set-pincode',
                  labelText: controller.d('ProfileOverview/LinkSetPincode'),
                  horizontal: true,
                  onclick: function () {
                    if (!userModel.pincode()) {
                      controller.goToRoute('/edit-pincode');
                    } else {
                      controller.goToRoute('/pincode');
                    }
                  }
                }) : null,
              playcardModel.playcardStatus() === 'order' ?
                InfoRow({
                  testId: 'retail_profile--order-playcard',
                  labelText: controller.d('ProfileOverview/LinkOrderPlaycard'),
                  horizontal: true,
                  onclick: function () {
                    controller.goToRoute('/manage');
                  }
                }) : null,
              controller.hasOfflineExclusion() ?
                InfoRow({
                  testId: 'retail_profile--reactivate-playcard',
                  labelText: controller.d('ProfileOverview/LinkReactivatePlaycard'),
                  horizontal: true,
                  onclick: function () {
                    controller.goToRoute('/reactivate');
                  }
                }) : null,
              playcardModel.playcardStatus() !== 'order' ?
                InfoRow({
                  testId: 'retail_profile--block-playcard',
                  labelText: controller.d('ProfileOverview/LinkBlockPlaycard'),
                  subLabelText: controller.d('ProfileOverview/LinkBlockPlaycardSubText'),
                  horizontal: true,
                  onclick: function () {
                    controller.goToRoute('/manage');
                  }
                }) : null,
              playcardModel.isPhysicalCard() && playcardModel.canReorder() ?
                InfoRow({
                  testId: 'retail_profile--reorder-playcard',
                  labelText: controller.d('ProfileOverview/LinkReorderPlaycard'),
                  subLabelText: controller.d('ProfileOverview/LinkReorderPlaycardSubText'),
                  horizontal: true,
                  onclick: function () {
                    controller.goToRoute('/reorder');
                  }
                }) : null,
            ])
          ] : null,
        StepHeader({
          title: controller.d('ProfileOverview/RestrictAccountTitle')
        }),
        m('.list', [
          InfoRow({
            testId: 'retail_profile--set-limits',
            labelText: controller.d('ProfileOverview/LinkSetLimits'),
            horizontal: true,
            onclick: function () {
              controller.goToRoute('/offline-limits');
            }
          }),
          InfoRow({
            testId: 'retail_profile--restrict-account',
            labelText: controller.d('ProfileOverview/LinkRestrictAccount'),
            horizontal: true,
            onclick: function () {
              controller.goToRoute('/restrict-account');
            }
          }),
          InfoRow({
            testId: 'retail_profile--close-account',
            labelText: controller.d('ProfileOverview/LinkCloseAccount'),
            horizontal: true,
            onclick: function () {
              controller.goToRoute('/close-account');
            }
          })
        ]),
        StepHeader({
          title: controller.d('ProfileOverview/CommunicationTitle')
        }),
        m('.list', [
          InfoRow({
            testId: 'retail_profile--news-permission',
            labelText: controller.d('ProfileOverview/LabelNewsletterPermissions'),
            horizontal: true,
            onclick: function () {
              controller.goToRoute('/emailPermissions');
            }
          }),
          InfoRow({
            testId: 'retail_profile--winnings-permission',
            labelText: controller.d('ProfileOverview/LabelWinnerMessagePermissions'),
            horizontal: true,
            onclick: function () {
              controller.goToRoute('/emailWinningsPermissions');
            }
          }),
        ]),
      ])
    ])
    );
  };

  return ProfileOverview;

});
