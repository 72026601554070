defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/OrderPlaycardConfirm', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Svg',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/BackButton',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Button',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/StepHeader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InfoRow'
], function (m, Utils, Svg, BackButton, Button, StepHeader, InfoRow) {

  var StepOrderPlaycardConfirm = function (controller) {
    var userModel = controller.model().fields;
    var dictionaryKey = 'OrderPlaycard/';

    if (Utils.isRetailIpad()) {
      dictionaryKey = 'RetailIpadPlaycard/';
    }

    return [
      !controller.hideBackButton() ?
        m('.retail-page__button-container', BackButton()) : null,
      m('.retail-page__content',
        m('.retail-page__row has-rows-2', [
          m('div', {
            class: 'retail-page__column retail-page__column--1'
          }, '', Utils.isRetailIpad() ? Svg('spil-id-forside', {
            class: 'retail-visual-playcard__icon'
          }) : null),
          m('div', {
            class: 'retail-page__column retail-page__column--2'
          }, [
            StepHeader({
              title: controller.d(dictionaryKey + 'PageTitle'),
              abstract: controller.d(dictionaryKey + 'PageDescription')
            }),
            m('.retail-rich-text', [
              m('h3', { class: 'retail-rich-text__title' }, controller.d('OrderPlaycard/PincodeChoiceLabel')),
              InfoRow({
                labelText: controller.d('OrderPlaycard/PincodeLabel'),
                value: userModel.pincode(),
                horizontal: true,
                class: 'retail-info-row--not-bold'
              }),
              m('.retail-rich-text__title', controller.d('OrderPlaycard/ShipPlaycardTitle')),
              m('div', userModel.firstName() + ' ' + userModel.lastName()),
              m('div', userModel.street() + ' ' + (userModel.houseNumber() ? userModel.houseNumber() : '') + (userModel.level() ? ', ' + userModel.level() : '') + (userModel.door() ? ' ' + userModel.door() : '')),
              m('div', userModel.zipCode() + ' ' + userModel.city()),
              m('.notice', controller.d('OrderPlaycard/AddressFromCPRRegistryNotice'))
            ]),
            m('.retail-button-container', [
              Button({
                class: 'retail-button--white',
                buttonText: controller.d(dictionaryKey + 'CancelButton'),
                onclick: function () {
                  var returnUrl = Utils.getReturnUrl();
                  if (returnUrl) {
                    window.location.href = returnUrl;
                  } else {
                    controller.goToRoute('/');
                  }
                }
              }),
              Button({
                isLoading: controller.isSubmitting(),
                buttonText: controller.d(dictionaryKey + 'SubmitButton'),
                onclick: controller.confirmPlaycardOrder
              })
            ])
          ])
        ])
      )
    ];
  };

  return StepOrderPlaycardConfirm;

});
