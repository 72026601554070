defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Models/PlaycardModel', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Mithril/Scripts/Core/Model'
], function (m, Model) {
  'use strict';

  var PlaycardModel = Model('PlaycardModel', function (content) {
    content = content || {};

    this.cardNumber = m.prop(content.CardNumber || null);
    this.expirationDate = m.prop(content.ExpirationDate || null);
    this.isPhysicalCard = m.prop(content.IsPhysicalCard || false);
    this.payoutAccount = m.prop(content.PaymentAccount || 'NemKonto');
    this.canReorder = m.prop(content.CanReorder || !content.IsPhysicalCard || false);
    this.playcardStatus = m.prop('order'); // ['order', 'block', 'unblock']
    this.digitalPlaycardImage = m.prop(null);
    this.digitalPlaycardNumber = m.prop(null);

    this.orderWithSamePincode = m.prop(false);
    this.orderWithNewPincode = m.prop(false);

    this.setPlaycardStatus = function (content) {
      if (content.ShowOrderPlayCardButton) {
        this.playcardStatus('order');
      } else {
        if (content.OnHold) {
          this.playcardStatus('unblock');
        } else {
          this.playcardStatus('block');
        }
      }
    }.bind(this);

    this.update = function (content) {
      this.cardNumber(content.CardNumber || null);
      this.expirationDate(content.ExpirationDate || null);
      this.isPhysicalCard(content.IsPhysicalCard || false);
      this.payoutAccount(content.PaymentAccount || 'NemKonto');
      this.canReorder(content.CanReorder || false);
      this.setPlaycardStatus(content);
    }.bind(this);
  });

  return PlaycardModel;
});
