defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Components/RetailProfile', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'Shared/Framework/Mithril/Scripts/Helpers/Storage',
  'Shared/Framework/Ensighten/Scripts/Ensighten',
  'Shared/Framework/Mithril/Scripts/Helpers/QuickLink',
  'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
  'DanskeSpil/Domain/Authentification/Scripts/LoginController',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Dictionary',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Api',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/UserModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/PurposeModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/FileUploadModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/PlaycardModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Models/LimitModel',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Loader',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/ErrorOverlay',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/ProfileOverview',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Email',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Phone',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Username',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Password',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/ViewPINCode',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/PINCode',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/PayoutMethod',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/UploadDocuments',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/EmailPermissions',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/EmailWinningsPermissions',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Receipt',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/CloseAccount',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/ConfirmAccountRestriction',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/RestrictAccount',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/Limits',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/ConfirmWagerLimits',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/ConfirmCallback',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/OrderPlaycardIntro',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/OrderPlaycardPincode',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/OrderPlaycardConfirm',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/ReactivatePlaycard',
],
function (Component, Storage, Ensighten, QuickLink, FrameworkOverlay, LoginController, Dictionary, Utils, RetailApi, UserModel, PurposeModel, FileUploadModel, PlaycardModel, LimitModel, Loader, ErrorOverlay, ProfileOverview, Email, Phone, Username, Password, ViewPINCode, PINCode, PayoutMethod, UploadDocuments, EmailPermissions, EmailWinningsPermissions, Receipt, CloseAccount, ConfirmAccountRestriction, RestrictAccount, Limits, ConfirmWagerLimits, ConfirmCallback, OrderPlaycardIntro, OrderPlaycardPincode, OrderPlaycardConfirm, ReactivatePlaycard) {
  // Declare steps in signup flow
  var routeConfiguration = [
    { route: '/', template: ProfileOverview },
    { route: '/email', fields: ['email'], dictionaryKey: 'Receipt/Email', template: Email },
    { route: '/phone', fields: ['phone'], dictionaryKey: 'Receipt/Phone', template: Phone },
    { route: '/password', fields: ['password', 'repeatPassword'], dictionaryKey: 'Receipt/Password', template: Password },
    { route: '/pincode', template: ViewPINCode },
    { route: '/edit-pincode', fields: ['pincode'], dictionaryKey: 'SetPINCode', template: PINCode, },
    { route: '/repeat-pincode', fields: ['pincode', 'pincodeConfirm'], dictionaryKey: 'ConfirmPINCode', template: PINCode },
    { route: '/username', fields: ['username'], dictionaryKey: 'Receipt/Username', template: Username },
    { route: '/payoutMethod', template: PayoutMethod },
    { route: '/uploadDocuments', template: UploadDocuments },
    { route: '/emailPermissions', dictionaryKey: 'Receipt/EmailPermissions', template: EmailPermissions },
    { route: '/emailWinningsPermissions', dictionaryKey: 'Receipt/EmailWinningsPermissions', template: EmailWinningsPermissions },
    { route: '/receipt', template: Receipt },
    { route: '/close-account', template: CloseAccount },
    { route: '/restrict-account', template: RestrictAccount },
    { route: '/restrict-account/confirm', template: ConfirmAccountRestriction },
    { route: '/offline-limits', template: Limits, dictionaryKey: 'OfflineLimits' },
    { route: '/limits', template: Limits, dictionaryKey: 'WagerLimits' },
    { route: '/limits/confirm', template: ConfirmWagerLimits },
    { route: '/callback/confirm', template: ConfirmCallback },
    { route: '/manage', template: OrderPlaycardIntro },
    { route: '/order/pincode-option', template: OrderPlaycardPincode },
    { route: '/order/confirm', template: OrderPlaycardConfirm },
    { route: '/reorder', template: OrderPlaycardIntro },
    { route: '/reactivate', template: ReactivatePlaycard },
  ];

  // Get the current step from route on page load
  var currentStep = routeConfiguration.filter(function (step) {
    var locationHash = window.location.hash.toLowerCase();
    var hash = locationHash.replace('#retail-profile=', '');
    return hash === step.route.toLowerCase();
  });

  // It empty get the current step from the routeConfiguration array (setting [0] in the controller)
  if (!currentStep.length) {
    currentStep = routeConfiguration[0];
  } else {
    currentStep = currentStep[0];
  }

  var RofusLinks = new QuickLink('/Rofus');

  // Component:
  Component('retail-profile', [Dictionary, RofusLinks], function (m, route, settings) {

    var preSelectedPurposes = Utils.getQueryParam('purpose') ? Utils.getQueryParam('purpose').split(',') : [];

    var root = {
      controller: function () {
        this.d = Dictionary.get.bind({ prefix: 'RetailProfile/' });
        this.isLoading = m.prop(true);
        this.buttonIsDisabled = m.prop(true);
        this.isSubmitting = m.prop(false);
        this.uploadSettings = settings;
        this.hasLoadedDocuments = m.prop(false);
        this.hasPreloadFiles = m.prop(false);
        this.noFilesSelected = m.prop(false);
        this.noLimitsChanged = m.prop(false);
        this.noPeriodSelected = m.prop(false);
        this.modalQRCode = m.prop(null);
        this.playIdRenewed = m.prop(false);
        this.purposes = m.prop([]);
        this.model = m.prop(new UserModel());
        this.playcardModel = m.prop(new PlaycardModel());
        this.modalError = m.prop(null);
        this.routeConfiguration = routeConfiguration;
        this.currentStep = m.prop(currentStep);
        this.hideBackButton = m.prop((Utils.getQueryParam('dsApplicationId') || window.dsApplicationConfig) || false);
        this.noPaddingTop = m.prop((Utils.getQueryParam('dsApplicationId') || window.dsApplicationConfig) || false);
        this.rofusLink = RofusLinks.get;
        this.postfix = ' kr.';
        this.fieldInFocus = m.prop();
        this.backAllowed = m.prop(true);
        this.playcardRenewed = m.prop(false);
        this.createUsernameFlow = m.prop(false);
        this.maskPincodeInput = m.prop(false);
        this.homeLink = m.prop(settings.homeLink?.Url || null);
        this.mobilePayLink = m.prop(settings.mobilePayLink?.Url || null);
        this.mobilePayQRCode = m.prop(null);
        this.zoomQR = m.prop(false);
        this.hasOfflineExclusion = m.prop(false);
        this.reactivatePlaycardFlow = m.prop(false);
        this.route = route;
        this.receiptContent = m.prop({
          title: this.d('Receipt/GenericTitle'),
          icon: 'circle_checkmark',
          callback: function () {
            var returnUrl = Utils.getQueryParam('returnUrl');
            if (returnUrl) {
              if (this.playcardRenewed()) {
                window.location.href = Utils.appendParameter('playIdRenewed', 'true', returnUrl);
              } else {
                window.location.href = returnUrl;
              }
            } else {
              this.createUsernameFlow(false);
              this.reactivatePlaycardFlow(false);
              route('/')();
            }
          }.bind(this),
          init: function () {
            Utils.pushVirtualPage('/', (window.location.pathname.replace(/-/g, '_') + '/'), '/');
          },
          timeout: 1000
        });
        this.retailEmailPermissionText = m.prop(settings.retailPermissionText || '');
        this.onlineEmailPermissionText = m.prop(settings.onlinePermissionText || '');
        this.calendarStartDate = m.prop(new Date());
        this.calendarStartDate().setDate(this.calendarStartDate().getDate() + 30);
        this.calendarEndDate = m.prop(new Date());
        this.calendarEndDate().setDate(this.calendarEndDate().getDate() + 364);

        this.goToRoute = function (routePath, persistValues) {
          var step = this.routeConfiguration.filter(function (step) {
            return step.route.toLowerCase() === routePath.toLowerCase();
          })[0];

          this.currentStep(step);

          if (!persistValues) {
            // Make sure that the model is reset (with the initial values) when going back to the edit view
            Object.keys(this.model().initialValues).forEach(function (fieldKey) {
              var originalValue = this.model().initialValues[fieldKey];
              this.model().fields[fieldKey](originalValue);
              this.model().errors[fieldKey](null);
            }.bind(this));
          }

          if (routePath === '/limits' || routePath === '/offline-limits') {
            this.model().userLimits().clearErrors();
            this.model().userLimits().limits().map(function (limit) {
              limit.reset();
            });
          }

          Utils.pushVirtualPage(routePath, (window.location.pathname.replace(/-/g, '_') + routePath), routePath);
          route(routePath)();
          window.scroll(0, 0);
        }.bind(this);

        this.restrictAccountSubmit = function (type) { // can be 'close' or undefined (undefined=restrict)

          if (type === 'close') {
            this.model().fields.restrictType('close');
          } else {
            if (this.model().fields.restrictType()) {
              this.noPeriodSelected(false);
            } else {
              this.noPeriodSelected(true);
            }
          }

          if (this.model().fields.restrictType() !== '24hour') {
            // Restrict or close
            Storage.set('restrictType', JSON.stringify(this.model().fields.restrictType()));
            Storage.set('restrictDate', JSON.stringify(this.model().fields.restrictDate().toString()));

            var variant = this.model().fields.isRetailAccount() ? 'Retail' : 'Default';

            if (this.model().fields.restrictType() === 'close') {
              variant += 'CloseAccount';
            } else if (this.model().fields.restrictType() === 'unlimited') {
              variant += 'LockAccount';
            } else {
              variant += 'RestrictAccount';
            }

            var returnUrl = window.location.href.split('#')[0] + '#retail-profile=/callback/confirm';
            var newUrl = DS.Config.CONTEXTPREFIX + '/scapi/danskespil/playeraccountmanagement/goto-identity-confirm';
            newUrl = Utils.appendParameter('variant', variant, newUrl);
            newUrl = Utils.appendParameter('returnUrl', encodeURIComponent(returnUrl), newUrl);
            newUrl = Utils.appendParameter('cancelUrl', encodeURIComponent(window.location.href), newUrl);
            window.location.href = newUrl;

          } else {
            // restrictType = '24hour', means only restrict
            this.route('/restrict-account/confirm')();
          }
        };

        this.goToConfirmLimit = function () {
          var isValid = this.validateLimit();
          var hasChanges = this.model().userLimits().hasChanges();
          this.noLimitsChanged(!hasChanges && isValid);
          if (isValid && hasChanges) {
            this.goToRoute('/limits/confirm');
          }
        }.bind(this);

        this.validateLimit = function () {
          this.model().userLimits().clearErrors(); // Lets cleanup old errors
          return this.model().userLimits().validate();
        }.bind(this);

        this.goToUploadDocumentation = function () {
          Utils.pushVirtualPage('/uploadDocuments', (window.location.pathname.replace(/-/g, '_') + '/uploadDocuments'), '/uploadDocuments');
          route('/uploadDocuments')();
        };

        this.backLinkCallback = function () {
          this.goToRoute('/');
        }.bind(this);

        this.updatePermissions = function (type) {
          var data = {};
          var fields = this.model().fields;

          if (type === 'winner') {
            data.EmailWinnerNotificationPermission = fields.emailWinningPermission();
            data.SmsWinnerNotificationPermission = fields.smsWinningPermission();
          } else if (type === 'marketing') {
            data.EmailPermission = fields.emailPermission();
            data.RetailPermission = fields.marketingTerms();
          }

          data.referenceId = 'RETAIL_PROFILE_PAGE';

          RetailApi.setNotificationPermissions(data).then(function (response) {
            if (response.status === 'success') {
              var receiptDictionaryKey = this.currentStep().dictionaryKey;
              this.receiptContent().title = this.d(receiptDictionaryKey + 'Title');
              this.model().update({
                emailWinningPermission: fields.emailWinningPermission(),
                smsWinningPermission: fields.smsWinningPermission(),
                emailPermission: fields.emailPermission(),
                marketingTerms: fields.marketingTerms()
              });

              if (this.model().initialValues.emailWinningPermission !== fields.emailWinningPermission()) {
                Utils.ensightenEvent('permission_' + fields.emailWinningPermission(), 'emailWinningPermission');
              }

              if (this.model().initialValues.smsWinningPermission !== fields.smsWinningPermission()) {
                Utils.ensightenEvent('permission_' + fields.smsWinningPermission(), 'smsWinningPermission');
              }

              if (this.model().initialValues.emailPermission !== fields.emailPermission()) {
                Utils.ensightenEvent('permission_' + fields.emailPermission(), 'emailPermission');
              }

              if (this.model().initialValues.marketingTerms !== fields.marketingTerms()) {
                Utils.ensightenEvent('permission_' + fields.marketingTerms(), 'marketingTerms');
              }

              Utils.pushVirtualPage('/receipt', (window.location.pathname.replace(/-/g, '_') + '/receipt'), '/receipt');
              route('/receipt')();
            }
          }.bind(this), function (error) {
            this.showErrorModal(error);
          }.bind(this)).then(function () {
            this.isLoading(false);
            this.buttonIsDisabled(true);
            m.redraw();
          }.bind(this));
        }.bind(this);

        this.showErrorModal = (error) => {
          var confirm = null;

          if (error.statusCode === 401) {
            confirm = function () {
              window.location.href = Utils.appendParameter('loginSuccessUrl', encodeURIComponent(window.location.href), settings.loginLink.Url);
            };
          }

          this.modalError(new FrameworkOverlay(ErrorOverlay({
            title: this.d('ErrorModal/Title'),
            body: error.message,
            confirm: confirm,
            dismisable: false,
            confirmButtonClass: settings.loginLink.CssClass || null,
            confirmButton: confirm ? settings.loginLink.Text : null
          }))).show();
        };

        this.verifyUsername = function () {
          if (!this.model().fields.username()) {
            Utils.pushVirtualPage('/username-missing', (window.location.pathname.replace(/-/g, '_') + '/username'), '/username-missing');
            route('/username')();
          }
        }.bind(this);

        this.createUsername = function () {
          var fields = this.currentStep().fields;
          Utils.extendFields(fields, ['password', 'repeatPassword']);
          this.createUsernameFlow(true);
          Utils.pushVirtualPage('/password', (window.location.pathname.replace(/-/g, '_') + '/password'), '/password');
          route('/password')();
        }.bind(this);

        this.confirmLimit = function () {
          var data = this.model().userLimits().mapToData();
          if (!this.validateLimit()) {
            return;
          }

          RetailApi.setWagerLimits(data).then(function (response) {
            Utils.trackLimitChanges('spilgraense', this.model().userLimits().limits());
            var limits = response.limits.map(function (limit) {
              return new LimitModel(limit);
            });

            this.model().userLimits().limits(limits);
            if (Utils.getQueryParam('returnUrl')) {
              window.location = Utils.getQueryParam('returnUrl');
            } else {
              Utils.pushVirtualPage('/', (window.location.pathname.replace(/-/g, '_') + '/'), '/');
              route('/')();
            }
          }.bind(this));
        }.bind(this);

        this.mergeFieldsToSingleModelField = (newFieldName, fieldNames = []) => {
          const fields = fieldNames.map((fieldName) => {
            return this.model().fields[fieldName]();
          });

          this.model().fields[newFieldName](fields.join(''));
        };

        this.submit = function () {
          var fields = this.currentStep().fields;
          var hasErrors = this.model().validate(fields);

          if (this.currentStep().route === '/password' && !this.model().fields.nemIdSignedIn()) {
            Utils.extendFields(fields, ['currentPassword']);
          } else if (this.currentStep().route === '/password' && this.createUsernameFlow()) {
            Utils.extendFields(fields, ['username']);
          }

          if (hasErrors) return;

          if (this.currentStep().route === '/edit-pincode') {
            if (this.model().fields.pincode().length !== 4) {
              return;
            }

            this.goToRoute('/repeat-pincode', true);
            return;
          }

          if (currentStep.optionalFields) {
            fields = currentStep.fields.concat(currentStep.optionalFields);
          }

          var data = Utils.mapModelToApiData(fields, this.model().fields, this.model().initialValues);
          this.isLoading(true);

          RetailApi.updateProfile(data).then(function (response) {
            if (!response.data.success) {
              this.model().setErrors(response.data);

              if (this.createUsernameFlow() && this.model().errors.username()) {
                Utils.pushVirtualPage('/username', (window.location.pathname.replace(/-/g, '_') + '/username'), '/username');
                route('/username')();
              }

              if (this.model().errors.pincode()) {
                ['pincode', 'pincodeConfirm', 'pin1', 'pin2', 'pin3', 'pin4', 'pin1Confirm', 'pin2Confirm', 'pin3Confirm', 'pin4Confirm'].forEach((fieldKey) => {
                  this.model().fields[fieldKey](null);
                });
                this.goToRoute('/edit-pincode', true);
              }

              return;
            }

            this.model().update(response.data.profile); // Make sure our frontend UserModel is in sync with backend

            if (this.playcardModel().orderWithNewPincode()) {
              this.goToRoute('/order/confirm');
              return;
            }

            if (this.createUsernameFlow()) {
              this.receiptContent().title = this.d('Receipt/UsernameTitle');
            } else if (this.reactivatePlaycardFlow()) {
              this.receiptContent().timeout = null;
              this.receiptContent().buttonText = this.d('Receipt/ReactivatePlaycardButtonText');
            } else if (this.currentStep().route === '/repeat-pincode') {
              this.receiptContent().title = this.d('Receipt/PINCodeTitle');
              this.receiptContent().buttonText = this.d('Receipt/PINCodeButtonText');
              this.receiptContent().timeout = null;
            } else {
              var receiptDictionaryKey = this.currentStep().dictionaryKey;
              this.receiptContent().title = this.d(receiptDictionaryKey + 'Title');
            }

            Utils.pushVirtualPage('/receipt', (window.location.pathname.replace(/-/g, '_') + '/receipt'), '/receipt');
            route('/receipt')();
          }.bind(this), function (error) {
            this.showErrorModal(error);
          }.bind(this)).then(function () {
            this.isLoading(false);
            this.buttonIsDisabled(true);
            m.redraw();
          }.bind(this));
        }.bind(this);

        this.setFieldFocus = function (e) {
          if (typeof this.fieldInFocus !== 'function') {
            return;
          }

          // If function is called with event from onfocus handler, set field to have focus.
          // If called without event, then set first input field to have focus
          var fieldName = e && e.target ? e.target.name : null;

          if (fieldName) {
            this.fieldInFocus(fieldName);
          }
        }.bind(this);

        this.validateField = function (ev, validateHandler) {
          if (ev.key === 'Tab' && !ev.target.value.length) return;

          if (typeof this.fieldInFocus === 'function') {
            this.fieldInFocus(null);
          }

          var fieldName = ev.target.name;
          this.model().validate([fieldName]);

          var hasErrors = this.model().validate(this.currentStep().fields, false);
          this.buttonIsDisabled(hasErrors); // Disable button if fields has errors

          if (!hasErrors && typeof validateHandler === 'function') {
            validateHandler();
          }
        }.bind(this);

        this.mergeAndValidateField = (ev, validateHandler) => {
          if (this.currentStep().route === '/edit-pincode') {
            this.mergeFieldsToSingleModelField('pincode', ['pin1', 'pin2', 'pin3', 'pin4']);
          }

          if (this.currentStep().route === '/repeat-pincode') {
            this.mergeFieldsToSingleModelField('pincodeConfirm', ['pin1Confirm', 'pin2Confirm', 'pin3Confirm', 'pin4Confirm']);
          }

          this.validateField(ev, validateHandler);
        };

        var getPermissionsSettings = function () {
          return RetailApi.getNotificationPermissions().then(function (response) {
            this.model().update(response);
          }.bind(this));
        }.bind(this);

        var getOfflineLoginExclusion = () => {
          return RetailApi.getOfflineLoginExclusion().then((response) => {
            this.hasOfflineExclusion(response.data);
          });
        };

        var getMobileLinkQRCode = function () {
          return RetailApi.getMobileLinkQR().then(function (response) {
            this.mobilePayQRCode(response.data.svg.Content);
          }.bind(this));
        }.bind(this);

        var getPlaycard = function () {
          return RetailApi.getPlaycard().then(function (response) {
            this.playcardModel().update(response.data);
          }.bind(this), function (error) {
            this.showErrorModal(error);
          }.bind(this));
        }.bind(this);

        var getVisualPlayCard = function () {
          return RetailApi.getVisualPlayCard().then(function (response) {
            this.playcardModel().digitalPlaycardImage(response.data.svg.Content);
            this.playcardModel().digitalPlaycardNumber(response.data.number);
            m.redraw();
          }.bind(this), function (error) {
            this.showErrorModal(error);
          }.bind(this));
        }.bind(this);

        var getPurposes = function () {
          return RetailApi.getUploadFilePurposes('RetailProfile').then(function (response) {
            this.purposes(response.data.map(function (purpose) {
              var purposeModel = new PurposeModel(purpose, true);
              var foundMatch = preSelectedPurposes.some(function (preSelected) {
                return preSelected.toLowerCase() === (purposeModel.identifier().toLowerCase());
              });
              if (foundMatch) {
                this.model().files().push(new FileUploadModel(purposeModel, this.uploadSettings, this.d, false));
              }
              return purposeModel;
            }.bind(this)));
            m.redraw();
          }.bind(this));
        }.bind(this);

        var hasDocuments = function () {
          return RetailApi.hasDocuments().then(function (response) {
            this.model().hasDocuments(response.data.HasDocuments || false);
            m.redraw();
          }.bind(this));
        }.bind(this);

        var getPlayerLimits = function () {
          return RetailApi.getPlayerLimits().then(function (response) {
            var limits = response.limits.map(function (limit) {
              return new LimitModel(limit);
            });

            this.model().userLimits().sitecoreLimits([
              { type: 'Daily', value: settings.maxRetailDaily },
              { type: 'Weekly', value: settings.maxRetailWeekly },
              { type: 'Monthly', value: settings.maxRetailMonthly }
            ]);
            this.model().userLimits().limits(limits);
            this.model().userLimits().limitRequired(settings.LimitRequired);
            this.model().userLimits().limitMinimum(settings.LimitMinimum);
            m.redraw();
          }.bind(this), function (error) {
            this.modalError(new FrameworkOverlay(ErrorOverlay({
              body: error
            }))).show();
          }.bind(this));
        }.bind(this);

        this.addFile = function (file, index) {
          this.model().files()[index].file(file);
        }.bind(this);

        this.removeSelection = function (selection) {
          var removeIndex = this.model().files().indexOf(selection);
          this.model().files().splice(removeIndex, 1);
        }.bind(this);

        this.validateUpload = function () {
          this.model().files().forEach(function (file) {
            var hasErrors = file.file() && file.validate();
            this.buttonIsDisabled(hasErrors);
          }.bind(this));
        }.bind(this);

        this.upload = function () {
          var hasErrors = this.model().files().map(function (file) {
            return file.validate();
          }).some(function (error) {
            return error;
          });

          if (this.model().files().length === 0) {
            this.noFilesSelected(true);
            hasErrors = true;
          }

          if (hasErrors) {
            return;
          }

          var data = Utils.mapFilesToApiData(this.model);
          this.isLoading(true);

          RetailApi.uploadDocuments(data).then().then(function (response) {
            var data = response.data;
            if (!data.OperationSuccess) {
              this.showErrorModal(data);
              return;
            }

            this.model().files([]); // Lets reset all uploaded files.

            this.receiptContent({
              title: data.Headline,
              abstract: this.d('Receipt/UploadDocumentsAbstract'),
              icon: 'circle_checkmark',
              timeout: 3000,
              callback: function () {
                if (Utils.getQueryParam('returnUrl')) {
                  window.location.href = Utils.getQueryParam('returnUrl');
                } else {
                  window.location.href = window.location.origin + window.location.pathname;
                }
              },
            });

            Utils.pushVirtualPage('/receipt', (window.location.pathname.replace(/-/g, '_') + '/receipt'), '/receipt');
            route('/receipt')();
          }.bind(this), function (error) {
            this.showErrorModal(error);
          }.bind(this)).then(function () {
            this.isLoading(false);
            this.buttonIsDisabled(true);
          }.bind(this));
        }.bind(this);

        this.confirmPlaycardOrder = () => {
          if (this.playcardModel().isPhysicalCard() && this.playcardModel().canReorder()) {
            this.reOrderPlaycard();
          } else if (this.playcardModel().isPhysicalCard()) {
            this.reactivatePlaycard();
          } else {
            this.orderPlaycard();
          }
        };

        this.orderPlaycard = function () {
          this.isSubmitting(true);
          RetailApi.orderPlaycard().then(function (response) {
            if (response.status === 'success') {
              Utils.ensightenEvent('card_order', 'success');
              this.playcardModel().playcardStatus('block');
              this.receiptContent().title = response.data.Abstract;

              Utils.pushVirtualPage('/receipt', (window.location.pathname.replace(/-/g, '_') + '/receipt'), '/receipt');

              if (Utils.isRetailIpad()) {
                this.receiptContent({
                  title: this.d('Receipt/RetailIpadOrderPlaycardTitle'),
                  icon: 'circle_checkmark',
                  callback: function () {
                    var returnUrl = Utils.getReturnUrl();
                    window.location.href = returnUrl;
                  },
                  buttonText: this.d('Receipt/RetailIpadPlaycardButton'),
                  timeout: 3000
                });
              }

              route('/receipt')();
            } else {
              Utils.ensightenEvent('card_order', 'error');
              this.showErrorModal();
            }
          }.bind(this), function (error) {
            this.showErrorModal(error);
            Utils.ensightenEvent('card_order', 'error_' + error.message);
          }.bind(this)).then(function () {
            this.isSubmitting(false);
            m.redraw();
          }.bind(this));
        }.bind(this);

        this.reactivatePlaycard = (renewPlaycardId = false) => {
          this.playcardRenewed(renewPlaycardId);
          this.reactivatePlaycardFlow(true);

          RetailApi.reactivatePlaycard().then(() => {
            if (this.playcardModel().isPhysicalCard()) {
              this.receiptContent().title = this.d('Receipt/ReactivatePlaycardPhysicalCard');
            } else {
              this.receiptContent().title = this.d('Receipt/ReactivatePlaycard');
            }
            this.hasOfflineExclusion(false);

            Utils.pushVirtualPage('/receipt', (window.location.pathname.replace(/-/g, '_') + '/receipt'), '/receipt');
            route('/receipt')();
          }, (error) => {
            Utils.ensightenEvent('card_reactivate', 'error');
            this.showErrorModal(error);
          }).then(() => {
            this.isSubmitting(false);
            m.redraw();
          });
        };

        this.reactivatePlaycardWithNewPincode = () => {
          if (!this.hasOfflineExclusion()) {
            this.receiptContent().title = this.d('Receipt/ReactivatePlaycardWithNewPincode');
            Utils.pushVirtualPage('/pincode', (window.location.pathname.replace(/-/g, '_') + '/pincode'), '/pincode');
            this.goToRoute('/pincode');
            m.redraw();
          } else {
            RetailApi.removePincodeExclusion().then(() => {
              this.hasOfflineExclusion(false);
              this.reactivatePlaycardFlow(true);
              this.receiptContent().title = this.d('Receipt/ReactivatePlaycardWithNewPincode');
              Utils.pushVirtualPage('/pincode', (window.location.pathname.replace(/-/g, '_') + '/pincode'), '/pincode');
              this.goToRoute('/pincode');
            }, (error) => {
              Utils.ensightenEvent('could_not_remove_pincode_exclusion', 'error');
              this.showErrorModal(error);
            }).then(() => {
              m.redraw();
            });
          }
        };

        this.reOrderPlaycard = function () {
          this.isSubmitting(true);

          RetailApi.reOrderPlaycard().then(function (response) {
            if (response.status === 'success') {
              Utils.ensightenEvent('card_reorder', 'success');
              this.playcardModel().playcardStatus('block');
              this.receiptContent().title = response.data.Abstract;
              Utils.pushVirtualPage('/receipt', (window.location.pathname.replace(/-/g, '_') + '/receipt'), '/receipt');
              route('/receipt')();
            } else {
              Utils.ensightenEvent('card_reorder', 'error');
              this.showErrorModal();
            }
          }.bind(this), function (error) {
            Utils.ensightenEvent('card_reorder', 'error_' + error.message);
            this.showErrorModal(error);
          }.bind(this)).then(function () {
            this.isSubmitting(false);
            m.redraw();
          }.bind(this));
        }.bind(this);

        this.togglePlaycardStatus = function () {
          RetailApi.togglePlaycardStatus().then(function (response) {
            if (response.status.toLowerCase() === 'ok') {
              this.playcardModel().playcardStatus(response.data ? 'unblock' : 'block');
              var title = response.data ? 'BlockedPlaycardTitle' : 'UnblockedPlaycardTitle';
              this.receiptContent().title = this.d('Receipt/' + title);
              Utils.ensightenEvent('card_' + response.data ? 'unblock' : 'block', 'success');
              Utils.pushVirtualPage('/receipt', (window.location.pathname.replace(/-/g, '_') + '/receipt'), '/receipt');
              route('/receipt')();
              m.redraw();
            }
          }.bind(this), function (error) {
            Utils.ensightenEvent('card_block_unblock', 'error_' + error.message);
            this.showErrorModal(error);
          }.bind(this));
        }.bind(this);

        this.hasFilesUploaded = function () {
          return this.model().files().filter(function (file) {
            return file.file() !== null;
          }).length > 0;
        }.bind(this);

        this.addFileRequirement = function (identifiers) {
          if (this.hasPreloadFiles() || this.purposes().length === 0) {
            return;
          }
          identifiers = Array.isArray(identifiers) ? identifiers : (typeof identifiers === 'string' ? [identifiers] : []);

          identifiers.forEach(function (identifier) {
            this.purposes().forEach(function (purpose) {
              if (purpose.identifier() === identifier) {
                this.model().files().push(new FileUploadModel(purpose, this.uploadSettings, this.d, false));
              }
            }.bind(this));
          }.bind(this));

          this.hasPreloadFiles(true);
        }.bind(this);

        this.loadDocumentData = function () {
          this.hasLoadedDocuments(true);
          m.sync([getPurposes(), hasDocuments()]).then(function () {
            m.redraw();
          }.bind(this));
        }.bind(this);

        this.logout = function (disableCallback) {
          LoginController.logout({
            callback: function () {
              if (disableCallback) return;
              var redirect = Utils.getQueryParam('redirectUrl');
              window.location.href = redirect ? redirect : window.location.origin;
            },
            redirect: false
          });
        }.bind(this);

        this.closeAccount = function () {
          RetailApi.closeAccount().then(function (response) {
            if (response.status === 'success') {
              var appliedUntil = Utils.formatDateTime(new Date(response.data.AppliedUntil));
              var dictPath = Utils.getRestrictDictionaryReceiptKey(this.model().fields.restrictType(), this.model().fields.isRetailAccount());
              this.logout(true);
              this.receiptContent({
                title: this.d(dictPath + '/Title'),
                abstract: this.d(dictPath + '/Abstract', { TIME: appliedUntil }),
                buttonText: this.d('Receipt/ButtonText'),
                icon: 'circle_checkmark',
                timeout: null,
                callback: function () {
                  if (Utils.getQueryParam('returnUrl')) {
                    window.location = Utils.getQueryParam('returnUrl');
                  } else {
                    window.location.href = this.homeLink() || window.location.origin;
                  }
                }.bind(this)
              });

              var identityConfirmMethod = Utils.getParameter('identityConfirmMethod');
              Ensighten.pushGaEvent('selvudelukkelse', 'PAM', identityConfirmMethod + '_sikkert_spil');
              Ensighten.pushGaEvent('information_bekraeftet', 'PAM', 'luk_konto');

              Utils.pushVirtualPage('/receipt', (window.location.pathname.replace(/-/g, '_') + '/receipt'), '/receipt');
              route('/receipt')();
            } else {
              this.showErrorModal(response.errors[0]);
              Utils.ensightenEvent('account_close', 'error_' + response.errors[0]);
            }
          }.bind(this), function (error) {
            Utils.ensightenEvent('account_close', 'error_' + error.message);
            this.showErrorModal(error);
          }.bind(this));
        };

        this.lockAccount = function () {
          RetailApi.lockAccount().then(function (response) {
            if (response.status === 'success') {
              var appliedUntil = Utils.formatDateTime(new Date(response.data.AppliedUntil));
              var dictPath = this.model().fields.isRetailAccount() ? 'CloseAccount/Receipt' : 'CloseAccount/Receipt/OnlineAccount';
              this.logout(true);
              this.receiptContent({
                title: this.d(dictPath + '/Title'),
                abstract: this.d(dictPath + '/Abstract', { TIME: appliedUntil }),
                buttonText: this.d('Receipt/ButtonText'),
                icon: 'circle_checkmark',
                timeout: null,
                callback: function () {
                  if (Utils.getQueryParam('returnUrl')) {
                    window.location = Utils.getQueryParam('returnUrl');
                  } else {
                    window.location.href = this.homeLink() || window.location.origin;
                  }
                }.bind(this)
              });

              var identityConfirmMethod = Utils.getParameter('identityConfirmMethod');
              Ensighten.pushGaEvent('selvudelukkelse', 'PAM', identityConfirmMethod + '_sikkert_spil');
              Ensighten.pushGaEvent('information_bekraeftet', 'PAM', 'spaer_og_luk_konto');

              Utils.pushVirtualPage('/receipt', (window.location.pathname.replace(/-/g, '_') + '/receipt'), '/receipt');
              route('/receipt')();
            } else {
              this.showErrorModal(response.errors[0]);
              Utils.ensightenEvent('account_lock', 'error_' + response.errors[0]);
            }
          }.bind(this), function (error) {
            Utils.ensightenEvent('account_lock', 'error_' + error.message);
            this.showErrorModal(error);
          }.bind(this));
        };

        this.restrictAccount = function (date) {
          RetailApi.restrictAccount(date).then(function (response) {
            if (response.status === 'success') {
              var appliedUntil = Utils.formatDateTime(new Date(response.data.AppliedUntil));
              var dictPath = Utils.getRestrictDictionaryReceiptKey(this.model().fields.restrictType(), this.model().fields.isRetailAccount());
              this.logout(true);
              Utils.ensightenEvent('account_restrict', date ? '30-365 days' : '24 hours');
              this.receiptContent({
                title: this.d(dictPath + '/Title'),
                abstract: this.d(dictPath + '/Abstract', { TIME: appliedUntil }),
                buttonText: this.d('Receipt/ButtonText'),
                icon: 'circle_checkmark',
                timeout: null,
                callback: function () {
                  if (Utils.getQueryParam('returnUrl')) {
                    window.location.href = Utils.getQueryParam('returnUrl');
                  } else {
                    window.location.href = this.homeLink() || window.location.origin;
                  }
                }.bind(this)
              });

              var identityConfirmMethod = Utils.getParameter('identityConfirmMethod');
              Ensighten.pushGaEvent('selvudelukkelse', 'PAM', identityConfirmMethod + '_sikkert_spil');
              Ensighten.pushGaEvent('information_bekraeftet', 'PAM', 'spaer_konto');

              Utils.pushVirtualPage('/receipt', (window.location.pathname.replace(/-/g, '_') + '/receipt'), '/receipt');
              route('/receipt')();
            } else {
              Utils.ensightenEvent('account_restrict', 'error_' + response.errors[0]);
              this.showErrorModal(response.errors[0]);
            }
          }.bind(this), function (error) {
            Utils.ensightenEvent('account_close', 'error_' + error.message);
            this.showErrorModal(error);
          }.bind(this));
        };

        this.routeToPrevStep = () => {
          this.goToRoute('/');
        };

        this.confirmCallback = function () {
          var restrictType = JSON.parse(Storage.get('restrictType'));
          var restrictDate = JSON.parse(Storage.get('restrictDate'));

          if (restrictType) {
            this.model().fields.restrictType(restrictType);
          }
          if (restrictDate) {
            this.model().fields.restrictDate(new Date(restrictDate));
          }

          if (this.model().fields.restrictType() === 'close') { // '24hour', 'customDate', 'unlimited' or 'close'
            this.lockAccount();
          } else if (this.model().fields.restrictType() === 'unlimited') {
            this.closeAccount();
          } else {
            var date = this.model().fields.restrictDate();
            this.restrictAccount(date);
          }
        };

        RetailApi.getProfileWithBankInformation().then(function (response) {
          document.body.classList.add('is-logged-in');
          var model = response.data.profile || {};

          if (model.isPending.value) {
            Utils.pushVirtualPage('/uploadDocuments', (window.location.pathname.replace(/-/g, '_') + '/uploadDocuments'), '/uploadDocuments');
            route('/uploadDocuments')();
            this.isLoading(false);
            return;
          }
          this.model().update(model);

          // Only fetch additional information when we have a logged in user
          var requests = [getPlaycard(), getVisualPlayCard(), getPlayerLimits(), getPermissionsSettings(), getOfflineLoginExclusion()];
          if (settings.enableMobilePay) {
            requests.push(getMobileLinkQRCode());
          }

          m.sync(requests).then(function () {
            this.isLoading(false);
            m.redraw();

            if (window.dsApplicationConfig && ['SHAPE_RETAILACCOUNT_IPADNATIVE'].includes(window.dsApplicationConfig?.ApplicationId)) {
              this.maskPincodeInput(true);
            }

            if (this.currentStep().route === '/reactivate' && !this.hasOfflineExclusion()) {
              this.receiptContent().title = this.d('Receipt/ReactivatePlaycardAlreadyActivateTitle');
              this.receiptContent().buttonText = this.d('Receipt/ButtonText');
              this.receiptContent().timeout = null;
              Utils.pushVirtualPage('/receipt', (window.location.pathname.replace(/-/g, '_') + '/receipt'), '/receipt');
              route('/receipt')();
            }

            if (this.currentStep().route === '/callback/confirm') {
              this.confirmCallback();
            }
          }.bind(this));
        }.bind(this), function (error) {
          this.showErrorModal(error);
        }.bind(this));

        document.body.setAttribute('data-override-logout-return-url', settings.homeLink?.Url || `/${window.location.pathname.split('/')[1]}`);

        Utils.setupLoginLinkEventListeners();
      },
      view: function (controller) {
        if (controller.isLoading()) {
          return Loader();
        }

        return m('div', {
          class: 'retail-page retail-profile',
          'data-uitest-id': Utils.replaceSlashWithHyphens(route(), 'retail-page-profile')
        }, routeConfiguration.reduce(function (template, step) {
          if (route() === step.route) {
            template = step.template(controller);
          }

          return template;
        }, null));
      }
    };


    // Routes
    route('/', root);

    for (var index in routeConfiguration) {
      var step = routeConfiguration[index];

      route(step.route, root);
    }
  });

});
