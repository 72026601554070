defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Components/RetailGameHistoryPrize', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Dictionary',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Api',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Pages/GameHistoryPrize'
], function (m, Utils, Dictionary, RetailApi, GameHistoryPrize) {

  var GameHistoryPrizeComponent = {
    controller: function (args) {
      this.isLoading = m.prop(true);
      this.gameId = m.route.param('gameId');
      this.d = Dictionary.get.bind({ prefix: 'RetailGameHistory/' });
      this.game = m.prop({});
      this.settings = args.settings;
      this.dealerText = m.prop(this.settings.dealerText || '');
      this.customerServiceText = m.prop(this.settings.customerServiceText || '');
      this.showErrorModal = args.showErrorModal;
      this.isWebview = m.prop(window.dsApplicationConfig);
      this.hideBackButton = m.prop(false);

      this.redirectBack = function (e) {
        e.preventDefault();
        window.history.back();
      };

      RetailApi.getGameDetails(this.gameId).then(function (response) {
        document.body.classList.add('is-logged-in');
        var playerGame = response.data;

        this.game({
          id: playerGame.Id,
          hasPayout: playerGame.WinStatus === 'PAID' || playerGame.WinStatus === 'PENDING',
          winStatus: playerGame.WinStatus,
          type: playerGame.Type,
          createdAt: playerGame.CreatedAt,
          channel: playerGame.Channel,
          wager: playerGame.Wager,
          win: parseInt(playerGame.Win, 10)
        });

        Utils.pushVirtualPage('/prize/' + this.gameId, (window.location.pathname.replace(/-/g, '_') + '/prize/' + this.gameId), '/prize/' + this.gameId);
      }.bind(this), function (error) {
        this.showErrorModal(error);
      }.bind(this)).then(function () {
        this.isLoading(false);
        m.redraw();
      }.bind(this));
    },
    view: function (controller) {
      return GameHistoryPrize(controller);
    }
  };

  return GameHistoryPrizeComponent;

});
