defineDs('DanskeSpil/Domain/RetailAccount/Scripts/Templates/LimitField', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/InputField',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Link',
  'DanskeSpil/Domain/RetailAccount/Scripts/Templates/Svg',
  'DanskeSpil/Domain/RetailAccount/Scripts/Helpers/Utils'
], function (m, InputField, Link, Svg, Utils) {

  var LimitField = function (options) {
    var model = options.model;

    this.onLimitInput = function (event) {
      var newValue = event.target.value.replace(/\D/g, '');
      if (newValue.length > 0) {
        newValue = parseInt(newValue, 10);
        model.value(newValue);
        model.formatted(Utils.formatLimit(newValue));
      } else {
        model.value(null);
        model.formatted(null);
      }
    };

    this.onFocus = function () {
      var modelValue = model.value() ? Utils.formatLimit(model.value()) : '';
      model.formatted(modelValue);
    };

    this.onBlur = function () {
      if (model.value()) {
        model.formatted(Utils.formatCurrency(model.value()));
      } else {
        model.formatted(null);
      }
    };

    return m('.retail-limit-container', [
      m('div', {
        class: [
          'retail-limit-field',
          options.hasErrors ? 'has-errors' : null,
          options.model || options.placeholder ? 'has-value' : null
        ].join(' '),
        'data-state': model.state()
      }, [
        !options.alwaysVisible && model.state() === 'unset' ?
          m('.retail-limit__unset', [
            m('button', {
              type: 'button',
              class: 'retail-limit__button',
              'aria-label': 'add-' + model.type() + '-button',
              onclick: function (e) {
                e.preventDefault();
                model.state('edit');
                model.hasFocus(true);
              }
            }, [
              m('span', [
                Svg('plus', { class: 'retail-file-upload__add-button--svg' }),
              ]),
              m('span', { class: 'retail-file-upload__button-text' }, options.buttonText)
            ]),
          ]) :
          m('.retail-limit__defined', [
            m('.retail-limit__amount-wrapper', [
              InputField({
                model: model.formatted,
                oninput: this.onLimitInput,
                onfocus: this.onFocus,
                onblur: this.onBlur,
                onmouseleave: function () {
                  if (Utils.isMobile() || Utils.isTabletDevice()) {
                    // Fix iOS bug by triggering the onmouseleave event
                    document.activeElement.blur();
                  }
                },
                setcaret: model.formatted() && options.postfix ? model.formatted().length - options.postfix.length : 1, // Default to index one to almost make it pretty when amount is null.
                forceNumeric: true,
                name: model.type(),
                type: 'text',
                labelText: options.limitText,
                placeholder: options.placeholder
              }),
              options.cannotDelete ? m('.retail-limit__space-placeholder') : null,
              !options.cannotDelete && model.state() !== 'delete' ? m('button', {
                type: 'button',
                class: 'retail-limit__button',
                'aria-label': 'remove-' + model.type() + '-button',
                onclick: function (e) {
                  e.preventDefault();
                  if (model.state() === 'edit') {
                    model.state('unset');
                    model.value(null);
                    model.formatted(null);
                    model.hasErrors([]);
                  } else {
                    model.value(null);
                    model.formatted(null);

                    if (typeof options.confirm === 'function') {
                      options.confirm();
                    }
                  }
                }
              }, [
                m('span', [
                  Svg('trashbin', { class: 'retail-limit__icon' }),
                ])
              ]) : null
            ]),
            model.hasErrors().length > 0 ? m('.retail-limit__error-wrapper', [
              model.hasErrors().map(function (error) {
                return m('.retail-limit__error', error);
              })
            ]) : null,
            model.state() === 'delete' ? m('.retail-limit__delete-wrapper', [
              m('.retail-limit__delete', [
                m('span', options.stateText),
                Link({
                  text: options.cancelDelete,
                  onclick: function () {
                    model.state('revert');
                    if (typeof options.confirm === 'function') {
                      options.confirm();
                    }
                  }
                })
              ])
            ]) : null,
            model.state() !== 'edit' && model.plannedValue() ?
              m('.retail-limit__planned-wrapper', [
                m('.retail-limit__planned', [
                  m('span', options.stateText),
                  Link({
                    text: options.cancelChange,
                    onclick: function () {
                      model.state('revert');

                      if (typeof options.confirm === 'function') {
                        options.confirm();
                      }
                    }
                  })
                ])
              ]) : null
          ]),
        model.isOperator() ?
          m('.retail-limit__operator-wrapper', [
            m('.retail-limit__operator', m.trust(options.operatorText))
          ]) : null
      ]),
    ]);
  };

  return LimitField;

});
